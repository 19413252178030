//Actions
import { 
  setModalAssociatesTabbarReducer, 
  setModalMenuTabbarReducer,
  setOptionSelectedTabBarTabBarReducer
 } from "../../../../../storage/reducers/tabbar/tabbar.actions";

//Assets
import { Assets } from '../../../../../assets';

//Components
import MemberAssociatesComponent from "./components/member-associate/member-associates.component";
import MenuComponent  from "./components/menu/menu.component";

// Libraries
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

// Styles
import './main-tab-bar.component.scss'

const MainTabBarComponent = (props) => {
  
  const {
    //Actions
    setModalAssociatesTabbarReducer,
    setModalMenuTabbarReducer,
    setOptionSelectedTabBarTabBarReducer,
    //variables
    isMembersNetwork,
    optionSelectedTabBar,
    modalMenu
  } = props;
  
  useEffect(() => {
    if (optionSelectedTabBar) {
      setState({
        ...state,
        optionSelectedState: optionSelectedTabBar
      })
    }
    return () => {
      setState({})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionSelectedTabBar])

  const INITIAL_STATE = {
    optionSelectedState: null
  };

  const [state, setState] = useState(INITIAL_STATE);

  const { optionSelectedState } = state;

  let history = useNavigate();

  const location = useLocation();

  const [t] = useTranslation("translation")
    
  const selectSectButtonActive = (indexButtonActive) =>{
    setOptionSelectedTabBarTabBarReducer(indexButtonActive);
    handleClickOptionSelected(indexButtonActive)
  };

  const handleClickOptionSelected = (indexButtonActive) => {
    switch (indexButtonActive) {
      case 1:
        setModalMenuTabbarReducer(true);
        break;
      case 2:
        history("/contact-list")
        break;
      case 3:
        setModalAssociatesTabbarReducer(true)
        setModalMenuTabbarReducer(false)
        break;
      // TODO: The cases for the menu options "voting-(option 4)" and "events-(option 5)" are missing to be added to this "switch".
      default:
        break;
    }
  };

  return (
    <div className="tab-bar">
      <div className="tab-bar__container align-middle">
        <div className="tab-bar__button">
          <button 
            className={optionSelectedState === 1
            ? "grid-y align-middle tab-bar__button__container__active" 
            : "grid-y align-middle tab-bar__button__container"
            }
            onClick={() => selectSectButtonActive(1)}
            >
              <img
                src={optionSelectedState === 1 ?  Assets.SharedIcons.icon_menu_active :  Assets.SharedIcons.icon_menu }
                alt={optionSelectedState === 1 ? "icon_menu_active " : "icon_menu" }
                className={optionSelectedState === 1 ? "tab-bar__icon__img__active" : "tab-bar__icon__img"} 
              />
                <div className={optionSelectedState === 1 ? "tab-bar__icon__contain__active" : "tab-bar__icon__contain"}>
                  <span>{t("tabBar.menu.title")}</span>
                </div>
            <div className={optionSelectedState === 1 ? "tab-bar__icon__content" : ""}></div>
          </button>
        </div>
        <div className="tab-bar__button">
          <button 
            className={optionSelectedState === 2
            ? "grid-y align-middle tab-bar__button__container__active" : "grid-y align-middle tab-bar__button__container"
            }
            onClick={()=> {selectSectButtonActive(2)}}
            >
              <img
                src= {optionSelectedState === 2 ? Assets.SharedIcons.icon_contacts_active : Assets.SharedIcons.icon_contact}
                alt= {optionSelectedState === 2 ? "icon_contact_active" : "icon_contact"}
                className={optionSelectedState === 2 ? "tab-bar__icon__img__active" : "tab-bar__icon__img"}  
              />
                <div className={optionSelectedState === 2 ? "tab-bar__icon__contain__active" : "tab-bar__icon__contain"}>
                  <span>{t("tabBar.contacts.contacts")}</span>
                </div>
            <div className={optionSelectedState === 2 ? "tab-bar__icon__content" : ""}></div>
          </button>
        </div>
         <div className="tab-bar__button">
              <button
                className={(((location.pathname === '/network' && isMembersNetwork) || (location.pathname === '/network' && !isMembersNetwork) || optionSelectedState === 3) && !modalMenu)
                  ? "grid-y align-middle tab-bar__button__container tab-bar__icon__container tab-bar__button__container--mod" 
                  : "grid-y align-middle tab-bar__button__container"
                }
                onClick={() => selectSectButtonActive(3)}
              >
                <img 
                  src={(((location.pathname === '/network' && isMembersNetwork) || (location.pathname === '/network' && !isMembersNetwork) || optionSelectedState === 3) && !modalMenu)
                    ?  Assets.SharedIcons.icon_add_member_active 
                    : Assets.SharedIcons.icon_add_member
                  } 
                  alt={(((location.pathname === '/network' && isMembersNetwork) || (location.pathname === '/network' && !isMembersNetwork) || optionSelectedState === 3) && !modalMenu)
                    ? "icon_add_member_active" 
                    : "icon_add_member"
                  }
                  className={(((location.pathname === '/network' && isMembersNetwork) || (location.pathname === '/network' && !isMembersNetwork) || optionSelectedState === 3) && !modalMenu)
                    ? "tab-bar__icon__img-mod" 
                    : "tab-bar__icon__img"
                  } 
                />
              <div className={(((location.pathname === '/network' && isMembersNetwork) || (location.pathname === '/network' && !isMembersNetwork) || optionSelectedState === 3) && !modalMenu)
                    ? "tab-bar__icon__contain__active" 
                    : "tab-bar__icon__contain"
                  } 
              >
                <span >{t("tabBar.createNewUser")}</span>
              </div>
                <div className={(((location.pathname === '/network' && isMembersNetwork) || (location.pathname === '/network' && !isMembersNetwork) || optionSelectedState === 3) && !modalMenu) ? "tab-bar__icon__content" : ""}></div>
              </button>
            </div>

        <div className="tab-bar__button">
          <button
           className={optionSelectedState 
            ? "grid-y align-middle tab-bar__button__container__active": "grid-y align-middle tab-bar__button__container"
            }
            onClick={() => selectSectButtonActive(4)}
            >
              <img 
                src= {optionSelectedState === 4 ? Assets.SharedIcons.icon_voting_active : Assets.SharedIcons.icon_voting}
                alt={optionSelectedState === 4 ? "icon_voting_active" : "icon_voting"}
                className={optionSelectedState === 4 ? "tab-bar__icon__img__active" : "tab-bar__icon__img"}
              />
            <div className={optionSelectedState === 4 ? "tab-bar__icon__contain__active" : "tab-bar__icon__contain"}>
              <span>{t("tabBar.whereToVote")}</span>
            </div>
            <div className={optionSelectedState === 4 ? "tab-bar__icon__content" : ""}></div>
          </button>
        </div>
        <div className="tab-bar__button">
          <button 
          
          className={optionSelectedState === 5
            ? "grid-y align-middle tab-bar__button__container__active" : "grid-y align-middle tab-bar__button__container"
            }
            onClick={() => selectSectButtonActive(5)}
            >
              <img 
                src= {optionSelectedState === 5 ? Assets.SharedIcons.icon_events_active : Assets.SharedIcons.icon_events}
                alt={optionSelectedState === 5 ? 'icon_events_active' : 'icon_events'}
                className={optionSelectedState === 5 ? "tab-bar__icon__img__active" : "tab-bar__icon__img"}
              />
            <div className={optionSelectedState === 5 ? "tab-bar__icon__contain__active" : "tab-bar__icon__contain"}>
              <span>{t("tabBar.events")}</span>
            </div>
            <div className={optionSelectedState === 5 ? "tab-bar__icon__content" : ""}></div>
          </button>
        </div>
      </div>
      <div>
      {optionSelectedState === 1 && <MenuComponent />}
      {optionSelectedState === 3 && <MemberAssociatesComponent />}
      </div>
    </div>
  );
}

const mapStateToProps = ({ MyNetworkReducer,TabbarReducer, AppReducer }) => {
  const { isMembersNetwork } = MyNetworkReducer;
  const { optionSelectedTabBar, modalMenu} = TabbarReducer;
  const { isViewNavBar } = AppReducer;
  return {
    isMembersNetwork,
    optionSelectedTabBar,
    modalMenu,
    isViewNavBar
  };
};
const mapStateToPropsActions = {
  setModalAssociatesTabbarReducer,
  setModalMenuTabbarReducer,
  setOptionSelectedTabBarTabBarReducer
};
export default connect(mapStateToProps,mapStateToPropsActions)(MainTabBarComponent);
