// Constants
import MyNetworkErrorCodesConst from "./my-network-codes.const"

// Models
import BuildErrorInternalAppModel from "../../../../models/aggregates/build-error-internal-app/build-error-internal-app.model"

const MyNetworkErrorConst = {
    illegalMovementFromTopToBottomLevel: new BuildErrorInternalAppModel(MyNetworkErrorCodesConst.HRC_NWK_001, "errors.myNetwork.illegalMovementFromTopToBottomLevel"),
}

export default MyNetworkErrorConst