import * as myNetworkTypes from './my-network.types'

export const setIsMemberMyNetworkReducer = (value) => (dispatch) => {
    dispatch({ type: myNetworkTypes.IS_MEMBER_MY_NETWORK, payload: value });
};

export const setNetworkMyNetworkReducer = (value) => (dispatch) => {
    dispatch({ type: myNetworkTypes.SET_NETWORK, payload: value });
}

export const setShowViewProfileMyNetworkReducer = (value) => (dispatch) => {
    dispatch({ type: myNetworkTypes.SET_SHOW_VIEW_PROFILE, payload: value });
}

export const setShowHierarchyEditReducer = (value) => (dispatch) => {
    dispatch({ type: myNetworkTypes.SET_SHOW_HIERARCHY_EDIT, payload: value });
}

export const setMemberViewProfileMyNetworkReducer = (value) => (dispatch) => {
    dispatch({ type: myNetworkTypes.SET_MEMBER_VIEW_PROFILE, payload: value });
}

export const setMemberMoreMyNetworkReducer = (value) => (dispatch) => {
    dispatch({ type: myNetworkTypes.SET_MEMBER_MORE, payload: value });
}

export const setNetworkOverviewByUserMyNetworkReducer = (value) => (dispatch) => {
    dispatch({ type: myNetworkTypes.SET_NETWORK_OVERVIEW_BY_USER, payload: value });
}

export const setIsUnsubscribeMyAccountReducer = (value) => (dispatch) => {
    dispatch({ type: myNetworkTypes.SET_IS_UNSUBSCRIBE, payload: value });
}

export const setIsUnsubscribeMemberReducer = (value) => (dispatch) => {
    dispatch({ type: myNetworkTypes.SET_IS_UNSUBSCRIBE_MEMBER, payload: value });
}

export const setShowUnderlyingLevelsMyNetworkReducer = (value) => (dispatch) => {
    dispatch({ type: myNetworkTypes.SET_SHOW_UNDERLYING_LEVELS, payload: value });
}

export const setStateMyNetworkReducer = (prop, value) => (dispatch) => {
    dispatch({ type: myNetworkTypes.SET_STATE_MY_NETWORK, payload: { prop, value } });
}

export const resetStateMyNetworkReducer = () => async (dispatch) => {
    dispatch({ type: myNetworkTypes.RESET_STATE_MY_NETWORK });
}