// Assets
import { Assets } from "../../../assets/index"

//Libraries
import React from "react";
import { useTranslation } from "react-i18next";

//Styles
import "./header-logo.page.scss";

const HeaderLogoPage = (props) => {
  const {isAnimation }= props
  const [t] = useTranslation("translation");

  return (
            <div className={isAnimation ? "header-logo__header" : "header-logo__header__head"}>   
                <div className="flex-container align-center-middle header-logo__header__logo__content">
                  <div className="header-logo__header__logo flex-container align-center-middle">
                  <img src={Assets.LoginIcons.icon_logo} alt="icon_logo" className="header-logo__header__logo__img"></img>
                  </div>
                </div>
                <div className="flex-container align-align-center-middle header-logo__header__container">
                  <span className={isAnimation ? "header-logo__header__container__text" : "header-logo__header__container__text__mod"}>{t("authentication.login.textInfo")}
                  </span>
                </div>
            </div>
  );
};

export default HeaderLogoPage;