//Actions
import {
    setShowHierarchyEditReducer,
    setShowUnderlyingLevelsMyNetworkReducer,
    setStateMyNetworkReducer
} from '../../../../../storage/reducers/my-network/my-network.actions';

// Assets
import { Assets } from '../../../../../assets';

// Components
import ErrorToastComponent from '../../../../../shared/components/toast/error-toast/error-toast.component';

// Constants - core
import Constants from '../../../../../core/constants';
import MyNetworkErrorConst from '../../../../../core/constants/errors/my-network/my-network-error.const';

//Libraries
import { useState, useEffect } from 'react';
import Modal from "react-modal";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

// Models - aggregates
import { createErrorInternalApp } from '../../../../../models/aggregates/build-error-internal-app/build-error-internal-app.dao';

// Service
import { getTokenService, getUserLoggedService } from '../../../../../services/authentication.services';
import { userRootChangeService } from '../../../../../services/my-network.service';

// Styles
import './hierarchy.component.scss'

//Utils
import { redirectsLogin } from '../../../../../utils/authentication.utils';

const HierarchyComponent = (props) => {

    const {
        // Actions
        setShowHierarchyEditReducer,
        setShowUnderlyingLevelsMyNetworkReducer,
        setStateMyNetworkReducer,
        // Variables
        levelsNetwork,
        isShowUnderlyingLevels
    } = props

    const [t] = useTranslation("translation")
    let history = useNavigate();

    const INITIAL_STATE = {
        hierarchyMembers: [],
        hierarchyMembersAux: [],
        isShowConfirmChange: false,
        successChange: false,
        visibilityModal: false,
        userMemberMoved: {},
        userMemberDestination: {},
        userLevelDestination: 0
    }

    const [state, setState] = useState(INITIAL_STATE)

    const {
        hierarchyMembers,
        hierarchyMembersAux,
        isShowConfirmChange,
        successChange,
        visibilityModal,
        userMemberMoved,
        userMemberDestination,
        userLevelDestination
    } = state

    useEffect(() => {
        let hierarchyMembersTemp = []
        levelsNetwork.forEach((level, index) => {
            let childrenArray = []
            childrenArray = level.memberByLevel.find((memberLevel) => memberLevel.isMemberSelected === true)

            if (index !== levelsNetwork.length - 1) {
                let column = {
                    id: `${index + 1}`,
                    level: index + 1,
                    children: [childrenArray]
                }
                hierarchyMembersTemp.push(column)
            }
        }
        )

        setState({
            ...state,
            hierarchyMembers: [...hierarchyMembersTemp],
            hierarchyMembersAux: JSON.parse(JSON.stringify(hierarchyMembersTemp))
        })

        return () => {
            setShowHierarchyEditReducer(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const reorder = (sourceColumn, destinationColumn) => {
        // let editColumn = [...hierarchyMembers];
        // const deletedItem = hierarchyMembers.find((el) => el.member.idUser === sourceColumn.droppableId).children.splice(sourceColumn.index, 1)
        // editColumn.find((el) => el.member.idUser === destinationColumn.droppableId).children.splice(destinationColumn.index, 0, deletedItem[0]);
        // console.log('reorder hierarchyMembers', hierarchyMembers)

        // setState({ 
        //     ...state, 
        //     hierarchyMembers: editColumn,
        //     isShowConfirmChange: false
        // })
    };

    const reorderColumn = (sourceMemberOfRow, destinationMemberOfRow) => {

        const {
            hierarchyMembersUpdated,
            memberMoved,
            memberDestination,
            levelDestination,
            isShowConfirmChangeResponse
        } = commonFactorProcessReorderRow(destinationMemberOfRow, sourceMemberOfRow);

        setState({
            ...state,
            hierarchyMembers: hierarchyMembersUpdated,
            userMemberMoved: memberMoved,
            userMemberDestination: memberDestination,
            isShowConfirmChange: isShowConfirmChangeResponse,
            userLevelDestination: levelDestination
        })
    }

    const addToColumn = (sourceMemberOfRow, destinationMemberOfRow) => {

        const {
            hierarchyMembersUpdated,
            memberMoved,
            memberDestination,
            levelDestination,
            isShowConfirmChangeResponse
        } = commonFactorProcessReorderRow(destinationMemberOfRow, sourceMemberOfRow);

        const commonFactorPropertiesUpdated = {
            ...state,
            hierarchyMembers: hierarchyMembersUpdated,
            userMemberMoved: memberMoved,
            userMemberDestination: memberDestination,
            userLevelDestination: levelDestination,
            isShowConfirmChange: isShowConfirmChangeResponse
        }

        if (Object.values(memberMoved).length === 0 || Object.values(memberDestination).length === 0) {
            setState({
                ...commonFactorPropertiesUpdated,
                isShowConfirmChange: false
            })
        } else {
            const isChangesHierarchy = checkChangesHierarchyInitial(hierarchyMembersUpdated)

            if (isChangesHierarchy) {
                setState({
                    ...commonFactorPropertiesUpdated,
                    isShowConfirmChange: true
                })
            } else {
                setState({
                    ...state,
                    hierarchyMembers: hierarchyMembersUpdated,
                    userMemberMoved: {},
                    userMemberDestination: {},
                    isShowConfirmChange: false
                })
            }
        }
    };

    const onDragEnd = async (result) => {
        const { source, destination } = result;
        // Nulls Change
        if (!destination || (source.index === destination.index && source.droppableId === destination.droppableId)) {
            return;
        }
        // Columns change
        if (source.index === destination.index && source.droppableId !== destination.droppableId) {
            reorderColumn(source, destination)
        }

        // Rows change
        if (source.index !== destination.index && source.droppableId === destination.droppableId) {
            reorder(source, destination)
        }

        if (source.index !== destination.index && source.droppableId !== destination.droppableId) {
            addToColumn(source, destination)
        }

    }

    const handlerConfirmChange = () => {
        let token = getTokenService();
        userRootChangeService(
            userMemberMoved.member.idUser,
            userMemberDestination.member.idUser,
            token
        )
            .then(() => {
                setState({
                    ...state,
                    hierarchyMembers: [],
                    isShowConfirmChange: false,
                    successChange: true,
                    visibilityModal: true
                })
            })
            .catch(error => {
                if (redirectsLogin(error)) history("/login", { replace: true });
            })
    }

    const handlerCancelChange = () => {
        setState({
            ...state,
            hierarchyMembers: JSON.parse(JSON.stringify(hierarchyMembersAux)),
            isShowConfirmChange: false,
            userMemberMoved: {},
            userMemberDestination: {},
            visibilityModal: false,
            successChange: false,
            userLevelDestination: 0
        })
    }


    const commonFactorProcessReorderRow = (destinationMemberOfRow, sourceMemberOfRow) => {

        let rowsUpdated = JSON.parse(JSON.stringify(hierarchyMembers));

        const sourceIndexRowFound = rowsUpdated.findIndex((row) => row.id === sourceMemberOfRow.droppableId)
        const destinationIndexRowFound = rowsUpdated.findIndex((row) => row.id === destinationMemberOfRow.droppableId)

        if (sourceIndexRowFound < destinationIndexRowFound) {
            const error = createErrorInternalApp(MyNetworkErrorConst.illegalMovementFromTopToBottomLevel)
            ErrorToastComponent({
                title: `${error.message}`,
                position: Constants.AlertConst.TOP_END_POSITION_TEXT,
                timer: Constants.AlertConst.TIMER_MEDIUM,
                iconHtml: {
                    src: Assets.SharedIcons.icon_alert_error,
                    alt: "icon_alert_error"
                },
                iconColor: "transparent"
            })
            return {
                hierarchyMembersUpdated: JSON.parse(JSON.stringify(hierarchyMembersAux)),
                memberMoved: {},
                memberDestination: {},
                levelDestination: 0,
                isShowConfirmChangeResponse: false
            }
        }

        if (sourceIndexRowFound !== -1 && destinationIndexRowFound !== -1) {
            const memberSourceRowFound = rowsUpdated[sourceIndexRowFound]

            const memberDestinationRowFound = rowsUpdated[destinationIndexRowFound]

            let memberSourceRowState = {
                ...memberSourceRowFound.children[sourceMemberOfRow.index]
            }

            let memberDestinationRowState

            if (memberDestinationRowFound.level === 1) {
                const memberFound = getUserLoggedService()
                let member = { ...memberFound }
                memberDestinationRowState = { member }
            } else {
                const levelHierarchy = memberDestinationRowFound.level - 1
                const levelHierarchyFound = rowsUpdated.find((row) => row.level === levelHierarchy)

                memberDestinationRowState = {
                    ...levelHierarchyFound.children[0]
                }
            }
            const deletedItem = memberSourceRowFound.children[sourceMemberOfRow.index]

            // Add
            if (destinationMemberOfRow.index === 1) {
                memberDestinationRowFound.children = [...memberDestinationRowFound.children, deletedItem]
            } else {
                memberDestinationRowFound.children = [deletedItem, ...memberDestinationRowFound.children]
            }
            // Delete
            const newMembersSourceRowFound = []
            memberSourceRowFound.children.forEach((member, index) => {
                if (index !== sourceMemberOfRow.index) {
                    newMembersSourceRowFound.push(member)
                }
            });
            memberSourceRowFound.children = [...newMembersSourceRowFound]

            return {
                hierarchyMembersUpdated: rowsUpdated,
                memberMoved: memberSourceRowState,
                memberDestination: memberDestinationRowState,
                levelDestination: memberDestinationRowFound?.level,
                isShowConfirmChangeResponse: true
            }

        }

        return {
            hierarchyMembersUpdated: JSON.parse(JSON.stringify(hierarchyMembersAux)),
            memberMoved: {},
            memberDestination: {},
            levelDestination: 0,
            isShowConfirmChangeResponse: false
        }
    }


    const checkChangesHierarchyInitial = (hierarchyMembersUpdated) => {
        const pivotHierarchy = JSON.parse(JSON.stringify(hierarchyMembersAux))
        let indexLevel = 0
        let isChangesHierarchy = false

        for (var dataLevel of pivotHierarchy) {
            if (dataLevel.children.length !== hierarchyMembersUpdated[indexLevel].children.length) {
                isChangesHierarchy = true
                break;
            }

            for (var memberArrayOrigin of dataLevel.children) {
                const elementMemberArrayOrigin = memberArrayOrigin
                const areChanged = hierarchyMembersUpdated[indexLevel].children.some(
                    (memberArrayEdited) => memberArrayEdited.idUser === elementMemberArrayOrigin.idUser
                )

                if (!areChanged) {
                    isChangesHierarchy = true
                    break;
                }
            }
            indexLevel++
        }

        return isChangesHierarchy;
    }

    const handleSelect = (level) => {
        let memberDestination = (level === 0)
            ? { member: getUserLoggedService() }
            : hierarchyMembers[level - 1].children[0];

        setState({
            ...state,
            userMemberDestination: memberDestination,
            userLevelDestination: level + 1
        })
    };

    const backFromHierarchyFactorCommon = () => { 
        if (isShowUnderlyingLevels) {
            setShowUnderlyingLevelsMyNetworkReducer(false)
        }
        setShowHierarchyEditReducer(false);
        setStateMyNetworkReducer('isTriggerNetworkInformationUpdate', true)
    }

    return (
        <div className='hierarchy'>
            <div className="hierarchy__navbar">
                <div className="grid-x align-middle">
                    <button
                        onClick={() => backFromHierarchyFactorCommon()}
                        className="grid-x small-4 align-middle hierarchy__navbar__back-button">
                        <img className="hierarchy__navbar__back-button__icon" src={Assets.HomeIcons.icon_dropdown_arrow} alt="icon_dropdown_arrow" />
                        <span className="hierarchy__navbar__text">
                            {t("myNetworkPage.hierarchyAdjust.back")}
                        </span>
                    </button>
                    <div className="small-8 grid-x align-center-middle hierarchy__navbar__title">
                        <img src={Assets.HomeIcons.icon_adjust_hierarchy} alt="icon_adjust_hierarchy" className='hierarchy__navbar__icon' />
                        <span className="hierarchy__navbar__text">
                            {t("myNetworkPage.hierarchyAdjust.titleFunctionality")}
                        </span>
                    </div>
                </div>
            </div>
            <div className="hierarchy__content-drag">
                <DragDropContext onDragEnd={(rest) => {
                    onDragEnd(rest)
                }}>
                    {hierarchyMembers.length > 0 && hierarchyMembers.map((level, dropIndex) =>
                        <Droppable direction="vertical" key={'drop' + dropIndex} droppableId={level.id}>
                            {(droppableProvided) => (
                                <div
                                    {...droppableProvided.droppableProps}
                                    ref={droppableProvided.innerRef}
                                    className="hierarchy__content hierarchy__content__container-items grid-x align-middle"
                                >
                                    {level.children.map((item, index) => {
                                        let isDragDisabled = (
                                            !userMemberMoved.member ||
                                            (userMemberMoved.member && userMemberMoved.member.idUser === item.member.idUser)
                                        )
                                            ? false
                                            : true
                                        return <Draggable
                                            key={'drag' + item.member.idUser}
                                            index={index}
                                            draggableId={'hierarchy' + item.member.idUser}
                                            isDragDisabled={isDragDisabled}
                                        >
                                            {(draggablePropsElement) => (
                                                <>
                                                    {index === 0 && <div className='small-2 medium-2 grid-x align-center-middle'>
                                                        <span className=" small-2 medium-4 hierarchy__content__level-state__level">{level.level}</span>
                                                        <div className='small-6 medium-5 grid-x align-center-middle'>
                                                            <div className={!isDragDisabled || (userLevelDestination - 1 === dropIndex)
                                                                ? "grid-y align-center-middle hierarchy__content__level-state--active"
                                                                : "grid-y align-center-middle hierarchy__content__level-state"
                                                            }>
                                                                {(!isDragDisabled || (userLevelDestination - 1 === dropIndex)) && <div className='hierarchy__content__level-state__state' />}
                                                            </div>
                                                        </div>
                                                        <div className='small-1 medium-2'>
                                                            {
                                                                (level.level !== 1 && !draggablePropsElement.draggableProps.style.position)
                                                                    ? <div className='hierarchy__content__level-state__line--visible ' />
                                                                    : <div className='hierarchy__content__level-state__line ' />
                                                            }
                                                        </div>
                                                    </div>}
                                                    <div
                                                        className={level.children.length > 1
                                                            ? !isDragDisabled ? "small-5 hierarchy__container--active hierarchy__container__short" : "small-4 medium-5 hierarchy__container hierarchy__container__short"
                                                            : !isDragDisabled ? "small-9 hierarchy__container--active" : "small-9 hierarchy__container"}
                                                        {...draggablePropsElement.draggableProps}
                                                        {...draggablePropsElement.dragHandleProps}
                                                        ref={draggablePropsElement.innerRef}
                                                    >

                                                        <div className={!draggablePropsElement.draggableProps.style.position
                                                            ? "hierarchy__container__user grid-x align-middle justify-content-between"
                                                            : "hierarchy__container__user--mod grid-x align-middle justify-content-between"
                                                        }
                                                        >
                                                            <div className='grid-y small-11' >
                                                                <span className={level.children.length > 1 ? "hierarchy__container__small" : ""}>
                                                                    {`${item.member.name} ${item.member.lastName}`}
                                                                </span>
                                                                <span className={!isDragDisabled ? 'hierarchy__container--active__level' : 'hierarchy__container__level'}>
                                                                    {t("myNetworkPage.hierarchyAdjust.level")} {level.level}
                                                                </span>
                                                            </div>
                                                            <button onClick={() => setState({ ...state, visibilityModal: true, userMemberMoved: { member: { ...item.member, level: level.level } }, })} className=''>
                                                                {!isDragDisabled
                                                                    ? <img src={Assets.HomeIcons.icon_dots_vertical_rounded} alt="icon_dots_vertical_rounded" />
                                                                    : null}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </Draggable>
                                    })}
                                    {droppableProvided.placeholder}
                                </div>)}
                        </Droppable>
                    )}
                </DragDropContext>
            </div>

            {!isShowConfirmChange
                ? <div className='grid-x justify-content-between align-middle hierarchy__information-card'>
                    <img
                        src={Assets.HomeIcons.icon_dots_vertical_rounded}
                        alt="icon_dots_vertical_rounded"
                        className='hierarchy__information-card__icon'
                    />
                    <div className='small-11' >
                        {t("myNetworkPage.hierarchyAdjust.informationCard")}
                    </div>
                </div>
                : <div className='grid-y align-center-middle justify-content-between hierarchy__button-container'>
                    <div className='small-2 hierarchy__button-container__text'>
                        <span className='hierarchy__button-container__text--bold'>{`${userMemberMoved.member?.name} ${userMemberMoved.member?.lastName} `}</span>
                        {t("myNetworkPage.hierarchyAdjust.confirmChange.firstLabel")}
                    </div>
                    <div className='small-2 hierarchy__button-container__text'>
                        {t("myNetworkPage.hierarchyAdjust.confirmChange.secondLabel")}
                        <span className='hierarchy__button-container__text--bold'>
                            {` ${t("myNetworkPage.hierarchyAdjust.level")} ${userLevelDestination}`}
                        </span>
                    </div>
                    <button onClick={() => handlerConfirmChange()} className='hierarchy__button-container__confirm'>
                        {t("myNetworkPage.hierarchyAdjust.confirmChange.confirm")}
                    </button>
                    <button onClick={() => { handlerCancelChange() }} className='small-3 hierarchy__button-container__back'>
                        {t("myNetworkPage.hierarchyAdjust.confirmChange.cancel")}
                    </button>
                </div>
            }

            <Modal
                isOpen={visibilityModal}
                onRequestClose={() => {
                    setState({ ...state, visibilityModal: false, successChange: false, userLevelDestination: 0 })
                    backFromHierarchyFactorCommon()
                }}
                ariaHideApp={false}
                overlayClassName="hierarchy__modal__overlay grid-x align-center-middle"
                className={`${successChange ? 'hierarchy__modal--success' : 'hierarchy__modal'} medium-5 `}
            >
                {successChange
                    ? <div className='grid-y hierarchy__modal__container '>
                        <div className='grid-x justify-content-end small-1'>
                            <button onClick={() => {backFromHierarchyFactorCommon()}} className="small-1">
                                <img src={Assets.SharedIcons.icon_close} alt='icon_close' />
                            </button>
                        </div>
                        <div className='grid-y small-9 align-center-middle'>
                            <div className='small-6 grid-y align-center-middle'>
                                <img src={Assets.AuthenticationIcons.icon_user} alt='icon_user' className='hierarchy__modal__success__icon--mod' />
                                <div className="grid-y align-center-middle m-1">
                                    <div className='hierarchy__modal__success__line' />
                                    <div className='hierarchy__modal__success__circle' />
                                </div>
                                <img src={Assets.AuthenticationIcons.icon_user} alt='icon_user' className='hierarchy__modal__success__icon' />
                            </div>
                            <div className='grid-y small-6 align-center-middle'>
                                <span className='small-3 hierarchy__modal__success__text__title'>
                                    {t("myNetworkPage.hierarchyAdjust.modal.successChange.modifiedHierarchy")}
                                </span>
                                <span className='text-center hierarchy__modal__success__text'>
                                    <span className='hierarchy__modal__success__text__bold'>
                                        {`${userMemberMoved.member?.name} ${userMemberMoved.member?.lastName} `}
                                    </span>
                                    {t("myNetworkPage.hierarchyAdjust.modal.successChange.promoted")}
                                    <span className=' hierarchy__modal__success__text__bold'>
                                        {` ${t("myNetworkPage.hierarchyAdjust.level")} ${userLevelDestination}`}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className='small-2 grid-x align-center-middle'>
                            <button onClick={() => {backFromHierarchyFactorCommon()}} className='hierarchy__modal__button'>
                                {t("myNetworkPage.hierarchyAdjust.modal.successChange.button")}
                            </button>
                        </div>
                    </div>
                    : <div className='grid-y hierarchy__modal__container hierarchy__modal__adjust'>
                        <div className='small-1 grid-x align-middle justify-content-between hierarchy__modal__adjust__title'>
                            <div className='small-11 grid-x align-middle'>
                                <img className='hierarchy__modal__adjust__icon' src={Assets.HomeIcons.icon_adjust_hierarchy} alt='icon_adjust_hierarchy' />
                                <span>{t("myNetworkPage.hierarchyAdjust.titleFunctionality")}</span>
                            </div>
                            <button onClick={() => handlerCancelChange()} className="small-1">
                                <img src={Assets.SharedIcons.icon_close} alt='icon_close' />
                            </button>
                        </div>
                        <div className='small-3 grid-x align-center-middle hierarchy__modal__adjust__user'>
                            <div className='small-3 grid-x hierarchy__modal__adjust__user__img'>
                                <img src={Assets.SharedIcons.icon_profile} alt='icon__profile' className='small-9' />
                                <div className='small-3 hierarchy__modal__adjust__user__img__count'>
                                    50
                                </div>
                            </div>
                            <div className='small-6 grid-y hierarchy__modal__adjust__user__info'>
                                <span className='small-7 hierarchy__modal__adjust__user__info__name'>{`${userMemberMoved?.member?.name} ${userMemberMoved?.member?.lastName}`}</span>
                                <span>{`${t("myNetworkPage.hierarchyAdjust.modal.adjust.leaderLevel")} ${userMemberMoved?.member?.level}`}</span>
                            </div>
                        </div>
                        <div className='small-2 grid-x align-center-middle hierarchy__modal__adjust__description'>
                            <span>
                                {t("myNetworkPage.hierarchyAdjust.modal.adjust.description")}
                            </span>
                        </div>
                        <div className='small-4 grid-y justify-content-around hierarchy__modal__adjust__selects'>
                            {hierarchyMembers.map((level, index) => (level.level < userMemberMoved?.member?.level)
                                ? <button
                                    key={index}
                                    onClick={() => handleSelect(level.level - 1)} className='grid-x align-middle'
                                >
                                    <div className={(level.level === userLevelDestination) ? 'hierarchy__modal__adjust__selects__radius--active' : 'hierarchy__modal__adjust__selects__radius'}>
                                        <div className={(level.level === userLevelDestination) ? 'hierarchy__modal__adjust__selects__radius__state--active' : 'hierarchy__modal__adjust__selects__radius__state'} />
                                    </div>
                                    <span className='hierarchy__modal__adjust__selects__hierarchy'>
                                        {`${t("myNetworkPage.hierarchyAdjust.modal.adjust.hierarchy")} ${level.level}`}
                                    </span>
                                </button>
                                : null
                            )}
                        </div>
                        <div className='small-2 grid-y justify-content-end'>
                            <button disabled={userLevelDestination === 0} onClick={() => handlerConfirmChange()} className='hierarchy__modal__button'>
                                {t("myNetworkPage.hierarchyAdjust.confirmChange.confirm")}
                            </button>
                        </div>
                    </div>
                }
            </Modal>

        </div>

    );
}
const mapStateToProps = ({ MyNetworkReducer }) => {
    const { levelsNetwork, isShowUnderlyingLevels } = MyNetworkReducer;
    return {
        levelsNetwork,
        isShowUnderlyingLevels
    };
};

const mapStateToPropsActions = {
    setShowHierarchyEditReducer,
    setShowUnderlyingLevelsMyNetworkReducer,
    setStateMyNetworkReducer
};
export default connect(mapStateToProps, mapStateToPropsActions)(HierarchyComponent);