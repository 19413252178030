// shared-icons
import icon_adjust from "./icons/icon_adjust.svg";
import icon_logo from "./icons/icon_logo.svg";
import icon_menu from "./icons/icon_menu.svg";
import icon_contact from "./icons/icon_contact.svg";
import icon_voting from "./icons/icon_voting.svg";
import icon_events from "./icons/icon_events.svg";
import icon_close from "./icons/icon_close.svg";
import icon_loading from "./icons/icon_loading.svg";
import icon_contact_only from "./icons/icon_contact_only.svg";
import icon_send_message from "./icons/contact profile/icon_send_message.svg";
import icon_make_calls from "./icons/contact profile/icon_make_calls.svg";
import icon_edit_contact from "./icons/contact profile/icon_edit_contact.svg"
import icon_warning from "./icons/contact profile/icon_warning.svg"
import icon_network from "./icons/icon_network.svg";
import icon_profile from "./icons/icon_profile.svg";
import icon_circle_check from "./icons/icon_circle_check.svg"
import icon_error_map from "./icons/icon_error_map.svg"
import icon_progress_success from "./icons/icon-alert/icon_progress_success.svg"
import icon_alert_error from "./icons/icon-alert/icon_alert_error.svg"
import icon_resend_invitation from "./icons/icon-alert/icon_resend_invitation.svg"
import icon_decline_invitation from "./icons/icon-alert/icon_decline_invitation.svg"
import icon_alert_pdf from "./icons/icon-alert/icon_alert_pdf.svg"
import icon_document_error from "./icons/icon-alert/icon_document_error.svg"
import icon_add_member_active from "./icons/icon_add_member_active.svg"
import icon_add_member from "./icons/icon_add_member.svg";
import icon_events_active from "./icons/icon_events_active.svg";
import icon_contacts_active from "./icons/icon_contacts_active.svg";
import icon_menu_active from "./icons/icon_menu_active.svg";
import icon_voting_active from "./icons/icon_voting_active.svg";
import icon_arrow_inactive from "./icons/icon_arrow_inactive.svg";
import icon_identification_warning from "./icons/icon_identification_warning.svg";
import icon_warning_big from "./icons/icon_warning_big.svg";
import icon_user_success from "./icons/icon_user_success.svg";
import icon_arrow_left from "./icons/icon_arrow_left.svg";
import icon_three_points_gray from "./icons/icon_three_points_gray.svg";
import icon_arrow_gray from "./icons/icon_arrow_gray.svg";
import icon_chrome from "./icons/icon_chrome.svg";
import icon_logo_info from "./icons/icon_logo_info.svg";

export const SharedIcons = {
    icon_logo,
    icon_adjust,
    icon_menu,
    icon_contact,
    icon_voting,
    icon_events,
    icon_close,
    icon_loading,
    icon_contact_only,
    icon_send_message,
    icon_make_calls,
    icon_edit_contact,
    icon_network,
    icon_circle_check,
    icon_profile,
    icon_error_map,
    icon_progress_success,
    icon_alert_error,
    icon_resend_invitation,
    icon_decline_invitation,
    icon_alert_pdf,
    icon_document_error,
    icon_add_member_active,
    icon_add_member,
    icon_events_active,
    icon_contacts_active,
    icon_menu_active,
    icon_voting_active,
    icon_arrow_inactive,
    icon_warning,
    icon_identification_warning,
    icon_warning_big,
    icon_user_success,
    icon_arrow_left,
    icon_three_points_gray,
    icon_arrow_gray,
    icon_chrome,
    icon_logo_info
}