// Libraries
import React from 'react'
import { useTranslation } from "react-i18next";

// Styles
import "./personal-information.component.scss";

const PersonalInformationComponent = (props) => {
    
    const { personalDataUser } = props;
    
    const [t] = useTranslation("translation");
    
    //TODO: the handling process must be adjusted with the telephone indicator
    const phone = personalDataUser?.phone ? personalDataUser.phone.slice(personalDataUser.phone.length-10, personalDataUser.phone.length) : "";
    const phoneWhatsapp = personalDataUser?.phoneWhatsapp ? personalDataUser.phoneWhatsapp.slice(personalDataUser.phoneWhatsapp.length-10, personalDataUser.phoneWhatsapp.length) : "";
    //TODO: adjust end user response
    const ethnicity = personalDataUser?.ethnicity ? 'Si' : "No";
    const disability = personalDataUser?.disability ? 'Si' : "No";

    return (
        <div className='personal-information'>
            <div className='personal-information__content'>
                <label className="personal-information__title">{t("home.myNetwork.personalInformation.name")}</label>
                <div className='personal-information__description'>
                    <span>{personalDataUser?.name}</span>
                </div>
                <label className="personal-information__title">{t("home.myNetwork.personalInformation.lastName")}</label>
                <div className='personal-information__description'>
                    <span>{personalDataUser?.lastName}</span>
                </div>
                <label className="personal-information__title">{t("home.myNetwork.personalInformation.dateOfBirth")}</label>
                <div className='personal-information__description'>
                    <span>{personalDataUser?.dateBirth}</span>
                </div>
                <label className="personal-information__title">{t("home.myNetwork.personalInformation.countryOfBirth")}</label>
                <div className='personal-information__description'>
                    <span>{personalDataUser?.countryBirth}</span>
                </div>
                <label className="personal-information__title">{t("home.myNetwork.personalInformation.departmentOfBirth")}</label>
                <div className='personal-information__description'>
                    <span>{personalDataUser?.departmentBirth}</span>
                </div>
                <label className="personal-information__title">{t("home.myNetwork.personalInformation.municipalityOfBirth")}</label>
                <div className='personal-information__description'>
                    <span>{personalDataUser?.municipalityBirth}</span>
                </div>
                <label className="personal-information__title">{t("home.myNetwork.personalInformation.document")}</label>
                <div className="grid-x personal-information__box">
                    <div className="personal-information__description personal-information__description__right">
                        <span>{personalDataUser?.typeIdentification}</span>
                    </div>
                    <div className="personal-information__description personal-information__description__left">
                        <span>{personalDataUser?.documentNumber}</span>
                    </div>
                </div>
                <label className="personal-information__title">{t("home.myNetwork.personalInformation.phone")}</label>
                <div className="grid-x personal-information__box">
                    <div className="personal-information__description personal-information__description__right">
                        <span>+57</span>
                    </div>
                    <div className="personal-information__description personal-information__description__left">
                        <span>{phone}</span>
                    </div>
                </div>
                <label className="personal-information__title">{t("home.myNetwork.personalInformation.whatsapp")}</label>
                <div className="grid-x personal-information__box">
                    <div className="personal-information__description personal-information__description__right">
                        <span>+57</span>
                    </div>
                    <div className="personal-information__description personal-information__description__left">
                        <span>{phoneWhatsapp}</span>
                    </div>
                </div>
                <label className="personal-information__title">{t("home.myNetwork.personalInformation.genre")}</label>
                <div className='personal-information__description'>
                    <span>{personalDataUser?.genre}</span>
                </div>
                <label className="personal-information__title">{t("home.myNetwork.personalInformation.ethnic")}</label>
                <div className='personal-information__description'>
                    <span>{ethnicity}</span>
                </div>
                <label className="personal-information__title">{t("home.myNetwork.personalInformation.disability")}</label>
                <div className='personal-information__description'>
                    <span>{disability}</span>
                </div>
            </div>
        </div>
    );

}

export default PersonalInformationComponent;