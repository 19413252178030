// Libraries
import { Navigate, Outlet } from 'react-router-dom';

// Services
import { getTokenService } from '../services/authentication.services';

const useAuth = () => {
    const token = getTokenService()
    if (token) {
      return true
    } else {
      return false
    }
  }
  
  const PublicRoutes = () => {
    const auth = useAuth()
  
    return auth ? <Navigate to="/network" /> : <Outlet />
  }
  
  export default PublicRoutes;
