import icon_logo from "./header-login/vertical_brand_negative_version.svg";
import icon_user_footer from "./footer-login/icon_active_user.svg"
import icon_house_footer from "./footer-login/icon_where_vote_is_inactive.svg"
import img_login from "./header-login/img_login.png"

export const LoginIcons = {
    icon_logo,
    icon_user_footer,
    icon_house_footer,
    img_login
}