//Actions
import { setModalMenuTabbarReducer } from "../../../../../../../storage/reducers/tabbar/tabbar.actions";
import { setMemberMoreMyNetworkReducer, setShowViewProfileMyNetworkReducer } from '../../../../../../../storage/reducers/my-network/my-network.actions'
import { setOptionSelectedTabBarTabBarReducer } from '../../../../../../../storage/reducers/tabbar/tabbar.actions';

//Assets
import { Assets } from "../../../../../../../assets";

//Libraries
import React from "react";
import { connect } from "react-redux";
import { RiApps2Line } from 'react-icons/ri';
import { VscSettings } from "react-icons/vsc";
import { FiLogOut, FiUser } from "react-icons/fi";
import { GoMegaphone } from "react-icons/go";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

// Services
import { clearKeysAuthenticationService, getTokenService, getUserLoggedService, logoutService } from "../../../../../../../services/authentication.services";

//Styles
import "./menu.component.scss";
import "react-phone-input-2/lib/style.css";

//Utils
import { redirectsLogin } from "../../../../../../../utils/authentication.utils";

const MenuComponent = (props) => {
    const {
        // Actions
        setModalMenuTabbarReducer,
        setOptionSelectedTabBarTabBarReducer,
        setShowViewProfileMyNetworkReducer,
        setMemberMoreMyNetworkReducer,
        // Variables
        modalMenu
    } = props;

    const [t] = useTranslation("translation")
    const location = useLocation();
    let history = useNavigate();

    const mouseHandler = (e) => {
        if (e === document.querySelector(".menu__container__overlay")) {
            modalClose()
        }
    }
    const modalClose = () => {
        setModalMenuTabbarReducer(false);
        setOptionSelectedTabBarTabBarReducer(null)
    };

    const processLogout = () => {
        let token = getTokenService();
        logoutService(token)
            .then(() => {
                clearKeysAuthenticationService()
                modalClose();
                history("/login", { replace: true })
            })
            .catch((err) => {
                if(redirectsLogin(err)) history("/login", { replace: true });
            })
    };

    const ViewProfileUserLogged = () => {
        switch (location.pathname) {
            case "/map":
            case "/reports":
            case "/contact-list":
            case "/network":
                const userLogged = getUserLoggedService();
                setShowViewProfileMyNetworkReducer({
                    isShowViewProfile: true,
                    uidMemberSelected: userLogged.idUser,
                    isUserLoggedSelected: true
                });
                setMemberMoreMyNetworkReducer({
                    isShowViewMore: false,
                    level:0,
                    totalChildren:0,
                    totalLeaders:0,
                    nameMemberMore:"",
                    uidMemberMore: ""
                });
                modalClose();
                history("/network", { replace: true });
                setOptionSelectedTabBarTabBarReducer(null);
                break;

            default:
                break;
        }
    };

    return (
        modalMenu && (<div className="menu__container__overlay"
            onMouseDown={(e) => mouseHandler(e.target)}
        >
            <div className="menu__container" >
                <div className="menu__container__menu-content">
                    <div className="menu__header__container">
                        <RiApps2Line />
                        <p className="menu__header__text">
                            {t("tabBar.menu.title")}
                        </p>
                    </div>
                    <div className="menu__content__container">
                        <div 
                            onClick={() => ViewProfileUserLogged()}
                            className="grid-x menu__content__containers"
                        >
                            <div className="small-1 menu__content__containers__icon__container">
                                <FiUser className=" menu__content__containers__icon" />
                            </div>
                            <div className="small-9 menu__content__containers__text-container">
                                <p className="menu__content__containers__text menu__content__containers__text--tittle" >
                                    {t("tabBar.menu.myProfile")}
                                </p>
                                <p className="menu__content__containers__text menu__content__containers__text--mod">
                                    {t("tabBar.menu.updateInfo")}
                                </p>
                            </div>
                            <img src={Assets.AuthenticationIcons.icon_active_arrow} alt="icon_active_arrow" className="menu__content__containers__chevron-icon" />
                        </div>
                        <div className="grid-x menu__content__containers">
                            <div className="small-1 menu__content__containers__icon__container">
                                <VscSettings className=" menu__content__containers__icon menu__content__containers__icon--mod" />
                            </div>

                            <div className="small-9 menu__content__containers__text-container">
                                <p className="menu__content__containers__text--tittle menu__content__containers__text">
                                    {t("tabBar.menu.settings")}
                                </p>
                                <p className=" menu__content__containers__text menu__content__containers__text--mod">
                                    {t("tabBar.menu.newActions")}
                                </p>
                            </div>
                            <img src={Assets.AuthenticationIcons.icon_active_arrow} alt="icon_active_arrow" className="menu__content__containers__chevron-icon" />
                        </div>
                        <div className="grid-x menu__content__containers menu__content__containers__border-bottom">
                            <div className="small-1 menu__content__containers__icon__container">
                                <GoMegaphone className=" menu__content__containers__icon menu__content__containers__icon--mod" />
                            </div>
                            <div className="small-9 menu__content__containers__text-container menu__content__containers__text-container--mod">
                                <p className="menu__content__containers__text menu__content__containers__text--tittle">
                                    {t("tabBar.menu.pqrs")}
                                </p>
                                <p className="menu__content__containers__text menu__content__containers__text--mod">
                                    {t("tabBar.menu.frequentQuestions")}
                                </p>
                            </div>
                            <img src={Assets.AuthenticationIcons.icon_active_arrow} alt="icon_active_arrow" className="menu__content__containers__chevron-icon" />
                        </div>
                        <div
                            onClick={() => processLogout()}
                            className="grid-x menu__content__containers"
                        >
                            <div className="small-1 menu__content__containers__icon__container">
                                <FiLogOut className=" menu__content__containers__icon menu__content__containers__icon--logout" />
                            </div>

                            <p className="menu__content__containers__text--tittle small-11 menu__content__containers__text menu__content__containers__text--logout">
                                {t("tabBar.menu.exit")}
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>)
    );
};

const mapStateToProps = ({ TabbarReducer }) => {
    const { modalMenu } = TabbarReducer;
    return {
        modalMenu,
    };
};

const mapStateToPropsActions = {
    setModalMenuTabbarReducer,
    setOptionSelectedTabBarTabBarReducer,
    setShowViewProfileMyNetworkReducer,
    setMemberMoreMyNetworkReducer
};

export default connect(mapStateToProps,mapStateToPropsActions)(MenuComponent);