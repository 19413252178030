class ErrorExternalApp {

    constructor(code, message, description = "") {
        this.code = code;
        this.message = message;
        this.description = description;
    }

}

export default ErrorExternalApp