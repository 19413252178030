import * as tabBarTypes from './tabbar.types'

export const setStateTabBarReducer = (prop, value) => (dispatch) => {
    dispatch({ type: tabBarTypes.SET_STATE_TAB_BAR, payload: { prop, value } });
};

export const resetStateTabBarReducer = () => async (dispatch) => {
    dispatch({ type: tabBarTypes.RESET_STATE_TAB_BAR });
};

export const setModalAssociatesTabbarReducer = (value) => (dispatch) => {
    dispatch({ type: tabBarTypes.SET_MODAL_ASSOCIATES, payload: value });
};

export const setModalMenuTabbarReducer = (value) => (dispatch) => {
    dispatch({ type: tabBarTypes.SET_MODAL_MENU, payload: value });
};

export const setViewContactTabBarReducer = (value) => (dispatch) => {
    dispatch({ type: tabBarTypes.SET_VIEW_CONTACT, payload: value });
};

export const setSelectedContactUidReducer = (value) => (dispatch) => {
    dispatch({ type: tabBarTypes.SET_SELECTED_CONTACT_UID, payload: value });
};

export const setOptionSelectedTabBarTabBarReducer = (value) => (dispatch) => {
    dispatch({ type: tabBarTypes.SET_OPTION_SELECTED_TAB_BAR, payload: value });
};