import * as completeProfileTypes from './complete-profile.types'

export const resetStateCompleteProfileReducer = () => async (dispatch) => {
    dispatch({ type: completeProfileTypes.RESET_STATE_COMPLETE_PROFILE });
};

export const setSelectorsInputCompleteProfileReducer = (value) => (dispatch) => {
    dispatch({ type: completeProfileTypes.SET_SELECTORS_INPUT, payload: value });
};

export const setInfoUserCompleteProfileReducer = (props,value) => (dispatch) => {
    dispatch({ type: completeProfileTypes.SET_INFO_USER, payload: {props,value} });
};

export const setStateInfoUserCompleteProfileReducer = (props) => (dispatch) => {
    dispatch({ type: completeProfileTypes.SET_STATE_INFO_USER, payload: props });
};

export const resetInfoUserCompleteProfileReducer = (value) => (dispatch) => {
    dispatch({ type: completeProfileTypes.RESET_INFO_USER, payload: value });
};

export const setStateDataCompleteProfileReducer = (props,value) => (dispatch) => {
    dispatch({ type: completeProfileTypes.SET_STATE_DATA, payload: {props,value} });
};

export const setStateButtonsCompleteProfileReducer = (value) => (dispatch) => {
    dispatch({ type: completeProfileTypes.SET_STATE_BUTTONS, payload: value });
};