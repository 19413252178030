// Assets
import { Assets } from '../../../../../../../assets'

// Libraries
import React, { useRef } from 'react'
import { t } from 'i18next'
import Xarrow, { Xwrapper } from 'react-xarrows';

// Styles
import './inactive-information.component.scss';

const InactiveIcon = () => {
    return (
        <>
            <div className='inactive-information__icon-container grid-x justify-content-middle'>
                <img className='inactive-information__icon-container__img' src={Assets.SharedIcons.icon_profile} alt="icon_logo" />
                <img src={Assets.HomeIcons.icon_alert_inactive} alt='icon_alert_inactive' className='inactive-information__icon-container__icon' />
            </div>
            <span className='inactive-information__icon-container__text '>{t("myNetworkPage.modalInformation.inactive.labelIcon")}</span>
        </>
    )
}

const InactiveSummary = () => {
    const reference = {
        img: { id: 'img', ref: useRef(null) },
        icon: { id: 'icon', ref: useRef(null) },
        name: { id: 'name', ref: useRef(null) },
    }
    return (
        <div className='grid-x inactive-information__summary'>
            <Xwrapper>
                <div className='grid-y small-6 inactive-information__summary__panel'>
                    <div className='grid-y align-middle inactive-information__summary__img'>
                        <div className='inactive-information__summary__icon-container grid-y justify-content-middle'>
                            <div
                                id={reference.img.id}
                                className='inactive-information__summary__icon-container__img-container'
                            >
                                <img
                                    className='inactive-information__summary__icon-container__img'
                                    src={Assets.SharedIcons.icon_profile}
                                    alt="icon_logo"
                                />
                            </div>
                            <img
                                id={reference.icon.id}
                                src={Assets.HomeIcons.icon_alert_inactive}
                                alt='icon_alert_inactive'
                                className='inactive-information__summary__icon-container__icon'
                            />
                        </div>
                        <span
                            id={reference.name.id}
                            className='inactive-information__summary__icon-container__text '
                        >
                            {t("myNetworkPage.modalInformation.inactive.summary.labelIcon")}
                        </span>
                    </div>
                    <div ref={reference.name.ref} className='inactive-information__summary__box'>
                        <span>{t("myNetworkPage.modalInformation.leaders.summary.boxOne")}</span>
                    </div>
                </div>
                <div className='grid-y small-6 inactive-information__summary__panel'>
                    <div className='inactive-information__summary__box'>
                        <span ref={reference.img.ref}>{t("myNetworkPage.modalInformation.inactive.summary.boxTwo.fistParagraph")}</span>
                        <p />
                        <span>{t("myNetworkPage.modalInformation.leaders.summary.boxTwo.secondParagraph")}</span>
                    </div>
                    <div className='inactive-information__summary__box__third'>
                        <span ref={reference.icon.ref}>{t("myNetworkPage.modalInformation.inactive.summary.boxThree")}</span>
                    </div>
                </div>
                <Xarrow
                    start={reference.name.id}
                    end={reference.name.ref}
                    startAnchor='bottom'
                    endAnchor='top'
                    strokeWidth={2}
                    headSize={4}
                    color='#371f7a'
                />
                <Xarrow
                    start={reference.img.id}
                    end={reference.img.ref}
                    startAnchor='right'
                    endAnchor='left'
                    strokeWidth={2}
                    headSize={4}
                    color='#371f7a'
                    path="grid"
                />
                <Xarrow
                    start={reference.icon.id}
                    end={reference.icon.ref}
                    startAnchor='right'
                    endAnchor='left'
                    strokeWidth={2}
                    headSize={4}
                    color='#371f7a'
                    path="grid"
                />
            </Xwrapper>
        </div>
    )
}

const InactiveDescription = () => {
    return (
        <div className='inactive-information__description'>
            <p className='inactive-information__description__text-description'>{
                t("myNetworkPage.modalInformation.inactive.description.firstParagraph")
            }</p>
        </div>
    )
}

export {
    InactiveIcon,
    InactiveSummary,
    InactiveDescription
}
