import { differenceInYears } from 'date-fns';
import * as Yup from 'yup';
import string from '../shared/translations/I18n';

export async function getNumberOptionUtils(num) {

    const NumberOption = []
    for (let index = 1; index <= num; index++) {
        NumberOption.push(`${index}`)
    }
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve({NumberOption});
        }, 1000);
    });
}

export async function getAffirmDenyUtils() {
    const option=["si","no"]
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve({option});
        }, 1000);
    });
}

export async function getFormatUtils() {
    const options=["Documento","Enlace o Link"]
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve({options});
        }, 1000);
    });
}

export const validatePersonalData = Yup.object().shape({
    name: Yup.string()
        .matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g)
        .trim()
        .required(),
    lastName: Yup.string()
        .matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g)
        .trim()
        .required(),
    dateBirth: Yup.date()
    .nullable()
    .required(string.t("home.completeRegistration.personalData.errors.validateRequiredDateBirth"))
    .test("dateBirth", string.t("home.completeRegistration.personalData.errors.validateIsLegalAge"), function (value){
      return differenceInYears(new Date(), new Date(value)) >= 18;
    } ),
    countryBirth: Yup.object()
        .shape({ uid: Yup.string().nullable().required() })
        .nullable()
        .required(),
    departmentBirth: Yup.object()
        .shape({ uid: Yup.string().nullable().required() })
        .nullable()
        .required(),
    municipalityBirth: Yup.object()
        .shape({ uid: Yup.string().nullable().required() })
        .nullable()
        .required(),
    documentNumber: Yup.string()
        .min(6)
        .max(10)
        .matches(/^[0-9]+$/)
        .required(),
    phone: Yup.string()
        .matches(/^[0-9]+$/)
        .length(10)
        .required(),
    phoneWhatsapp: Yup.string()
        .matches(/^[0-9]+$/)
        .length(10)
        .required(),
    genre: Yup.number()
        .required()
        .nullable(),
    ethnicity: Yup.boolean()
        .required()
        .nullable(),
    disability: Yup.boolean()
        .required()
        .nullable(),
    photoProfile: Yup.mixed()
        .test((value) => {
            if (value) return true
        }),
});

export const validateHouseholdData = Yup.object().shape({

    residenceCountry: Yup.string()
        .required(),
    residenceDepartment: Yup.string()
        .required(),
    residenceMunicipality: Yup.string()
        .required(),
    residenceNeighborhood: Yup.string()
        .required(),
    residenceAddress: Yup.string()
        .required(),
    typeOfHousing: Yup.string()
        .required(),
    stratum: Yup.string()
        .matches(/^[0-9]+$/)
        .nullable()
        .required(),
    peopleLiveYou: Yup.string()
        .matches(/^[0-9]+$/)
        .trim()
        .nullable()
        .required()
});

export const validateVotingData = Yup.object().shape({

    votingDepartment: Yup.object()
        .shape({ uid: Yup.string().nullable().required() })
        .nullable()
        .required(),
    votingMunicipality: Yup.object()
        .shape({ uid: Yup.string().nullable().required() })
        .nullable()
        .required(),
    votingBooth: Yup.object()
        .shape({ uid: Yup.string().nullable().required() })
        .nullable()
        .required(),
    votingTable: Yup.string()
        .matches(/^[0-9]+$/)
        .nullable()
        .required(),
});

export const validateWorkProfile = Yup.object().shape({

    educationalLevel: Yup.string()
        .matches(/^[0-9]+$/)
        .nullable()
        .required(),
    profession: Yup.string()
        .matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g)
        .trim()
        .required(),
    currentOccupation: Yup.string()
        .matches(/^[0-9]+$/)
        .nullable()
        .required(),
    companyName: Yup.string()
        .when('currentOccupation', {
          is: value => !value || value === "0",
          then: Yup.string()
            .trim()
            .required(),
        }),
    timeExperience: Yup.number()
        .when('currentOccupation', {
            is: value => !value || value !== "3",
            then: Yup.number()
            .integer()
            .required(),
        }),
    lookingJob: Yup.boolean()
        .when('currentOccupation', {
            is: value => !value || value === "0" || value === "1",
            then: Yup.boolean()
            .nullable()
            .required(),
        })
        .nullable(),
    curriculumVitae: Yup.mixed()
        .when('currentOccupation', {
            is: value => !value || value === "0" || value === "1",
            then: Yup.mixed()
            .when('lookingJob', {
                is: true,
                then: Yup.mixed()
                .test((value) => {
                    if (value) return true
                }),
            })
        }),
    nameLastCompany: Yup.string()
        .when('currentOccupation', {
            is: "1",
            then: Yup.string()
            .trim()
            .required(),
        }),
    typeOccupation: Yup.string()
        .when('currentOccupation', {
            is: "1",
            then: Yup.string()
            .trim()
            .required(),
        }),
    portfolio: Yup.boolean()
        .when('currentOccupation', {
            is: value => value === "2" || value === "3",
            then: Yup.boolean()
            .nullable()
            .required(),
        })
        .nullable(),
    socialNetworks: Yup.boolean()
        .when('currentOccupation', {
            is: value => value === "2" || value === "3",
            then: Yup.boolean()
            .nullable()
            .required(),
        })
        .nullable(),
    linkSocialNetworks: Yup.string()
        .when('currentOccupation', {
            is: value => value === "2" || value === "3",
            then: Yup.string()
            .when('socialNetworks', {
                is: true,
                then: Yup.string()
                .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/)
                .required()
            })
        }),
    format: Yup.string()
        .when('currentOccupation', {
            is: value => value === "2" || value === "3",
            then: Yup.string()
            .when('portfolio', {
                is: true,
                then: Yup.string()     
                .matches(/^[0-9]+$/)
                .nullable()
                .required()
            })
            .nullable()
        })
        .nullable(),
    portfolioLink: Yup.string()
        .when('currentOccupation', {
            is: value => value === "2" || value === "3",
            then: Yup.string()
            .when('portfolio', {
                is: true,
                then: Yup.string()     
                .when('format', {
                    is: "1",
                    then:  Yup.string()
                    .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/)
                    .required()
                })
            })
        }),
    economicSector: Yup.string()
        .when('currentOccupation', {
            is: "3",
            then: Yup.string()     
            .matches(/^[0-9]+$/)
            .nullable()
            .required(),
        })
        .nullable(),
    yourCompanyName: Yup.string()
        .when('currentOccupation', {
            is: "3",
            then: Yup.string()
            .trim()
            .required(),
        }),
    located: Yup.string()
        .when('currentOccupation', {
            is: "3",
            then: Yup.string()
            .trim()
            .required(),
        }),
    descriptionServices: Yup.string()
        .when('currentOccupation', {
            is: value => value === "2" || value === "3",
            then: Yup.string()     
            .trim()
            .required(),
        }),
    attachPortfolio: Yup.mixed()
        .when('currentOccupation', {
        is: value => value === "2" || value === "3",
        then: Yup.mixed()
        .when('portfolio', {
            is: true,
            then: Yup.mixed()     
            .when('format', {
                is: "0",
                then:  Yup.mixed()
                .test((value) => {
                    if (value) return true
                }),
            })
        })
    }),
});