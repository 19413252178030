class GroupModel {

    constructor(idGroup, name, target) {
        this.idGroup = idGroup;
        this.name = name;
        this.target = target;
    }

}

export default GroupModel