//Actions
import { 
  setSelectorsInputCompleteProfileReducer, 
  setStateButtonsCompleteProfileReducer, 
  setStateDataCompleteProfileReducer,
  resetStateCompleteProfileReducer
} from '../../../../../storage/reducers/complete-profile/complete-profile.actions';

//Assets
import { Assets } from '../../../../../assets'

//Components
import PersonalDataComponent from './personal-data/personal-data.component';
import HouseholdDataMap from './household-data-map/household-data-map.component';
import VotingDataComponent from './voting-data/voting-data.component';
import WorkProfileComponent from './work-profile/work-profile.component';
import ModalSuccessfulChange from './modal-successful-changes/modal-successful-change.component';

//Components - shared
import SuccessToastComponent from '../../../../../shared/components/toast/success-toast/success-toast.component';

// Constants - core
import Constants from '../../../../../core/constants';

//Libraries
import { Stepper, Step } from 'react-form-stepper';
import { useEffect, useState } from 'react'
import string from '../../../../../shared/translations/I18n';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Services
import { 
  getCurrentOccupationService, 
  getEconomicSector, 
  getEducationLevelService, 
  getGenderService, 
  getTypeIdentificationNumberService, 
  getTypeOfHouseService, 
  saveProgressService, 
  userCompletedService
} from '../../../../../services/complete-registration.services';
import { 
  getTokenService, 
  getUserLoggedService,
  setUserLoggedService
} from '../../../../../services/authentication.services';

// Styles
import "./complete-profile.component.scss"

//Utils
import { redirectsLogin } from '../../../../../utils/authentication.utils';


const CompleteProfileComponent = (props) => {

  let history = useNavigate();
  const {
    //Actions
    setSelectorsInputCompleteProfileReducer,
    setStateDataCompleteProfileReducer,
    setStateButtonsCompleteProfileReducer,
    resetStateCompleteProfileReducer,
    //Variables
    user,
    showButtons,
    disabledButtonNext
  } = props;

  const [t] = useTranslation("translation");

  const INITIAL_STATE = {
    locationStepperState: 0,
    successModalVisibility: false,
    dataUserLogged: undefined,
    isCompleteProfile: false,
    isSaveProgress: false,
    filesCompare: {}
  }

  const [state, setState] = useState(INITIAL_STATE)
  
  const {
    locationStepperState,
    successModalVisibility,
    dataUserLogged,
    isCompleteProfile,
    filesCompare
  }= state

  const nameSteppers = [
    {
      label:string.t("home.completeRegistration.completeProfile.personalData")
    },
    {
      label:string.t("home.completeRegistration.completeProfile.householdData")
    },
    {
      label:string.t("home.completeRegistration.completeProfile.votingData")
    },
    {
      label:string.t("home.completeRegistration.completeProfile.workProfile")
    },
  ]

  useEffect(() => {
    const userLogged = getUserLoggedService()
    if (userLogged && userLogged.isCompletedRegister) {
      history("/network", { replace: true })
    }
    dataChildrenComponents();
    return () => { 
      resetStateCompleteProfileReducer()
     }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dataChildrenComponents = async () => {
    const promisesName = [
      Constants.ProfileFormConst.TYPE_OF_HOUSE,
      Constants.ProfileFormConst.EDUCATION_LEVEL,
      Constants.ProfileFormConst.CURRENT_OCCUPATION,
      Constants.ProfileFormConst.TYPE_IDENTIFICATION_NUMBER,
      Constants.ProfileFormConst.GENDER,
      Constants.ProfileFormConst.ECONOMIC_SECTOR
    ];
    await Promise.allSettled([
      getTypeOfHouseService(),
      getEducationLevelService(),
      getCurrentOccupationService(),
      getTypeIdentificationNumberService(),
      getGenderService(),
      getEconomicSector()
    ])
      .then((response) => {
        let valuesSelectors = [];
        for (let index = 0; index < response.length; index++) {
          const elementResponse = response[index];
          if (elementResponse.status === Constants.AppConst.FULFILLED) {
            valuesSelectors.push({
              typeSelector: promisesName[index],
              values: elementResponse.value,
            });
            setSelectorsInputCompleteProfileReducer({
              selectors: valuesSelectors,
            });
          }
        }
        const userLogged = getUserLoggedService()
        setStateDataCompleteProfileReducer("user",userLogged)
        setState({
          ...state,
          filesCompare: {
            ...filesCompare,
            photoProfile: userLogged?.photoProfile,
            imgResidence: userLogged?.imgResidence,
            curriculumVitae:userLogged?.curriculumVitae,
            attachPortfolio: userLogged?.attachPortfolio
          }
        })
      })
      .catch((err) => {
        if(redirectsLogin(err)) history("/login", { replace: true })
      });
  };

  const userCompletedProcess = async() => {
    let token = getTokenService()
    try {
      const responseSaveProgress = await saveProgressService(user, token)
      if (responseSaveProgress) {
        const responseUserComplete = await userCompletedService(token);
        if (responseUserComplete) {
          setState((s) => ({
            ...s,
            dataUserLogged: `${user.name} ${user.lastName}`,
            isCompleteProfile:false,
            successModalVisibility: true
            }))
          }
      }
    } catch (err) {
      setState({
        ...state,
        successModalVisibility: false,
        isCompleteProfile: false
      })
      if(redirectsLogin(err)) history("/login", { replace: true })
    }
  }

  const registerUsers = async(userChanged, newUser, filesData, saveProgress) => {
    try {
      let token = getTokenService()
      const responseRegisterUsers = await saveProgressService(userChanged, token);

      if(responseRegisterUsers){
        setUserLoggedService(newUser)
        setState((prevState) =>({
          ...prevState,
          successModalVisibility: false,
          isSaveProgress: false,
          filesCompare: filesData
        }))
        saveProgress && SuccessToastComponent({
          title: `${t("home.completeRegistration.completeProfile.progressSuccessfully")}`,
          position: Constants.AlertConst.TOP_END_POSITION_TEXT,
          timer: Constants.AlertConst.TIMER_MEDIUM,
          iconHtml: {
            src: Assets.SharedIcons.icon_progress_success,
            alt: "icon_progress_success"
          },
          iconColor: "transparent"
        })
      }
    } catch (err) {
      setState({
        ...state,
        successModalVisibility: false,
        isSaveProgress: false
      })
      if(redirectsLogin(err)) history("/login", { replace: true })
    }
  }

  const comparisonChanges = async (saveProgress) => {
    const userLogged = getUserLoggedService()
    let dataUser = {}
    for (const [key, value] of Object.entries(user)) {

      if(value !== userLogged[key] && key !== "role"){
        switch (typeof value) {
          case "string":
            if (value.trim() !== "") {
              dataUser = {
                ...dataUser,
                [key]: value
              }
            }
          break;
              
          case "object":
              if(userLogged[key] !== "" && userLogged[key] !== null && userLogged[key] !== undefined ){
                if (filesCompare[key] instanceof File || typeof filesCompare[key] === "string") {
                  if(filesCompare[key]){
                    if (value !== filesCompare[key]) {
                      dataUser = {
                        ...dataUser,
                        [key]: value
                      }
                    }
                  }
                }else{
                    if(value && Object.keys(value).length !== 0){
                      if ( userLogged[key] && Object.keys(userLogged[key]).length !== 0) {
                        for (const [id, data] of Object.entries(userLogged[key])) {
                            if(data !== value[id]){
                              dataUser = {
                                ...dataUser,
                                [key]: value
                              }
                            }
                        }
                      } else {
                        dataUser = {
                          ...dataUser,
                          [key]: value
                        }
                      }
                    }
                }
              }else{
                if(filesCompare[key]){
                  if (value !== filesCompare[key]) {
                    dataUser = {
                      ...dataUser,
                      [key]: value
                    }
                  }
                }else{
                  dataUser = {
                    ...dataUser,
                    [key]: value
                  }
                }
              }
          break;

          default:
            if (value !== "" && value !== null) {
              dataUser = {
                ...dataUser,
                [key]: value
              }
            }
          break;
        }
      }
    };
    if(Object.keys(dataUser).length > 0){
      let newUser = userLogged
      let filesData = filesCompare
      for (const [key, value] of Object.entries(dataUser)) {
        if (!(value instanceof File)) {
          newUser = {...newUser,[key]: value}
        }else{
          filesData = {...filesData,[key]: value}
        }
      }
      if(Object.keys(filesData).length === 0){
        filesData = filesCompare
      }
      await registerUsers(dataUser,newUser,filesData, saveProgress)
    }else{
      setState({
        ...state,
        successModalVisibility: false,
        isSaveProgress: false
      })
    }
  }

  function scrollEnd(cb) {
    let element = document.getElementById("scroll");
    cb && cb(element.offsetHeight + element.scrollTop >= element.scrollHeight - 50)
    if (element.offsetHeight + element.scrollTop >= element.scrollHeight - 50) setStateButtonsCompleteProfileReducer(true);
  }
  
  return (
    <>
      <div className="complete-profile">
        <div className="complete-profile__header">
          <div className="complete-profile__header__box grid-x">
            <img src={Assets.SharedIcons.icon_logo} alt="icon_logo" />
            <button className="grid-x align-middle"
              onClick={async() => {
                setState({
                  ...state,
                  isSaveProgress: true,
                  successModalVisibility: true
                })
                await comparisonChanges(true)
              }}
            >
              <span className="complete-profile__header__save text-right">
                {t("home.completeRegistration.completeProfile.saveProgress")}
              </span>
              <img src={Assets.HomeIcons.icon_save} alt="icon_save" />
            </button>
          </div>
          <div className="complete-profile__header__line"></div>
          <div>
            <Stepper
              activeStep={locationStepperState}
              connectorStateColors={locationStepperState ? true : false}
              connectorStyleConfig={{
                activeColor: "#5F3EF2",
                disabledColor: "transparent",
                size: 5,
                completedColor: "#5F3EF2",
              }}
              className="complete-profile__header__stepper"
              styleConfig={{
                labelFontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {nameSteppers.map((steps, index) => {
                return (
                  <Step
                    key={index}
                    label={steps.label}
                    onClick={() =>
                      setState({
                        ...state,
                        locationStepperState: index,
                      })
                    }
                    className={
                      locationStepperState >= index
                        ? "complete-profile__header__step"
                        : "complete-profile__header__step-inactive"
                    }
                  />
                );
              })}
            </Stepper>
          </div>
        </div>
        <div className="complete-profile__footer">
          {locationStepperState === 0 && <PersonalDataComponent onScrollEnd={scrollEnd}/>}
          {locationStepperState === 1 && <HouseholdDataMap onScrollEnd={scrollEnd}/>}
          {locationStepperState === 2 && <VotingDataComponent onScrollEnd={scrollEnd}/>}
          {locationStepperState === 3 && <WorkProfileComponent onScrollEnd={scrollEnd}/>}
        </div>
        {showButtons &&
          <div className="complete-profile__buttons grid-y">
            <button
              className="complete-profile__buttons__next"
              onClick={async() => {
                if (nameSteppers.length - 1 > locationStepperState) {
                  setState((prevState) => ({
                    ...prevState,
                    isSaveProgress: true,
                    successModalVisibility: true
                  }));
                  await comparisonChanges()
                  setState((prevState) =>({
                    ...prevState,
                    locationStepperState: locationStepperState + 1,
                  }));
                } else if (locationStepperState === 3) {
                  setState({
                    ...state,
                    successModalVisibility: true,
                    isCompleteProfile: true
                  })
                  userCompletedProcess()
                }
              }}
              disabled={disabledButtonNext || isCompleteProfile}
            >
              {t("home.completeRegistration.completeProfile.next")}
            </button>
            <button
              className="complete-profile__buttons__back"
              onClick={() => {
                if (locationStepperState === 0) {
                  history("/welcome", { replace: true });
                }else{
                  setState({
                    ...state,
                    locationStepperState: locationStepperState - 1,
                  });
              }
              }}
            >
              {t("home.completeRegistration.completeProfile.back")}
            </button>
          </div>
        }
      </div>
      <ModalSuccessfulChange
        visibility={successModalVisibility}
        dataUserLogged={dataUserLogged}
      />
    </>
  );
}

const mapStateToProps = ({ CompleteProfileReducer }) => {
  const { user, showButtons, disabledButtonNext } = CompleteProfileReducer;

  return {
    user,
    showButtons,
    disabledButtonNext
  };
};

const mapStateToPropsActions = {
  setSelectorsInputCompleteProfileReducer,
  setStateDataCompleteProfileReducer,
  setStateButtonsCompleteProfileReducer,
  resetStateCompleteProfileReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(CompleteProfileComponent);