class LocationDTO {
    constructor(idLocation, lctTypeLocation, lctPlaceName, lctImgLocation, lctAddress, lctGeographicLocation, lctMunicipality, lctVotingTables) {
        this.idLocation = idLocation;
        this.lctTypeLocation = lctTypeLocation;
        this.lctPlaceName = lctPlaceName;
        this.lctImgLocation = lctImgLocation;
        this.lctAddress = lctAddress;
        this.lctGeographicLocation = lctGeographicLocation;
        this.lctMunicipality = lctMunicipality;
        this.lctVotingTables = lctVotingTables;
    }
};
export default LocationDTO;