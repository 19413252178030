//Actions
import { setOptionSelectedTabBarTabBarReducer, setSelectedContactUidReducer, setViewContactTabBarReducer } from "./../../../../../storage/reducers/tabbar/tabbar.actions"

//Assets
import { Assets } from '../../../../../assets';

//Libraries
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoSearchOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

//Services
import { getAllUsersGroupService } from "../../../../../services/contact-list.services";
import { getTokenService, getUserLoggedService } from "../../../../../services/authentication.services";

//Styles
import "./contact-list.component.scss";

//Utils
import { redirectsLogin } from "../../../../../utils/authentication.utils";

//Component
import ViewContactListSkeletonComponent from "../../../../../shared/components/skeleton/contact-list/contact-list-skeleton.component";

const ContactListComponent = (props) => {

  const {
    //Actions
    setViewContactTabBarReducer,
    setSelectedContactUidReducer,
    setOptionSelectedTabBarTabBarReducer
  } = props;

  const [t] = useTranslation("translation");
  let history = useNavigate();

  const INITIAL_STATE = {
    userContactLists: [],
    textSearchState: '',
    userContactSearchState: [],
    isLoading: true
  }
  const [state, setState] = useState(INITIAL_STATE);

  const { userContactLists, textSearchState, userContactSearchState, isLoading } = state;

  useEffect(() => {
    setOptionSelectedTabBarTabBarReducer(2);
    getContactList();

    return () => setOptionSelectedTabBarTabBarReducer(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getContactList = async (searchUser) => {
    try {
      let token = getTokenService();
      const userLogged = getUserLoggedService();
      const response = await getAllUsersGroupService({ uid: userLogged.idUser, searchUser }, token);
      setState({
        ...state,
        userContactLists: response,
        isLoading: !isLoading
      });
    } catch (error) {
      if(redirectsLogin(error)) history("/login", { replace: true })
    }
  };

  const filterUserGroup = (value) => {
    let filteringCharacter = value;
    if (filteringCharacter.length > 2) {
      const filteredUsers = userContactLists.filter(users => {
        let response = `${users.name.toLowerCase()} ${users.lastName.toLowerCase()}`
        return response.includes(filteringCharacter.toLocaleLowerCase())
      });
      setState({
        ...state,
        textSearchState: filteringCharacter,
        userContactSearchState: filteredUsers
      });
    } else {
      setState({
        ...state,
        textSearchState: filteringCharacter
      });
    }
  };


  const sortContacts = userContactLists.sort(function (previousContact, newContact) {
    if (previousContact.name > newContact.name) {
      return 1;
    }
    if (previousContact.name < newContact.name) {
      return -1;
    }
    return 0;
  });

  const contactIndexes = [...new Set((textSearchState.length > 2 ? userContactSearchState : sortContacts).map((contact) => contact.name.charAt(0)))];

  const selectContact = (uid) => {
    setSelectedContactUidReducer(uid);
    setViewContactTabBarReducer(true)
  };
  
  return (
    <>
      <div className="contact-list__container">
        <div className="grid-x justify-content-between contact-list__header">
          <button
            className="flex-container align-middle"
            onClick={() => history("/login", { replace: true })}
          >
            <img
              alt="icon_arrow_left"
              src={Assets.SharedIcons.icon_arrow_left}
            />
            <span className="contact-list__header__back__text">
              {t("tabBar.contacts.myNetwork")}
            </span>
          </button>
          <div className="flex-container align-middle">
            <span className="contact-list__header__title">{t("tabBar.contacts.contacts")}</span>
            <img src={Assets.SharedIcons.icon_contact_only} alt="icon_contact" className="contact-list__header--img" />
          </div>
        </div>
        {contactIndexes.length !== 0
          ? <div className="contact-list__header__input-filter__container">
              <IoSearchOutline className="contact-list__header__input-filter__icon" />
              <input
                className="contact-list__header__input-filter"
                placeholder={t("tabBar.contacts.searchPlaceholder")}
                onChange={(value) => { filterUserGroup(value.target.value) }}
              />
            </div>
          : null
        }
      </div>
      {contactIndexes.length !== 0
        ? <div className="contact-list__section">
            {isLoading
              ? <ViewContactListSkeletonComponent />
              : contactIndexes.map((contactIndex, index) => (
                <div key={index}>
                  <div className="contact-list__section__letter">
                    <span className="contact-list__section__letter__box">
                      {contactIndex.toUpperCase()}
                    </span>
                  </div>
                  {(textSearchState.length > 2 ? userContactSearchState : sortContacts)
                    .filter((contact) => contact.name.charAt(0) === contactIndex)
                    .map((contact) => (
                      <div key={contact.idUser} className="grid-x align-center-middle">
                        <button
                          className={
                            sortContacts.filter((contact) => contact.name.charAt(0) === contactIndex)[sortContacts.filter((contact) => contact.name.charAt(0) === contactIndex).length - 1] === contact
                              ? "contact-list__img__content"
                              : "contact-list__img__content contact-list__img__content--mod"
                          }
                          onClick={() => selectContact(contact.idUser)}
                        >
                          <img src={contact?.photoProfile ? contact?.photoProfile : Assets.SharedIcons.icon_profile} alt="icon_profile" className="contact-list__img" />
                          <p className="contact-list__name">{` ${contact.name} ${contact.lastName}`}</p>
                        </button>
                      </div>
                    ))}
                </div>
              ))
            }
          </div>
        : <div className="contact-list__no-contacts-container grid-y align-center-middle">
            <span className="contact-list__no-contacts-container__title">{t("tabBar.contacts.title")}</span>
            <span className="contact-list__no-contacts-container__description">{t("tabBar.contacts.description")}</span>
          </div>
      }
    </>
  );
};

const mapStateToPropsActions = {
    setViewContactTabBarReducer,
    setSelectedContactUidReducer,
    setOptionSelectedTabBarTabBarReducer
};
export default connect(null,mapStateToPropsActions)(ContactListComponent);