class GroupDTO {

    constructor(idGroup, groupName, groupTarget) {
        this.idGroup = idGroup;
        this.groupName = groupName;
        this.groupTarget = groupTarget;
    }

}

export default GroupDTO