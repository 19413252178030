/**
* Secret Key to create (#Wiedii-Lions@2022) SHA256
*/
const LocalStorageConst = {
    TOKEN_SESSION: "ed2aa797e42a81729bcd607a20de3fb2ca34caa0157f2a87154be087d65cb701",
    ROL_USER: "42d6f9efb80810ce65cd592465b395c4d8080af907d4193f1beccec30aa86a28",
    USER_LOGGED: "c00c275ae78ae5ad2fc068c63bb77a160f90e400a0947487076e5beaebd14d8b",
    TOTAL_MEMBERS: "e689d3f36ead99dcb5d00c57d7f843891c5e4bb556f3102d685bf39b094b872b",
    SAVE_FORM_VALUES: "744b54d731f0c843897c4c29a6e78dc228f7d20efc80650038cd05e79126aadd",
    NUMBER_DOCUMENT: "09d50da1ce142cee6a8100c39fe37beba8aec7da54a051064c2a5ec1af7fc5ac",
    CREDENTIALS_LOGIN: "1ee2531e94d08b161c2bfea17406d5dd268d124fd7f7edb4861cc431ce1b59a2"
}

export default LocalStorageConst