import * as authenticationTypes from './authentication.types';

const INITIAL_STATE = {
	manyRolesAndGroupAssociateByUser: [],
	isCheckingSession: false,
	isValidSession: false,
	isCompletedRegisterUserLogged: true,
	isStateActiveUserLogged: true,
	isCheckingSessionSemiProtectedRoutes: false,
	isValidSessionSemiProtectedRoutes: false,
    isMemberGuest: false
};

/**
 * AuthenticationReducer - This reducer stores the information related to the user authentication module, so we will find the information of a user logged into the project, it also contains the errors occurred in the authentication process.
 *
 * @param {[object]} manyRolesAndGroupAssociateByUser Contains information on the roles and associated groups of a given user who is logged into the project.
 * @param {[object]} manyRolesAndGroupAssociateByUser[index].roleUser Contains information about the associated role of a given user on a specific group.
 * @param {[object]} manyRolesAndGroupAssociateByUser[index].groupAssociated Contains information about the associated group of a specific user.
 * @param {[object]} manyRolesAndGroupAssociateByUser[index].userMember Contains information about the user of a given group.
 *
 * @param {[object]} isCheckingSession Flag containing the boolean value confirming whether the session token was checked with the API for private routes.
 * @param {[object]} isValidSession Flag containing the boolean value confirming whether the API session token is valid and/or correct for private routes.
 *
 * @param {[boolean]} isCompletedRegisterUserLogged It is a flag that allows to identify if a logged user has not completed his registration in the system, so he is redirected to the semi-private routes.
 * @param {[boolean]} isStateActiveUserLogged This is a flag that identifies whether a logged-in user has deactivated his or her account.
 * 
 * @param {[object]} isCheckingSessionSemiProtectedRoutes Flag containing the boolean value confirming whether the session token was checked with the API for semi protected routes.
 * @param {[object]} isValidSessionSemiProtectedRoutes Flag containing the boolean value confirming whether the API session token is valid and/or correct for semi protected routes.
 * @param {[object]} isMemberGuest Flag that allows to identify when a user is invited through a specific URL.
 * 
 * @return  {AuthenticationReducer} Returns the reducer with the new state.
 */

const AuthenticationReducer = (state = INITIAL_STATE, action) => {

	switch (action.type) {		

		case authenticationTypes.SET_STATE_AUTHENTICATION:
			return {
				...state,
				[action.payload.prop]: action.payload.value
			};

		case authenticationTypes.RESET_STATE:

			return {
				...state,
				INITIAL_STATE
			};

		case authenticationTypes.SET_MANY_ROLES_AND_GROUPS:
			return {
				...state,
				manyRolesAndGroupAssociateByUser: action.payload
			};

		case authenticationTypes.SET_PROCESS_VALIDATE_SESSION:
			return {
				...state,
				isCheckingSession: action.payload.isCheckingSession,
				isValidSession: action.payload.isValidSession
			};
		
		case authenticationTypes.RESET_FLAGS_AUTHENTICATION:

			return {
				...state,
				isCheckingSession: false,
				isValidSession: false,
				isCompletedRegisterUserLogged: true,
				isStateActiveUserLogged: true
			}

			case authenticationTypes.RESET_FLAGS_AUTHENTICATION_SEMI_PROTECTED_ROUTES:

				return {
					...state,
					isCheckingSessionSemiProtectedRoutes: false,
					isValidSessionSemiProtectedRoutes: false
				}

		case authenticationTypes.SET_FLAG_PROCESS_VALIDATE_SESSION_SEMI_PROTECTED_ROUTES:
			return {
				...state,
				isCheckingSessionSemiProtectedRoutes: action.payload.isCheckingSession,
				isValidSessionSemiProtectedRoutes: action.payload.isValidSession
			};

		default:
			return state;
	}

};

export default AuthenticationReducer;