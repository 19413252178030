//Libraries
import { createTheme } from '@mui/material/styles';

export const calendarThemeMemberRegistration = createTheme({
    palette: {
        primary: {
            main: '#5F3EF2',
        },
        secondary: {
            main: '#5F3EF2',
        },
    },
})
