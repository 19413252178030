//Actions
import { resetInfoUserCompleteProfileReducer, setInfoUserCompleteProfileReducer, setStateDataCompleteProfileReducer, setStateButtonsCompleteProfileReducer } from '../../../../../../storage/reducers/complete-profile/complete-profile.actions';

//Assets
import { Assets } from '../../../../../../assets';

//Components - shared
import ErrorToastComponent from '../../../../../../shared/components/toast/error-toast/error-toast.component';

// Constants - core
import Constants from '../../../../../../core/constants';

//Libraries
import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//Services
import { getAllDepartmentsService, getAllMunicipalityService, getAllVotingStationService, getAllVotingTableService} from '../../../../../../services/complete-registration.services';
import { getTokenService } from '../../../../../../services/authentication.services';

// Share
import { getAddress, getLocation, GoogleMap, initMap, Marker } from '../../../../../../shared/components/google-map/google-map.component';

//Styles
import "./voting-data.component.scss"

//Utils
import { validateVotingData } from '../../../../../../utils/complete-profile.utils';
import { redirectsLogin } from '../../../../../../utils/authentication.utils';


const VotingDataComponent = (props) => {

  const {
    //Actions
    setInfoUserCompleteProfileReducer,
    resetInfoUserCompleteProfileReducer,
    setStateDataCompleteProfileReducer,
    setStateButtonsCompleteProfileReducer,
    // Functions
    onScrollEnd,
    //Variables
    user,
    allVotingDepartments,
    allVotingMunicipalityData,
    allVotingBooth,
    allVotingStation,
    disabledButtonNext
  }= props;
  const [t] = useTranslation("translation");
  let history = useNavigate();
  const INITIAL_STATE ={
    markerPosition: undefined,
    isLoaded : false,
    count: 0,
    isEndScroll: false
  }
  const [state, setState] = useState(INITIAL_STATE)
  const { count, markerPosition, isLoaded, isEndScroll } = state;

  useEffect(() => {
    allVotingDepartment()
    async function loadMap() {
      const { isLoaded } = await initMap(Constants.AppConst.GOOGLE_MAPS_API_KEY);
      setState({
        ...state,
        isLoaded
      })
    }
    loadMap();
    return () => {
      setState({});
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user.votingDepartment?.uid && !user.votingDepartment.dptName && allVotingMunicipalityData.length === 0) {
      allResidenceMunicipality(user.votingDepartment)
    }
    
    if (user.votingMunicipality?.uid && !user.votingMunicipality.mnpName && allVotingBooth.length === 0) {
      allResidenceNeighborhood(user.votingMunicipality)
    }

    if (user.votingBooth?.uid && !user.votingBooth.lctPlaceName && allVotingStation.length === 0) {
      allVotingTable(user.votingBooth)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.countryBirth])

  useEffect(() => {
    if (user.idUser) {
      if (count === 0) {
        setValues({
          votingDepartment: user.votingDepartment || null,
          votingMunicipality: user.votingMunicipality || null,
          votingBooth: user.votingBooth || null,
          votingTable: user.votingTable || null
        })
        setState({
          ...state,
          count: count + 1
        })
      }
      if (isLoaded) {
        if (user.votingBooth?.uid) {
          allVotingBooth.length !== 0 && showInMapLocation(allVotingBooth.find(booth => booth.uid === user.votingBooth.uid).lctGeographicLocation[0], 15)
        } else if (user.votingMunicipality?.uid) {
          allVotingMunicipalityData.length !== 0 && showInMapAddress(allVotingMunicipalityData.find(municipality => municipality.uid === user.votingMunicipality.uid).mnpName, 11)
        } else if (user.votingDepartment?.uid) {
          allVotingDepartments.length !== 0 && showInMapAddress(allVotingDepartments.find(department => department.uid === user.votingDepartment.uid).dptName)
        } else {
          getUserLocation();
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, allVotingBooth, allVotingMunicipalityData, allVotingDepartments, isLoaded])

  const { errors, handleSubmit, setValues, setFieldValue, values } = useFormik({
    initialValues: {
      votingDepartment: null,
      votingMunicipality: null,
      votingBooth: null,
      votingTable: null
    },
    validationSchema: validateVotingData
  });

  useEffect(() => {

    if (Object.keys(errors).length > 0 && !disabledButtonNext) {
      setStateDataCompleteProfileReducer("disabledButtonNext",true)
    }
    
    if (Object.keys(errors).length === 0 && disabledButtonNext){
      setStateDataCompleteProfileReducer("disabledButtonNext",false)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  const userRegistrationChanges = (name, value) =>{
    switch (name) {

      case "votingDepartment":
        if (value === null || (value !== user.votingMunicipality && user.votingMunicipality !== null)) {
          resetInfoUserCompleteProfileReducer({
            [name]: value,
            votingBooth: null,
            votingMunicipality: null,
            votingTable: null
          })
          setValues({
            ...values,
            [name]: value,
            votingBooth: null,
            votingMunicipality: null,
            votingTable: null
          })
          if(value !== null && value !== user.votingMunicipality  && user.votingMunicipality !== null){
            allResidenceMunicipality(value)
          }
        }
         else {
          setFieldValue(name,value)
          setInfoUserCompleteProfileReducer(name,value)
          allResidenceMunicipality(value)
        }
        break;
        case "votingMunicipality":
        if (value === null || (value !== user.votingBooth  && user.votingBooth !== null)) {
          resetInfoUserCompleteProfileReducer({
            [name]: value,
            votingBooth: null,
            votingTable: null
          })
          setValues({
            ...values,
            [name]: value,
            votingBooth: null,
            votingTable: null
          })
          if(value !== null &&  value !== user.votingBooth  && user.votingBooth !== null){
            allResidenceNeighborhood(value)
          }
        } else {
          setFieldValue(name,value)
          setInfoUserCompleteProfileReducer(name,value)
          allResidenceNeighborhood(value)
        }
        break;
        case "votingBooth":
          if (value === null || (value !== user.votingTable  && user.votingTable !== null)) {
            resetInfoUserCompleteProfileReducer({
              [name]: value,
              votingTable: null
            })
            setValues({
              ...values,
              [name]: value,
              votingTable: null
            })
            if(value !== null &&  value !== user.votingTable  && user.votingTable !== null){
              allVotingTable(value)
            }
          } else {
            setFieldValue(name,value)
            setInfoUserCompleteProfileReducer(name,value)
            allVotingTable(value)
          }
          break;
      default:
        setFieldValue(name,value)
        setInfoUserCompleteProfileReducer(name,value)
        break;
    }
  }

  const allVotingDepartment = async () => {
    let token = getTokenService()
    try {
      const getAllResidenceDepartments = await getAllDepartmentsService("6335cd05d9cde1aea666f030",token);
      setStateDataCompleteProfileReducer("allVotingDepartments",getAllResidenceDepartments)
    } catch (err) {
      if(redirectsLogin(err)) history("/login", { replace: true });
    }
  };

  const allResidenceMunicipality = async ({uid}) => {
    let token = getTokenService()
    try {
      const getAllResidenceMunicipality = await getAllMunicipalityService(uid,token);
      setStateDataCompleteProfileReducer("allVotingMunicipalityData",getAllResidenceMunicipality)
    } catch (err) {
      if(redirectsLogin(err)) history("/login", { replace: true });
    }
  };

  const allResidenceNeighborhood = async ({uid}) => {
    let token = getTokenService()
    try {
      const getAllMunicipality = await getAllVotingStationService(uid,token);
      setStateDataCompleteProfileReducer("allVotingBooth",getAllMunicipality)
    } catch (err) {
      if(redirectsLogin(err)) history("/login", { replace: true });
    }
  };

  const allVotingTable = async ({uid}) => {
    let token = getTokenService()
    try {
      const getAllMunicipality = await getAllVotingTableService(uid,token);
      setStateDataCompleteProfileReducer("allVotingStation",getAllMunicipality)
    } catch (err) {
      if(redirectsLogin(err)) history("/login", { replace: true });
    }
  };

  const validateFocusFields = (text) => {
    const showButtons = text ==='focus' ? false : text === "blur" && true;
    !isEndScroll && setStateButtonsCompleteProfileReducer(showButtons)
  }

  const createCoordinates = (lat=0, lng=0) => ({lat, lng});

  const getUserLocation = () => {
    if (!!navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => 
        getAddress(createCoordinates(location.coords.latitude, location.coords.longitude))
      )
    } else {
      ErrorToastComponent({
        title: `'EL navegador no soporta geolocation'`,
        position: Constants.AlertConst.TOP_END_POSITION_TEXT,
        timer: Constants.AlertConst.TIMER_MEDIUM,
        iconHtml:{
          src: Assets.SharedIcons.icon_alert_error,
          alt: "icon_alert_error"
        },
        iconColor: "transparent"
      });
    }
  };

  const showInMapAddress = async (address, zoom) => {
    const { geometry } = await getLocation(address, zoom);
    setState({
      ...state,
      markerPosition: geometry.location
    });
  };
  
  const showInMapLocation = async (location, zoom) => {
    const { geometry } = await getAddress(createCoordinates(location.gplLatitude, location.gplLongitude), zoom);
    setState({
      ...state,
      markerPosition: geometry?.location
    });
  };

  return (
    <form 
      id='scroll' 
      onScroll={() => onScrollEnd(e => (isEndScroll !== e && setState({...state, isEndScroll:e})))}
      className='voting-data__box-content'
      onSubmit={handleSubmit}
    >
      <div className="flex-container voting-data__content-map">
        {isLoaded
          ? <GoogleMap>
            {markerPosition &&
              <Marker
                position={markerPosition}
              />
            }
          </GoogleMap>
          : <div  className="grid-y align-center-middle voting-data__loading">
              <div className='voting-data__loading-spinner'>
                  <img
                    src={Assets.SharedIcons.icon_loading}
                    alt="icon_loading"
                  />
                  </div>
            <span className='text-center voting-data__loading__text'>{t("home.completeRegistration.votingData.loadingMap")}</span>
          </div>
        }
      </div>
      <Autocomplete
        sx={{ width: "100%", marginTop: "65px" }}
        options={allVotingDepartments}
        autoHighlight
        getOptionLabel={(option) => option.dptName}
        popupIcon={
            <img size={16} alt="icon_dropdown_arrow" src={Assets.HomeIcons.icon_dropdown_arrow} />
        }
        onChange={(e, value) => {
          if (value) {
            showInMapAddress(value.dptName)
          } else {
            setState({
              ...state,
              markerPosition: undefined
            })
            getUserLocation();
          }
          userRegistrationChanges("votingDepartment", value)
        }}
        value={allVotingDepartments.find(department => department.uid === values.votingDepartment?.uid) || null}
        onFocus={()=> validateFocusFields("focus")}
        onBlur={()=> validateFocusFields("blur")}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{
              backgroundColor: "#F1F1F8",
              borderBottom: "1px solid #887EA6",
            }}
            {...props}
          >
            {option.dptName}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("home.completeRegistration.votingData.votingDepartment")}
            className="voting-data__input-autocomplete"
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
              readOnly: true
            }}
            error={errors.votingDepartment ? true : false}
          />
        )}
      />
      <Autocomplete
        sx={{ width: "100%", marginTop: "40px" }}
        options={allVotingMunicipalityData}
        autoHighlight
        getOptionLabel={(option) => option.mnpName}
        disabled={values.votingDepartment?.uid ? false : true}
        popupIcon={
            <img size={16} alt="icon_dropdown_arrow" src={Assets.HomeIcons.icon_dropdown_arrow} />
        }
        onChange={(e,value) => {
          (value)
            ? showInMapAddress(value?.mnpName, 11)
            : showInMapAddress(values.votingDepartment.dptName)
          userRegistrationChanges("votingMunicipality",value)
          }}
        value={allVotingMunicipalityData.find(municipality => municipality.uid === values.votingMunicipality?.uid) || null}
        onFocus={()=> validateFocusFields("focus")}
        onBlur={()=> validateFocusFields("blur")}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{
              backgroundColor: "#F1F1F8",
              borderBottom: "1px solid #887EA6",
            }}
            {...props}
          >
            {option.mnpName}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("home.completeRegistration.votingData.votingMunicipality")}
            className="voting-data__input-autocomplete"
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
            }}
            error={errors.votingMunicipality ? true : false}
          />
        )}
      />
      <Autocomplete
        sx={{ width: "100%", marginTop: "40px" }}
        options={allVotingBooth}
        autoHighlight
        getOptionLabel={(option) => option.lctPlaceName}
        disabled={values.votingMunicipality?.uid ? false : true}
        popupIcon={
            <img size={16} alt="icon_dropdown_arrow" src={Assets.HomeIcons.icon_dropdown_arrow} />
        }
        onChange={async(e, value) => {
          (value)
            ? showInMapLocation(value.lctGeographicLocation[0], 15)
            : showInMapAddress(values.votingMunicipality.mnpName, 11)
          userRegistrationChanges("votingBooth", value)
        }}
        value={allVotingBooth.find(booth => booth.uid === values.votingBooth?.uid) || null}
        onFocus={()=> validateFocusFields("focus")}
        onBlur={()=> validateFocusFields("blur")}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{
              backgroundColor: "#F1F1F8",
              borderBottom: "1px solid #887EA6",
            }}
            {...props}
          >
            {option.lctPlaceName}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("home.completeRegistration.votingData.pollingPlace")}
            className="voting-data__input-autocomplete"
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
            }}
            error={errors.votingBooth ? true : false }
          />
        )}
      />
      <Autocomplete
        sx={{ width: "100%", marginTop: "40px" }}
        disabled={values.votingBooth?.uid ? false : true}
        options={allVotingStation}
        autoHighlight
        getOptionLabel={(option) => option}
        popupIcon={
            <img size={16} alt="icon_dropdown_arrow" src={Assets.HomeIcons.icon_dropdown_arrow} />
        }
        onChange={(e,value) => userRegistrationChanges("votingTable",value)}
        value={allVotingStation.length > 0 ? (values.votingTable ? allVotingStation[values.votingTable - 1] : null)  : null}
        onFocus={()=> validateFocusFields("focus")}
        onBlur={()=> validateFocusFields("blur")}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{
              backgroundColor: "#F1F1F8",
              borderBottom: "1px solid #887EA6",
            }}
            {...props}
          >
            {option}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("home.completeRegistration.votingData.votingTable")}
            className="voting-data__input-autocomplete"
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
            }}
            error={errors.votingTable ? true : false}
          />
        )}
      />
    </form>
  );
}

const mapStateToProps = ({ CompleteProfileReducer }) => {
  const { 
    user, allVotingDepartments, allVotingMunicipalityData,
    allVotingBooth, allVotingStation, disabledButtonNext 
  } = CompleteProfileReducer;

  return {
    user,
    allVotingDepartments,
    allVotingMunicipalityData,
    allVotingBooth,
    allVotingStation,
    disabledButtonNext
  };
};

const mapStateToPropsActions = {
  setInfoUserCompleteProfileReducer,
  resetInfoUserCompleteProfileReducer,
  setStateDataCompleteProfileReducer,
  setStateButtonsCompleteProfileReducer
};
export default connect(mapStateToProps, mapStateToPropsActions)(VotingDataComponent);