import * as tabBarTypes from './tabbar.types';

const INITIAL_STATE = {
    modalAssociate: false,
    modalMenu: false,
    isViewContact: false,
    selectedContactUid: null,
    optionSelectedTabBar: null,
    auxiliaryOptionSelectedTabBar: null
};

/** 
* This reducer is intended to store information to be shared at tabBar level.

* @param {[boolean]} modalAssociate returns true or false to know if a user's registration modal is displayed or not.
* @param {[boolean]} modalMenu returns true or false to know if the menu modal is displayed or not.
* @param {[boolean]} isViewContact status controlling the list contacts and view contact views.
* @param {[object { string string string}]} selectedContactUid contains the UID of the selected contact, within contact list.
* @param {[number || null]} optionSelectedTabBar Contains the numeric value of the selected tabBar menu option.
* @param {[number || null]} auxiliaryOptionSelectedTabBar Auxiliary variable containing the numeric value of the selected tabBar menu option - backup for the variable 'optionSelectedTabBar' in case a modal (tabBar option) is opened and closed and a tabBar option of type 'screen' has been previously selected.
*/

const TabbarReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case tabBarTypes.SET_STATE_TAB_BAR:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            };

        case tabBarTypes.RESET_STATE_TAB_BAR:
            return {
                ...state,
                INITIAL_STATE
            };
        
        case tabBarTypes.SET_MODAL_ASSOCIATES:
            return {
                ...state,
                modalAssociate: action.payload
            };
        case tabBarTypes.SET_MODAL_MENU:
            return {
                ...state,
                modalMenu: action.payload
            };

        case tabBarTypes.SET_VIEW_CONTACT:
            return {
                ...state,
                isViewContact: action.payload
            }
        case tabBarTypes.SET_SELECTED_CONTACT_UID:
            return {
                ...state,
                selectedContactUid: action.payload
            }

        case tabBarTypes.SET_OPTION_SELECTED_TAB_BAR:
            let newOptionSelectedObjectConfig = {
                optionSelectedTabBar: null,
                auxiliaryOptionSelectedTabBar: null
            }

            if (!state.optionSelectedTabBar) {
                newOptionSelectedObjectConfig.optionSelectedTabBar = action.payload
                if (
                    action.payload === 1 ||
                    action.payload === 3
                ) {
                    newOptionSelectedObjectConfig.auxiliaryOptionSelectedTabBar = null
                } else {
                    newOptionSelectedObjectConfig.auxiliaryOptionSelectedTabBar = action.payload
                }
            }
            /* This conditional represents for when the selected option of the tabBar is 1 and 3 (Modal type options). */
            if (
                state.optionSelectedTabBar === 1 ||
                state.optionSelectedTabBar === 3
            ) {
                if (!action.payload) {
                    newOptionSelectedObjectConfig.optionSelectedTabBar = state.auxiliaryOptionSelectedTabBar
                    newOptionSelectedObjectConfig.auxiliaryOptionSelectedTabBar = null
                } else {
                    newOptionSelectedObjectConfig.optionSelectedTabBar = action.payload
                    newOptionSelectedObjectConfig.auxiliaryOptionSelectedTabBar = null
                }
            }
            /* This conditional represents for when the selected option of the tabBar is 2, 4 and 5 (Screen type options). */
            if (
                state.optionSelectedTabBar === 2 ||
                state.optionSelectedTabBar === 4 ||
                state.optionSelectedTabBar === 5
            ) {
                if (!action.payload) {
                    newOptionSelectedObjectConfig.optionSelectedTabBar = null
                    newOptionSelectedObjectConfig.auxiliaryOptionSelectedTabBar = null
                }

                if (action.payload === 1 || action.payload === 3) {
                    newOptionSelectedObjectConfig.optionSelectedTabBar = action.payload
                    newOptionSelectedObjectConfig.auxiliaryOptionSelectedTabBar = state.optionSelectedTabBar
                }

                if (
                    action.payload === 2 ||
                    action.payload === 4 ||
                    action.payload === 5
                ) {
                    newOptionSelectedObjectConfig.optionSelectedTabBar = action.payload
                    newOptionSelectedObjectConfig.auxiliaryOptionSelectedTabBar = null
                }
            }

            // TODO: This conditional must be removed for when tabBar options 4 and 5 are enabled.
            if (
                action.payload === 4 ||
                action.payload === 5
            ) {
                newOptionSelectedObjectConfig.optionSelectedTabBar = state.optionSelectedTabBar
                newOptionSelectedObjectConfig.auxiliaryOptionSelectedTabBar = state.auxiliaryOptionSelectedTabBar
            }

            return {
                ...state,
                ...newOptionSelectedObjectConfig
            };

        default:
            return state;
    }
    
    };
export default TabbarReducer;
