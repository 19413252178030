// Actions
import {
    setInfoUserCompleteProfileReducer,
    setStateButtonsCompleteProfileReducer,
    setStateDataCompleteProfileReducer,
    setStateInfoUserCompleteProfileReducer
} from '../../../../../../storage/reducers/complete-profile/complete-profile.actions';

// Assets
import { Assets } from '../../../../../../assets';

// Components
import ModalSolveMap from '../../moda-solve-map/modal-solve-map.component';

// core - Constants
import Constants from '../../../../../../core/constants';

// Libraries
import { Autocomplete, Box, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';

// Services
import { getTokenService } from '../../../../../../services/authentication.services';
import { autocompletePlaceService } from '../../../../../../services/map.services';

// Shared
import {
    getAddress,
    getHomeAddress,
    getHomeLocation,
    GoogleMap,
    initMap,
    Marker
} from '../../../../../../shared/components/google-map/google-map.component'
import ErrorToastComponent from '../../../../../../shared/components/toast/error-toast/error-toast.component';

// Styles
import './household-data-map.component.scss';

//Utils
import { getNumberOptionUtils, validateHouseholdData } from '../../../../../../utils/complete-profile.utils';

const INITIAL_STATE = {
    isLoaded: false,
    loadError: false,
    markerPosition: undefined,
    userLocation: undefined,
    showForm: false,
    loadingForm: false,
    draggablePin: true,
    isEndScroll: false,
    selectImg:"",
    optionsAddress: [],
    optionsStratum: [],
    showModalSolve: false
}

const HouseholdDataMap = (props) => {
    const {
        // Actions
        setStateDataCompleteProfileReducer,
        setInfoUserCompleteProfileReducer,
        setStateInfoUserCompleteProfileReducer,
        setStateButtonsCompleteProfileReducer,
        // Functions 
        onScrollEnd,
        // Variables
        typeOfHouse,
        user
    } = props;

    const [state, setState] = useState(INITIAL_STATE)
    const {
        isLoaded, loadError, markerPosition, userLocation,
        showForm, draggablePin, loadingForm, selectImg,
        optionsAddress, optionsStratum, isEndScroll, showModalSolve
    } = state

    useEffect(() => {
        const {
            residenceLocation, residenceCountry, residenceDepartment,
            residenceMunicipality, residenceNeighborhood, residenceAddress,
            typeOfHousing, stratum, peopleLiveYou, imgResidence
        } = user;
        initMap(Constants.AppConst.GOOGLE_MAPS_API_KEY)
            .then(({ isLoaded, loadError }) => {
                if (loadError) {
                    setState((prevState) => ({ ...prevState, loadError }))
                } else {
                    if (residenceCountry) {
                        setState((prevState) => ({
                            ...prevState,
                            showForm: true,
                            isLoaded,
                            markerPosition: residenceLocation
                        }))
                        getAddress(residenceLocation, 13)
                        setValues({
                            residenceCountry,
                            residenceDepartment,
                            residenceMunicipality,
                            residenceNeighborhood,
                            residenceAddress,
                            typeOfHousing: typeOfHousing,
                            stratum: stratum?.toString(),
                            peopleLiveYou,
                            imgResidence
                        })
                    } else {
                        getUserLocation(isLoaded)
                    }
                }
            })
        getNumberOptionUtils(10).then(({ NumberOption }) => {
            setState((prevState) => ({
                ...prevState,
                optionsStratum: NumberOption
            }));
        })
        return () => {
            setState((prevState) => prevState)
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function getUserLocation(isLoaded) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async ({ coords }) => {
                    setState((prevState) => ({ ...prevState, isLoaded, }))
                    try {
                        await getAddress({ lat: coords.latitude, lng: coords.longitude })
                        setState((prevState) => ({
                            ...prevState,
                            userLocation: { lat: coords.latitude, lng: coords.longitude },
                        }))
                    } catch (error) {
                        setState((prevState) => ({...prevState,loadError: true}))
                        ErrorToastComponent({
                            title: `Error al obtener la ubicación: ${error.message}`,
                            position: Constants.AlertConst.TOP_END_POSITION_TEXT,
                            timer: Constants.AlertConst.TIMER_MEDIUM,
                            iconHtml: {
                                src: Assets.SharedIcons.icon_alert_error,
                                alt: "icon_alert_error"
                            },
                            iconColor: "transparent"
                        });
                    }
                },
                (error) => {
                    setState((prevState) => ({ ...prevState, loadError: true }))
                    ErrorToastComponent({
                        title: `Error al obtener la ubicación: ${error.message}`,
                        position: Constants.AlertConst.TOP_END_POSITION_TEXT,
                        timer: Constants.AlertConst.TIMER_MEDIUM,
                        iconHtml: {
                            src: Assets.SharedIcons.icon_alert_error,
                            alt: "icon_alert_error"
                        },
                        iconColor: "transparent"
                    });
                });
        }
        else {
            setState((prevState) => ({ ...prevState, loadError: true }))
            ErrorToastComponent({
                title: 'El navegador no soporta geolocation',
                position: Constants.AlertConst.TOP_END_POSITION_TEXT,
                timer: Constants.AlertConst.TIMER_MEDIUM,
                iconHtml: {
                    src: Assets.SharedIcons.icon_alert_error,
                    alt: "icon_alert_error"
                },
                iconColor: "transparent"
            });
        }
    };


    const { values, errors, setValues, resetForm} = useFormik({
        initialValues: {
            search: '',
            residenceCountry: "",
            residenceDepartment: "",
            residenceMunicipality: "",
            residenceNeighborhood: "",
            residenceAddress: "",
            typeOfHousing: undefined,
            stratum: null,
            peopleLiveYou: "",
            imgResidence: ""
        },
        validationSchema: validateHouseholdData
    });

    useEffect(() => {
        setStateDataCompleteProfileReducer("disabledButtonNext", (Object.keys(errors).length || !showForm))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors, showForm])

    const onChange = (data, target) => {
        let value = data?.target?.files
            ? data.target.files[0]
            : data?.target ?
                data.target.value 
                : data || '';

        if (target === 'typeOfHousing') value = typeOfHouse.indexOf(value);
        if (target === 'imgResidence') value = validateImage(value, data.target.value);

        const newValues = { ...values, [target]: value };
        setValues(newValues);
        if (target === 'peopleLiveYou' || target === 'stratum') {
            const parsedValue = parseInt(value.trim(), 10);
            value = Number.isNaN(parsedValue) || !Number.isInteger(parsedValue)
                ? value
                : parsedValue;
        }
        setStateInfoUserCompleteProfileReducer({ ...newValues, [target]: value });
    };

    const validateImage = (file, selectImg) => {
        if (!(file?.type === "image/jpg" || file?.type === "image/jpeg")) {
            ErrorToastComponent({
                title: t("home.completeRegistration.householdData.validationSizePhoto"),
                position: Constants.AlertConst.TOP_END_POSITION_TEXT,
                timer: Constants.AlertConst.TIMER_MEDIUM,
                iconHtml: {
                    src: Assets.SharedIcons.icon_document_error,
                    alt: "icon_document_error"
                },
                iconColor: "transparent"
            });
            setState({...state, selectImg: ""})
            return ""
        }
        if ((file?.size > 4194304)) {
            ErrorToastComponent({
                title: t("home.completeRegistration.householdData.validationSizePhoto"),
                position: Constants.AlertConst.TOP_END_POSITION_TEXT,
                timer: Constants.AlertConst.TIMER_MEDIUM,
                iconHtml: {
                    src: Assets.SharedIcons.icon_document_error,
                    alt: "icon_document_error"
                },
                iconColor: "transparent"
            });
            setState({...state, selectImg: ""})
            return ""
        }
        setState({...state, selectImg})
        return file
    };

    const selectAddress = async (address) => {
        setState((prevState) => ({
            ...prevState,
            loadingForm: true
        }))
        const { address_components, geometry } = await getHomeLocation(address)
        deconstructingAddress(address_components, address)

        setState((prevState) => ({
            ...prevState,
            markerPosition: geometry.location,
            showForm: true,
            loadingForm: false
        }))
        setInfoUserCompleteProfileReducer('residenceLocation', { lat: geometry.location.lat(), lng: geometry.location.lng() })
    };

    const onDragPin = async (location) => {
        const { formatted_address, address_components } = await getHomeAddress(location);
        deconstructingAddress(address_components, formatted_address)

        setState((prevState) => ({
            ...prevState,
            markerPosition: location,
        }))
        setInfoUserCompleteProfileReducer('residenceLocation', location)
    };

    const searchLocation = async () => {
        setState((prevState) => ({
            ...prevState,
            loadingForm: true
        }))
        const { formatted_address, address_components } = await getAddress(userLocation, 13);
        deconstructingAddress(address_components, formatted_address)
        setState((prevState) => ({
            ...prevState,
            markerPosition: userLocation,
            showForm: true,
            loadingForm: false
        }))
        setInfoUserCompleteProfileReducer('residenceLocation', userLocation)
    };

    const searchAgain = () => {
        setState((prevState) => ({ ...prevState, loadingForm: true, isLoaded: false }))
        resetForm()
        getUserLocation(true)
        setState((prevState) => ({ 
            ...prevState, 
            showForm: false, 
            markerPosition: undefined, 
            loadingForm: false, 
            draggablePin: true 
        }))
    };

    const deconstructingAddress = (component, address) => {
        const addressValues = {
            residenceCountry: getAddressComponent(component, "country"),
            residenceDepartment: getAddressComponent(component, "administrative_area_level_1"),
            residenceMunicipality: getAddressComponent(component, ["locality", 'administrative_area_level_2', 'political']),
            residenceNeighborhood: getAddressComponent(component, ["sublocality", "neighborhood"]),
            residenceAddress: address.split(',')[0],
        }
        setValues({ ...values, ...addressValues })
        setStateInfoUserCompleteProfileReducer(addressValues)
    }

    const getAddressComponent = (addressComponents, type) => {
        if (!Array.isArray(type)) type = [type];
        for (const t of type) {
            const [result] = addressComponents.filter(obj => obj.types.includes(t)) || [];
            if (result) return result.long_name;
        }
        return '';
    };

    const getPlace = async (data) => {
        const { value: place } = data.target;
        let token = getTokenService()
        const optionsAddress = await autocompletePlaceService({ place, ...userLocation }, token)
        setValues({ ...values, search: place })
        setState((prevState) => ({
            ...prevState,
            optionsAddress
        }))
    };

    const validateFocusFields = (text) => {
        const showButtons = text === 'focus' ? false : text === "blur" && true;
        !isEndScroll && setStateButtonsCompleteProfileReducer(showButtons)
    }

    // const nameImgResidence = () => {
    //     if (values?.imgResidence?.name) {
    //         return values?.imgResidence?.name
    //     } else {
    //         return values?.imgResidence?.split('.com/').pop()
    //     }
    // }

    return (
        <div
            className='household-data-map'
            id='scroll'
            onScroll={() => onScrollEnd(e => (isEndScroll !== e && setState({ ...state, isEndScroll: e })))}
        >
            <div className="household-data-map__content-map">
                {isLoaded
                    ? <>
                        <GoogleMap>
                            {markerPosition && Object.keys(markerPosition).length
                                ? <Marker
                                    position={markerPosition}
                                    draggable={draggablePin}
                                    onDragEnd={(location) => onDragPin(location)}
                                />
                                : null
                            }
                        </GoogleMap>
                        {!showForm
                            ? <button
                                onClick={() => searchLocation()}
                                className='household-data-map__button-map'
                            >
                                <span>{t("home.completeRegistration.householdData.searchLocation")}</span>
                            </button>
                            : <div className='grid-x household-data-map__button-map__container'>
                                <button
                                    onClick={searchAgain}
                                    className='household-data-map__button-map--mod household-data-map__button-map--color'
                                >
                                    <span>{t("home.completeRegistration.householdData.searchAgain")}</span>
                                </button>
                                <button
                                    disabled={!draggablePin}
                                    onClick={() => setState((prevState) => ({
                                        ...prevState,
                                        draggablePin: false
                                    }))}
                                    className='household-data-map__button-map--mod'
                                >
                                    <span>{t("home.completeRegistration.householdData.confirmLocation")}</span>
                                </button>
                            </div>
                        }
                    </>
                    : loadError
                        ? <div className="household-data-map__error">
                            <img src={Assets.SharedIcons.icon_error_map} alt='error_map' />
                            <div className='text-center household-data-map__error__container' >
                                <span className='household-data-map__error__text'>
                                    {t("map.googleMap.errorLoadingMap")}
                                </span>
                            </div>
                            <button
                                disabled={showModalSolve}
                                onClick={() => setState((prevState) => ({...prevState, showModalSolve: true}))}
                                className='household-data-map__error__button'
                            >
                                <span>{t("home.completeRegistration.householdData.resolve")}</span>
                            </button>
                        </div>
                        : <div className="grid-y align-center-middle household-data-map__loading">
                            <div className='household-data-map__loading-spinner'>
                                <img
                                    src={Assets.SharedIcons.icon_loading}
                                    alt="icon_loading"
                                />
                            </div>
                            <span className='text-center household-data-map__loading__text'>{t("home.completeRegistration.votingData.loadingMap")}</span>
                        </div>
                }
            </div>
            <div className=' gird-y household-data-map__form'>
                {loadingForm
                    ? <div className="grid-y align-center-middle household-data-map__loading--mod">
                        <div className='household-data-map__loading-spinner'>
                            <img
                                src={Assets.SharedIcons.icon_loading}
                                alt="icon_loading"
                            />
                        </div>
                    </div>
                    : !showForm ?
                        <div className='gird-y  household-data-map__field'>
                            <Autocomplete
                                disabled={!isLoaded}
                                options={optionsAddress || []}
                                sx={{ width: "100%", marginTop: "10px" }}
                                getOptionLabel={(option) => option.description}
                                popupIcon={<img size={16} alt="icon_dropdown_arrow" src={Assets.HomeIcons.icon_dropdown_arrow} />}
                                onFocus={() => validateFocusFields("focus")}
                                onBlur={() => validateFocusFields("blur")}
                                renderOption={(props, option) => (
                                    <Box
                                        sx={{
                                            backgroundColor: "#F1F1F8",
                                            borderBottom: "1px solid #887EA6",
                                        }}
                                        {...props}
                                    >
                                        <button
                                            className='household-data-map__option'
                                            onClick={() => selectAddress(option.description)}
                                        >
                                            {option.description}
                                        </button>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("home.completeRegistration.householdData.inputAddress")}
                                        className="household-data-map__input-autocomplete"
                                        onChange={getPlace}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "off",
                                        }}
                                    />
                                )}
                            />
                        </div>
                        : <>
                            <div className='gird-y household-data-map__field'>
                                <TextField
                                    label={t("home.completeRegistration.householdData.residenceDepartment")}
                                    disabled
                                    value={values.residenceDepartment}
                                    className='household-data-map__input'
                                />
                            </div>
                            <div className='gird-y household-data-map__field'>
                                <TextField
                                    label={t("home.completeRegistration.householdData.municipalityResidence")}
                                    disabled
                                    value={values.residenceMunicipality}
                                    className='household-data-map__input'
                                />
                            </div>
                            <div className='gird-y household-data-map__field'>
                                <TextField
                                    label={t("home.completeRegistration.householdData.neighborhoodResidence")}
                                    value={values.residenceNeighborhood}
                                    error={!!errors.residenceNeighborhood}
                                    onFocus={() => validateFocusFields("focus")}
                                    onBlur={() => validateFocusFields("blur")}
                                    onChange={(value) => onChange(value, 'residenceNeighborhood')}
                                    className='household-data-map__input'
                                />
                            </div>
                            <div className='gird-y  household-data-map__field'>
                                <TextField
                                    label={t("home.completeRegistration.householdData.residenceAddress")}
                                    value={values.residenceAddress}
                                    error={!!errors.residenceAddress}
                                    onFocus={() => validateFocusFields("focus")}
                                    onBlur={() => validateFocusFields("blur")}
                                    onChange={(value) => onChange(value, 'residenceAddress')}
                                    className='household-data-map__input'
                                />
                            </div>
                            <div className='gird-y  household-data-map__field'>
                                <Autocomplete
                                    sx={{ width: "100%", marginTop: "10px" }}
                                    options={typeOfHouse}
                                    autoHighlight
                                    getOptionLabel={(option) => option}
                                    popupIcon={
                                        <img size={16} alt="icon_dropdown_arrow" src={Assets.HomeIcons.icon_dropdown_arrow} />
                                    }
                                    onFocus={() => validateFocusFields("focus")}
                                    onBlur={() => validateFocusFields("blur")}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    value={values.typeOfHousing !== undefined && typeOfHouse?.length
                                        ? typeOfHouse[values.typeOfHousing]
                                        : null
                                    }
                                    onChange={(e, value) => onChange(value, 'typeOfHousing')}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{
                                                backgroundColor: "#F1F1F8",
                                                borderBottom: "1px solid #887EA6",
                                            }}
                                            {...props}
                                        >
                                            {option}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("home.completeRegistration.householdData.typeHousing")}
                                            className="household-data-map__input-autocomplete"
                                            error={!!errors.typeOfHousing}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "off",
                                                readOnly: true
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className='gird-y  household-data-map__field'>
                                <Autocomplete
                                    sx={{ width: "100%", marginTop: "10px" }}
                                    options={optionsStratum}
                                    autoHighlight
                                    getOptionLabel={(option) => option}
                                    popupIcon={
                                        <img size={16} alt="icon_dropdown_arrow" src={Assets.HomeIcons.icon_dropdown_arrow} />
                                    }
                                    onFocus={() => validateFocusFields("focus")}
                                    onBlur={() => validateFocusFields("blur")}
                                    onChange={(e, value) => onChange(value, 'stratum')}
                                    value={values.stratum
                                        ? optionsStratum?.length && optionsStratum.indexOf(values.stratum) !== -1
                                            ? optionsStratum[optionsStratum.indexOf(values.stratum)]
                                            : null
                                        : null
                                    }
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{
                                                backgroundColor: "#F1F1F8",
                                                borderBottom: "1px solid #887EA6",
                                            }}
                                            {...props}
                                        >
                                            {option}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("home.completeRegistration.householdData.stratum")}
                                            className="household-data-map__input-autocomplete"
                                            error={!!errors.stratum}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "off",
                                                readOnly: true
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className='gird-y  household-data-map__field'>
                                <TextField
                                    label={t("home.completeRegistration.householdData.peopleLive")}
                                    value={values.peopleLiveYou}
                                    error={!!errors.peopleLiveYou}
                                    onFocus={() => validateFocusFields("focus")}
                                    onBlur={() => validateFocusFields("blur")}
                                    onChange={(value) => onChange(value, 'peopleLiveYou')}
                                    className='household-data-map__input'
                                />
                            </div>
                            {/* <div className='gird-y  household-data-map__field'>
                                <div className={`grid-x align-center-middle ${errors.imgResidence
                                    ? 'household-data-map__photo-residence__error'
                                    : 'household-data-map__photo-residence'}`
                                }>
                                    <input
                                        className="household-data-map__hidden"
                                        type="file"
                                        id="fileImg"
                                        onChange={(e) => onChange(e, "imgResidence")}
                                        accept="image/jpg, image/jpeg"
                                        value={selectImg}
                                    />
                                    <label
                                        className='household-data-map__photo-residence__icon grid-x align-center-middle'
                                        htmlFor='fileImg'
                                    >
                                        <img
                                            alt='icon_camera'
                                            src={Assets.HomeIcons.icon_camera}
                                        />
                                    </label>
                                    <img
                                        alt='icon_circle_check'
                                        src={Assets.SharedIcons.icon_circle_check}
                                        className={values.imgResidence
                                            ? "household-data-map__photo-residence__icon__check"
                                            : "household-data__hidden"
                                        }
                                    />
                                    {values.imgResidence ?
                                        <div className='small-8 grid-y'>
                                            <span className='household-data-map__photo-residence__name'>{nameImgResidence()}</span>
                                            <div className='grid-x justify-content-between'>
                                                <button
                                                    onClick={() => {
                                                        setValues({ ...values, imgResidence: "" })
                                                        setState({ ...state, selectImg: "" })
                                                        setInfoUserCompleteProfileReducer("imgResidence", "")
                                                    }}
                                                    className="grid-x align-center-middle"
                                                >
                                                    <span className='household-data-map__photo-residence__button'>
                                                        {t("home.completeRegistration.householdData.delete")}
                                                    </span>
                                                    <img
                                                        alt='icon_delete'
                                                        src={Assets.HomeIcons.icon_delete}
                                                    />
                                                </button>
                                                <label
                                                    htmlFor='fileImg'
                                                    className='grid-x align-center-middle household-data-map__photo-residence__cursor'
                                                >
                                                    <span className='household-data-map__photo-residence__button'>
                                                        {t("home.completeRegistration.householdData.update")}
                                                    </span>
                                                    <img
                                                        alt='icon_update'
                                                        src={Assets.HomeIcons.icon_update}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        :
                                        <span className='small-8 household-data-map__photo-residence__text'>
                                            {t("home.completeRegistration.householdData.addPhoto")}
                                        </span>
                                    }
                                </div>
                            </div> */}
                        </>
                }
            </div>
            <ModalSolveMap visibility={showModalSolve} closeModal={() => setState((prevState) => ({ ...prevState, showModalSolve: false }))} />
        </div>
    )
}

const mapStateToProps = ({ CompleteProfileReducer }) => {
    const { typeOfHouse, user } = CompleteProfileReducer;
    return {
        typeOfHouse,
        user
    };
};

const mapStateToPropsActions = {
    setStateInfoUserCompleteProfileReducer,
    setInfoUserCompleteProfileReducer,
    setStateDataCompleteProfileReducer,
    setStateButtonsCompleteProfileReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(HouseholdDataMap)