// Libraries
import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

const PieChartComponent = (props) => {
    const { data } = props

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart>
                <Pie
                    data={data}
                    dataKey="value"
                    innerRadius='70%'
                    outerRadius='100%'
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Pie>
            </PieChart>

        </ResponsiveContainer>
    )
}

export default PieChartComponent