import * as authenticationTypes from './authentication.types';

export const setStateAuthenticationReducer = (prop, value) => (dispatch) => {
	dispatch({ type: authenticationTypes.SET_STATE_AUTHENTICATION, payload: { prop, value } });
};

export const resetStateAuthenticationReducer = () => async (dispatch) => {
	dispatch({ type: authenticationTypes.RESET_STATE });
};

export const setManyRolesAndGroupByUserAuthenticationReducer = (value) => (dispatch) => {
	dispatch({ type: authenticationTypes.SET_MANY_ROLES_AND_GROUPS, payload: value });
};

export const setProcessValidateSessionAuthenticationReducer = (isCheckingSession, isValidSession) => (dispatch) => {
	dispatch({ type: authenticationTypes.SET_PROCESS_VALIDATE_SESSION, payload: { isCheckingSession, isValidSession } });
};


export const resetFlagsAuthenticationAuthenticationReducer = () => async (dispatch) => {
	dispatch({ type: authenticationTypes.RESET_FLAGS_AUTHENTICATION });
};

export const resetFlagsAuthenticationSemiProtectedRoutesAuthenticationReducer = () => async (dispatch) => {
	dispatch({ type: authenticationTypes.RESET_FLAGS_AUTHENTICATION_SEMI_PROTECTED_ROUTES });
};

export const setFlagProcessValidateSessionSemiProtectedAuthenticationReducer = (isCheckingSession, isValidSession) => (dispatch) => {
	dispatch({ type: authenticationTypes.SET_FLAG_PROCESS_VALIDATE_SESSION_SEMI_PROTECTED_ROUTES, payload: { isCheckingSession, isValidSession } });
};