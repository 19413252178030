// Components - shared
import NavbarComponentsManagerComponent from '../shared/components/nav-bar-components-manager/nav-bar-components-manager.component'
import TabBarComponentsManagerComponent from '../shared/components/tab-bar-components-manager/components/tab-bar-components-manager.component';

// Libraries
import { Navigate, Route, Routes } from 'react-router-dom'

// Views - Components shared
import MapPage from '../pages/home/map/map.page'

// Views - Screen
import MyNetwork from '../pages/home/my-network/my-network.page'
import ReportsPage from '../pages/home/reports/reports.page'

const MainRoutes = (props) => {
    return (
        <>
            <NavbarComponentsManagerComponent />
            <div>
                <Routes>
                    <Route path="/network" element={<MyNetwork />} />
                    <Route path="/reports" element={<ReportsPage />} />
                    <Route path="/map" element={<MapPage />} />

                    <Route path="*" element={<Navigate replace to="/network" />} />
                </Routes>
            </div>
            <TabBarComponentsManagerComponent />
        </>
    )
};

export default MainRoutes;