const MyNetworkEndPoints =  {
    USER_CHILDREN: `/api/users/getCountUserChildren`,
    USER_CHILDREN_ALL: `/api/users/getCountAllChildren`,
    GET_USER: `/api/users/getUser`,
    USER_ROOT_CHANGE: `/api/users/changeUserRoot`,
    GET_LEVELS_LEADERS:`/api/users/getLevelsLeadersInactive`,
    GET_TOTAL_MEMBERS_AND_LEADERS:`/api/users/getTotalAndLeaders`,
    VALIDATE_NUMBER_IDENTIFICATION: `/api/auth/userAlreadyExist`,
    RESEND_INVITATION: `/api/users/resendInvitation`,
    DELETE_USER_DECLINE_INVITATION: `/api/users/deleteUserDeclineInvitation`,
    GET_FILES_FROM_API_FILES: `/api/users/getFilesFromApiFiles`,
    UNSUBSCRIBE_USER: `/api/users/unsubscribe`
}

export default MyNetworkEndPoints