// Assets
import { Assets } from '../../../../../assets';

// Libraries
import React, { useState } from 'react';
import Modal from 'react-modal';
import { t } from 'i18next';

// Styles
import './modal-solve-map.component.scss'

const ModalSolveMap = ({
    // functions
    closeModal = () => { },
    // variables
    visibility = false
}) => {
    const [current, setCurrent] = useState(0);

    const taps = [
        {
            title: t("home.completeRegistration.householdData.modalSolve.android"),
            content: (<>
                <li>
                    {t("home.completeRegistration.householdData.modalSolve.content.android.first")}
                    <img src={Assets.SharedIcons.icon_chrome} alt='icon_chrome' />.
                </li>
                <li>
                    {t("home.completeRegistration.householdData.modalSolve.content.android.second-one")}
                    <strong>{t("home.completeRegistration.householdData.modalSolve.content.android.second-two")}</strong>
                    <img src={Assets.SharedIcons.icon_three_points_gray} alt='icon_three_points_gray' />
                    <img src={Assets.SharedIcons.icon_arrow_gray} alt='icon_arrow_gray' />
                    <strong>{t("home.completeRegistration.householdData.modalSolve.content.android.second-tree")}</strong>
                </li>
                <li>
                    {t("home.completeRegistration.householdData.modalSolve.content.android.third-one")}
                    <strong>{t("home.completeRegistration.householdData.modalSolve.content.android.third-two")}</strong>
                    <img src={Assets.SharedIcons.icon_arrow_gray} alt='icon_arrow_gray' />
                    <strong>{t("home.completeRegistration.householdData.modalSolve.content.android.third-tree")}</strong>
                </li>
                <li>
                    {t("home.completeRegistration.householdData.modalSolve.content.android.fourth-one")}
                    <strong>{t("home.completeRegistration.householdData.modalSolve.content.android.fourth-two")}</strong>
                </li>
            </>)
        },
        {
            title: t("home.completeRegistration.householdData.modalSolve.ios"),
            content: (<>
                <li>{t("home.completeRegistration.householdData.modalSolve.content.ios.first")}</li>
                <li>{t("home.completeRegistration.householdData.modalSolve.content.ios.second-one")} <strong>{t("home.completeRegistration.householdData.modalSolve.content.ios.second-two")}</strong></li>
                <li>{t("home.completeRegistration.householdData.modalSolve.content.ios.third")}</li>
                <li>{t("home.completeRegistration.householdData.modalSolve.content.ios.fourth")}</li>
            </>)
        }
    ]

    return (
        <Modal
            bodyOpenClassName="moda-solve-map__body"
            isOpen={visibility}
            onRequestClose={closeModal}
            ariaHideApp={false}
            overlayClassName="moda-solve-map__overlay grid-x align-center-middle"
            className="moda-solve-map"
        >
            <div className="moda-solve-map__close-container">
                <button className="moda-solve-map__button-close" onClick={closeModal}>
                    <span className="moda-solve-map__button-close__text">{t("home.completeRegistration.householdData.modalSolve.close")}</span>
                    <div className="moda-solve-map__button-close__icon">
                        <img className='' src={Assets.SharedIcons.icon_close} alt="icon_logo" />
                    </div>
                </button>
            </div>
            <div className="moda-solve-map__description">
                <span>
                    {t("home.completeRegistration.householdData.modalSolve.description")}
                </span>
            </div>
            <div className="moda-solve-map__steps">
                {taps.map((tap, index) => (
                    <button
                        key={index}
                        className={`moda-solve-map__steps__item ${index === current ? 'moda-solve-map__steps__item--active' : ''}`}
                        onClick={() => setCurrent(index)}
                    >
                        <span>
                            {tap.title}
                        </span>
                    </button>
                ))}
            </div>
            <div className="moda-solve-map__content__container">
                <div className="moda-solve-map__content">
                    <span className="moda-solve-map__content__text-strong">
                        {t("home.completeRegistration.householdData.modalSolve.content.description")}
                    </span>
                    <ol className="moda-solve-map__content__steps" >
                        {taps[current].content}
                    </ol>
                </div>
            </div>
        </Modal>
    )
}

export default ModalSolveMap;