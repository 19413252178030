//Actions
import { 
  setInfoUserCompleteProfileReducer,
  setStateButtonsCompleteProfileReducer,
  setStateDataCompleteProfileReducer 
} from '../../../../../../storage/reducers/complete-profile/complete-profile.actions';

//Assets
import { Assets } from '../../../../../../assets';

//Components - Shared
import ErrorToastComponent from '../../../../../../shared/components/toast/error-toast/error-toast.component';
import SuccessToastComponent from '../../../../../../shared/components/toast/success-toast/success-toast.component';

//Constants - Core
import Constants from '../../../../../../core/constants';

//Libraries
import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

//Styles
import "./work-profile.component.scss"

//Utils
import { getAffirmDenyUtils, getFormatUtils, validateWorkProfile } from '../../../../../../utils/complete-profile.utils';

const WorkProfileComponent = (props) => {

  const {
    //Actions
    setInfoUserCompleteProfileReducer,
    setStateButtonsCompleteProfileReducer,
    setStateDataCompleteProfileReducer,
    // Functions
    onScrollEnd,
    // Variables
    educationLevel,
    currentOccupation,
    user,
    disabledButtonNext,
    economicSectors
  } = props;

  const [t] = useTranslation("translation");

  const INITIAL_STATE = {
    optionAffirmDeny:[],
    selectPdf:"",
    count: 0,
    fieldPercentage: 0,
    characterNumberServices: 500,
    attachPortfolioSelect:"",
    maximumCharacters: 500,
    optionFormat:[],
    isEndScroll: false
  }

  const [state, setState] = useState(INITIAL_STATE);
  const {
    optionAffirmDeny,
    selectPdf,
    count,
    fieldPercentage,
    characterNumberServices,
    attachPortfolioSelect,
    maximumCharacters,
    optionFormat,
    isEndScroll
  } = state;

  useEffect(() => {

    getAffirmDenyUtils().then(({ option }) => {
      setState((s) => ({
        ...s,
        optionAffirmDeny: option,
      }));
    })

    getFormatUtils().then(({ options }) => {
      setState((s) => ({
        ...s,
        optionFormat: options,
      }));
    })

    return () => {
      setState({});
    };
  }, [])

  useEffect(() => {
    if (user.idUser && count === 0){
      setValues({
        educationalLevel:!user.educationalLevel && user.educationalLevel !== 0 ? null : user.educationalLevel,
        profession:user.profession || "",
        currentOccupation:!user.currentOccupation && user.currentOccupation !== 0 ? null : user.currentOccupation,
        companyName:user.companyName || "",
        timeExperience:!user.timeExperience && user.timeExperience !== 0 ? "" : user.timeExperience,
        lookingJob:!user.lookingJob && user.lookingJob !== false ? null : user.lookingJob,
        nameLastCompany: user.nameLastCompany || "",
        typeOccupation: user.typeOccupation || "",
        portfolio: !user.portfolio && user.portfolio !== false ? null : user.portfolio,
        socialNetworks: !user.socialNetworks && user.socialNetworks !== false ? null : user.socialNetworks,
        linkSocialNetworks: user.linkSocialNetworks || "",
        format: !user.format && user.format !== 0 ? null : user.format,
        portfolioLink: user.portfolioLink || "",
        economicSector:!user.economicSector && user.economicSector !== 0 ? null : user.economicSector,
        yourCompanyName: user.yourCompanyName || "",
        located: user.located || "",
        descriptionServices: user.descriptionServices || "",
        curriculumVitae: user.curriculumVitae || "",
        attachPortfolio: user.attachPortfolio || ""
      })
      setState({
        ...state,
        count: count + 1
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const { errors, handleSubmit, values, setValues, setFieldValue } = useFormik({
    initialValues: {
      educationalLevel:null,
      profession:"",
      currentOccupation:null,
      companyName:"",
      timeExperience:"",
      lookingJob:null,
      nameLastCompany:"",
      typeOccupation:"",
      portfolio: null,
      socialNetworks: null,
      linkSocialNetworks:"",
      format:null,
      portfolioLink:"",
      economicSector:null,
      yourCompanyName:"",
      located:"",
      descriptionServices: "",
      curriculumVitae: "",
      attachPortfolio:""
    },
    validationSchema: validateWorkProfile
  });

  useEffect(() => {

    if (Object.keys(errors).length > 0 && !disabledButtonNext) {
      setStateDataCompleteProfileReducer("disabledButtonNext",true)
    }
    
    if (Object.keys(errors).length === 0 && disabledButtonNext){
      setStateDataCompleteProfileReducer("disabledButtonNext",false)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  const userRegistrationChanges = (name, value) =>{
    switch (name) {
      case "educationalLevel":
        const indexEducationalLevel = educationLevel.indexOf(value)
        setInfoUserCompleteProfileReducer(name,indexEducationalLevel === -1 ? null : indexEducationalLevel )
        setFieldValue(name,indexEducationalLevel === -1 ? null : indexEducationalLevel )
        break;

      case "currentOccupation":
        const indexCurrentOccupation = currentOccupation.indexOf(value)
        setInfoUserCompleteProfileReducer(name,indexCurrentOccupation === -1 ? null : indexCurrentOccupation)
        setFieldValue(name,indexCurrentOccupation === -1 ? null : indexCurrentOccupation)
        break;

      case "format":
        const indexFormat = optionFormat.indexOf(value)
        setInfoUserCompleteProfileReducer(name,indexFormat === -1 ? null : indexFormat)
        setFieldValue(name,indexFormat === -1 ? null : indexFormat)
        break;

      case "economicSector":
        const indexEconomicSector = economicSectors.indexOf(value)
        setInfoUserCompleteProfileReducer(name,indexEconomicSector === -1 ? null : indexEconomicSector)
        setFieldValue(name,indexEconomicSector === -1 ? null : indexEconomicSector)
        break;

      case "lookingJob":
      case "socialNetworks":
      case "portfolio":
        let responseValue = null
        if(value === null){
          responseValue = value
        }else if(value === "si"){
          responseValue = true
        }else{
          responseValue = false
        }
        setInfoUserCompleteProfileReducer(name,responseValue)
        setFieldValue(name,responseValue)
        break;
      case "timeExperience":
        let calcTimeExperience = /^((0+[1-9])|[1-9]\d*)$/.test(`${value.target.value}`) 
        ? (
            value.target.value.charAt(0) === "0" 
            ? value.target.value.slice(1) 
            : value.target.value
          )
        : (
            !value.target.value || value.target.value === "0" 
            ? value.target.value 
            : values.timeExperience
          )
        setFieldValue(name,calcTimeExperience)
        setInfoUserCompleteProfileReducer(name,calcTimeExperience)
        break;
      case "curriculumVitae":
        const { target: { value: fileImgValue, files: filesCurriculum } } = value;
        const fileValueCurriculum = filesCurriculum[0]
        if(fileValueCurriculum?.type === "application/pdf"){
          if(fileValueCurriculum?.size < 16777216){
            setFieldValue(name,fileValueCurriculum)
            setInfoUserCompleteProfileReducer(name,fileValueCurriculum)
            setState({
              ...state,
              selectPdf:fileImgValue
            })
          }else {
            setFieldValue(name,"")
            setInfoUserCompleteProfileReducer(name,"")
            setState({
              ...state,
              selectPdf: ""
            })
            ErrorToastComponent({
              title: t("home.completeRegistration.workProfile.validationSizePdf"),
              position: Constants.AlertConst.TOP_END_POSITION_TEXT,
              timer: Constants.AlertConst.TIMER_MEDIUM,
              iconHtml:{
                src: Assets.SharedIcons.icon_document_error,
                alt: "icon_document_error"
              },
              iconColor: "transparent"
            });
          }
        }else {
          setState({
            ...state,
            selectPdf: ""
          })
          if(fileValueCurriculum){
            ErrorToastComponent({
              title: t("home.completeRegistration.workProfile.validationDocumentPdf"),
              position: Constants.AlertConst.TOP_END_POSITION_TEXT,
              timer: Constants.AlertConst.TIMER_MEDIUM,
              iconHtml:{
                src: Assets.SharedIcons.icon_alert_pdf,
                alt: "icon_alert_pdf"
              },
              iconColor: "transparent"
            });
          }
        }
        break
      case "attachPortfolio":
        const { target: { value: fileImg, files } } = value;
        const fileValue = files[0]
        if(fileValue?.type === "application/pdf"){
          if(fileValue?.size < 16777216){
            setFieldValue(name,fileValue)
            setInfoUserCompleteProfileReducer(name,fileValue)
            setState({
              ...state,
              attachPortfolioSelect:fileImg
            })
          }else {
            setFieldValue(name,"")
            setInfoUserCompleteProfileReducer(name,"")
            setState({
              ...state,
              attachPortfolioSelect: ""
            })
            ErrorToastComponent({
              title: t("home.completeRegistration.workProfile.validationSizePdf"),
              position: Constants.AlertConst.TOP_END_POSITION_TEXT,
              timer: Constants.AlertConst.TIMER_MEDIUM,
              iconHtml:{
                src: Assets.SharedIcons.icon_document_error,
                alt: "icon_document_error"
              },
              iconColor: "transparent"
            });
          }
        }else {
          setState({
            ...state,
            attachPortfolioSelect: ""
          })
          if (fileValue) {
            ErrorToastComponent({
              title: t("home.completeRegistration.workProfile.validationDocumentPdf"),
              position: Constants.AlertConst.TOP_END_POSITION_TEXT,
              timer: Constants.AlertConst.TIMER_MEDIUM,
              iconHtml:{
                src: Assets.SharedIcons.icon_alert_pdf,
                alt: "icon_alert_pdf"
              },
              iconColor: "transparent"
            });
          }
        }
        break
      case "nameLastCompany":
      case "companyName":
      case "yourCompanyName":
        const { target: { value: nameCompanyValue } } = value;
        setInfoUserCompleteProfileReducer("companyName",nameCompanyValue)
        setValues({
          ...values,
          companyName: nameCompanyValue,
          nameLastCompany: nameCompanyValue,
          yourCompanyName: nameCompanyValue
        })
        break
        default:
        const { target: { value: data } } = value;
        setInfoUserCompleteProfileReducer(name,data)
        setFieldValue(name,data)
        break;
    }
  }

  const validateFocusFields = (text) => {
    const showButtons = text ==='focus' ? false : text === "blur" && true;
    !isEndScroll && setStateButtonsCompleteProfileReducer(showButtons)
  }

  useEffect(() => {
    setState((s) => ({
      ...s,
      characterNumberServices: (maximumCharacters - values.descriptionServices?.length),
      fieldPercentage: ((values.descriptionServices?.length * 100) / maximumCharacters)
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.descriptionServices])

  const validateColorProgressBar = () => {
    if (fieldPercentage > 0 && fieldPercentage <= 85) {
      return "#5F3EF2"
    } else if (fieldPercentage > 85 && fieldPercentage <= 100) {
      return "#FFA849"
    } else if (fieldPercentage > 100) {
      return "#FF0058"
    }
  };

  const nameFile = (values) =>{
    if (values?.name) {
      return values?.name
    } else {
      return values?.split('.com/').pop()
    }
  }

  return (
    <form 
      id='scroll'
      onScroll={() => onScrollEnd(e => (isEndScroll !== e && setState({ ...state, isEndScroll: e })))}
      className='work-profile__box-content'
      onSubmit={handleSubmit}
    >
      <Autocomplete
        sx={{ width: "100%", marginTop: "10px" }}
        options={educationLevel}
        autoHighlight
        getOptionLabel={(option) => option}
        popupIcon={
          <img size={16} alt="icon_dropdown_arrow" src={Assets.HomeIcons.icon_dropdown_arrow} />
        }
        onChange={(e,value) => userRegistrationChanges("educationalLevel",value)}
        value={!values.educationalLevel && values.educationalLevel !== 0 ? null  : educationLevel[values.educationalLevel]}
        onFocus={()=> validateFocusFields("focus")}
        onBlur={()=> validateFocusFields("blur")}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{
              backgroundColor: "#F1F1F8",
              borderBottom: "1px solid #887EA6",
            }}
            {...props}
          >
            {option}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("home.completeRegistration.workProfile.educationalLevel")}
            className="work-profile__input-autocomplete"
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
              readOnly: true
            }}
            error={errors.educationalLevel ? true : false}
          />
        )}
      />
      <TextField
        sx={{ width: "100%", marginTop: "40px" }}
        className="work-profile__input"
        label={t("home.completeRegistration.workProfile.profession")}
        error={errors.profession ? true : false}
        onChange={(value) => userRegistrationChanges("profession",value)}
        value={values.profession}
        onFocus={()=> validateFocusFields("focus")}
        onBlur={()=> validateFocusFields("blur")}
        autoComplete="off"
      />
      <Autocomplete
        sx={{ width: "100%", marginTop: "40px" }}
        options={currentOccupation}
        autoHighlight
        getOptionLabel={(option) => option}
        popupIcon={
          <img size={16} alt="icon_dropdown_arrow" src={Assets.HomeIcons.icon_dropdown_arrow} />
        }
        onChange={(e,value) => userRegistrationChanges("currentOccupation",value)}
        value={!values.currentOccupation && values.currentOccupation !== 0 ? null  : currentOccupation[values.currentOccupation]}
        onFocus={()=> validateFocusFields("focus")}
        onBlur={()=> validateFocusFields("blur")}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{
              backgroundColor: "#F1F1F8",
              borderBottom: "1px solid #887EA6",
            }}
            {...props}
          >
            {option}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("home.completeRegistration.workProfile.currentOccupation")}
            className="work-profile__input-autocomplete"
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
              readOnly: true
            }}
            error={errors.currentOccupation ? true : false}
          />
        )}
      />
      { (values.currentOccupation === 1 && values.currentOccupation !== 2) &&
        <TextField
          sx={{ width: "100%", marginTop: "50px" }}
          className="work-profile__input-ellipse"
          label={t("home.completeRegistration.workProfile.nameLastCompany")}
          error={errors.nameLastCompany ? true : false}
          onChange={(value) => userRegistrationChanges("nameLastCompany",value)}
          value={values.nameLastCompany}
          onFocus={()=> validateFocusFields("focus")}
          onBlur={()=> validateFocusFields("blur")}
          autoComplete="off"
        />
      }
      { (!values.currentOccupation && values.currentOccupation !== 2) &&
          <TextField
          sx={{ width: "100%", marginTop: "50px" }}
          className="work-profile__input-ellipse"
          label={t("home.completeRegistration.workProfile.nameCompany")}
          error={errors.companyName ? true : false}
          onChange={(value) => userRegistrationChanges("companyName",value)}
          value={values.companyName}
          onFocus={()=> validateFocusFields("focus")}
          onBlur={()=> validateFocusFields("blur")}
          autoComplete="off"
        />
      }
        { values.currentOccupation !== 3 &&
          <TextField
            type="tel"
            sx={{ width: "100%", marginTop: "50px" }}
            className="work-profile__input-ellipse"
            label={t("home.completeRegistration.workProfile.timeWork")}
            error={errors.timeExperience ? true : false}
            onChange={(value) => userRegistrationChanges("timeExperience",value)}
            value={values.timeExperience}
            onFocus={()=> validateFocusFields("focus")}
            onBlur={()=> validateFocusFields("blur")}
            autoComplete="off"
          />
        }
      { (values.currentOccupation === 1 && values.currentOccupation !== 2) &&
        <TextField
          sx={{ width: "100%", marginTop: "50px" }}
          className="work-profile__input-ellipse"
          label={t("home.completeRegistration.workProfile.typeOccupation")}
          error={errors.typeOccupation ? true : false}
          onChange={(value) => userRegistrationChanges("typeOccupation",value)}
          value={values.typeOccupation}
          onFocus={()=> validateFocusFields("focus")}
          onBlur={()=> validateFocusFields("blur")}
          autoComplete="off"
        />
      }
      { values.currentOccupation !== 2 && values.currentOccupation !== 3 &&
        <>
        <Autocomplete
        sx={{ width: "100%", marginTop: "40px" }}
        options={optionAffirmDeny}
        autoHighlight
        getOptionLabel={(option) => option}
        popupIcon={
          <img size={16} alt="icon_dropdown_arrow" src={Assets.HomeIcons.icon_dropdown_arrow} />
        }
        onChange={(e,value) => userRegistrationChanges("lookingJob",value)}
        value={
          optionAffirmDeny?.length > 0
            ? values.lookingJob
              ? optionAffirmDeny[0]
              : values.lookingJob === false
                ? optionAffirmDeny[1]
                : null
            : null
        }
        onFocus={()=> validateFocusFields("focus")}
        onBlur={()=> validateFocusFields("blur")}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{
              backgroundColor: "#F1F1F8",
              borderBottom: "1px solid #887EA6",
            }}
            {...props}
          >
            {option}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("home.completeRegistration.workProfile.currentlyJob")}
            className="work-profile__input-autocomplete"
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
              readOnly: true
            }}
            error={errors.lookingJob ? true : false}
          />
          )}
        />
          <div className={values.lookingJob === true ? 'grid-x work-profile__container' : "work-profile__hidden"}>
            <div className='flex-container work-profile__content'>
          <span className='work-profile__label'>{t("home.completeRegistration.workProfile.attachResume")}</span>
            <label 
              className={
                values.curriculumVitae ?
                  "work-profile__hidden"
                :
                  'grid-x align-center-middle work-profile__label__cursor'
              }
              htmlFor="filePdf"
            >
            <input
              className='work-profile__hidden'
              type="file"
              id="filePdf"
              onChange={(e) => userRegistrationChanges("curriculumVitae",e)}
              accept="application/pdf"
              value={selectPdf}
            />
            <span className='work-profile__upload-file'>{t("home.completeRegistration.workProfile.upload")}</span>
            <img src={Assets.HomeIcons.icon_upload_files} alt="icon_upload_files" />
            </label>
        </div>
        { !values.curriculumVitae ?
          <span className='work-profile__upload-file__info'>
            {t("home.completeRegistration.workProfile.uploadResume")}
              </span>
              :
              <div className='grid-x small-12'>
            <span className='small-12 work-profile__upload-file__info flex-container align-middle'>
              {nameFile(values.curriculumVitae)}
            </span>
            <div className='small-6 grid-x align-center-middle'>
              <button 
                className='flex-container' 
                type='button'
                onClick={()=>{
                  setFieldValue("curriculumVitae","")
                  setInfoUserCompleteProfileReducer("curriculumVitae","")
                  setState({
                    ...state,
                    selectPdf: ""
                  })
                  SuccessToastComponent({
                    title: `${t("home.completeRegistration.workProfile.removeDocument")}`,
                    position: Constants.AlertConst.TOP_END_POSITION_TEXT,
                    timer: Constants.AlertConst.TIMER_MEDIUM,
                    iconHtml: {
                      src: Assets.SharedIcons.icon_progress_success,
                      alt: "icon_progress_success"
                    },
                    iconColor: "transparent"
                  })
                }}
              >
                <span className='work-profile__upload-file__text'>{t("home.completeRegistration.workProfile.delete")}</span>
                <img src={Assets.HomeIcons.icon_delete} alt="icon_upload_files" />
              </button>
            </div>
            <div className='small-6 grid-x align-center-middle'>
              <label 
                className='flex-container work-profile__label__cursor'
                htmlFor="filePdf"
              >
                <span className='work-profile__upload-file__text'>{t("home.completeRegistration.workProfile.change")}</span>
                <img src={Assets.HomeIcons.icon_update} alt="icon_upload_files" />
              </label>
            </div>
              </div>
            }
          </div>
        </>
      }
      { values.currentOccupation === 3 &&
        <>
          <TextField
            sx={{ width: "100%", marginTop: "50px" }}
            className="work-profile__input"
            label={t("home.completeRegistration.workProfile.yourCompanyName")}
            error={errors.yourCompanyName ? true : false}
            onChange={(value) => userRegistrationChanges("yourCompanyName",value)}
            value={values.yourCompanyName}
            onFocus={()=> validateFocusFields("focus")}
            onBlur={()=> validateFocusFields("blur")}
            autoComplete="off"
          />
          <TextField
            sx={{ width: "100%", marginTop: "50px" }}
            className="work-profile__input"
            label={t("home.completeRegistration.workProfile.located")}
            error={errors.located ? true : false}
            onChange={(value) => userRegistrationChanges("located",value)}
            value={values.located}
            onFocus={()=> validateFocusFields("focus")}
            onBlur={()=> validateFocusFields("blur")}
            autoComplete="off"
          />
          <Autocomplete
            sx={{ width: "100%", marginTop: "30px" }}
            options={economicSectors}
            autoHighlight
            getOptionLabel={(option) => option}
            popupIcon={
              <img
                size={16}
                alt="icon_dropdown_arrow"
                src={Assets.HomeIcons.icon_dropdown_arrow}
              />
            }
            onChange={(e, value) =>
              userRegistrationChanges("economicSector", value)
            }
            value={economicSectors?.length > 0 ? (!values.economicSector && values.economicSector !== 0 ? null  : economicSectors[values.economicSector]) : null}
            onFocus={() => validateFocusFields("focus")}
            onBlur={() => validateFocusFields("blur")}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{
                  backgroundColor: "#F1F1F8",
                  borderBottom: "1px solid #887EA6",
                }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("home.completeRegistration.workProfile.economicSector")}
                className="work-profile__input-autocomplete"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off",
                  readOnly: true
                }}
                error={errors.economicSector ? true : false}
              />
            )}
          />
        </>
      }
      { (values.currentOccupation === 2 || values.currentOccupation === 3) &&
        <>
          <label className="work-profile__services__circular-progress-bar__label">
            {t("home.completeRegistration.workProfile.servicesOffered")}
          </label>
          <div className={errors.descriptionServices ? "work-profile__services__info--mod": "work-profile__services__info"}>
            <textarea
              style={{
                color: characterNumberServices < 0 && validateColorProgressBar(),
              }}
              className="work-profile__services__content-textarea"
              placeholder={t("home.completeRegistration.workProfile.iWorkAs")}
              value={user.descriptionServices}
              onChange={(e) => userRegistrationChanges("descriptionServices",e)}
            />
            <div className="work-profile__services__circular-progress-bar__container">
              <div className="work-profile__services__circular-progress-bar__content">
                <label
                  className="work-profile__services__circular-progress-bar__progress"
                  style={{
                    color: validateColorProgressBar(),
                  }}
                >
                  {characterNumberServices}
                </label>
                <div className="work-profile__services__circular-progress-bar">
                  <CircularProgressbar
                    value={fieldPercentage}
                    styles={buildStyles({
                      pathColor: validateColorProgressBar(),
                      trailColor: "#C7C6DC"
                    })}
                    strokeWidth={15}
                  />
                </div>
              </div>
            </div>
          </div>
          <Autocomplete
            sx={{ width: "100%", marginTop: "30px" }}
            options={optionAffirmDeny}
            autoHighlight
            getOptionLabel={(option) => option}
            popupIcon={
              <img
                size={16}
                alt="icon_dropdown_arrow"
                src={Assets.HomeIcons.icon_dropdown_arrow}
              />
            }
            onChange={(e, value) =>
              userRegistrationChanges("portfolio", value)
            }
            value={
              optionAffirmDeny?.length > 0
                ? values.portfolio
                  ? optionAffirmDeny[0]
                  : values.portfolio === false
                  ? optionAffirmDeny[1]
                  : null
                : null
            }
            onFocus={() => validateFocusFields("focus")}
            onBlur={() => validateFocusFields("blur")}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{
                  backgroundColor: "#F1F1F8",
                  borderBottom: "1px solid #887EA6",
                }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("home.completeRegistration.workProfile.portfolio")}
                className="work-profile__input-autocomplete"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off",
                  readOnly: true
                }}
                error={errors.portfolio ? true : false}
              />
            )}
          />
          { values.portfolio &&
            <>
            <Autocomplete
            sx={{ width: "100%", marginTop: "30px" }}
            options={optionFormat}
            autoHighlight
            getOptionLabel={(option) => option}
            popupIcon={
              <img
                size={16}
                alt="icon_dropdown_arrow"
                src={Assets.HomeIcons.icon_dropdown_arrow}
              />
            }
            onChange={(e, value) =>
              userRegistrationChanges("format", value)
            }
            value={optionFormat?.length > 0 ? (!values.format && values.format !== 0 ? null  : optionFormat[values.format]) : null}
            onFocus={() => validateFocusFields("focus")}
            onBlur={() => validateFocusFields("blur")}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{
                  backgroundColor: "#F1F1F8",
                  borderBottom: "1px solid #887EA6",
                }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("home.completeRegistration.workProfile.format")}
                className="work-profile__input-autocomplete"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off",
                  readOnly: true
                }}
                error={errors.format ? true : false}
              />
              )}
            />
                <div className={ values.format === 0 ? "grid-x work-profile__container": "work-profile__hidden"}>
                  <div className="flex-container work-profile__content">
                    <span className="work-profile__label">
                      {t("home.completeRegistration.workProfile.attachPortfolio")}
                    </span>
                    <label
                      className={
                        values.attachPortfolio
                          ? "work-profile__hidden"
                          : "grid-x align-center-middle work-profile__label__cursor"
                      }
                      htmlFor="fileAttachPortfolio"
                    >
                      <input
                        className="work-profile__hidden"
                        type="file"
                        id="fileAttachPortfolio"
                        onChange={(e) =>
                          userRegistrationChanges("attachPortfolio", e)
                        }
                        accept="application/pdf"
                        value={attachPortfolioSelect}
                      />
                      <span className="work-profile__upload-file">
                        {t("home.completeRegistration.workProfile.upload")}
                      </span>
                      <img
                        src={Assets.HomeIcons.icon_upload_files}
                        alt="icon_upload_files"
                      />
                    </label>
                  </div>
                  {!values.attachPortfolio ? (
                    <span className="work-profile__upload-file__info">
                      {t("home.completeRegistration.workProfile.uploadResume")}
                    </span>
                  ) : (
                    <div className="grid-x small-12">
                      <span className="small-12 work-profile__upload-file__info flex-container align-middle">
                        {nameFile(values?.attachPortfolio)}
                      </span>
                      <div className="small-6 grid-x align-center-middle">
                        <button
                          className="flex-container"
                          type="button"
                          onClick={() => {
                            setFieldValue("attachPortfolio","");
                            setInfoUserCompleteProfileReducer("attachPortfolio","");
                            setState({
                              ...state,
                              attachPortfolioSelect: "",
                            });
                            SuccessToastComponent({
                              title: `${t("home.completeRegistration.workProfile.removeDocument")}`,
                              position: Constants.AlertConst.TOP_END_POSITION_TEXT,
                              timer: Constants.AlertConst.TIMER_MEDIUM,
                              iconHtml: {
                                src: Assets.SharedIcons.icon_progress_success,
                                alt: "icon_progress_success"
                              },
                              iconColor: "transparent"
                            })
                          }}
                        >
                          <span className="work-profile__upload-file__text">
                            {t("home.completeRegistration.workProfile.delete")}
                          </span>
                          <img
                            src={Assets.HomeIcons.icon_delete}
                            alt="icon_upload_files"
                          />
                        </button>
                      </div>
                      <div className="small-6 grid-x align-center-middle">
                        <label
                          className="flex-container work-profile__label__cursor"
                          htmlFor="fileAttachPortfolio"
                        >
                          <span className="work-profile__upload-file__text">
                            {t("home.completeRegistration.workProfile.change")}
                          </span>
                          <img
                            src={Assets.HomeIcons.icon_update}
                            alt="icon_upload_files"
                          />
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              {values.format === 1 &&
                <>
                <TextField
                  type="url"
                  sx={{ width: "100%", marginTop: "50px" }}
                  className="work-profile__input"
                  label={t("home.completeRegistration.workProfile.portfolioLink")}
                  error={errors.portfolioLink ? true : false}
                  onChange={(value) => userRegistrationChanges("portfolioLink",value)}
                  value={values.portfolioLink}
                  onFocus={()=> validateFocusFields("focus")}
                  onBlur={()=> validateFocusFields("blur")}
                  autoComplete="off"
                />
                </>
              }
            </>
          }
          <Autocomplete
            sx={{ width: "100%", marginTop: "30px" }}
            options={optionAffirmDeny}
            autoHighlight
            getOptionLabel={(option) => option}
            popupIcon={
              <img
                size={16}
                alt="icon_dropdown_arrow"
                src={Assets.HomeIcons.icon_dropdown_arrow}
              />
            }
            onChange={(e, value) =>
              userRegistrationChanges("socialNetworks", value)
            }
            value={
              optionAffirmDeny?.length > 0
                ? values.socialNetworks
                  ? optionAffirmDeny[0]
                  : values.socialNetworks === false
                  ? optionAffirmDeny[1]
                  : null
                : null
            }
            onFocus={() => validateFocusFields("focus")}
            onBlur={() => validateFocusFields("blur")}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{
                  backgroundColor: "#F1F1F8",
                  borderBottom: "1px solid #887EA6",
                }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("home.completeRegistration.workProfile.socialNetworks")}
                className="work-profile__input-autocomplete-ellipse"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off",
                  readOnly: true
                }}
                error={errors.socialNetworks ? true : false}
              />
            )}
          />
          { values.socialNetworks &&
            <>
              <TextField
                sx={{ width: "100%", marginTop: "50px" }}
                className="work-profile__input"
                label={t("home.completeRegistration.workProfile.linkSocialNetworks")}
                error={errors.linkSocialNetworks ? true : false}
                onChange={(value) => userRegistrationChanges("linkSocialNetworks",value)}
                value={values.linkSocialNetworks}
                onFocus={()=> validateFocusFields("focus")}
                onBlur={()=> validateFocusFields("blur")}
                autoComplete="off"
              />
            </>
          }
        </>
      }
    </form>
  );
}

const mapStateToProps = ({ CompleteProfileReducer }) => {
  const { educationLevel, currentOccupation, user, disabledButtonNext, economicSectors } = CompleteProfileReducer;

  return {
    educationLevel,
    currentOccupation,
    user,
    disabledButtonNext,
    economicSectors
  };
};

const mapStateToPropsActions = {
  setInfoUserCompleteProfileReducer,
  setStateButtonsCompleteProfileReducer,
  setStateDataCompleteProfileReducer
};
export default connect(mapStateToProps, mapStateToPropsActions)(WorkProfileComponent); 