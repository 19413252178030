// Models
import BuildErrorInternalAppModel from "../../../../models/aggregates/build-error-internal-app/build-error-internal-app.model"
// Constants
import InfrastructureErrorCodesConst from "./alerts-error-codes.const"

const InfrastructureErrorsConst = {
    responseStatusFailed: new BuildErrorInternalAppModel(InfrastructureErrorCodesConst.RES_IFT_001, "errors.infrastructure.responseStatusFailed"),
    requestNeverReceived: new BuildErrorInternalAppModel(InfrastructureErrorCodesConst.REQ_IFT_001, "errors.infrastructure.requestNeverReceived"),
    responseOtherFail: new BuildErrorInternalAppModel(InfrastructureErrorCodesConst.RES_IFT_002, "errors.infrastructure.responseOtherFail"),
    responseErrorAxiosFound: "errors.infrastructure.responseErrorAxiosFound"
}

export default InfrastructureErrorsConst