//Actions
import {
  setNetworkMyNetworkReducer
} from '../../../../../storage/reducers/my-network/my-network.actions'

//Constants - Core 
import Constants from '../../../../../core/constants';

//Components
import BoxMemberComponent from '../box-member/box-member.component';
import BoxMemberSkeletonComponent from '../../../../../shared/components/skeleton/box-member/box-member-skeleton.component';

//Libraries
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//Services
import { getTokenService, getUserLoggedService } from '../../../../../services/authentication.services';

//Utils
import { redirectsLogin } from '../../../../../utils/authentication.utils';
import { secondWorkFlowCommonUpdateDataApplication } from '../../../../../utils/my-network.utils';

const UserLevelComponent = (props) => {
  const {
    //Actions
    setNetworkMyNetworkReducer,
    //  Variables
    levelsNetwork,
    loadingBoxMemberActivated,
    innerWidth
  } = props;

  const INITIAL_STATE = {
    levelsNetworkState: []
  }

  const [state, setState] = useState(INITIAL_STATE)
  const { levelsNetworkState } = state;
  let history = useNavigate();

  useEffect(() => {
    let token = getTokenService()
    async function fetchData() {
      const userLogged = getUserLoggedService()
      try {
        if (token && userLogged && userLogged.idUser) {
          const { dataLevelDetermined } = await secondWorkFlowCommonUpdateDataApplication(userLogged.idUser, token)

          setNetworkMyNetworkReducer({
            levelMyNetwork: dataLevelDetermined,
            indexReference: -1
          })
          setState({
            levelsNetworkState: [dataLevelDetermined]
          })
        }

      } catch (err) {
        if(redirectsLogin(err)) history("/login", { replace: true })
      }
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNetworkMyNetworkReducer])

  useEffect(() => {
    setState({
      levelsNetworkState: levelsNetwork
    });
  }, [levelsNetwork])

  useEffect(() => {
    return () => {
      setState({})
    }
  }, [])

  return (
    <div style={{marginBottom:"60px"}}>
      {innerWidth < Constants.AppConst.MEDIUM_WIDTH_SCREEN_SIZE &&
        levelsNetworkState.length === 1 &&
        loadingBoxMemberActivated !== null ?
        null :
        <>
          {levelsNetworkState.map(({ memberByLevel, overviewNetwork }, index) => {
            return (
              <BoxMemberComponent
                key={index}
                level={memberByLevel}
                index={index}
                totalMemberLevel={overviewNetwork?.totalChildren}
              />
            );
          })
          }
        </>
      }
      {loadingBoxMemberActivated !== null && loadingBoxMemberActivated === levelsNetwork.length - 1 &&
        <BoxMemberSkeletonComponent
          boxLevelIndex={loadingBoxMemberActivated}
        />}
    </div>
  );

}

const mapStateToPropsActions = {
  setNetworkMyNetworkReducer
};

const mapStateToProps = ({ MyNetworkReducer, AppReducer }) => {
  const { levelsNetwork, loadingBoxMemberActivated } = MyNetworkReducer;
  const { windowSize: { innerWidth } } = AppReducer;
  return {
    levelsNetwork,
    loadingBoxMemberActivated,
    innerWidth
  };
};

export default connect(mapStateToProps, mapStateToPropsActions)(UserLevelComponent);