// Assets
import { Assets } from '../../../../../../../assets';

// Libraries
import { t } from 'i18next';
import React, { useRef } from 'react';
import Xarrow, { Xwrapper } from 'react-xarrows';

// Styles
import './leaders-information.component.scss';


const LeaderIcon = () => {
    return (
        <>
            <div className='leaders-information__icon-container grid-x justify-content-middle'>
                <img className='leaders-information__icon-container__img' src={Assets.SharedIcons.icon_profile} alt="icon_logo" />
                <div className='leaders-information__icon-container__count grid-x align-center-middle'>
                    50
                </div>
            </div>
            <span className='leaders-information__icon-container__text '>{t("myNetworkPage.modalInformation.leaders.labelIcon")}</span>
        </>
    );
}

const LeadersSummary = () => {
    const reference = {
        img: { id: 'img', ref: useRef(null) },
        icon: { id: 'icon', ref: useRef(null) },
        name: { id: 'name', ref: useRef(null) },
    }
    return (
        <div className='grid-x leaders-information__summary'>
            <Xwrapper>
                <div className='grid-y small-6 leaders-information__summary__panel'>
                    <div className='grid-y align-middle leaders-information__summary__img'>
                        <div className='leaders-information__summary__icon-container grid-y justify-content-middle'>
                            <div
                                id={reference.img.id}
                                className='leaders-information__summary__icon-container__icon-content'
                            >
                                <img
                                    className='leaders-information__summary__icon-container__icon'
                                    src={Assets.SharedIcons.icon_profile}
                                    alt="icon_logo"
                                />
                            </div>
                            <div
                                id={reference.icon.id}
                                className='leaders-information__summary__icon-container__count grid-x align-center-middle'
                            >
                                50
                            </div>
                        </div>
                        <span
                            id={reference.name.id}
                            className='leaders-information__summary__icon-container__text '
                        >
                            {t("myNetworkPage.modalInformation.leaders.summary.labelIcon")}
                        </span>
                    </div>
                    <div ref={reference.name.ref} className='leaders-information__summary__box'>
                        <span>{t("myNetworkPage.modalInformation.leaders.summary.boxOne")}</span>
                    </div>
                </div>
                <div className='grid-y small-6 leaders-information__summary__panel'>
                    <div className='leaders-information__summary__box'>
                        <span ref={reference.img.ref}>{t("myNetworkPage.modalInformation.leaders.summary.boxTwo.fistParagraph")}</span>
                        <p />
                        <span>{t("myNetworkPage.modalInformation.leaders.summary.boxTwo.secondParagraph")}</span>
                    </div>
                    <div className='leaders-information__summary__box__third'>
                        <span ref={reference.icon.ref}>{t("myNetworkPage.modalInformation.leaders.summary.boxThree")}</span>
                    </div>
                </div>
                <Xarrow
                    start={reference.name.id}
                    end={reference.name.ref}
                    startAnchor='bottom'
                    endAnchor='top'
                    strokeWidth={2}
                    headSize={4}
                    color='#371f7a'
                />
                <Xarrow
                    start={reference.img.id}
                    end={reference.img.ref}
                    startAnchor='right'
                    endAnchor='left'
                    strokeWidth={2}
                    headSize={4}
                    color='#371f7a'
                    path="grid"
                />
                <Xarrow
                    start={reference.icon.id}
                    end={reference.icon.ref}
                    startAnchor='right'
                    endAnchor='left'
                    strokeWidth={2}
                    headSize={4}
                    color='#371f7a'
                    path="grid"
                />
            </Xwrapper>
        </div>
    )
}

const LeadersDescription = () => {
    return (
        <div className='leaders-information__description'>
            <p className='leaders-information__description__text-description'>{
                t("myNetworkPage.modalInformation.leaders.description.firstParagraph")
            }</p>
            <p className='leaders-information__description__text-description'>{
                t("myNetworkPage.modalInformation.leaders.description.secondParagraph")
            }</p>
            <p className='leaders-information__description__text-description'>{
                t("myNetworkPage.modalInformation.leaders.description.thirdParagraph")
            }</p>
            <p className='leaders-information__description__text-description'>{
                t("myNetworkPage.modalInformation.leaders.description.fourthParagraph")
            }</p>
            <p className='leaders-information__description__text-description'>{
                t("myNetworkPage.modalInformation.leaders.description.fifthParagraph")
            }</p>
        </div>
    )
};

export {
    LeaderIcon,
    LeadersSummary,
    LeadersDescription
};