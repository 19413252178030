// Assets
import { Assets } from '../../../../../../../assets';

// Libraries
import { t } from 'i18next'
import React from 'react'

// Styles
import './levels-information.component.scss'

const LevelsIcon = () => {
    return (
        <div className='grid-y align-center-middle levels-information__icon-container'>
            <img src={Assets.HomeIcons.icon_levels} alt="icon_levels" className='levels-information__icon-container__icon' />
            <span className='levels-information__icon-container__text'>{t("myNetworkPage.modalInformation.levels.labelIcon")}</span>
        </div>
    )
}

const LevelsSummary = ({ navigation }) => {
    return (
        <div className='levels-information__summary'>
            <div className='grid-x  align-middle levels-information__summary__box-one'>
                <div className='levels-information__summary__box-one__img-container'>
                    <img 
                        src={Assets.HomeIcons.icon_levels_summary} 
                        alt='icon_levels_summary' 
                        className='levels-information__summary__box-one__img-container__img'

                        />
                </div>
                <div className='small-6 levels-information__summary__box-one__description'>
                    <p className='levels-information__summary__title'>
                        {t("myNetworkPage.modalInformation.levels.summary.boxOne.title")}
                    </p>
                    <p className='levels-information__summary__description'>
                        {t("myNetworkPage.modalInformation.levels.summary.boxOne.description")}
                    </p>
                </div>
            </div>
            <div className='grid-x levels-information__summary__box-two'>
                <div className='grid-y justify-content-between small-12 levels-information__summary__box-two__first-colum'>
                    <div className='grid-x flex-row-reverse align-middle levels-information__summary__box-two__first-colum__first-row'>
                        <div className='levels-information__summary__box-two__first-colum__content'>
                            <p className='levels-information__summary__title'>
                                {t("myNetworkPage.modalInformation.levels.summary.boxTwo.levelOne.title")}
                            </p>
                            <p className='levels-information__summary__description'>
                                {t("myNetworkPage.modalInformation.levels.summary.boxTwo.levelOne.description")}
                            </p>
                        </div>
                    </div>
                    <div className='grid-x flex-row-reverse align-middle levels-information__summary__box-two__first-colum__second-row'>
                        <div className='levels-information__summary__box-two__first-colum__content'>
                            <p className='levels-information__summary__title'>
                                {t("myNetworkPage.modalInformation.levels.summary.boxTwo.levelTwo.title")}
                            </p>
                            <p className='levels-information__summary__description'>
                                {t("myNetworkPage.modalInformation.levels.summary.boxTwo.levelTwo.description")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='grid-y align-middle levels-information__summary__box-three'>
                <span className='small-5 levels-information__summary__box-three__text'>
                    {t("myNetworkPage.modalInformation.levels.summary.boxThree.description")}
                </span>
                <button onClick={navigation} className='grid-x small-5 align-middle mt-1'>
                    <img className='levels-information__summary__box-three__link__icon' src={Assets.HomeIcons.icon_info} alt='icon_info' />
                    <span className='levels-information__summary__box-three__link__text'>{t("myNetworkPage.modalInformation.levels.summary.boxThree.link")}</span>
                </button>
            </div>
        </div>
    )
}

const LevelsDescription = () => {
    return (
        <div className='levels-information__description'>
            <p className='levels-information__description__text-description'>{
                t("myNetworkPage.modalInformation.levels.description.firstParagraph")
            }</p>
            <p className='levels-information__description__text-description'>{
                t("myNetworkPage.modalInformation.levels.description.secondParagraph")
            }</p>
        </div>
    )
}

export {
    LevelsIcon,
    LevelsSummary,
    LevelsDescription
}
