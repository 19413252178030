//Actions
import {
  setInfoUserCompleteProfileReducer,
  resetInfoUserCompleteProfileReducer,
  setStateDataCompleteProfileReducer,
  setStateButtonsCompleteProfileReducer
} from '../../../../../../storage/reducers/complete-profile/complete-profile.actions';

//Assets
import { Assets } from '../../../../../../assets'

// Components - shared
import ErrorToastComponent from '../../../../../../shared/components/toast/error-toast/error-toast.component';

//Constants - core
import Constants from '../../../../../../core/constants';

//Libraries
import { Autocomplete, Box, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import PhoneInput from "react-phone-input-2";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { connect } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

//Services
import {
  getAllCountryService,
  getAllDepartmentsService,
  getAllMunicipalityService
} from '../../../../../../services/complete-registration.services';
import { getTokenService } from '../../../../../../services/authentication.services';

//Styles
import "./personal-data.component.scss"
import { calendarThemeMemberRegistration } from '../../../../../../shared/styles/themes/calendarThemes';

//Utils
import { getAffirmDenyUtils, validatePersonalData } from '../../../../../../utils/complete-profile.utils';
import { redirectsLogin } from '../../../../../../utils/authentication.utils';

const PersonalDataComponent = (props) => {

  const {
    //Actions
    setInfoUserCompleteProfileReducer,
    resetInfoUserCompleteProfileReducer,
    setStateDataCompleteProfileReducer,
    setStateButtonsCompleteProfileReducer,
    // Functions
    onScrollEnd,
    // Variables
    typeIdentificationNumber,
    gender,
    user,
    allDepartmentsBirth,
    allMunicipalityBirth,
    allCountry,
    disabledButtonNext
  } = props;

  const [t] = useTranslation("translation");
  let history = useNavigate();
  const INITIAL_STATE = {
    optionAffirmDeny: [],
    selectImg: "",
    count: 0,
    isEndScroll: false
  }

  const [state, setState] = useState(INITIAL_STATE);
  const {
    optionAffirmDeny,
    selectImg,
    count,
    isEndScroll
  } = state;

  useEffect(() => {
    getAffirmDenyUtils().then(({ option }) => {
      setState((s) => ({
        ...s,
        optionAffirmDeny: option,
      }));
    })

    allCountryData()
    return () => {
      setState({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user.countryBirth?.uid && !user.countryBirth.cntName && allDepartmentsBirth.length === 0) {
      allDepartments(user.countryBirth)
    }

    if (user.departmentBirth?.uid && !user.departmentBirth.dptName && allMunicipalityBirth.length === 0) {
      allMunicipality(user.departmentBirth)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.countryBirth])

  useEffect(() => {
    if (user.idUser && count === 0) {
      setValues({
        name: user.name || "",
        lastName: user.lastName || "",
        dateBirth: user.dateBirth || "",
        countryBirth: user.countryBirth || null,
        departmentBirth: user.departmentBirth || null,
        municipalityBirth: user.municipalityBirth || null,
        documentNumber: user.documentNumber || "",
        phone: user.phone?.slice(user.phone.length - 10, user.phone.length) || "",
        callSignPhone: user.phone?.slice(1, user.phone.length - 10) ,
        phoneWhatsapp: user.phoneWhatsapp?.slice(user.phoneWhatsapp.length-10, user.phoneWhatsapp.length) || "",
        callSignPhoneWhatsapp: user.phoneWhatsapp?.slice(1, user.phoneWhatsapp.length - 10) || "57",
        genre: !user.genre && user.genre !== 0 ? null : user.genre,
        ethnicity: user.ethnicity === undefined ? null : user.ethnicity,
        disability: user.disability === undefined ? null : user.disability,
        photoProfile: user.photoProfile || null,
        typeIdentification: !user.typeIdentification && user.typeIdentification !== 0 ? null : user.typeIdentification,
      })
      setState({
        ...state,
        count: count + 1
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const { handleSubmit, errors, values, handleChange, setValues, setFieldValue } = useFormik({
    initialValues: {
      name: "",
      lastName: "",
      dateBirth: "",
      countryBirth: null,
      departmentBirth: null,
      municipalityBirth: null,
      documentNumber: "",
      phone: "",
      callSignPhone: "",
      phoneWhatsapp: "",
      callSignPhoneWhatsapp: "",
      genre: null,
      ethnicity: null,
      disability: null,
      photoProfile: null,
      typeIdentification: ""
    },
    validationSchema: validatePersonalData
  });

  useEffect(() => {

    if (Object.keys(errors).length > 0 && !disabledButtonNext) {
      setStateDataCompleteProfileReducer("disabledButtonNext", true)
    }

    if (Object.keys(errors).length === 0 && disabledButtonNext) {
      setStateDataCompleteProfileReducer("disabledButtonNext", false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  const userRegistrationChanges = (name, value) => {
    switch (name) {
      //Autocomplete
      case "municipalityBirth":
        setInfoUserCompleteProfileReducer(name, value)
        break;

      case "callSignPhone":
        handleChange(name)(value)
        setInfoUserCompleteProfileReducer('phone', `+${value}${values.phone}`)
        break;

      case "phone":
        handleChange(name)(value)
        setInfoUserCompleteProfileReducer('phone', `+${values.callSignPhone}${value?.target?.value || ''}`)
        break;

      case "callSignPhoneWhatsapp":
        handleChange(name)(value)
        setInfoUserCompleteProfileReducer('phoneWhatsapp', `+${value}${values.phoneWhatsapp}`);
        break;
      case "phoneWhatsapp":
        handleChange(name)(value)
        setInfoUserCompleteProfileReducer('phoneWhatsapp', `+${values.callSignPhoneWhatsapp}${value?.target?.value || ''}`);
        break;

      case "dateBirth":
        setInfoUserCompleteProfileReducer(name, value ? format(value, "MM/dd/yyyy") : null)
        break;

      case "typeIdentification":
        setInfoUserCompleteProfileReducer(name, value)
        break;

      case "genre":
        const indexGender = gender.indexOf(value)
        setFieldValue(name,indexGender === -1 ? null : indexGender)
        setInfoUserCompleteProfileReducer(name, indexGender === -1 ? null : indexGender)
        break;

      case "ethnicity":
      case "disability":
        let responseValue = null
        if (value === null) {
          responseValue = value
        } else if (value === "si") {
          responseValue = true
        } else {
          responseValue = false
        }
        setInfoUserCompleteProfileReducer(name, responseValue)
        break;

      //Group
      case "countryBirth":
        if (value === null || (value !== user.departmentBirth && user.departmentBirth !== null)) {
          resetInfoUserCompleteProfileReducer({
            [name]: value,
            departmentBirth: null,
            municipalityBirth: null
          })
          setValues({
            ...values,
            [name]: value,
            departmentBirth: null,
            municipalityBirth: null
          })
          if (value !== null && value !== user.departmentBirth && user.departmentBirth !== null) {
            allDepartments(value)
          }
        }
        else {
          setFieldValue(name, value)
          setInfoUserCompleteProfileReducer(name, value)
          allDepartments(value)
        }
        break;
      case "departmentBirth":
        if (value === null || (value !== user.municipalityBirth && user.municipalityBirth !== null)) {
          resetInfoUserCompleteProfileReducer({
            [name]: value,
            municipalityBirth: null
          })
          setValues({
            ...values,
            [name]: value,
            municipalityBirth: null
          })
          if (value !== null && value !== user.municipalityBirth && user.municipalityBirth !== null) {
            allMunicipality(value)
          }
        } else {
          setFieldValue(name, value)
          setInfoUserCompleteProfileReducer(name, value)
          allMunicipality(value)
        }
        break;
      case "photoProfile":
        const { target: { value: fileImg, files } } = value;
        const fileValue = files[0]
        if (fileValue?.type === "image/jpg" || fileValue?.type === "image/jpeg") {
          if (fileValue?.size <= 4194304) {
            setFieldValue(name, fileValue)
            setInfoUserCompleteProfileReducer(name, fileValue)
            setState({
              ...state,
              selectImg: fileImg
            })
          } else {
            setFieldValue(name, "")
            setInfoUserCompleteProfileReducer(name, "")
            setState({
              ...state,
              selectImg: ""
            })
            ErrorToastComponent({
              title: t("home.completeRegistration.personalData.validationSizePhoto"),
              position: Constants.AlertConst.TOP_END_POSITION_TEXT,
              timer: Constants.AlertConst.TIMER_MEDIUM,
              iconHtml: {
                src: Assets.SharedIcons.icon_document_error,
                alt: "icon_document_error"
              },
              iconColor: "transparent"
            });
          }
        } else {
          setState({
            ...state,
            selectImg: ""
          })
          if (fileValue) {
            ErrorToastComponent({
              title: `${t("home.completeRegistration.personalData.validationImgPhoto")}`,
              position: Constants.AlertConst.TOP_END_POSITION_TEXT,
              timer: Constants.AlertConst.TIMER_MEDIUM,
              iconHtml: {
                src: Assets.SharedIcons.icon_alert_pdf,
                alt: "icon_alert_pdf"
              },
              iconColor: "transparent"
            });
          }
        }
        break
      default:
        const { target: { value: data } } = value;
        setInfoUserCompleteProfileReducer(name, data)
        break;
    }
  }

  const allCountryData = async () => {
    let token = getTokenService()
    try {
      const getallCountry = await getAllCountryService(token);
      setStateDataCompleteProfileReducer("allCountry", getallCountry)
    } catch (err) {
      if (redirectsLogin(err)) history("/login", { replace: true });
    }
  };

  const allDepartments = async ({ uid }) => {
    let token = getTokenService()
    try {
      const getAllDepartments = await getAllDepartmentsService(uid, token);
      setStateDataCompleteProfileReducer("allDepartmentsBirth", getAllDepartments)
    } catch (err) {
      if (redirectsLogin(err)) history("/login", { replace: true });
    }
  };

  const allMunicipality = async ({ uid }) => {
    let token = getTokenService()
    try {
      const getAllMunicipality = await getAllMunicipalityService(uid, token);
      setStateDataCompleteProfileReducer("allMunicipalityBirth", getAllMunicipality)
    } catch (err) {
      if (redirectsLogin(err)) history("/login", { replace: true });
    }
  };

  const validateFocusFields = (text) => {
    const showButtons = text === 'focus' ? false : text === "blur" && true;
    !isEndScroll && setStateButtonsCompleteProfileReducer(showButtons)
  }

  return (
    <form
      id='scroll'
      onScroll={() => onScrollEnd(e => (isEndScroll !== e && setState({ ...state, isEndScroll: e })))}
      className='personal-data__box-content'
      onSubmit={handleSubmit}
    >
      <div className="grid-x">
        <div className="grid-y align-middle personal-data__content-photo">
          <img
            alt="icon_profile"
            src={values.photoProfile ? ((typeof values.photoProfile === "string") ? values.photoProfile : URL.createObjectURL(values.photoProfile)) : Assets.SharedIcons.icon_profile}
            className={errors.photoProfile ? "personal-data__icon__error" : "personal-data__icon"}
          />
          <input
            className='personal-data__hidden'
            type="file"
            id="fileImg"
            onChange={(e) => userRegistrationChanges("photoProfile", e)}
            accept="image/jpg, image/jpeg"
            value={selectImg}
          />
          <label className={errors.photoProfile ? "personal-data__text__error" : "text-center personal-data__text"} htmlFor="fileImg">
            <span>
              {values.photoProfile ?
                t("home.completeRegistration.personalData.changePhoto")
                :
                t("home.completeRegistration.personalData.uploadPhoto")
              }
            </span>
          </label>
        </div>
        <div className="personal-data__content grid-y">
          <TextField
            label={t("home.completeRegistration.personalData.yourName")}
            className="personal-data__input"
            onChange={(value) => {
              userRegistrationChanges("name", value)
              handleChange('name')(value)
            }}
            value={values.name}
            onFocus={() => validateFocusFields("focus")}
            onBlur={() => validateFocusFields("blur")}
            error={errors.name ? true : false}
            autoComplete="off"
          />
          <TextField
            label={t("home.completeRegistration.personalData.yourLastName")}
            className="personal-data__input"
            onChange={(value) => {
              userRegistrationChanges("lastName", value)
              handleChange('lastName')(value)
            }}
            value={values.lastName}
            onFocus={() => validateFocusFields("focus")}
            onBlur={() => validateFocusFields("blur")}
            error={errors.lastName ? true : false}
            autoComplete="off"
          />
        </div>
      </div>
      <div className="personal-data__container">
        <ThemeProvider theme={calendarThemeMemberRegistration}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
            <MobileDatePicker
              sx={{
                color: "#5F3EF2"
              }}
              componentsProps={{
                switchViewButton: {
                  sx: { color: "#5F3EF2" }
                },
                leftArrowButton: {
                  sx: { color: "#5F3EF2" }
                },
                rightArrowButton: {
                  sx: { color: "#5F3EF2" }
                }
              }}
              label={t("home.completeRegistration.personalData.yourDateBirth")}
              inputFormat="dd/MM/yyyy"
              onChange={(value) => {
                userRegistrationChanges("dateBirth", value)
                setFieldValue('dateBirth', value)
              }}
              disableFuture={true}
              value={values.dateBirth ? new Date(values.dateBirth) : null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="personal-data__input-date"
                  onFocus={() => validateFocusFields("focus")}
                  onBlur={(value) => {
                    validateFocusFields("blur")
                  }
                  }
                  error={errors.dateBirth ? true : false}
                />
              )}
            />
          </LocalizationProvider>
        </ThemeProvider>
        {errors.dateBirth &&
          <label className="user-invitation-setting__input-select__error-text">
            {errors.dateBirth}
          </label>
        }
        <Autocomplete
          sx={{ width: "100%", marginTop: "40px" }}
          options={allCountry}
          autoHighlight
          getOptionLabel={(option) => option.cntName}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          popupIcon={
            <img size={16} alt="icon_dropdown_arrow" src={Assets.HomeIcons.icon_dropdown_arrow} />
          }
          onChange={(e, value) => {
            userRegistrationChanges("countryBirth", value)
          }}
          onFocus={() => validateFocusFields("focus")}
          onBlur={() => validateFocusFields("blur")}
          value={allCountry.find(country => country.uid === values.countryBirth?.uid) || null}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{
                backgroundColor: "#F1F1F8",
                borderBottom: "1px solid #887EA6",
              }}
              {...props}
            >
              {option.cntName}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("home.completeRegistration.personalData.countryBirth")}
              className="personal-data__input-autocomplete"
              inputProps={{
                ...params.inputProps,
                autoComplete: "off",
              }}
              error={errors.countryBirth ? true : false}
            />
          )}
        />
        <Autocomplete
          sx={{ width: "100%", marginTop: "40px" }}
          options={allDepartmentsBirth}
          autoHighlight
          getOptionLabel={(option) => option.dptName}
          disabled={values.countryBirth?.uid ? false : true}
          popupIcon={
            <img size={16} alt="icon_dropdown_arrow" src={Assets.HomeIcons.icon_dropdown_arrow} />
          }
          onChange={(e, value) => {
            userRegistrationChanges("departmentBirth", value)
          }}
          value={allDepartmentsBirth.find(department => department.uid === values.departmentBirth?.uid) || null}
          onFocus={() => validateFocusFields("focus")}
          onBlur={() => validateFocusFields("blur")}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{
                backgroundColor: "#F1F1F8",
                borderBottom: "1px solid #887EA6",
              }}
              {...props}
            >
              {option.dptName}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("home.completeRegistration.personalData.departmentBirth")}
              className="personal-data__input-autocomplete"
              inputProps={{
                ...params.inputProps,
                autoComplete: "off",
                readOnly: true
              }}
              error={errors.departmentBirth ? true : false}
            />
          )}
        />
        <Autocomplete
          sx={{ width: "100%", marginTop: "40px" }}
          options={allMunicipalityBirth}
          autoHighlight
          getOptionLabel={(option) => option.mnpName}
          disabled={values.departmentBirth?.uid ? false : true}
          popupIcon={
            <img size={16} alt="icon_dropdown_arrow" src={Assets.HomeIcons.icon_dropdown_arrow} />
          }
          onChange={(e, value) => {
            userRegistrationChanges("municipalityBirth", value)
            setFieldValue('municipalityBirth', value)
          }}
          value={allMunicipalityBirth.find(municipality => municipality.uid === values.municipalityBirth?.uid) || null}
          onFocus={() => validateFocusFields("focus")}
          onBlur={() => validateFocusFields("blur")}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{
                backgroundColor: "#F1F1F8",
                borderBottom: "1px solid #887EA6",
              }}
              {...props}
            >
              {option.mnpName}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("home.completeRegistration.personalData.municipalityBirth")}
              className="personal-data__input-autocomplete"
              inputProps={{
                ...params.inputProps,
                autoComplete: "off",
              }}
              error={errors.municipalityBirth ? true : false}
            />
          )}
        />
        <div className="grid-x personal-data__box">
          <div className="personal-data__select__content">
            <Select
              value={values?.typeIdentification}
              onChange={(e) => {
                setFieldValue("typeIdentification", e.target.value)
                userRegistrationChanges("typeIdentification", e.target.value);
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              className="personal-data__select"
              disabled={true}
              IconComponent={(props) => {
                return (
                  <img
                    src={Assets.HomeIcons.icon_dropdown_arrow}
                    alt="icon_dropdown_arrow"
                    className={'personal-data__select__icon ' + props?.className}
                  />
                );
              }}
            >
              {typeIdentificationNumber?.map((option, index) => (
                <MenuItem
                  className="personal-data__select__options"
                  key={option?.value}
                  {...option}
                  value={index}
                >
                  {option?.label}
                </MenuItem>
              ))}
            </Select>
          </div>
          <TextField
            type="number"
            className="personal-data__input-select"
            label={t("home.completeRegistration.personalData.document")}
            error={errors.documentNumber ? true : false}
            onChange={(value) => {
              userRegistrationChanges("documentNumber", value);
              handleChange('documentNumber')(value)
            }}
            value={values.documentNumber}
            onFocus={() => validateFocusFields("focus")}
            onBlur={() => validateFocusFields("blur")}
            autoComplete="off"
            disabled={true}
          />
        </div>
        <div className="grid-x personal-data__box align-middle">
          <PhoneInput
            className="personal-data__input-phone"
            onChange={(phone) => userRegistrationChanges("callSignPhone", phone)}
            onlyCountries={["co", "ve"]}
            country={"co"}
            countryCodeEditable={false}
            inputProps={{ readOnly: "readonly" }}
            value={values.callSignPhone}
          />
          <TextField
            type="number"
            className="personal-data__input-select"
            label={t("home.completeRegistration.personalData.phoneCellPhone")}
            error={errors.phone ? true : false}
            onChange={(value) => {
              userRegistrationChanges("phone", value)
              handleChange('phone')(value)
            }}
            value={values.phone}
            onFocus={() => validateFocusFields("focus")}
            onBlur={() => validateFocusFields("blur")}
            autoComplete="off"
          />
        </div>
        <div className="grid-x personal-data__box align-middle">
        <PhoneInput
            className="personal-data__input-phone"
            onChange={(phone) => userRegistrationChanges("callSignPhoneWhatsapp", phone)}
            onlyCountries={["co", "ve"]}
            country={"co"}
            countryCodeEditable={false}
            inputProps={{ readOnly: "readonly" }}
            value={values.callSignPhoneWhatsapp}
          />
          <TextField
            type="number"
            className="personal-data__input-select"
            label={t("home.completeRegistration.personalData.whatsappLine")}
            error={errors.phoneWhatsapp ? true : false}
            onChange={(value) => userRegistrationChanges("phoneWhatsapp", value)}
            value={values.phoneWhatsapp}
            onFocus={() => validateFocusFields("focus")}
            onBlur={() => validateFocusFields("blur")}
            autoComplete="off"
          />
        </div>
        <Autocomplete
          sx={{ width: "100%", marginTop: "40px" }}
          options={gender}
          autoHighlight
          getOptionLabel={(option) => option}
          popupIcon={
            <img size={16} alt="icon_dropdown_arrow" src={Assets.HomeIcons.icon_dropdown_arrow} />
          }
          onChange={(e, value) => {
            userRegistrationChanges("genre", value)
          }}
          value={values.genre === null ? null : gender[values.genre]}
          onFocus={() => validateFocusFields("focus")}
          onBlur={() => validateFocusFields("blur")}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{
                backgroundColor: "#F1F1F8",
                borderBottom: "1px solid #887EA6",
              }}
              {...props}
            >
              {option}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("home.completeRegistration.personalData.genderIdentifyWith")}
              className="personal-data__input-autocomplete"
              inputProps={{
                ...params.inputProps,
                autoComplete: "off",
                readOnly: true
              }}
              error={errors.genre ? true : false}
            />
          )}
        />
        <Autocomplete
          sx={{ width: "100%", marginTop: "40px" }}
          options={optionAffirmDeny}
          autoHighlight
          getOptionLabel={(option) => option}
          popupIcon={
            <img size={16} alt="icon_dropdown_arrow" src={Assets.HomeIcons.icon_dropdown_arrow} />
          }
          onChange={(e, value) => {
            userRegistrationChanges("ethnicity", value)
            setFieldValue('ethnicity', value === "si" ? true : value === "no" ? false : null)
          }}
          value={
            optionAffirmDeny?.length > 0
              ? values.ethnicity
                ? optionAffirmDeny[0]
                : values.ethnicity === false
                  ? optionAffirmDeny[1]
                  : null
              : null
          }
          onFocus={() => validateFocusFields("focus")}
          onBlur={() => validateFocusFields("blur")}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{
                backgroundColor: "#F1F1F8",
                borderBottom: "1px solid #887EA6",
              }}
              {...props}
            >
              {option}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("home.completeRegistration.personalData.ethnicOrMinority")}
              className="personal-data__input-autocomplete-ellipse"
              inputProps={{
                ...params.inputProps,
                autoComplete: "off",
                readOnly: true
              }}
              error={errors.ethnicity ? true : false}
            />
          )}
        />
        <Autocomplete
          sx={{ width: "100%", marginTop: "40px" }}
          options={optionAffirmDeny}
          autoHighlight
          getOptionLabel={(option) => option}
          popupIcon={
            <img size={16} alt="icon_dropdown_arrow" src={Assets.HomeIcons.icon_dropdown_arrow} />
          }
          onFocus={() => validateFocusFields("focus")}
          onBlur={() => validateFocusFields("blur")}
          onChange={(e, value) => {
            userRegistrationChanges("disability", value)
            setFieldValue('disability', value === "si" ? true : value === "no" ? false : null)
          }}
          value={
            optionAffirmDeny?.length > 0
              ? values.disability
                ? optionAffirmDeny[0]
                : values.disability === false
                  ? optionAffirmDeny[1]
                  : null
              : null
          }
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{
                backgroundColor: "#F1F1F8",
                borderBottom: "1px solid #887EA6",
              }}
              {...props}
            >
              {option}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("home.completeRegistration.personalData.disabilityOrLimitation")}
              className="personal-data__input-autocomplete-ellipse"
              inputProps={{
                ...params.inputProps,
                autoComplete: "off",
                readOnly: true
              }}
              error={errors.disability ? true : false}
            />
          )}
        />
      </div>
    </form>
  );
}

const mapStateToProps = ({ CompleteProfileReducer }) => {
  const {
    typeIdentificationNumber, gender, user,
    allDepartmentsBirth, allMunicipalityBirth,
    allCountry, disabledButtonNext
  } = CompleteProfileReducer;

  return {
    typeIdentificationNumber,
    gender,
    user,
    allDepartmentsBirth,
    allMunicipalityBirth,
    allCountry,
    disabledButtonNext
  };
};

const mapStateToPropsActions = {
  setInfoUserCompleteProfileReducer,
  resetInfoUserCompleteProfileReducer,
  setStateDataCompleteProfileReducer,
  setStateButtonsCompleteProfileReducer,
};
export default connect(mapStateToProps, mapStateToPropsActions)(PersonalDataComponent); 