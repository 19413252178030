//Assets
import { Assets } from '../../../../../../../assets';

//Actions
import { setStateMyNetworkReducer } from '../../../../../../../storage/reducers/my-network/my-network.actions';

//Components - shared
import ErrorToastComponent from '../../../../../../../shared/components/toast/error-toast/error-toast.component';

//Constants - core
import Constants from '../../../../../../../core/constants';

//Libraries
import React, { useState } from 'react'
import Modal from 'react-modal';
import { connect } from 'react-redux';
import PDF from "@mikecousins/react-pdf"
import { useTranslation } from 'react-i18next';

//Styles
import "./preview-document.component.scss"

const PreviewDocumentComponent = (props) => {

    const {
        //Actions
        setStateMyNetworkReducer,
        //Variables
        isModalPreview,
        pdf
    } = props

    const [t] = useTranslation("translation");

    const INITIAL_STATE = {
        numTotalPage: 0,
        numPage: 1,
        zoom: 1,
        isLoading: true,
    }
    const [state, setState] = useState(INITIAL_STATE)
    const {
        numTotalPage,
        numPage,
        zoom,
        isLoading,
    } = state

    const onDocumentComplete = ({_pdfInfo : { numPages }}) =>{
        setState({
            ...state,
            numTotalPage: numPages,
            isLoading: !isLoading,
        })
    }

    const onDocumentError = (err) => {
          ErrorToastComponent({
            title: `${t("errors.myNetwork.workProfile.errorLoadingPdf")}`,
            position: Constants.AlertConst.TOP_END_POSITION_TEXT,
            timer: Constants.AlertConst.TIMER_MEDIUM,
            iconHtml:{
                src: Assets.SharedIcons.icon_alert_error,
                alt: "icon_alert_error"
              },
              iconColor: "transparent"
        });
    }

    const onClose = () =>{
        setStateMyNetworkReducer("isModalPreview", false)
        setState(INITIAL_STATE)
    }

    const onNext = () =>{
        setState({
            ...state,
            numPage: numPage + 1
        })
    }

    const onBack = () =>{
        setState({
            ...state,
            numPage:numPage - 1
        })
    }
  return (
    <Modal
      isOpen={isModalPreview}
      ariaHideApp={false}
      overlayClassName="preview-document__overlay grid-x align-center-middle"
      className="preview-document__content grid-y"
      bodyOpenClassName="preview-document__body"
    >
      <div className='preview-document__container'>
        <div className='grid-x align-right'>
            <button
              onClick={() => onClose()}
            >
              <img
                alt='icon_close'
                src={Assets.SharedIcons.icon_close}
              />
            </button>
        </div>
        <div className='preview-document__pdf'>
            {isLoading &&  
                <div className="grid-y align-center-middle preview-document__loading">
                    <div className='preview-document__loading-spinner'>
                            <img
                                src={Assets.SharedIcons.icon_loading} 
                                alt="icon_loading"
                            />
                    </div>
                    <span className='preview-document__loading__text'>
                            {t("home.myNetwork.workProfile.loadingPdf")}
                    </span>
                </div>
            } 

            {pdf &&
                <div
                    className={
                        isLoading ?
                        'preview-document__pdf__hide'
                        :
                        ''
                    }
                    >
                    <PDF
                        file={pdf}
                        page={numPage}
                        onDocumentLoadFail={onDocumentError}
                        onDocumentLoadSuccess={onDocumentComplete}
                        scale={zoom}
                        className={
                            zoom === 1 ?
                            'preview-document__pdf__adjust'
                            :
                            ""
                        }
                    />  
                </div>
            }
        </div>
        <div className='grid-x justify-content-between preview-document__pdf__container'>
            <div className='grid-x preview-document__pdf__content justify-content-between'>
                <button className='grid-x align-center-middle'
                    onClick={()=>onBack()}
                    disabled={numPage === 1}
                >
                    <img
                        src={numPage === 1 ? Assets.SharedIcons.icon_arrow_inactive : Assets.HomeIcons.icon_dropdown_arrow}
                        alt='icon_dropdown_arrow'
                        className='preview-document__button__back'
                    />
                </button>
                <button
                    className='preview-document__button__text'
                    disabled={numPage === 1}
                    onClick={()=>onBack()}
                >
                    {t("myNetworkPage.viewProfile.previewDocument.previous")}
                </button>
                <span className='preview-document__button__center'>|</span>
                <button
                    className='preview-document__button__text'
                    disabled={numPage === numTotalPage}
                    onClick={()=>onNext()}
                >
                    {t("myNetworkPage.viewProfile.previewDocument.next")}
                </button>
                <button className='grid-x align-center-middle'
                    onClick={()=>onNext()}
                    disabled={numPage === numTotalPage}
                >
                    <img
                        src={numPage === numTotalPage ? Assets.SharedIcons.icon_arrow_inactive : Assets.HomeIcons.icon_dropdown_arrow}
                        alt='icon_dropdown_arrow'
                        className='preview-document__button__back--mod'
                    />
                </button>
            </div>
            <div className='grid-x preview-document__zoom justify-content-between align-middle'>
                <button
                    disabled={zoom === 2}
                    className='preview-document__button__circle'
                    onClick={()=>{
                        setState({
                            ...state,
                            zoom:((zoom*10) + 1 )/ 10
                        })
                    }}
                >
                    <span className='preview-document__button__circle__icon'>
                        +
                    </span>
                </button>
                <span className='preview-document__zoom__text'>{(zoom * 1000 / 10)}%</span>
                <button
                    className='preview-document__button__circle'
                    disabled={zoom === 1 ?  true : false }
                    onClick={()=>{
                        setState({
                            ...state,
                            zoom:((zoom*10) - 1 )/ 10
                        })
                    }}
                >
                    <span className='preview-document__button__circle__icon'>
                        -
                    </span>
                </button>
            </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ MyNetworkReducer }) => {
    const { isModalPreview } = MyNetworkReducer;
    return {
        isModalPreview
    };
  };
  
const mapStateToPropsActions = {
    setStateMyNetworkReducer
  };
    
export default connect(mapStateToProps, mapStateToPropsActions)(PreviewDocumentComponent);