// Libraries
import { t } from 'i18next'
import React from 'react'
import { Assets } from '../../../../../../../assets'

//Styles
import "./members-information.component.scss";

// Utils
import { memberInformation } from '../../../../../../../utils/my-network.utils'

const MembersIcon = () => {
    return (
        <>
            <div className='members-information__icon-container grid-x justify-content-middle'>
                <img className='members-information__icon-container__img' src={Assets.SharedIcons.icon_profile} alt="icon_logo" />
            </div>
            <span className='members-information__icon-container__text '>{t("myNetworkPage.modalInformation.members.labelIcon")}</span>
        </>
    )
}

const MembersSummary = () => {
    return (
        <div className='members-information__summary'>
            {memberInformation.map((member, index) => (
                <div key={index} className='grid-x members-information__summary__member'>
                    <div className='grid-y members-information__summary__member__icon-container'>
                        <div className='members-information__summary__member__icon-container__icon'>
                            <img
                                className={member.isActive
                                    ? 'members-information__summary__member__icon-container__icon__img'
                                    : !member.isRegistered
                                        ? 'members-information__summary__member__icon-container__icon__img--pending'
                                        : 'members-information__summary__member__icon-container__icon__img--inactive'
                                }
                                src={Assets.SharedIcons.icon_profile}
                                alt="icon_profile"
                            />
                            {member.icon
                                ? <img className='members-information__summary__member__icon-container__icon__icon' src={member.icon} alt="icon" />
                                : member.type === t("myNetworkPage.modalInformation.members.summary.leader.type")
                                    ? <div className='members-information__summary__member__icon-container__icon__count grid-x align-center-middle'>
                                        50
                                    </div>
                                    : null
                            }
                        </div>
                        <span className={member.isActive
                            ? 'members-information__summary__member__icon-container__icon__text'
                            : 'members-information__summary__member__icon-container__icon__text--mod'}
                        >
                            {t("myNetworkPage.modalInformation.members.summary.userName")}
                        </span>
                    </div>
                    <div className='grid-y members-information__summary__member__info'>
                        <span className='members-information__summary__member__info__type'>{member.type}</span>
                        <div className='members-information__summary__member__info__description'>
                                 <p className='members-information__summary__member__info__description__paragraph'>{member.description}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

const MembersDescription = () => {
    return (
        <div className='members-information__description'>
            <p className='members-information__description__text-description'>{
                t("myNetworkPage.modalInformation.members.description.firstParagraph")
            }</p>
            <p className='members-information__description__text-description'>{
                t("myNetworkPage.modalInformation.members.description.secondParagraph")
            }</p>
            <p className='members-information__description__text-description'>{
                t("myNetworkPage.modalInformation.members.description.thirdParagraph")
            }</p>
        </div>
    )
}


export {
    MembersIcon,
    MembersSummary,
    MembersDescription
}