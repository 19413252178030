//Constants
import MapEndPoints from "./constants/map.end-points"

//Infrastructure
import Http from "./infrastructure/http.infrastructure"

//Models - DTO
import UserDTO from "../models/user/dto/user.dto"
import LocationDTO from "../models/location/dto/location.dto"
import { mapperToUserModel } from "../models/user/dto/user.dto.dao"
import { mapperToLocationModel } from "../models/location/dto/location.dto.dao"

/* 
 Begin Data Simulate
*/

export async function getVotingStationService(typeLocation, token) {
  try {
      const { data: responseVotingStation, error } = await Http.get(MapEndPoints.VOTING_STATION, { typeLocation } , token);
      if (responseVotingStation && responseVotingStation.status) {
        return responseVotingStation.data
      } else {
          throw error
      }
  } catch (error) {
      throw error
  }
}

export async function getVotingTableService(votingStation, token) {
  try {
      const { data: responseVotingTable, error } = await Http.get(MapEndPoints.VOTING_TABLE, { votingStation } , token);
      if (responseVotingTable && responseVotingTable.status) {
        return responseVotingTable.data
      } else {
          throw error
      }
  } catch (error) {
      throw error
  }
}

export async function getUsersByVotingStationService(votingStation, votingTable, token) {
  try {
    const { data: responseFilterMember, error } = await Http.get(MapEndPoints.USER_BY_VOTING_STATION, { votingStation, votingTable }, token);
    if (responseFilterMember && responseFilterMember.status) {
      const { users, geographicLocation } = responseFilterMember.data;

      const filterMemberUser = users
        ? users.map((element) => (
          mapperToUserModel(new UserDTO(
            element.uid,
            element.usrName,
            element.usrLastName,
            element.usrImgProfile
          ))
        ))
        : [];

      let votingStationResp;

      if (geographicLocation) {
        let Location = new LocationDTO();
        Location.lctGeographicLocation = geographicLocation
        votingStationResp = mapperToLocationModel(Location)
      }

      return {
        filterMemberUser,
        votingStationResp
      };
    } else {
      throw error;
    }
  } catch (error) {
    throw error
  }
}

export async function getUsersBySectorService(sector, token) {
  try {
      const { data: responseFilterMember, error } = await Http.get(MapEndPoints.USER_BY_SECTOR, { sector } , token);
      if (responseFilterMember && responseFilterMember.status) {
        //TODO: requesting changes to the DTO model
        const filterMemberUser = responseFilterMember.data
          ? responseFilterMember.data.map(user => mapperToUserModel({...user, idUser: user.uid}))
          : [];

        return filterMemberUser;
      } else {
        throw error;
      }
  } catch (error) {
      throw error
  }
}

export async function generateReportVotingStation(body, token) {
  try {
      const { data: response, error } = await Http.post(MapEndPoints.GENERATE_REPORT_VOTING_STATION, body , token);
      if (response && response.status) {
        return response.status
      }else{
        throw error;
      }
  } catch (error) {
      throw error
  }
}

export async function generateReportSector(body, token) {
  try {
      const { data: response, error } = await Http.post(MapEndPoints.GENERATE_REPORT_SECTOR, body , token);
      if (response && response.status) {
        return response.status
      }else{
        throw error;
      }
  } catch (error) {
      throw error
  }
}

export async function autocompletePlaceService(body, token) {
  try {
      const { data: response, error } = await Http.get(MapEndPoints.AUTOCOMPLETE_PLACES, body , token);
      if (response && response.status) {
        return response.data
      }else{
        throw error;
      }
  } catch (error) {
      throw error
  }
}
