// Components
import MainNavBarComponent from './components/main-nav-bar/main-nav-bar.component';
import SecondaryNavBarComponent from './components/secondary-nav-bar/secondary-nav-bar.component';

//Libraries";
import { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

const NavbarComponentsManagerComponent = (props) => {

  const {
    //Variables
    showViewProfile,
    isViewTabBar,
    memberMore,
    isShowEditHierarchyComponent,
    isShowUnderlyingLevels,
    isViewContact
  } = props;

  const location = useLocation();

  const INITIAL_STATE = {
    pathModule: ""
  };
  const [state, setState] = useState(INITIAL_STATE);
  const { pathModule } = state;

  useEffect(() => {
    const pathModule = location.pathname
    setState((s) => ({ ...s, pathModule }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location.pathname, isViewContact, memberMore?.isShowViewMore, isViewTabBar,
    showViewProfile.isShowViewProfile, isShowEditHierarchyComponent, isShowUnderlyingLevels
  ])

  const navBarsTemplateManager = () => {

    switch (pathModule) {
      case "/map":
      case "/reports":
        return <MainNavBarComponent />

      case "/network":
        if (showViewProfile.isShowViewProfile || isShowEditHierarchyComponent) {
          return <></>
        };
        if (memberMore?.isShowViewMore || isShowUnderlyingLevels) {
          return <></>
        };
        return <MainNavBarComponent />

      case "/contact-list":
        if (isViewContact) {
          return <></>
        };
        return <SecondaryNavBarComponent />
      default:
        return <></>
    }
  }

  return navBarsTemplateManager()
};

const mapStateToProps = ({ TabbarReducer, MyNetworkReducer }) => {
  const { isViewTabBar, isViewContact } = TabbarReducer;
  const { showViewProfile, memberMore, isShowEditHierarchyComponent, isShowUnderlyingLevels } = MyNetworkReducer;
  return {
    showViewProfile,
    isShowEditHierarchyComponent,
    isViewTabBar,
    memberMore,
    isShowUnderlyingLevels,
    isViewContact
  };
};

export default connect(mapStateToProps, null)(NavbarComponentsManagerComponent);