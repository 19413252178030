//Assets
import { Assets } from "../../../../assets";

//Libraries
import { useTranslation } from "react-i18next";

//Styles
import './loading-app.component.scss'

const LoadingAppComponent = () => {
  const [t] = useTranslation("translation");
  return (
    <div className="align-center-middle grid-y loading-app">
      <div className="align-center-middle grid-y loading-app__container">
      <div className="loading-app__container__loading-spinner">
        <img  
        src={Assets.SharedIcons.icon_loading} 
        alt="icon_loading"
        />
      </div>
      </div>
      <div className=" align-center-middle loading-app__container__text">
      <span>
      {t("home.loadingApp")}
      </span>
      </div>
    </div>
    
  )
}

export default LoadingAppComponent