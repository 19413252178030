// Components
import MainTabBarComponent from './main-tab-bar/main-tab-bar.component';

//Libraries"
import { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

const TabBarComponentsManagerComponent = (props) => {

  const {
    //Variables
    showViewProfile,
    isViewTabBar,
    memberMore,
    isShowEditHierarchyComponent,
    isShowUnderlyingLevels,
    isViewContact
  } = props;

  const location = useLocation();

  const INITIAL_STATE = {
    pathModule: ""
  };
  const [state, setState] = useState(INITIAL_STATE);
  const { pathModule } = state;

  useEffect(() => {
    const pathModule = location.pathname
    setState((s) => ({ ...s, pathModule }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location.pathname, isViewContact, memberMore?.isShowViewMore, isViewTabBar,
    showViewProfile.isShowViewProfile, isShowEditHierarchyComponent, isShowUnderlyingLevels
  ])

  const tabBarsTemplateManager = () => {

    switch (pathModule) {
      case "/map":
      case "/reports":
        return  <></>

      case "/network":
        if (memberMore?.isShowViewMore) {
          return <MainTabBarComponent/>
        };
        if (isShowEditHierarchyComponent || isShowUnderlyingLevels || showViewProfile.isShowViewProfile) {
          return <></>
        };
        return <MainTabBarComponent/>

      case "/contact-list":
        if (isViewContact) {
          return <></>
        };
        return <MainTabBarComponent/>
      default:
        return <></>
    }
  }

  return tabBarsTemplateManager()
};

const mapStateToProps = ({ TabbarReducer, MyNetworkReducer }) => {
  const { isViewTabBar, isViewContact } = TabbarReducer;
  const { showViewProfile, memberMore, isShowEditHierarchyComponent, isShowUnderlyingLevels } = MyNetworkReducer;
  return {
    showViewProfile,
    isShowEditHierarchyComponent,
    isViewTabBar,
    memberMore,
    isShowUnderlyingLevels,
    isViewContact
  };
};

export default connect(mapStateToProps, null)(TabBarComponentsManagerComponent);