//Actions
import { setIsUnsubscribeMemberReducer, setIsUnsubscribeMyAccountReducer, setMemberViewProfileMyNetworkReducer, setShowViewProfileMyNetworkReducer, setStateMyNetworkReducer } from "../../../../../storage/reducers/my-network/my-network.actions";

// Assets
import { Assets } from "../../../../../assets";

//Components
import PersonalInformationComponent from "../personal-information/personal-information.component";
import HouseholdInformationComponent from "../household-information/household-information.component";
import VotingInformationComponent from "../voting-information/voting-information.component";
import WorkProfileComponent from "../work-profile/work-profile.component";
import ContactCardComponent from "../contact-card/contact-card.component";
import ViewProfileSkeletonComponent from "../../../../../shared/components/skeleton/view-profile/view-profile-skeleton.component";

// Constants - core
import Constants from "../../../../../core/constants";

//Libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";

//Services
import { clearKeysAuthenticationService, getTokenService, getUserLoggedService, logoutService, setTotalMembersService } from "../../../../../services/authentication.services";
import { getUserService } from "../../../../../services/my-network.service";

//Styles
import './view-profile.component.scss';

//Utils
import { redirectsLogin } from "../../../../../utils/authentication.utils";
import { firstWorkFlowCommonUpdateDataApplication } from "../../../../../utils/my-network.utils";

const ViewProfileComponent = (props) => {

  const {
    //Actions
    setMemberViewProfileMyNetworkReducer,
    setIsUnsubscribeMyAccountReducer,
    setShowViewProfileMyNetworkReducer,
    setStateMyNetworkReducer,
    setIsUnsubscribeMemberReducer,
    // Variables
    isUnsubscribeMyAccount,
    showViewProfile,
    memberViewProfile,
    isUnsubscribeMember,
    levelsNetwork,
    isTriggerNetworkInformationUpdate
  } = props;

  const [t] = useTranslation("translation");
  let history = useNavigate();
  const INITIAL_STATE = {
    buttonActiveState: 1,
    isLoading: true,
  };
  const [state, setState] = useState(INITIAL_STATE);

const { buttonActiveState, isLoading} = state;

  useEffect(() => {
    if (showViewProfile.isShowViewProfile) {
      async function getUser() {
        let token = getTokenService();
        try {
          const userProfile = await getUserService(
            {uid: showViewProfile.uidMemberSelected},
            token,
          );
          setMemberViewProfileMyNetworkReducer(userProfile);
          setState({
            ...state,
            isLoading: !isLoading
          })
        } catch (err) {
          if(redirectsLogin(err)) history("/login", { replace: true });
        }
      }
      getUser();
    }
    return () => {
      setShowViewProfileMyNetworkReducer({
        isShowViewProfile: false,
        uidMemberSelected: '',
        isUserLoggedSelected: false
      });
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMemberViewProfileMyNetworkReducer, showViewProfile]);  

  useEffect(() => {
    let userLogged = getUserLoggedService();
    if (userLogged && !userLogged.isStateActive && userLogged.isCompletedRegister) {
      setIsUnsubscribeMyAccountReducer(true);
    }
    return () => {
      setMemberViewProfileMyNetworkReducer({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  const selectSectButtonActive = (indexButtonActive) => {
    setState({
      ...state,
      buttonActiveState: indexButtonActive
    })
  };

  const selectClassName = (indexButtonActive) => {
    let selectStyle = '';
    switch (indexButtonActive) {
      case 1:
        selectStyle = 'view-profile__footer view-profile__footer__box-right';
        break;
      case 2:
      case 3:
        selectStyle = 'view-profile__footer view-profile__footer__box-center';
        break;
      case 4:
        selectStyle = 'view-profile__footer view-profile__footer__box-left';
        break;
      default:
        selectStyle = 'view-profile__footer';
        break;
    }
    return selectStyle;
  };

  const exitPlatformFunction = async () => {
    if (isUnsubscribeMyAccount && showViewProfile.isUserLoggedSelected) {
      let token = getTokenService();
      logoutService(token)
        .then(() => {
          setShowViewProfileMyNetworkReducer({
            isShowViewProfile: false,
            uidMemberSelected: '',
            isUserLoggedSelected: false
          });
          clearKeysAuthenticationService();
          setStateMyNetworkReducer('isUnsubscribeMyAccount', false);
          history("/login", { replace: true })
        })
        .catch((err) => {
          if (redirectsLogin(err)) history("/login", { replace: true });
        })
    } else {
      if (isTriggerNetworkInformationUpdate) {
        await fetchData()
      }
      setShowViewProfileMyNetworkReducer({
        isShowViewProfile: false,
        uidMemberSelected: '',
        isUserLoggedSelected: false
      });
      setIsUnsubscribeMemberReducer(false);
    }
  };

  async function fetchData() {

    let token = getTokenService()
    let userLogged = getUserLoggedService()
    if (userLogged && userLogged.idUser && token) {
      try {
        setStateMyNetworkReducer('isTriggerNetworkInformationUpdate', true)
        const responseDataUpdated = await firstWorkFlowCommonUpdateDataApplication(userLogged.idUser, token)

        setTotalMembersService(responseDataUpdated.totalMembersUserLogged)

        let updateFirstLevel = [...levelsNetwork]
        let newFirstLevel = responseDataUpdated.firstLevelData
        if (updateFirstLevel.length > 1) {
          let memberLevelSelected = updateFirstLevel[0].memberByLevel.find(
            (element) => {
              return element.isMemberSelected === true
            }
          );

          let isAddedMemberSeeMore = true

          newFirstLevel.memberByLevel.forEach(element => {
            if (element.member.idUser === memberLevelSelected.member.idUser) {
              isAddedMemberSeeMore = false
              element.isMemberSelected = true
            }
          });

          if (isAddedMemberSeeMore) {

            const remainingMembersAddedInSeeMore = updateFirstLevel[0].memberByLevel.splice(Constants.MyNetworkConst.LIMIT_MEMBERS_TO_DISPLAY)
            let remainingMembersList = []

            for (let indexFindMember = 0; indexFindMember < remainingMembersAddedInSeeMore.length; indexFindMember++) {
              const element = remainingMembersAddedInSeeMore[indexFindMember];
              if (element.member.idUser === memberLevelSelected.member.idUser) {
                element.isMemberSelected = true
                remainingMembersList.push(element)
              }
            }

            newFirstLevel.memberByLevel = [...newFirstLevel.memberByLevel.concat(remainingMembersList)]
          }
        }

        newFirstLevel.overviewNetwork = { ...responseDataUpdated.firstLevelData.overviewNetwork }
        updateFirstLevel[0] = newFirstLevel
        setStateMyNetworkReducer('levelsNetwork', updateFirstLevel)

      } catch (error) {
      }
    }
  }

  return (
    isLoading
    ?
    <ViewProfileSkeletonComponent/>
    :
    <div className="view-profile">
      {((isUnsubscribeMyAccount && showViewProfile.isUserLoggedSelected) || (isUnsubscribeMember && !showViewProfile.isUserLoggedSelected)) &&
        <div className="view-profile__isDisabled">
          {(isUnsubscribeMyAccount && showViewProfile.isUserLoggedSelected)
            ?
              <button
                className="view-profile__isDisabled__exitPlatform"
                onClick={() => exitPlatformFunction()}
              >
                {t("home.myNetwork.exitPlatform")}
              </button>
            : (isUnsubscribeMember && !showViewProfile.isUserLoggedSelected) &&
              <button
                className="view-profile__isDisabled__back"
                onClick={() => exitPlatformFunction()}
              >
                {t("home.myNetwork.back")}
              </button>
          }
        </div>
      }
      <ContactCardComponent
        cardUser={memberViewProfile.cardUser}
        isUserLoggedSelected={showViewProfile.isUserLoggedSelected}
        exitPlatformFunction={() => exitPlatformFunction()}
      />
      <div className="grid-x view-profile__container view-profile__box">
        <button
          className={buttonActiveState === 1
            ? "grid-y small-3 align-center-middle view-profile__box__box-buttons__button-active"
            : "grid-y small-3 align-center-middle view-profile__box__box-buttons"
          }
          onClick={() => selectSectButtonActive(1)}
        >
          <img
            src={buttonActiveState === 1 ? Assets.ViewProfileIcons.icon_user_contact_list : Assets.ViewProfileIcons.icon_user_inactive}
            alt={buttonActiveState === 1 ? "icon_user_contact_list" : "icon_user_inactive"}
            className={buttonActiveState === 1
              ? ((isUnsubscribeMyAccount && showViewProfile.isUserLoggedSelected) || (isUnsubscribeMember && !showViewProfile.isUserLoggedSelected))
                ? "view-profile__box__img__inactive"
                : "view-profile__box__img"
              : "small-3 view-profile__box__img"
            }
          />
          <div className={buttonActiveState === 1 ? "view-profile__box__text" : "view-profile__box__text-inactive"}>
            <span>{t("home.myNetwork.personalData")}</span>
          </div>
        </button>
        <button
          className={buttonActiveState === 2
            ? "grid-y small-3 align-center-middle view-profile__box__box-buttons__button-active"
            : "grid-y small-3 align-center-middle view-profile__box__box-buttons"
          }
          onClick={() => selectSectButtonActive(2)}
        >
          <img
            src={buttonActiveState === 2 ? Assets.ViewProfileIcons.icon_location : Assets.ViewProfileIcons.icon_location_inactive}
            alt={buttonActiveState === 2 ? "icon_location" : "icon_location_inactive"}
            className={buttonActiveState === 2
              ? ((isUnsubscribeMyAccount && showViewProfile.isUserLoggedSelected) || (isUnsubscribeMember && !showViewProfile.isUserLoggedSelected))
                  ? "view-profile__box__img__inactive"
                  : "view-profile__box__img"
              : "small-3 view-profile__box__img"
            }
          />
          <div className={buttonActiveState === 2 ? "view-profile__box__text" : "view-profile__box__text-inactive"}>
            <span>{t("home.myNetwork.homeData")}</span>
          </div>
        </button>
        <button
          className={buttonActiveState === 3
            ? "grid-y small-3 align-center-middle view-profile__box__box-buttons__button-active"
            : "grid-y small-3 align-center-middle view-profile__box__box-buttons"
          }
          onClick={() => selectSectButtonActive(3)}
        >
          <img
            src={buttonActiveState === 3 ? Assets.ViewProfileIcons.icon_vote : Assets.ViewProfileIcons.icon_vote_inactive}
            alt={buttonActiveState === 3 ? "icon_vote" : "icon_vote_inactive"}
            className={buttonActiveState === 3
              ? ((isUnsubscribeMyAccount && showViewProfile.isUserLoggedSelected) || (isUnsubscribeMember && !showViewProfile.isUserLoggedSelected))
                  ? "view-profile__box__img__inactive"
                  : "view-profile__box__img"
              : "small-3 view-profile__box__img"
            }
          />
          <div className={buttonActiveState === 3 ? "view-profile__box__text" : "view-profile__box__text-inactive"}>
            <span>{t("home.myNetwork.votingData")}</span>
          </div>
        </button>
        <button
          className={buttonActiveState === 4
            ? "grid-y small-3 align-center-middle view-profile__box__box-buttons__button-active"
            : "grid-y small-3 align-center-middle view-profile__box__box-buttons"
          }
          onClick={() => selectSectButtonActive(4)}
        >
          <img
            src={buttonActiveState === 4 ? Assets.ViewProfileIcons.icon_profile : Assets.ViewProfileIcons.icon_profile_inactive}
            alt={buttonActiveState === 4 ? "icon_profile" : "icon_profile_inactive"}
            className={buttonActiveState === 4
              ? ((isUnsubscribeMyAccount && showViewProfile.isUserLoggedSelected) || (isUnsubscribeMember && !showViewProfile.isUserLoggedSelected))
                  ? "view-profile__box__img__inactive"
                  : "view-profile__box__img"
              : "small-3 view-profile__box__img"
            }
          />
          <div className={buttonActiveState === 4 ? "view-profile__box__text" : "view-profile__box__text-inactive"}>
            <span>{t("home.myNetwork.jobProfile")}</span>
          </div>
        </button>
      </div>
      <div className={selectClassName(buttonActiveState)}>
        {buttonActiveState === 1 && <PersonalInformationComponent personalDataUser={memberViewProfile.personalDataUser} />}
        {buttonActiveState === 2 && <HouseholdInformationComponent homeDataUser={memberViewProfile.homeDataUser} />}
        {buttonActiveState === 3 && <VotingInformationComponent votingDataUser={memberViewProfile.votingDataUser} />}
        {buttonActiveState === 4 && <WorkProfileComponent jobProfileUser={memberViewProfile.jobProfileUser} user={showViewProfile.uidMemberSelected} />}
      </div>
    </div>
  );
}

const mapStateToProps = ({ MyNetworkReducer }) => {
  const { 
    showViewProfile, memberViewProfile, isUnsubscribeMyAccount, 
    isUnsubscribeMember, levelsNetwork, isTriggerNetworkInformationUpdate
  } = MyNetworkReducer;

  return {
    showViewProfile,
    memberViewProfile,
    isUnsubscribeMyAccount,
    isUnsubscribeMember,
    levelsNetwork,
    isTriggerNetworkInformationUpdate
  };
};

const mapStateToPropsActions = {
  setShowViewProfileMyNetworkReducer,
  setMemberViewProfileMyNetworkReducer,
  setStateMyNetworkReducer,
  setIsUnsubscribeMyAccountReducer,
  setIsUnsubscribeMemberReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(ViewProfileComponent);