// complete-registration
import icon_save from "./complete-registration/icon_save.svg"
import icon_dropdown_arrow from "./complete-registration/icon_dropdown_arrow.svg"
import icon_camera from "./complete-registration/icon_camera.svg"

// my-network
import icon_arrow from "./my-network/icon_arrow.svg"
import icon_plus from "./my-network/icon_plus.svg"
import icon_info from "./my-network/icon_info.svg"
import icon_adjust_hierarchy from "./my-network/icon_adjust_hierarchy.svg"
import icon_dots_vertical_rounded from "./my-network/icon_dots_vertical_rounded.svg"
import icon_pending from './my-network/icon_pending.svg'
import icon_edit_profile from './my-network/icon_edit_profile.svg'
import icon_user_active from './my-network/icon_user_active.svg'
import icon_alert_inactive from './my-network/icon_alert_inactive.svg'
import icon_share from './my-network/icon_share.svg'
import icon_prohibited from './my-network/icon_prohibited.svg'
import icon_levels from './my-network/icon_levels.svg'
import icon_levels_summary from './my-network/icon_levels_summary.svg'

// working-profile
import icon_upload_files from "./complete-registration/upload-files.svg"
import icon_delete from "./complete-registration/delete.svg"
import icon_update from "./complete-registration/update.svg"

// map
import icon_filter from "./map/icon_filter.svg"
import icon_report from "./map/icon_report.svg"
import icon_download from "./map/icon_download.svg"
import icon_clean from "./map/icon_clean.svg"

//reports
import icon_download_active from './reports/icon_download_active.svg'

export const HomeIcons = {
    icon_save,
    icon_dropdown_arrow,
    icon_arrow,
    icon_plus,
    icon_info,
    icon_pending,
    icon_edit_profile,
    icon_user_active,
    icon_alert_inactive,
    icon_share,
    icon_prohibited,
    icon_levels,
    icon_levels_summary,
    icon_adjust_hierarchy,
    icon_dots_vertical_rounded,
    icon_upload_files,
    icon_delete,
    icon_update,
    icon_filter,
    icon_report,
    icon_download,
    icon_camera,
    icon_clean,
    icon_download_active
}