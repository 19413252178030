const storage = window.localStorage

function remove(key) {
    storage.removeItem(key)
}
function set(key, value) {
    storage.setItem(key, JSON.stringify(value))
}

function get(key, isParseValue = false) {
    return isParseValue ? JSON.parse(storage.getItem(key)) : storage.getItem(key)
}

const LocalStorage = {
    remove,
    set,
    get
}

export default LocalStorage