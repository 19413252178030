class RoleModel {

    constructor(idRole, name, description) {
        this.idRole = idRole;
        this.name = name;
        this.description = description;
    }

}

export default RoleModel