// Constants - core
import Constants from "../core/constants"

// Constants - Endpoints
import AuthenticationEndPoints from "./constants/authentication.end-points"

// Infrastructures - constants
import LocalStorage from "./infrastructure/local-storage.infrastructure"
import Http from "./infrastructure/http.infrastructure"

// Models - DAO/DTO
import { mapperToRoleModel } from "../models/role/dto/role.dto.dao"
import { mapperToGroupModel } from "../models/group/dto/group.dto.dao"
import { mapperToUserModel } from "../models/user/dto/user.dto.dao"

// Models - DAO/model
import { mapperToCredentialDTO } from "../models/credential/model/credential.model.dao"

// Models - model
import UserModel from "../models/user/model/user.model"

// Models - DTO
import GroupDTO from "../models/group/dto/group.dto"
import RoleDTO from "../models/role/dto/role.dto"
import UserDTO from "../models/user/dto/user.dto"

/** 
* Begin Block API Services 
**/

export async function logoutService(token) {

    try {
        const { data: responseLogout, error } = await Http.post(AuthenticationEndPoints.LOGOUT, null, token)
        if (responseLogout && responseLogout.status) {
            return responseLogout.status
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}

export async function loginService(CredentialModel) {
    try {
        const credentialDTO = mapperToCredentialDTO(CredentialModel)
        const { data: responseValidatePhoneNumber, error } = await Http.post(AuthenticationEndPoints.LOGIN, credentialDTO)
        if (responseValidatePhoneNumber && responseValidatePhoneNumber.status) {
            return responseValidatePhoneNumber.data
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}

export async function validateCodeService(objectValidation) {
    try {
        const { data: responseValidateCode, error } = await Http.post(AuthenticationEndPoints.VALIDATE_CODE, objectValidation)

        if (responseValidateCode && responseValidateCode.status) {
            const { user, token } = responseValidateCode.data
            if (Array.isArray(user)) {
                let dataLoginBuilded = []

                dataLoginBuilded = user.length === 0 ?
                    [] :
                    (() => {
                        let arrayBuilded = []
                        user.forEach(element => {
                            const roleUserDTO = new RoleDTO(null, element.roleName)
                            const roleUser = mapperToRoleModel(roleUserDTO)

                            const groupAssociatedDTO = new GroupDTO(null, element.groupName, element.groupTarget)
                            const groupAssociated = mapperToGroupModel(groupAssociatedDTO)

                            let userMember = new UserModel()
                            userMember.idUser = element._id

                            arrayBuilded.push({
                                roleUser,
                                groupAssociated,
                                userMember
                            })
                        });
                        return arrayBuilded
                    })();

                return { objectResponse: dataLoginBuilded, token }
            } else {
                const { user, token } = responseValidateCode.data
                const roleDTO = new RoleDTO(user.usrRole, null)
                const groupDTO = new GroupDTO(user.usrGroup.uid, user.usrGroup.grpName)
    
                const userDTO = new UserDTO(
                    user.uid,
                    user.usrName,
                    user.usrLastName,
                    user.usrImgProfile,
                    user.usrIdentificationNumber,
                    undefined,
                    user.usrPhone,
                    user.usrEmail,
                    groupDTO,
                    roleDTO,
                    user.usrRoot,
                    user.usrIsStateActive
                )
                userDTO.usrIsCompletedRegister = user.usrIsRegistered
                    
                return {objectResponse: mapperToUserModel(userDTO), token}
            }

        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}

export async function changePasswordService(newPassword) {
    try {
        const { data: responseChangePassword, error } = await Http.post(AuthenticationEndPoints.CHANGE_PASSWORD, newPassword)
        if (responseChangePassword && responseChangePassword.status) {
            return responseChangePassword.data
        } else {
            return error
        }
    } catch (error) {
        throw error
    }
}

export async function validateSessionService(token) {
    try {
        const { data: responseValidateCode, error } = await Http.get(AuthenticationEndPoints.VALIDATE_TOKEN_SESSION, null, token)
        if (responseValidateCode && responseValidateCode.status) {
                    let {count, user} = responseValidateCode.data
                    const userRoleDTO = new RoleDTO(user.usrRole, null)
                    const userGroupDTO = new GroupDTO(user.usrGroup.uid, user.usrGroup.grpName)
                    let userLogged  = {
                        idUser: user.uid,
                        isStateActive: user.usrIsStateActive,
                        userRoot: user.usrRoot,
                        companyName:user.usrCompanyWorking, 
                        documentNumber:user.usrIdentificationNumber, 
                        typeIdentification:user.usrTypeIdentificationNumber,
                        name:user.usrName,
                        lastName:user.usrLastName,
                        phone:user.usrPhone,
                        email:user.usrEmail, 
                        genre:user.usrGender === undefined ? null : user.usrGender, 
                        ethnicity:user.usrIsEthnicGroup === undefined ? null : user.usrIsEthnicGroup, 
                        disability:user.usrIsDisabilityStatus === undefined ? null : user.usrIsDisabilityStatus, 
                        educationalLevel:user.usrEducationLevel,
                        profession:user.usrProfession,
                        currentOccupation:user.usrCurrentOccupation, 
                        timeExperience: user.usrExperienceMonths === null ? "" : user.usrExperienceMonths,
                        lookingJob:user.usrIsSeekingEmployment,
                        dateBirth:user.usrDateBirth,
                        phoneWhatsapp: user.usrPhoneWhatsapp ? user.usrPhoneWhatsapp : "",
                        countryBirth:{uid:user.usrCountry},
                        departmentBirth:{uid:user.usrDepartment},
                        municipalityBirth:{uid:user.usrMunicipality},
                        votingDepartment:{uid:user.usrDepartmentVotingStation},
                        votingMunicipality:{uid:user.usrMunicipalityVotingStation},
                        votingBooth: {uid:user.usrPlaceVotingStation},
                        votingTable:user.usrVotingTable,

                        residenceCountry: user.usrCountryResidence ,
                        residenceDepartment:user.usrDepartmentResidence,
                        residenceMunicipality:user.usrMunicipalityResidence,
                        residenceNeighborhood:user.usrNeighborhoodResidence,
                        residenceAddress:user.usrAddressResidence,
                        residenceLocation: {
                            lat: user.usrLocation?.gplLatitude,
                            lng: user.usrLocation?.gplLongitude
                        },
                        typeOfHousing:user.usrTypeOfHouse, 
                        stratum:user.usrStratum,
                        peopleLiveYou:user.usrTotalPeopleLiving, 

                        groupAssociated:userGroupDTO,
                        role: userRoleDTO,
                        isCompletedRegister:user.usrIsRegistered,


                        nameLastCompany: user.usrCompanyWorking,
                        typeOccupation: user.usrOfficeHeld,
                        portfolio: user.usrIsServicePortfolio,
                        socialNetworks: user.usrIsSocialNetwork,
                        linkSocialNetworks: user.usrSocialNetworkLink,
                        format: user.usrPortfolioFormatType,
                        portfolioLink: user.usrPortfolioFile,
                        economicSector: user.usrEconomicSector,
                        yourCompanyName: user.usrCompanyWorking,
                        located: user.usrCompanyWorkingLocation,
                        descriptionServices: user.usrDescriptionServiceOffered,
                        photoProfile: user.usrImgProfile,
                        imgResidence:user.lctImgHouse,
                        curriculumVitae: user.usrCurriculum || null,
                        attachPortfolio: user.usrAttachPortfolio || null
                    }
                    
            return {
                totalMembers: count,
                userLogged: userLogged
            }
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}

export async function userGroupSelectedService(uidSelected, token) {
    
    try {
        const { data: responseUserGroupSelected, error } = await Http.post(AuthenticationEndPoints.USER_GROUP_SELECTED, uidSelected, token)
        if (responseUserGroupSelected && responseUserGroupSelected.status) {
            const { user, token } = responseUserGroupSelected.data
            const roleDTO = new RoleDTO(user.usrRole, null)
            const groupDTO = new GroupDTO(user.usrGroup.uid, user.usrGroup.grpName)

            const userDTO = new UserDTO(
                user.uid,
                user.usrName,
                user.usrLastName,
                user.usrImgProfile,
                user.usrIdentificationNumber,
                undefined,
                user.usrPhone,
                undefined,
                user.usrEmail,
                groupDTO,
                roleDTO,
                user.usrRoot,
                user.usrIsStateActive
            )
            userDTO.usrIsCompletedRegister = user.usrIsRegistered
                
            return {userLogged: mapperToUserModel(userDTO), token}
        } else {
            return error
        }
    } catch (error) {
        throw error
    }
}

export async function getUserAuthenticationService(params){
    try {
        const { data: responseUser, error } = await Http.get(AuthenticationEndPoints.GET_USER, {params});
        if (responseUser && responseUser.status) {
            const user = responseUser.data? mapperToUserModel(responseUser.data) : false;
            return user;
        } else {
            return error;
        }
    } catch (error) {
        throw error
    }
}

export async function declineInvitationService(params){
    try {
        const { data: responseUser, error } = await Http.put(AuthenticationEndPoints.DECLINE, {params});
        if (responseUser && responseUser.status) {
            return responseUser.status;
        } else {
            return error
        }
    } catch (error) {
        throw error
    }
}

export async function changeUserDataService(params){
    try {
        const { data: response, error } = await Http.post(AuthenticationEndPoints.CHANGE_DATA, params);
        if (response && response) {
            return response.status;
        } else {
            return error
        }
    } catch (error) {
        throw error
    }
}

/** 
* End Block API Services 
**/

/** 
* Begin Block LocalStorage Services 
**/
export function setTokenService(token) {
    LocalStorage.set(Constants.LocalStorageConst.TOKEN_SESSION, token)
}

export function clearKeysAuthenticationService() {
    LocalStorage.remove(Constants.LocalStorageConst.TOKEN_SESSION)
    LocalStorage.remove(Constants.LocalStorageConst.ROL_USER)
    LocalStorage.remove(Constants.LocalStorageConst.USER_LOGGED)
}

export function getTokenService() {
    return LocalStorage.get(Constants.LocalStorageConst.TOKEN_SESSION, true)
}

export function getUserRolesService() {
    return LocalStorage.get(Constants.LocalStorageConst.ROL_USER, true)
}

export function setUserRolesService(roles) {
    LocalStorage.set(Constants.LocalStorageConst.ROL_USER, roles)
}

export function deleteTokenService() {
    LocalStorage.remove(Constants.LocalStorageConst.TOKEN_SESSION)
}

export function setUserLoggedService(userLogged) {
    LocalStorage.set(Constants.LocalStorageConst.USER_LOGGED, userLogged)
}

export function getUserLoggedService() {
    return LocalStorage.get(Constants.LocalStorageConst.USER_LOGGED, true)
}

export function setTotalMembersService(userLogged) {
    LocalStorage.set(Constants.LocalStorageConst.TOTAL_MEMBERS, userLogged)
}

export function getTotalMembersService() {
    return LocalStorage.get(Constants.LocalStorageConst.TOTAL_MEMBERS, true)
}

export function setNumberDocumentService(document) {
    LocalStorage.set(Constants.LocalStorageConst.NUMBER_DOCUMENT, document)
}

export function getNumberDocumentService() {
    return LocalStorage.get(Constants.LocalStorageConst.NUMBER_DOCUMENT, true)
}

export function clearNumberDocumentService(){
    LocalStorage.remove(Constants.LocalStorageConst.NUMBER_DOCUMENT)
}

export function setValidateCode(credential) {
    LocalStorage.set(Constants.LocalStorageConst.CREDENTIALS_LOGIN, credential)
}

export function isValidateCode() {
    return LocalStorage.get(Constants.LocalStorageConst.CREDENTIALS_LOGIN, true)
}

export function clearValidateCode() {
    LocalStorage.remove(Constants.LocalStorageConst.CREDENTIALS_LOGIN)
}
/** 
* End Block LocalStorage Services 
**/