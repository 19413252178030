// Constants - Errors
import InfrastructureErrorCodesConst from "../../core/constants/errors/infrastructure/alerts-error-codes.const";
import InfrastructureErrorsConst from "../../core/constants/errors/infrastructure/alerts-error.const";

// Libraries
import axios from "axios";

// Models
import { createErrorInternalApp } from "../../models/aggregates/build-error-internal-app/build-error-internal-app.dao";
import ErrorExternalAppModel from "../../models/aggregates/error-external-app.model";

// Utils
import { conversionCodeAPI } from "../../utils/conversion-of-api-error-codes/main-conversion-codes.utils";

export const callExternalApi = async (options) => {
  try {
    const response = await axios(options.config);
    if (!response.status) {
      
      return {
        data: null,
        error: new ErrorExternalAppModel(response.code, response.message)
      };
    }

    const { data } = response;

    return {
      data,
      error: null,
    };

  } catch (error) {

    if (axios.isAxiosError(error) && error.response) {
      // client received an error response (5xx, 4xx)
      const { response } = error;
      
      if (!response) {
        return {
          data: null,
          error: createErrorInternalApp(InfrastructureErrorsConst.responseStatusFailed)
        };
      }

      const { data } = response;

      let code = data && data.errors && data.errors.code ? data.errors.code : InfrastructureErrorCodesConst.RES_IFT_002;
      
      const codeAPIConverted = conversionCodeAPI(code);

      if(['US0011', 'US0008'].includes(code)){
          window.location.reload();
      }

      let message = codeAPIConverted.message
      let description = codeAPIConverted.description

      return {
        data: null,
        error: new ErrorExternalAppModel(code, message, description)
      };

    } else if (error.request) {
      // client never received a response, or request never left 
      const { request } = error;

      if (!request) {
        return {
          data: null,
          error: createErrorInternalApp(InfrastructureErrorsConst.requestNeverReceived)
        };
      }

      const { data } = request;

      let code = data && data.errors && data.errors.code ? data.errors.code : InfrastructureErrorCodesConst.REQ_IFT_002;
      
      const codeAPIConverted = conversionCodeAPI(code);

      let message = codeAPIConverted.message
      let description = codeAPIConverted.description

      return {
        data: null,
        error: new ErrorExternalAppModel(code, message, description)
      };

    } else {
      // anything else 
      // Something happened in setting up the request that triggered an Error
      return {
        data: null,
        error: {
          message: createErrorInternalApp(InfrastructureErrorsConst.responseOtherFail)
        },
      };
    }
  }
};
