//Assets
import { Assets } from '../assets';

//Constants - core
import Constants from '../core/constants';

//Components - Shared
import ErrorToastComponent from '../shared/components/toast/error-toast/error-toast.component';

export function isContainedAnyRolesRequired (rolesRequired, roleToVerify) {
    if (roleToVerify.length === 0 || !rolesRequired) {
        return false
    }

    if (rolesRequired.includes(roleToVerify.toUpperCase())) {
        return true;
    }
    return false;
}

export function extractAcronymAPIErrorCode(codeAssociate) {
    let acronym = "";
    if (codeAssociate.trim().length > 0) {
        const code = codeAssociate.split("");
        let pattern = /^[A-Z]+$/i;
        for (const character of code) {
            const isValid = character.toUpperCase().match(pattern)

            if (isValid) acronym += character.toUpperCase();

            if (character === "" || !isValid) break;
        }
    }
    return acronym;
}

export function redirectsLogin(err) {

    ErrorToastComponent({
        title:`${err?.code} ${err?.message}`,
        position: Constants.AlertConst.TOP_END_POSITION_TEXT,
        timer: Constants.AlertConst.TIMER_MEDIUM,
        iconHtml:{
          src: Assets.SharedIcons.icon_alert_error,
          alt: "icon_alert_error"
        },
        iconColor: "transparent"
    })
    if (err?.code === "US0007"){
        return true
    }else{
        return false
    }
}