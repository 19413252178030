// Actions
import {
  setProcessValidateSessionAuthenticationReducer,
  setStateAuthenticationReducer
} from './../../../storage/reducers/authentication/authentication.actions'

// Assets
import { Assets } from '../../../assets';

// Components
import ErrorToastComponent from '../../../shared/components/toast/error-toast/error-toast.component';

// Components
import ChangeDataForm from './components/change-data-form/change-data-form.component';

// Constants - core
import Constants from '../../../core/constants';

// Libraries
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';

// Models - model
import CredentialModel from "../../../models/credential/model/credential.model";

//Pages
import HeaderLogoPage from '../header-logo/header-logo.page';

// Services
import { declineInvitationService, getUserAuthenticationService, loginService, setValidateCode } from "../../../services/authentication.services";

// Styles
import "./login.page.scss";

// Utils
import { validatePhoneNumber } from "../../../utils/validate-recovery-password.utils";

const LoginPage = (props) => {

  const {
    //Actions
    setProcessValidateSessionAuthenticationReducer,
    setStateAuthenticationReducer,
    //variables
    
  } = props

  const INITIAL_STATE = {
    isUserGuest: undefined,
    userGuest:undefined,
    isGenerateCode: false,
    changeData: false
  };

  const [state, setState] = useState(INITIAL_STATE);

  const { isUserGuest, userGuest, isGenerateCode, changeData } = state;

  useEffect(() => {
    isGuest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  const [t] = useTranslation("translation");
  let history = useNavigate();

  const [phoneCallSign, setPhoneCallSign] = useState("57");

  const { handleSubmit, handleChange, resetForm, values, errors } = useFormik({
    initialValues: {
      phoneNumber: "",
      identifierNumber: "",
    },
    initialErrors: {
      phoneNumber: "",
      identifierNumber: "",
    },
    validate: validatePhoneNumber,
    onSubmit: () => {
      processLogin();
    },
  });

  const isGuest = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search).get('params');
      if (!!urlParams) {
        const user = await getUserAuthenticationService(urlParams);
        if (!!user) {
          handleChange("identifierNumber")(user.documentNumber);
          handleChange("phoneNumber")(user.phoneNumber.slice(user.phoneNumber.length - 10, user.phoneNumber.length));
          setPhoneCallSign(user.phoneNumber.slice(1, user.phoneNumber.length - 10));
          setStateAuthenticationReducer('isMemberGuest', !user.isCompletedRegister);
          setState({
            ...state,
            isUserGuest: !user.isCompletedRegister,
            userGuest: user
          })
        } else {
          history("/login", { replace: true })
        }
      }
    } catch (error) {
      ErrorToastComponent({
        title: `${error.code} ${error.message}`,
        position: Constants.AlertConst.TOP_END_POSITION_TEXT,
        timer: Constants.AlertConst.TIMER_MEDIUM,
        iconHtml:{
          src: Assets.SharedIcons.icon_alert_error,
          alt: "icon_alert_error"
        },
        iconColor: "transparent"
      })
    }
  };

  const processLogin = () => {
    setState({
      ...state,
      isGenerateCode: true
    })
    const credentials = new CredentialModel(values.identifierNumber, `+${phoneCallSign}${values.phoneNumber}`);
    loginService(credentials)
    .then(({ uidCode, usrEmail }) => {
        setValidateCode({
          uidCode,
          ...credentials
        });
        history("/validateCode", { replace: true, state:{usrEmail} })
    })
    .catch((err) => {
      setState({
        ...state,
        isGenerateCode: false
      })
      ErrorToastComponent({
        title:`${err.code} ${err.message}`,
        position: Constants.AlertConst.TOP_END_POSITION_TEXT,
        timer: Constants.AlertConst.TIMER_MEDIUM,
        iconHtml:{
          src: Assets.SharedIcons.icon_alert_error,
          alt: "icon_alert_error"
        },
        iconColor: "transparent"
      })
    })
  }

  useEffect(() => {
    setProcessValidateSessionAuthenticationReducer(false, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const declineInvitation = async () => {
    const urlParams = new URLSearchParams(window.location.search).get('params');
    try {
      await declineInvitationService(urlParams);
      setState(INITIAL_STATE);
      resetForm();
      history("/login", { replace: true })
    } catch (error) {
      ErrorToastComponent({
        title: `${error.code} ${error.message}`,
        position: Constants.AlertConst.TOP_END_POSITION_TEXT,
        timer: Constants.AlertConst.TIMER_MEDIUM,
        iconHtml:{
          src: Assets.SharedIcons.icon_alert_error,
          alt: "icon_alert_error"
        },
        iconColor: "transparent"
      })
    }
  };

  return (
    <div className='login__content'>
        <img src={Assets.LoginIcons.img_login} alt="img_login" className="header-logo__header__img" >
        </img>
        <HeaderLogoPage
          isAnimation={true}
        />
      {changeData
        ? <ChangeDataForm
          credentials={{ identifierNumber: values.identifierNumber, phoneNumber: `+${phoneCallSign}${values.phoneNumber}` }}
          handleReturn={(isReset = false) => {
            if (isReset) {
              resetForm()
              setState((prevState) => ({ ...prevState, changeData: false, isUserGuest: undefined }))
            }else{
              setState((prevState) => ({ ...prevState, changeData: false }))
            }
          }}
        />
        : <form onSubmit={handleSubmit}>
          <div className='login__footer'>
            <div className='grid-x login__footer__content'>
              <div>
                <button
                  type='button'
                >
                  <img src={Assets.LoginIcons.icon_user_footer} alt="icon_user_footer" className='login__footer__button' />
                </button>
              </div>
              <div className='login__footer__line'></div>
              <div>
                <button
                  type='button'
                >
                  <img src={Assets.LoginIcons.icon_house_footer} alt="icon_house_footer" className='login__footer__button' />
                </button>
              </div>
            </div>
            <div className='login__user grid-y align-middle'>
              {isUserGuest ? (
                <div className='login__user grid-y align-middle'>
                  <h6 className='login__user__title'>{t("authentication.login.hello")},</h6>
                  <h4 className='text-center login__user__title login__user__title--mod'>{`${userGuest.name} ${userGuest.lastName}`}</h4>
                  <h4 className='text-center login__user__text'>{t("authentication.login.welcome")}</h4>
                </div>
              ) : (
                <div className='login__user grid-y align-middle'>
                  <h4 className='text-center login__user__title login__user__title--mod'>{t("authentication.login.greeting")}</h4>
                  <h4 className='text-center login__user__text'>{t("authentication.login.welcomeText")}</h4>
                </div>
              )}
              <div className='login__user__input__content grid-y'>
                <div className="grid-x login__user__input__type">
                  <input
                    className="login__user__input"
                    placeholder={t("authentication.login.identifierNumber")}
                    aria-label="identifierNumber"
                    id="identifierNumber"
                    aria-describedby="basic-addon1"
                    onChange={(value) => handleChange("identifierNumber")(value)}
                    value={values.identifierNumber}
                  />
                </div>
                {errors.identifierNumber && (
                  <span className="login__user__input__error text-center">{errors.identifierNumber}</span>
                )}
              </div>

              <div className="grid-y login__user__phone__content">
                <div className="grid-x login__user__phone">
                  <PhoneInput
                    className="login__user__input-phone"
                    onChange={(phone) => setPhoneCallSign(phone)}
                    value={phoneCallSign}
                    country={'co'}
                    countryCodeEditable={false}
                    inputProps={{ readOnly: "readonly" }}
                  />
                  <div className='grid-y align-right login__user__input__container'>
                    <input
                      className="login__user__input login__user__input--mod"
                      placeholder={t("authentication.login.phoneNumber")}
                      id="phoneNumber"
                      value={values.phoneNumber}
                      onChange={(value) => handleChange("phoneNumber")(value)} />
                  </div>
                </div>
                {errors.phoneNumber && (
                  <span className="login__user__input__error text-center">{errors.phoneNumber}</span>
                )}
              </div>
              {isUserGuest ? (
                <>
                  <button
                    className="login__button__change-data"
                    type='button'
                    onClick={() => setState((prevState) => ({...prevState, changeData: true}))}
                  >
                    Modificar mis datos
                  </button>
                  <button
                    type='submit'
                    className="login__button"
                    disabled={Object.keys(errors).length !== 0 || isGenerateCode}
                  >
                    {t("authentication.login.loginButton")}
                  </button>
                  <div className='login__button__container'>
                    <button onClick={() => declineInvitation()} type="button" className='login__button__text'>{t("authentication.login.back")}</button>
                  </div>
                </>
              ) : (
                <>
                  <button
                    type='submit'
                    className="login__button"
                    disabled={Object.keys(errors).length !== 0 || isGenerateCode}
                  >
                    {t("authentication.login.sendButton")}
                  </button>
                </>
              )
              }
            </div>
          </div>
        </form>
      }
      </div>
  );
}

const mapStateToPropsActions = {
  setProcessValidateSessionAuthenticationReducer,
  setStateAuthenticationReducer
};

export default connect(null, mapStateToPropsActions)(LoginPage)