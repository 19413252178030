// Contact List
import icon_user_contact_list from "./user.svg";
import icon_location from "./location.svg";
import icon_vote from "./vote.svg";
import icon_profile from "./profile.svg";
import icon_pdf from "./pdf.svg";
import icon_user_inactive from "./user-inactive.svg";
import icon_location_inactive from "./location-inactive.svg";
import icon_vote_inactive from "./vote-inactive.svg";
import icon_profile_inactive from "./profile-inactive.svg";

export const ViewProfileIcons = {
    icon_user_contact_list,
    icon_location,
    icon_vote,
    icon_profile,
    icon_pdf,
    icon_user_inactive,
    icon_location_inactive,
    icon_vote_inactive,
    icon_profile_inactive
}