//Actions
import { setMemberMoreMyNetworkReducer, setStateMyNetworkReducer, setShowUnderlyingLevelsMyNetworkReducer } from '../../../../../storage/reducers/my-network/my-network.actions';
import { IoSearchOutline } from 'react-icons/io5'

//Assets
import { Assets } from '../../../../../assets';

// Constants - core
import Constants from '../../../../../core/constants';

//Libraries
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//Services
import { 
    getTokenService,
    getUserLoggedService
} from '../../../../../services/authentication.services';
import { getCountAllChildrenService } from '../../../../../services/my-network.service';

//Styles
import './members-filter.component.scss';

//Utils
import { redirectsLogin } from '../../../../../utils/authentication.utils';
import { secondWorkFlowCommonUpdateDataApplication } from '../../../../../utils/my-network.utils';

const MembersFilterComponent = (props) => {
    const {
        // Actions  
        setMemberMoreMyNetworkReducer,
        setStateMyNetworkReducer,
        setShowUnderlyingLevelsMyNetworkReducer,
        // Variables
        memberMore,
        levelsNetwork
    } = props;
    const listInnerRef = useRef();
    const [t] = useTranslation("translation")
    let history = useNavigate();

    const INITIAL_STATE = {
        membersState: [],
        numberScrollState: 0,
        textSearchState: '',
        isLoading: false,
        idUserLogged: null
    }

    const [state, setState] = useState(INITIAL_STATE)

    let { membersState, numberScrollState, textSearchState, isLoading, idUserLogged } = state;

    useEffect(() => {
        countAllChildren()
        getInfoUserLogged()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberMore])

    const getInfoUserLogged = () => {
        const { idUser } =  getUserLoggedService()
        setState({
            ...state,
            idUserLogged: idUser,
        })
    }

    const countAllChildren = async (filter) => {
        setState((prevState) => ({
            ...prevState,
            textSearchState: filter || ""
        }))
        if (memberMore.uidMemberMore !== "") {
            let token = getTokenService();
            try {
                let numberPag = filter || filter === "" ? 0 : numberScrollState
                let textFilter = filter || filter === "" ? filter : textSearchState
                const getLevelUser = await getCountAllChildrenService(memberMore.uidMemberMore, numberPag, textFilter, token);
                let getLevelUserArray = numberPag > 0 ? membersState.concat(getLevelUser) : getLevelUser
                setState((prevState) => ({
                    ...prevState,
                    numberScrollState: numberPag + 1,
                    membersState: getLevelUserArray,
                    isLoading: false,
                }))
            } catch (err) {
                if(redirectsLogin(err)) history("/login", { replace: true });
            }
        }
    }

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if ((Math.floor(scrollTop + clientHeight) === scrollHeight || Math.ceil(scrollTop + clientHeight) === scrollHeight) 
            ) {
                setState({
                    ...state,
                    isLoading:true
                })
            countAllChildren();
            }
        }
    }

    const memberMoreBack = () => {
        setMemberMoreMyNetworkReducer({
            isShowViewMore: false,
            level:0,
            totalChildren:0,
            totalLeaders:0,
            nameMemberMore:"",
            uidMemberMore: ""
        });
        setState(INITIAL_STATE)
        setShowUnderlyingLevelsMyNetworkReducer(false);
    };

    const renderIcon = (member) => {
        let icon = null;
        if (!member.member.isStateActive) {
            icon = (!member.member.isCompletedRegister)
                ? <img
                    src={Assets.HomeIcons.icon_prohibited}
                    alt="icon_prohibited"
                    className="grid-x align-center-middle members-filter__pending-icon"
                />
                : <img
                    src={Assets.HomeIcons.icon_alert_inactive}
                    alt="icon_alert_inactive"
                    className="grid-x align-center-middle members-filter__pending-icon"
                />
        } else if (!member.member.isCompletedRegister) {
            icon =  <img
                        src={Assets.HomeIcons.icon_pending}
                        alt="icon_pending"
                        className="grid-x align-center-middle members-filter__pending-icon"
                    />
        } else if (member.totalMember > 0) {
            icon =  <p
                        className={member.totalMember > 99
                            ? "members-filter__number-member members-filter__number-member--mod"
                            : "members-filter__number-member"
                        }
                    >
                        {member.totalMember > 99 ? '99+' : member.totalMember}
                    </p>
        };
        return icon;
    };

    const handlerGetChildren = async (member) => {
        await fetchData(member, member.totalMember)

        setMemberMoreMyNetworkReducer({
            isShowViewMore: false,
            level: 0,
            totalChildren: 0,
            totalLeaders: 0,
            nameMemberMore: "",
            uidMemberMore: ""
        });
    }

    async function fetchData(memberSelected) {
        const { member, totalMember } = memberSelected
        let isFirstMembersShown = false
        let levelNetworkCompare = [...levelsNetwork]
        let networkLevelInspect = levelNetworkCompare[memberMore.level - 1].memberByLevel

        for (let indexMemberSelected = 0; indexMemberSelected < Constants.MyNetworkConst.LIMIT_MEMBERS_TO_DISPLAY; indexMemberSelected++) {
            let memberElement = networkLevelInspect[indexMemberSelected];

            if (memberElement.member.idUser === member.idUser) {
                isFirstMembersShown = true
                memberElement.isMemberSelected = true;
                break;
            }
        }

        if (!isFirstMembersShown) {
            networkLevelInspect.push({
                isMemberSelected: true,
                member,
                totalMember
            })
        }

        let token = getTokenService()

        try {
            if (token && member && member.idUser) {
                const { dataLevelDetermined } = await secondWorkFlowCommonUpdateDataApplication(member.idUser, token)

                levelNetworkCompare.push(dataLevelDetermined)

                setStateMyNetworkReducer('levelsNetwork', levelNetworkCompare)
            }
        } catch (err) {
            if (redirectsLogin(err)) history("/login", { replace: true })
        }
    }
    return (
        <div className='grid-x members-filter__content'>
            <div className="members-filter__container">
                <div className="grid-x">
                    <button className='members-filter__back' onClick={() => memberMoreBack()}>
                        <img src={Assets.AuthenticationIcons.icon_active_arrow} alt='icon_active_arrow' className='members-filter__back__img' />
                    </button>
                    <div className="members-filter__header__content">
                        <img src={Assets.SharedIcons.icon_network} alt='icon_network' className="members-filter__header__icon" />
                        <span className="members-filter__header__text">
                            {idUserLogged === memberMore?.uidMemberMore
                            ? (
                                <>{t("myNetworkPage.filter.myNetwork")}</>
                            ) : (
                                <>{memberMore?.nameMemberMore}</>
                            )}
                        </span>
                    </div>
                </div>
            </div>
            <div className='members-filter__members-container--associated members-filter__members-container'>
                <div className='members-filter__member-associate__input-container'>
                    <IoSearchOutline className='members-filter__member-associate__input--icon' />
                    <input
                        className="members-filter__member-associate__input"
                        placeholder={t("myNetworkPage.filter.searchPlaceholder")}
                        onChange={(e) => {
                            countAllChildren(e.target.value)
                        }}
                        value={textSearchState}
                    />
                </div>
                <div className='members-filter__footer'>
                    <div className='members-filter__members-container__header grid-x'>
                        <div className='members-filter__members-container__header__level-container'>
                            <p className='members-filter__members-container__header__text'>
                                {`${t("myNetworkPage.level")} ${memberMore.level}`}
                            </p>
                            <p className='members-filter__members-container__header__text__label__strong'>
                                {`${t("myNetworkPage.affiliated")} ${memberMore.nameMemberMore}`}
                            </p>
                        </div>
                        <div className='members-filter__members-container__header__counter-container'>
                            <p className='members-filter__members-container__header__text__label'>
                                {`${memberMore.totalChildren} ${t("myNetworkPage.members")} / ${memberMore.totalLeaders} ${t("myNetworkPage.leaders")} `}
                            </p>
                        </div>
                    </div>
                    <div ref={listInnerRef} onScroll={onScroll} className='members-filter__members-container__items members-filter__members-container__items--associated grid-x'>
                        {membersState.map(member => {
                            return (
                                <div 
                                id={member.member.idUser} key={member.member.idUser} className='small-3 members-filter__details-container__details-user'
                                onClick={() => { handlerGetChildren(member) }}
                                >
                                    <div className={
                                        (!member.totalMember > 0 && !member.member.isCompletedRegister) 
                                        ||
                                        (!member.member.isStateActive && member.member.isCompletedRegister)
                                         ?
                                        "members-filter__member__img--inactive":
                                        ""
                                    }>
                                        <img 
                                            src={ member?.member?.photoProfile ? member?.member?.photoProfile : Assets.SharedIcons.icon_profile}
                                            alt='icon_profile' 
                                            className='members-filter__member__img'
                                        />
                                    </div>
                                        { renderIcon(member) }
                                    <p className={(!member.member.isStateActive && member.member.isCompletedRegister) || (!member.totalMember > 0 && !member.member.isCompletedRegister)
                                            ? 'members-filter__members-container__details__text--inactive'
                                            : 'members-filter__members-container__details__text'
                                        }
                                    >
                                        {`${member.member.name} ${member.member.lastName}`}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                    {isLoading &&
                        <div className="align-center-middle grid-y loading-globally__container">
                            <div className='loading-globally__container__icon-loading'>
                                <img
                                    src={Assets.SharedIcons.icon_loading}
                                    alt="icon_loading"
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToPropsActions = {
    setMemberMoreMyNetworkReducer,
    setStateMyNetworkReducer,
    setShowUnderlyingLevelsMyNetworkReducer
};

const mapStateToProps = ({ MyNetworkReducer }) => {
    const { memberMore, levelsNetwork } = MyNetworkReducer;
    return {
        memberMore,
        levelsNetwork
    };
};

export default connect(mapStateToProps, mapStateToPropsActions)(MembersFilterComponent);