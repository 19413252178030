//Actions
import { setViewContactTabBarReducer } from "./../../../../../storage/reducers/tabbar/tabbar.actions"

//Assets
import { Assets } from "../../../../../assets";

//Component
import ViewContactSkeletonComponent from "../../../../../shared/components/skeleton/view-contact/view-contact-skeleton.component";

//Libraries
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

//Services
import { getTokenService } from "../../../../../services/authentication.services";
import { getContactInformationService } from "../../../../../services/contact-list.services";

//Styles
import "./view-contact.component.scss";

// Utils
import { validateIsMobileDeviceUtils } from "../../../../../utils/validate-is-mobile-device.utils";
import { redirectsLogin } from "../../../../../utils/authentication.utils";

const ViewContactComponent = (props) => {

    const {
        //Variables
        selectedContactUid,
        //Actions
        setViewContactTabBarReducer
    } = props;

    const [t] = useTranslation("translation");
    let history = useNavigate();

    useEffect(() => {
        getContactInformation();

        return () => backContact();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const INITIAL_STATE = {
        contactInformationState: {},
        isMobileDevice: false,
        isLoading: true,
    };

    const [state, setState] = useState(INITIAL_STATE);

    const { isMobileDevice, contactInformationState, isLoading } = state;

    const getContactInformation = () => {
        let token = getTokenService();
        getContactInformationService({ uid: selectedContactUid }, token)
            .then((response) => {
                const isMobileResponse = validateIsMobileDeviceUtils();
                setState({
                    ...state,
                    contactInformationState: response,
                    isMobileDevice: isMobileResponse,
                    isLoading: !isLoading
                });
            })
            .catch((err) => {
                if(redirectsLogin(err)) history("/login", { replace: true })
            })
    };

    const contactNames = (contactInformationState?.name || contactInformationState?.lastName) 
        ? `${contactInformationState?.name} ${contactInformationState?.lastName}` 
        : "";

    const contactPhone = contactInformationState?.phoneNumber
        ? `${contactInformationState?.phoneNumber.slice(0, 3)} ${contactInformationState?.phoneNumber.slice(3, 6)} ${contactInformationState?.phoneNumber.slice(6, 9)} ${contactInformationState?.phoneNumber.slice(9, 13)}`
        : "";

    const contactWhatsapp = contactInformationState?.phoneWhatsapp
        ? `${contactInformationState?.phoneWhatsapp.slice(0, 3)} ${contactInformationState?.phoneWhatsapp.slice(3, 6)} ${contactInformationState?.phoneWhatsapp.slice(6, 9)} ${contactInformationState?.phoneWhatsapp.slice(9, 13)}`
        : "";

    const backContact = () => {
        setState(INITIAL_STATE);
        setViewContactTabBarReducer(false);
    };

    return (
        isLoading 
        ? 
            <ViewContactSkeletonComponent/>
        : 
        <div className="view-contact__container">
            <div className="view-contact__target">
                <div className="view-contact__box">
                    <button
                        className='view-contact__box__close'
                        onClick={() => backContact()}>
                        <img src={Assets.SharedIcons.icon_close} alt="icon_close" />
                    </button>
                    <div className="view-contact__box__content-info">
                        <div className="view-contact__box__content-info__content-user">
                            <img 
                                src={contactInformationState?.photoProfile ? contactInformationState?.photoProfile : Assets.SharedIcons.icon_profile} 
                                alt="icon_profile"
                                className="view-contact__box__content-info__img"
                            />
                            <div className="view-contact__box__content-info__box-text"> 
                                <label className="view-contact__box__content-info__name-user">
                                    {contactNames}
                                </label>
                            </div>
                        </div>
                        { isMobileDevice &&
                            <div className="grid-x view-contact__box__content-info__content-buttons">
                                <a
                                    //TODO: Bases for messaging functionalities
                                    // https://t.me/+1XXXXXXX
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`https://wa.me/${contactInformationState?.phoneNumber}`}
                                    className={contactWhatsapp !== ''
                                        ? 'grid-y small-6 align-center-middle'
                                        : 'grid-y small-6 align-center-middle view-contact__box__content-info__content-buttons__inactive'
                                    }
                                >
                                    <img src={Assets.SharedIcons.icon_send_message} alt="icon_send_message" />
                                    <div className="view-contact__box__content-info__content-buttons__text">
                                        <span>{t("tabBar.contacts.viewContact.textMessage")}</span>
                                    </div>
                                </a>
                                <a 
                                    href={`tel:${contactInformationState?.phoneNumber}`}
                                    className={contactPhone !== ''
                                        ? 'grid-y small-6 align-center-middle'
                                        : 'grid-y small-6 align-center-middle view-contact__box__content-info__content-buttons__inactive'
                                    }
                                >
                                    <img src={Assets.SharedIcons.icon_make_calls} alt="icon_icon_calls" />
                                    <div className="view-contact__box__content-info__content-buttons__text">
                                        <span>{t("tabBar.contacts.viewContact.makeCalls")}</span>
                                    </div>
                                </a>
                            </div>
                        }
                    </div>
                </div>
                <div className="view-contact__box-contacts">
                    <div className="view-contact__box-contacts__content-info">
                        <label className="view-contact__box-contacts__content-info__title">{t("tabBar.contacts.viewContact.phone")}</label>
                        <label className="view-contact__box-contacts__content-info__description">{contactPhone}</label>
                    </div>
                    
                </div>
                <div className="view-contact__box-contacts">
                    <div className="view-contact__box-contacts__content-info">
                        <label className="view-contact__box-contacts__content-info__title">{t("tabBar.contacts.viewContact.whatsapp")}</label>
                        <label className="view-contact__box-contacts__content-info__description">{contactWhatsapp}</label>
                    </div>
                    
                </div>
                <div className="view-contact__box-contacts">
                    <div className="view-contact__box-contacts__content-info">
                        <label className="view-contact__box-contacts__content-info__title">{t("tabBar.contacts.viewContact.email")}</label>
                        <label className="view-contact__box-contacts__content-info__description">
                            {contactInformationState?.email}
                        </label>
                    </div>
                    
                </div>
                <div className="view-contact__box-buttons">  
                    <button 
                        className="view-contact__box-buttons__button"
                        onClick={() => null}
                    >
                        {t("tabBar.contacts.viewContact.inviteEvent")}
                    </button>
                    <button 
                        className="view-contact__box-buttons__button__text" 
                        onClick={() => backContact()}
                    >
                        {t("tabBar.contacts.viewContact.back")}
                    </button>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = ({ TabbarReducer }) => {
    const { selectedContactUid } = TabbarReducer;
    return {
        selectedContactUid
    };
}

const mapStateToPropsActions = {
    setViewContactTabBarReducer
};
export default connect(mapStateToProps, mapStateToPropsActions)(ViewContactComponent);