class LocationModel {
    constructor(idLocation, name, latitude, longitude, address, sector, imgLocation, typeLocation, municipality) {
        this.idLocation = idLocation;
        this.name = name;
        this.latitude = latitude;
        this.longitude = longitude;
        this.address = address;
        this.sector = sector;
        this.imgLocation = imgLocation;
        this.typeLocation = typeLocation;
        this.municipality = municipality;
    }
};
export default LocationModel;