//Components
import CompleteProfileComponent from './components/complete-profile/complete-profile.component'
//Libraries
import React from 'react'

const CompleteRegistrationPage = () => {
  return (
    <div>
        <CompleteProfileComponent/>
    </div>
  )
}

export default CompleteRegistrationPage