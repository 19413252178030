// Models - DAO/DTO
import { mapperToGroupModel } from "../../group/dto/group.dto.dao";
import { mapperToLocationModel } from "../../location/dto/location.dto.dao";
import { mapperToRoleModel } from "../../role/dto/role.dto.dao";

// Models - model
import UserModel from "../model/user.model";

export function mapperToUserModel(userDTO) {
  const usrResidencePlace = userDTO.usrLocation? mapperToLocationModel(userDTO.usrLocation): undefined;
  const groupAssociated = !userDTO.usrGroupAssociated ? undefined : mapperToGroupModel(userDTO.usrGroupAssociated);
  const role = !userDTO.usrRole ? undefined : mapperToRoleModel(userDTO.usrRole);

    return new UserModel(
      userDTO.idUser,
      userDTO.usrName,
      userDTO.usrLastName,
      userDTO.usrImgProfile,
      userDTO.usrIdentificationNumber,
      userDTO.usrTypeIdentificationNumber,
      userDTO.usrPhone,
      userDTO.usrPhoneWhatsapp,
      userDTO.usrEmail,
      groupAssociated,
      role,
      userDTO.usrUserRoot,
      userDTO.usrIsStateActive,
      userDTO.usrOverviewNetwork,
      userDTO.usrIsCompletedRegister,
      userDTO.usrDateBirth,
      usrResidencePlace,
      userDTO.usrVotingStation,
      userDTO.usrCountry,
      userDTO.usrDepartment,
      userDTO.usrMunicipality
    )
}