// Assets
import { Assets } from '../../../../../assets';

// Libraries
import React, { useState } from 'react'
import Modal from 'react-modal';
import { useTranslation } from "react-i18next";

//Core - constants
import { ModalInformativeConstants } from '../../../../../core/constants/modal-informative.constants';

// Components
import { LeaderIcon, LeadersDescription, LeadersSummary } from './components/leaders-information/leaders-information.component';
import { InactiveDescription, InactiveIcon, InactiveSummary } from './components/inactive-information/inactive-information.component';
import { MembersDescription, MembersIcon, MembersSummary } from './components/members-information/members-information.component';
import { LevelsDescription, LevelsIcon, LevelsSummary } from './components/levels-information/levels-information.component';

// Styles
import './modal-information.component.scss'

const ModalInformation = (props) => {
    const {
        // Functions
        closeModal,
        navigation,
        // Variables
        visibility,
        typeInformation,
    } = props;
    

    const [t] = useTranslation("translation");

    const TapNavigation = () => {
        const [changePanel, setChangePanel] = useState(false);
        return (
            <>
                <div className='grid-x small-1 '>
                    <button
                        onClick={() => setChangePanel(false)}
                        className={`grid-y small-6 align-justify align-middle ${!changePanel ? 'modal-information__tap--active' : 'modal-information__tap'}`}
                    >
                        {t("myNetworkPage.modalInformation.tapNavigation.summary")}
                        <div className={`small-2 ${!changePanel ? 'modal-information__tap__state--active' : 'modal-information__tap__state'}`} />
                    </button>
                    <button
                        onClick={() => setChangePanel(true)}
                        className={`grid-y small-6 align-justify align-middle ${changePanel ? 'modal-information__tap--active' : 'modal-information__tap'}`}
                    >
                        {t("myNetworkPage.modalInformation.tapNavigation.description")}
                        <div className={`small-2 ${changePanel ? 'modal-information__tap__state--active' : 'modal-information__tap__state'}`} />
                    </button>
                </div>
                {!changePanel
                    ? <SummaryPanel />
                    : <DescriptionPanel />
                }
            </>
        )
    };
    
    const renderIcon = () => {
        switch (typeInformation) {
            case ModalInformativeConstants.LEADERS:
                return <LeaderIcon />;
            case ModalInformativeConstants.INACTIVE:
                return <InactiveIcon />;
            case ModalInformativeConstants.MEMBERS:
                return <MembersIcon />;
            case ModalInformativeConstants.LEVELS:
                return <LevelsIcon />;
            default:
                return null;
        }
    };

    const SummaryPanel = () => {
        switch (typeInformation) {
            case ModalInformativeConstants.LEADERS:
                return <LeadersSummary />; 
            case ModalInformativeConstants.INACTIVE:
                return <InactiveSummary />; 
            case ModalInformativeConstants.MEMBERS:
                return <MembersSummary />; 
            case ModalInformativeConstants.LEVELS:
                return <LevelsSummary navigation={navigation} />; 
            default:
                return null;
        }
    };

    const DescriptionPanel = () => {
        switch (typeInformation) {
            case ModalInformativeConstants.LEADERS:
                return <LeadersDescription />
            case ModalInformativeConstants.INACTIVE:
                return <InactiveDescription />;
            case ModalInformativeConstants.MEMBERS:
                return <MembersDescription />;
            case ModalInformativeConstants.LEVELS:
                return <LevelsDescription />;
            default:
                return null;
        }
    };

    return (
        <Modal
            bodyOpenClassName="modal-information__body"
            isOpen={visibility}
            onRequestClose={closeModal}
            ariaHideApp={false}
            overlayClassName="modal-information__overlay grid-x align-center-middle"
            className="modal-information grid-y"
        >
            <div className='small-1 grid-x justify-content-between'>
                <div className='grid-x align-center-middle'>
                    <img className='modal-information__logo' src={Assets.SharedIcons.icon_logo} alt="icon_logo" />
                    <span className='modal-information__logo__text'>{t("myNetworkPage.modalInformation.logoLabel")}</span>
                </div>
                <button onClick={closeModal}>
                    <img className='modal-information__close' src={Assets.SharedIcons.icon_close} alt="icon_logo" />
                </button>
            </div>
            <div className='small-3 grid-y align-center-middle'>
                {renderIcon()}
            </div>
            <div className='grid-y small-7'>
                <TapNavigation />
            </div>
            <div className='small-1 grid-x align-center-middle'>
                <button
                    onClick={closeModal}
                    className="modal-information__button"
                >
                    {t("myNetworkPage.modalInformation.buttonText")}
                </button>
            </div>
        </Modal>
    )
}

export default ModalInformation;