// Actions
import {
  setManyRolesAndGroupByUserAuthenticationReducer
} from './../../../storage/reducers/authentication/authentication.actions'

// Assets
import { Assets } from '../../../assets';

// Components - shared
import ErrorToastComponent from '../../../shared/components/toast/error-toast/error-toast.component';

// Constants - core
import AuthenticationErrorsConst from '../../../core/constants/errors/authentication/authentication-error.const';
import Constants from '../../../core/constants';

// Libraries
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import titleize from 'titleize';

// Models - aggregated
import { createErrorInternalApp } from '../../../models/aggregates/build-error-internal-app/build-error-internal-app.dao';

// Pages
import HeaderLogoPage from '../header-logo/header-logo.page';

// Services
import { 
  deleteTokenService,
  getTokenService, 
  setTokenService,
  setUserLoggedService,
  userGroupSelectedService
} from '../../../services/authentication.services';

//Styles
import "./group-and-role-selection.page.scss";

//Utils
import { redirectsLogin } from '../../../utils/authentication.utils';

const GroupAndRoleSelection = (props) => {
  const {
    // Actions
    setManyRolesAndGroupByUserAuthenticationReducer,
    // Variables
    manyRolesAndGroupAssociateByUser
  } = props
  const [t] = useTranslation("translation");
  let history = useNavigate();

  useEffect(() => {
    if (!getTokenService()) {
      const error = createErrorInternalApp(AuthenticationErrorsConst.errorGettingVerificationIdentifier)
      ErrorToastComponent({
        title: `${error.code} ${error.message}`,
        position: Constants.AlertConst.TOP_END_POSITION_TEXT,
        timer: Constants.AlertConst.TIMER_MEDIUM,
        iconHtml:{
          src: Assets.SharedIcons.icon_alert_error,
          alt: "icon_alert_error"
        },
        iconColor: "transparent"
      })
      history("/login", { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const processSelectionProfile = (index) => {
    userGroupSelectedService(
      { uid: manyRolesAndGroupAssociateByUser[index].userMember.idUser },
      getTokenService()
    )
      .then(res => {
        const { userLogged, token } = res;
        setTokenService(token)
        setUserLoggedService(userLogged)
        setManyRolesAndGroupByUserAuthenticationReducer([])
        if (userLogged.isCompletedRegister) {
          history("/network", { replace: true });
        } else {
          history("/welcome", { replace: true });
        }
      })
      .catch(error => {
        if(redirectsLogin(error)) history("/login", { replace: true })
      })
  }
  const processLogout = () => {
    deleteTokenService();
    history("/login", { replace: true })
};

  return (
    <>
      <HeaderLogoPage/>
      <div className='group-and-role-selection__footer'>
          <div className='grid-x group-and-role-selection__footer__content'>
            <div>
              <button
                type='button'
              >
                <img src={Assets.LoginIcons.icon_user_footer} alt="icon_user_footer" className='login__footer__button'/>
              </button>
            </div>
            <div className='group-and-role-selection__footer__line'></div>
            <div>
              <button
                type='button'
              >
                <img src={Assets.LoginIcons.icon_house_footer} alt="icon_house_footer" className='login__footer__button'/>
              </button>
            </div>
          </div>
          <div className='group-and-role-selection__user grid-y align-middle'>
            <p className='text-center group-and-role-selection__user__text'>{t("authentication.groupAndRoleSelection.mainTitle")}</p>
            <div className={manyRolesAndGroupAssociateByUser.length >3 ? 'group-and-role-selection__button': ""}>
            {manyRolesAndGroupAssociateByUser.map( (element, index) => {
              const { groupAssociated } = element;
              const { name,target } = groupAssociated;
              return (
                <button
                  key={index}
                  onClick={() => processSelectionProfile(index)}
                  className="group-and-role-selection__content flex-container align-middle"
                >
                  <div className="grid-x group-and-role-selection__container">
                    <div className="grid-x">
                      <img
                        src={Assets.SharedIcons.icon_profile}
                        alt="icon_profile"
                        className="group-and-role-selection__icon"
                      />
                      <div className="grid-y align-center grid-container">
                        <span className="text-left group-and-role-selection__text group-and-role-selection__text--mod">
                          {titleize(name) || ""}
                        </span>
                        <span className="text-left group-and-role-selection__text">
                          {target || ""}
                        </span>
                      </div>
                    </div>
                    <div className="grid-y align-center">
                      <div type="button">
                        <img
                          src={Assets.AuthenticationIcons.icon_active_arrow}
                          alt="icon_house_footer"
                          className="group-and-role-selection__icon-next"
                        />
                      </div>
                    </div>
                  </div>
                </button>
              );
            })}
            </div>
                <div className='group-and-role-selection__button__container'>
                  <button type="button" className='group-and-role-selection__exit' onClick={() => processLogout()}>{t("authentication.groupAndRoleSelection.exit")}</button>
                </div>
          </div>
      </div>
    </>
  );
}

const mapStateToProps = ({AuthenticationReducer}) => {
  const { manyRolesAndGroupAssociateByUser } = AuthenticationReducer  

  return {
    manyRolesAndGroupAssociateByUser
  }
}

const mapStateToPropsActions = {
  setManyRolesAndGroupByUserAuthenticationReducer
};


export default connect(mapStateToProps, mapStateToPropsActions)(GroupAndRoleSelection)