// Libraries
import string from "../shared/translations/I18n";
import * as Yup from 'yup';
import { differenceInYears } from "date-fns/esm";

// Services
import { validateNumberIdentificationService } from "../services/my-network.service";
import { getNumberDocumentService, getUserLoggedService } from "../services/authentication.services";


//TODO: Move this file on validations-forms folder

export const validateEmail = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = string.t("authentication.validations.required");
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = string.t("authentication.validations.invalidEmail");
  }
  return errors;
};

export const validateCode = (values) => {
  const errors = {};

  if (!values.code) {
    errors.code = string.t("authentication.validations.required");
  } else if (values.code.length < 6) {
    errors.code = string.t("authentication.validations.invalidCode");
  }
  return errors;
};

export const validatePassword = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = string.t("authentication.validations.required");
  } else {
    if (!/^(?=.*[A-Z])/.test(values.password)) {
      errors.password = string.t("authentication.validations.invalidPassword");
    }
    if (!/^(?=.*[0-9])/.test(values.password)) {
      errors.password = string.t("authentication.validations.invalidPassword");
    }
    if (!/^(?=.*[!@#$%^&*])/.test(values.password)) {
      errors.password = string.t("authentication.validations.invalidPassword");
    }
    if (values.password.length < 8) {
      errors.password = string.t("authentication.validations.invalidPassword");
    }
    if (!/^(?=.*[a-z])/.test(values.password)) {
      errors.password = string.t("authentication.validations.invalidPassword");
    }
  }

  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = string.t("authentication.validations.required");
  } else if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = string.t("authentication.validations.invalidPasswordConfirmation" );
  } else if (values.password.split(" ").length >= 2) {
    errors.passwordConfirmation = string.t("authentication.validations.spaces");
  }


  return errors;
};

export const validateRegisterAssociates = Yup.object().shape({
  documentNumber: Yup.string()
        .matches(/^[0-9]+$/,string.t("tabBar.memberAssociates.errors.validateMatchesDocumentNumber"))
        .min(6,string.t("tabBar.memberAssociates.errors.validateAtLeastDocumentNumber"))
        .max(10,string.t("tabBar.memberAssociates.errors.validateAtMostDocumentNumber"))
        .required(string.t("tabBar.memberAssociates.errors.validateRequiredDocumentNumber"))
        .test("documentNumber", string.t("tabBar.memberAssociates.errors.validateIsDocumentNumber"), 
        async function(value) {
          const {groupAssociated}= getUserLoggedService()
          return (await validateNumberIdentificationService({usrIdentificationNumber: value, usrGroup: groupAssociated.idGroup}));
        }),
  name: Yup.string()
        .matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g)
        .trim()
        .required(),
  lastName: Yup.string()
        .matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g)
        .trim()
        .required(),
  dateBirth: Yup.date()
        .nullable()
        .test("dateBirth", string.t("tabBar.memberAssociates.errors.validateIsLegalAge"), function (value){
          if (!value) return true;
          return differenceInYears(new Date(), new Date(value)) >= 18;
        } ),
  countryBirth: Yup.object()
        .shape({ uid: Yup.string().nullable().required() })
        .nullable(),
  departmentBirth: Yup.object()
        .shape({ uid: Yup.string().nullable().required() })
        .nullable(),
  municipalityBirth: Yup.object()
        .shape({ uid: Yup.string().nullable().required() })
        .nullable(),
  phone: Yup.string()
        .matches(/^[0-9]+$/)
        .length(10)
        .required(),
  email: Yup.string()
        .email()
        .trim()
        .required()
});

export const validatePhoneNumber = (values) => {
  const errors = {};

  if(values.phoneNumber.trim()=== "" || values.phoneNumber.length !== 10 || isNaN(values.phoneNumber) || values.phoneNumber.split(" ").length >= 2) {
    errors.phoneNumber = string.t("authentication.validations.invalidPhoneNumber");
  }
  if(values.identifierNumber.trim()=== "" || !(values.identifierNumber.length >=6 && values.identifierNumber.length <=10)  || isNaN(values.identifierNumber) || values.identifierNumber.split(" ").length >= 2) {
    errors.identifierNumber = string.t("authentication.validations.invalidIdentifierNumber");
  }
  
  return errors;
};

export const validateChangeData = Yup.object().shape({
  document: Yup.string()
    .matches(/^[0-9]+$/, string.t("tabBar.memberAssociates.errors.validateMatchesDocumentNumber"))
    .min(8, string.t("tabBar.memberAssociates.errors.validateAtLeastDocumentNumber"))
    .max(10, string.t("tabBar.memberAssociates.errors.validateAtMostDocumentNumber"))
    .required(string.t("tabBar.memberAssociates.errors.validateRequiredDocumentNumber"))
    ,
  phone: Yup.string()
    .matches(/^[0-9]+$/, string.t("authentication.changeData.errors.phone.type"))
    .length(10,  string.t("authentication.changeData.errors.phone.invalid"))
    .required( string.t("authentication.changeData.errors.phone.required")),
});

export const validateEditInvitation = Yup.object().shape({
       documentNumber: Yup.string()
        .matches(/^[0-9]+$/,string.t("myNetworkPage.userInvitationSetting.errors.validateMatchesDocumentNumber"))
        .min(6,string.t("myNetworkPage.userInvitationSetting.errors.validateAtLeastDocumentNumber"))
        .max(10,string.t("myNetworkPage.userInvitationSetting.errors.validateAtMostDocumentNumber"))
        .required(string.t("myNetworkPage.userInvitationSetting.errors.validateRequiredDocumentNumber"))
        .test({
          name: "documentNumbers", 
           async test (value, message){
            let identificationNumberLocal = getNumberDocumentService()
            const {groupAssociated}= getUserLoggedService()
            if( identificationNumberLocal === value) {
              return true
            }
            else if (!await validateNumberIdentificationService({usrIdentificationNumber: value, usrGroup: groupAssociated.idGroup})){
              return message.createError({message: string.t("myNetworkPage.userInvitationSetting.errors.validateIsDocumentNumber")})
            }else{
              return true
            }
          }
      }),
  names: Yup.string()
        .matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g)
        .trim()
        .required(),
  lastNames: Yup.string()
        .matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g)
        .trim()
        .required(),
  dateBirth: Yup.date()
        .nullable()
        .transform((v) => (v === '' || Number.isNaN(v) ? null : v))
        .typeError(string.t("myNetworkPage.userInvitationSetting.errors.validateRequiredDateBirth"))
        .test("dateBirth", string.t("myNetworkPage.userInvitationSetting.errors.validateIsLegalAge"), function (value){
          return value ? differenceInYears(new Date(), new Date(value)) >= 18 : true;
        } ),
  phone: Yup.string()
        .matches(/^[0-9]+$/)
        .length(10)
        .required(),
  email: Yup.string()
        .email()
        .trim()
        .required(),
});