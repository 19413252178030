// Assets
import { Assets } from '../../../../../assets';

// Components
import ErrorToastComponent from '../../../../../shared/components/toast/error-toast/error-toast.component';

// Core - constants
import Constants from '../../../../../core/constants';

// Libraries
import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2';
import { ConfigProvider, Select } from 'antd';
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { t } from 'i18next';

// Services
import { getTypeIdentificationNumberService } from '../../../../../services/complete-registration.services';
import { changeUserDataService } from '../../../../../services/authentication.services';

// Styles
import './change-data-form.component.scss'

// Utils
import { validateChangeData } from '../../../../../utils/validate-recovery-password.utils';

const ChangeDataForm = ({
    credentials: {
        phoneNumber,
        identifierNumber
    },
    handleReturn = () => { }
}) => {

    const navigate = useNavigate();

    const [state, setState] = useState({
        loading: false,
        optionDocument: [],
        phoneCallSign: '57',
        documentType: 0
    });
    const { loading, optionDocument, phoneCallSign, documentType } = state;

    useEffect(() => {
        getTypeIdentificationNumberService()
            .then((resp) => {
                setState((prevState) => ({ ...prevState, optionDocument: resp.map((document, index) => ({ value: index, label: document })) }))
            }).catch((error) => ErrorToastComponent({
                title: `${error.code} ${error.message}`,
                position: Constants.AlertConst.TOP_END_POSITION_TEXT,
                timer: Constants.AlertConst.TIMER_MEDIUM,
                iconHtml: {
                    src: Assets.SharedIcons.icon_alert_error,
                    alt: "icon_alert_error"
                },
                iconColor: "transparent"
            }))

        return () => {

        }
    }, [])

    const { values, errors, touched, handleSubmit, handleChange, setFieldTouched } = useFormik({
        initialValues: {
            document: '',
            phone: ''
        },
        validationSchema: validateChangeData,
        onSubmit: async () => {
            setState((prevState) => ({...prevState, loading: true}))
            try {
                await changeUserDataService({
                    usrIdentificationNumber: identifierNumber,
                    usrPhone: phoneNumber,
                    correctUsrIdentificationNumber: values.document,
                    correctUsrPhone: `+${phoneCallSign}${values.phone}`,
                    correctUsrTypeIdentificationNumber: documentType
                })
            } catch (error) {
                ErrorToastComponent({
                    title: `${error.code} ${error.message}`,
                    position: Constants.AlertConst.TOP_END_POSITION_TEXT,
                    timer: Constants.AlertConst.TIMER_MEDIUM,
                    iconHtml: {
                        src: Assets.SharedIcons.icon_alert_error,
                        alt: "icon_alert_error"
                    },
                    iconColor: "transparent"
                })
            }finally{
                setState((prevState) => ({...prevState, loading: false}));
                navigate('/login', {replace: true});
                handleReturn(true);
            }
        }
    })

    return (
        <div className='change-data-form'>
            <div className='change-data-form__header'>
                <button onClick={handleReturn}>
                    <div className='change-data-form__header__icon'>
                        <img src={Assets.SharedIcons.icon_arrow_left} alt='icon_arrow_left' />
                    </div>
                    <span>{t("authentication.changeData.goBack")}</span>
                </button>
            </div>
            <div className='change-data-form__description'>
                <p>{t("authentication.changeData.descriptionOne")}</p>
                <p>{t("authentication.changeData.descriptionTwo")}</p>
            </div>
            <div className='change-data-form__title'>
                <span>{t("authentication.changeData.title")}</span>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='change-data-form__form'>
                    <ConfigProvider
                        theme={{
                            components: {
                                Select: {
                                    colorText: "#9389B1",
                                    controlHeight: 25,
                                    lineWidth: 0,
                                    fontSize: 16,
                                    paddingSM: 2,
                                    controlOutline: 'none',
                                    controlItemBgHover: "#E8E8F1",
                                    controlItemBgActive: "#C7C6DC"
                                }
                            }
                        }}
                    >
                        <div>
                            <div className='change-data-form__form__field'>
                                <Select
                                    defaultValue={"C.C."}
                                    onChange={(value) => setState((prevState) => ({ ...prevState, documentType: value }))}
                                    className='change-data-form__form__field__select'
                                    options={optionDocument}
                                    suffixIcon={<img src={Assets.HomeIcons.icon_dropdown_arrow} alt='icon_dropdown_arrow' width={15} />}
                                />
                                <div className='change-data-form__form__field__input'>
                                    <input
                                        id="document"
                                        name="document"
                                        placeholder="Número de documento"
                                        onBlur={(() => setFieldTouched("document", true))}
                                        onChange={handleChange}
                                        value={values.document}
                                    />
                                    <label
                                        className='change-data-form__form__field__input__label'
                                        htmlFor="document"
                                    >
                                        {t("authentication.changeData.document")}
                                    </label>
                                </div>
                            </div>
                            <span className='change-data-form__form__error'>{(touched.document && errors.document) ? errors.document : ''}</span>
                        </div>
                        <div>
                            <div className='change-data-form__form__field'>
                                <PhoneInput
                                    className="change-data-form__form__field__input-phone"
                                    onlyCountries={["co", "ve"]}
                                    onChange={(phone) => setState((prevState) => ({ ...prevState, phoneCallSign: phone }))}
                                    country={"co"}
                                    countryCodeEditable={false}
                                    inputProps={{ readOnly: "readonly" }}
                                    value={values.callSignPhone}
                                />
                                <div className='change-data-form__form__field__input'>
                                    <input
                                        id="phone"
                                        name="phone"
                                        placeholder="Número de teléfono"
                                        onBlur={(() => setFieldTouched("phone", true))}
                                        onChange={handleChange}
                                        value={values.phone}
                                    />
                                    <label
                                        className='change-data-form__form__field__input__label'
                                        htmlFor="phone"
                                    >
                                        {t("authentication.changeData.phone")}
                                    </label>
                                </div>
                            </div>
                            <span className='change-data-form__form__error'>{(touched.phone && errors.phone) ? errors.phone : ''}</span>
                        </div>
                    </ConfigProvider>
                </div>
                <div className='change-data-form__form__button'>
                    <button
                        type='submit'
                        disabled={Object.keys(errors).length !== 0 || !values.document || !values.phone || loading}
                    >
                        {t("authentication.changeData.button")}
                    </button>
                </div>
            </form>
            {loading
                ? <div className='change-data-form__loading'>
                    <div className='change-data-form__loading__spinner'>
                        <img src={Assets.SharedIcons.icon_loading} alt='icon_loading' />
                    </div>
                </div>
                : null
            }
        </div>
    )
}

const mapStateToProps = ({ CompleteProfileReducer }) => {
    const {
        typeIdentificationNumber,
    } = CompleteProfileReducer;

    return {
        typeIdentificationNumber,
    };
};

export default connect(mapStateToProps)(ChangeDataForm);