// Libraries
import { combineReducers } from 'redux';

// Reducers
import AuthenticationReducer from '../reducers/authentication/authentication.reducer';
import MapReducer from '../reducers/map/map.reducer';
import MyNetworkReducer from '../reducers/my-network/my-network.reducer';
import TabbarReducer from '../reducers/tabbar/tabbar.reducer';
import AppReducer from '../reducers/app/app.reducer';
import CompleteProfileReducer from '../reducers/complete-profile/complete-profile.reducer';

const rootReducer = combineReducers({
	AuthenticationReducer,
	MapReducer,
	MyNetworkReducer,
	CompleteProfileReducer,
	TabbarReducer,
	AppReducer
});

export default rootReducer;
