// Assets
import { Assets } from '../../../../../../assets/index.js'

// Libraries
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

// Services
import { getTokenService, validateSessionService } from '../../../../../../services/authentication.services.js';

// Styles
import './modal-successful-change.component.scss';

const ModalSuccessfulChange = (props) => {
    const {
        // Variables
        visibility,
        dataUserLogged
    } = props;

    const INITIAL_STATE = {
        isDisableNextButton: false
    }
    
    const [state, setState] = useState(INITIAL_STATE)
    const [t] = useTranslation("translation");
    const history = useNavigate();

    let {
        isDisableNextButton,
    } = state

    const acceptChange = async () => {
        let token = getTokenService()
        try {
            const { userLogged } = await validateSessionService(token);
            if (userLogged.isCompletedRegister) {
                history("/network");
            } else {
                setState((s) => ({
                    ...s,
                    isDisableNextButton: false
                }))
            }
        } catch (error) {
            setState((s) => ({
                ...s,
                isDisableNextButton: false
            }))
        }
    };

    return (
        <Modal
            isOpen={visibility}
            bodyOpenClassName="modal-successful-change__body"
            onRequestClose={() => dataUserLogged && acceptChange()}
            ariaHideApp={false}
            overlayClassName="modal-successful-change__overlay grid-x align-center-middle"
            className={`modal-successful-${!!dataUserLogged ? 'change' : "change__loading"} grid-y`}
        >
            {dataUserLogged
                ?<>
                    <div className='grid-y align-center-middle small-10'>
                        <div className='grid-y small-2 justify-content-end '>
                            <span className='modal-successful-change__title'>
                                {`${t("home.completeRegistration.completeProfile.modalSuccess.title")} ${dataUserLogged ? dataUserLogged : ""}`}
                            </span>
                        </div>
                        <div className='grid-y align-center-middle small-5'>
                            <img
                                src={Assets.AuthenticationIcons.icon_user}
                                alt="icon_user"
                                className='modal-successful-change__icon'
                            />
                        </div>
                        <div className='grid-y align-middle small-4 p-2'>
                            <span>{t("home.completeRegistration.completeProfile.modalSuccess.successChange")}</span>
                        </div>
                    </div>
                    <div className='small-2 grid-y align-center-middle'>
                        <button
                            onClick={() => {
                                setState({
                                    ...state,
                                    isDisableNextButton: true
                                })
                                acceptChange()
                            }}
                            className="modal-successful-change__button"
                            disabled={isDisableNextButton}
                        >
                            {t("home.completeRegistration.completeProfile.modalSuccess.button")}
                        </button>
                    </div>
                </>
                : <div className='modal-successful-change__loading__icon-loading'>
                    <img
                        src={Assets.SharedIcons.icon_loading}
                        alt="icon_loading"
                    />
                </div>
            }
        </Modal>
    )
}

export default ModalSuccessfulChange;