import * as mapTypes from './map.types';

const INITIAL_STATE = {
    showInfoId: 0,
};

const MapReducer = (state = INITIAL_STATE, action) => {
    
        switch (action.type) {
            case mapTypes.INFO_SHOW:
                return {
                    ...state,
                    showInfoId: action.payload
                };
            default:
                return state;
        }
    
    };
export default MapReducer;
