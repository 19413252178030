//Endpoints
import CompleteRegistrationEndPoints from "./constants/complete-registration.end-points";
//Infrastructure
import Http from "./infrastructure/http.infrastructure";

export async function getTypeOfHouseService() {

    try {
        const { data: responseTypeOfHouse, error } = await Http.get(CompleteRegistrationEndPoints.GET_TYPE_OF_HOUSE);
        if (responseTypeOfHouse && responseTypeOfHouse.status) {
            return responseTypeOfHouse.data
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}

export async function getEducationLevelService() {

    try {
        const { data: responseEducationLevel, error } = await Http.get(CompleteRegistrationEndPoints.GET_EDUCATION_LEVEL);
        if (responseEducationLevel && responseEducationLevel.status) {
            return responseEducationLevel.data
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}
export async function getCurrentOccupationService() {

    try {
        const { data: responseCurrentOccupation, error } = await Http.get(CompleteRegistrationEndPoints.GET_CURRENT_OCCUPATION);
        if (responseCurrentOccupation && responseCurrentOccupation.status) {
            return responseCurrentOccupation.data
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}

export async function getTypeIdentificationNumberService() {

    try {
        const { data: responseTypeIdentificationNumber, error } = await Http.get(CompleteRegistrationEndPoints.GET_TYPE_IDENTIFICATION_NUMBER);
        if (responseTypeIdentificationNumber && responseTypeIdentificationNumber.status) {
            return responseTypeIdentificationNumber.data
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}

export async function getGenderService() {

    try {
        const { data: responseGender, error } = await Http.get(CompleteRegistrationEndPoints.GET_GENDER);
        if (responseGender && responseGender.status) {
            return responseGender.data
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}

export async function getAllCountryService(token) {

    try {
        const { data: responseAllCountry, error } = await Http.get(CompleteRegistrationEndPoints.GET_ALL_COUNTRY,null,token);
        if (responseAllCountry && responseAllCountry.status) {
            return responseAllCountry.data
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}

export async function getAllDepartmentsService(country,token) {

    try {
        const { data: responseAllDepartments, error } = await Http.get(CompleteRegistrationEndPoints.GET_ALL_DEPARTMENTS,{country},token);
        if (responseAllDepartments && responseAllDepartments.status) {
            return responseAllDepartments.data
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}

export async function getAllMunicipalityService(department,token) {
    try {
        const { data: responseAllMunicipality, error } = await Http.get(CompleteRegistrationEndPoints.GET_ALL_MUNICIPALITY,{department},token);
        if (responseAllMunicipality && responseAllMunicipality.status) {
            return responseAllMunicipality.data
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}
export async function getAllNeighborhoodService(municipality,token) {
    try {
        const { data: responseAllNeighborhood, error } = await Http.get(CompleteRegistrationEndPoints.GET_ALL_NEIGHBORHOOD,{municipality},token);
        if (responseAllNeighborhood && responseAllNeighborhood.status) {
            return responseAllNeighborhood.data
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}

export async function getAllVotingStationService(municipality,token) {
    try {
        const { data: responseAllVotingStation, error } = await Http.get(CompleteRegistrationEndPoints.GET_ALL_VOTING_STATION,{municipality},token);
        if (responseAllVotingStation && responseAllVotingStation.status) {
            return responseAllVotingStation.data
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}

export async function getAllVotingTableService(votingStation,token) {
    try {
        const { data: responseAllVotingTable, error } = await Http.get(CompleteRegistrationEndPoints.GET_ALL_VOTING_TABLE,{votingStation},token);
        if (responseAllVotingTable && responseAllVotingTable.status) {
            return responseAllVotingTable.data[0].lctVotingTables
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}

export async function userCompletedService(token) {
    try {
        const { data: responseUserCompleted, error } = await Http.post(CompleteRegistrationEndPoints.USER_COMPLETED,null,token);
        if (responseUserCompleted && responseUserCompleted.status) {
            return responseUserCompleted.status
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}

export async function saveProgressService(user, token) {

    const newUser ={
        usrCompanyWorking:user.companyName,
        usrIdentificationNumber:user.documentNumber,
        usrTypeIdentificationNumber:user.typeIdentification,
        usrName:user.name,
        usrLastName:user.lastName,
        usrPhone: user.phone,
        usrPhoneWhatsapp: user.phoneWhatsapp,
        usrEmail:user.email,
        usrGender:user.genre,
        usrIsEthnicGroup:user.ethnicity,
        usrIsDisabilityStatus:user.disability,
        usrTypeOfHouse:user.typeOfHousing,
        usrStratum:user.stratum,
        usrTotalPeopleLiving:user.peopleLiveYou,
        usrEducationLevel:user.educationalLevel,
        usrProfession:user.profession,
        usrCurrentOccupation:user.currentOccupation,
        usrExperienceMonths:user.timeExperience,
        usrIsSeekingEmployment:user.lookingJob,

        usrDateBirth:user.dateBirth,
        usrCountry:user.countryBirth ? user.countryBirth?.uid : null,
        usrDepartment:user.departmentBirth ? user.departmentBirth?.uid : null,
        usrMunicipality:user.municipalityBirth ? user.municipalityBirth?.uid : null,

        usrCountryResidence: user.residenceCountry ? user.residenceCountry : null,
        usrNeighborhoodResidence:user.residenceNeighborhood ? user.residenceNeighborhood : null,
        usrDepartmentResidence:user.residenceDepartment ? user.residenceDepartment : null,
        usrMunicipalityResidence:user.residenceMunicipality ? user.residenceMunicipality : null,
        usrAddressResidence:user.residenceAddress,
        usrLatitudeResidence: user.residenceLocation? user.residenceLocation.lat : null,
        usrLongitudeResidence: user.residenceLocation? user.residenceLocation.lng : null,
        lctImgHouse: user.imgResidence,

        usrDepartmentVotingStation:user.votingDepartment ? user.votingDepartment?.uid : null,
        usrMunicipalityVotingStation:user.votingMunicipality ? user.votingMunicipality?.uid : null,
        usrPlaceVotingStation:user.votingBooth ? user.votingBooth?.uid : null,
        usrVotingTable:user.votingTable,

        usrOfficeHeld: user.typeOccupation,
        usrIsServicePortfolio: user.portfolio,
        usrIsSocialNetwork: user.socialNetworks,
        usrSocialNetworkLink: user.linkSocialNetworks,
        usrPortfolioFormatType: user.format,
        usrPortfolioFile: user.portfolioLink,
        usrEconomicSector: user.economicSector,
        usrCompanyWorkingLocation: user.located,
        usrDescriptionServiceOffered: user.descriptionServices,
        usrImgProfile: user.photoProfile,
        usrCurriculum: user.curriculumVitae,
        usrAttachPortfolio: user.attachPortfolio
    }

    try {
        const { data: responseRegisterUsers, error } = await Http.post(CompleteRegistrationEndPoints.SAVE_PROGRESS,newUser,token, true);
        if (responseRegisterUsers && responseRegisterUsers.status) {
            return responseRegisterUsers.status
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}

export async function getEconomicSector() {

    try {
        const { data: responseEconomicSector, error } = await Http.get(CompleteRegistrationEndPoints.GET_SECTOR_ECONOMIC);
        if (responseEconomicSector && responseEconomicSector.status) {
            return responseEconomicSector.data
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}