// Constants - Endpoints
import CoreAppEndPoints from "../constants/core.end-points";
// Infrastructure
import { callExternalApi } from "./external-api.infrastructure";

/**
 * GET type function
 * @date 2022-11-24
 * 
 * @param {string} url - It is  the string endPoint to the API endpoint.
 * @param {any} body - It is  the data that is part of the API request.
 * @param {string} token - It is the value of the access key for authentication in the API. 
 * 
 * @returns {Promise} - Return a Promise that you will receive the API response.
 */
async function get(url, body, token) {
    return commonConfigurationVerbHttp('GET',url, body, token)
}

/**
 * POST type function
 * @date 2022-11-24
 * 
 * @param {string} url - It is  the string endPoint to the API endpoint.
 * @param {any} body - It is  the data that is part of the API request.
 * @param {string} token - It is the value of the access key for authentication in the API. 
 * @param {boolean} [isContainFiles = false] - Flag that allows to determine the process flow of the POST request to form a configuration for sending files to the API.
 * 
 * @returns {Promise} - Return a Promise that you will receive the API response.
 */
async function post(url, body, token, isContainFiles = false) {
  if (isContainFiles) {
    
    let bodyFormData = new FormData();
    const bodyValues = Object.entries(body)

    bodyValues.forEach(propertyElement => {
      const [key, value] = propertyElement
      bodyFormData.append(key, value)
    })

    return commonConfigurationVerbHttp('POST', url, bodyFormData, token, isContainFiles)
  } else {
    return commonConfigurationVerbHttp('POST', url, body, token)
  }
}

/**
 * PUT type function
 * @date 2022-11-24
 * 
 * @param {string} url - It is  the string endPoint to the API endpoint.
 * @param {any} body - It is  the data that is part of the API request.
 * @param {string} token - It is the value of the access key for authentication in the API. 
 * 
 * @returns {Promise} - Return a Promise that you will receive the API response.
 */
async function put(url, body, token) {
    return commonConfigurationVerbHttp('PUT',url, body, token)
}

/**
 * DELETE type function
 * @date 2022-11-24
 * 
 * @param {string} url - It is  the string endPoint to the API endpoint.
 * @param {any} body - It is  the data that is part of the API request.
 * @param {string} token - It is the value of the access key for authentication in the API. 
 * 
 * @returns {Promise} - Return a Promise that you will receive the API response.
 */
async function deleteRequest (url, body, token) {
    return commonConfigurationVerbHttp('DELETE',url, body, token)
}

async function commonConfigurationVerbHttp (verbHttp, url, body, token, isContainFiles = false){
  let config = {
      url: `${CoreAppEndPoints.DOMAIN}${url}`,
      headers: {
        "content-type": isContainFiles ? "multipart/form-data" : "application/json",
      },
      method: verbHttp
    };

    if (token) {
      config.headers = {
        ...config.headers,
        "x-token": token,
      };
    }

    if (body) {
      if (verbHttp !== "GET") {
        config.data = isContainFiles ? body : JSON.stringify(body);
      } else {
        config.params = body;
      }
    }
    
    const { data, error } = await callExternalApi({ config });
    
    return {
        data: data || null,
        error,
    };
}

const Http = {
    get,
    post,
    put,
    delete: deleteRequest
}

export default Http