const MapEndPoints =  {
    VOTING_STATION: `/api/location/getLocations`,
    VOTING_TABLE: `/api/location/getVotingTable`,
    USER_BY_VOTING_STATION: `/api/location/getUsersByVotingStation`,
    USER_BY_SECTOR: `/api/location/getUsersBySector`,
    GENERATE_REPORT_VOTING_STATION: `/api/location/generateReportVotingStation`,
    GENERATE_REPORT_SECTOR: `/api/location/generateReportSector`,
    AUTOCOMPLETE_PLACES: `/api/map-service/autocomplete-places`
}

export default MapEndPoints