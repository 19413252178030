const AuthenticationErrorCodesConst = {
    /**
     * Roles and group associated selection
     * */
    RGA_ATH_001: "RGA_ATH_001",
    RGA_ATH_002: "RGA_ATH_002",
    RGA_ATH_003: "RGA_ATH_003",
    /** 
    * Codes API
    * */
    // AU
    AU0001: "AU0001",
    AU0002: "AU0002",
    AU0003: "AU0003",
    AU0005: "AU0005",
    AU0013: "AU0013",
    AU0014: "AU0014",
    AU0016: "AU0016",
    AU0017: "AU0017",

     // US
    US0001: "US0001",
    US0002: "US0002",
    US0003: "US0003",
    US0004: "US0004",
    US0005: "US0005",
    US0006: "US0006",
    US0007: "US0007",
    US0008: "US0008",
    US0011: "US0011",
    US0012: "US0012",
    US0013: "US0013",
    US0014: "US0014",
    US0017: "US0017",
    US0019: "US0019",
    US0020: "US0020",
    US0021: "US0021",
    US0022: "US0022",
    US0025: "US0025",
    US0026: "US0026",
    US0027: "US0027",
    US0028: "US0028",
    US0029: "US0029",
    US0030: "US0030",
    US0031: "US0031",
    US0032: "US0032",
    US0033: "US0033",
    US0034: "US0034",
    US0035: "US0035",
    US0036: "US0036",
    US0037: "US0037",
    US0038: "US0038",
    US0039: "US0039",
    US0057: "US0057",
    US0060: "US0060"
}

export default AuthenticationErrorCodesConst