const CompleteRegistrationEndPoints =  {
    GET_TYPE_OF_HOUSE: `/api/domain/typeOfHouse`,
    GET_EDUCATION_LEVEL: `/api/domain/educationLevel`,
    GET_CURRENT_OCCUPATION: `/api/domain/currentOccupation`,
    GET_TYPE_IDENTIFICATION_NUMBER: `/api/domain/typeIdentificationNumber`,
    GET_GENDER:`/api/domain/gender`,
    GET_ALL_DEPARTMENTS:`/api/location/getAllDepartments`,
    GET_ALL_MUNICIPALITY:`/api/location/getAllMunicipality`,
    GET_ALL_NEIGHBORHOOD:`/api/location/getAllSector`,
    USER_COMPLETED:`/api/users/userCompleted`,
    SAVE_PROGRESS:`/api/users/register`,
    GET_ALL_VOTING_STATION:`/api/location/getAllVotingStation`,
    GET_ALL_VOTING_TABLE:`/api/location/getAllVotingTable`,
    GET_ALL_COUNTRY:`/api/location/getAllCountry`,
    GET_SECTOR_ECONOMIC:`/api/domain/economicSector`
}

export default CompleteRegistrationEndPoints