const AlertsErrorCodesConst = {
    /**
     * Alerts
     * */
     ALT_APP_001 : "ALT_APP_001",
     /**
     * Toast
     * */
     TST_APP_001 : "TST_APP_001"
  }

  export default AlertsErrorCodesConst