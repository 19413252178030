// Style
import "./view-profile-skeleton.component.scss";

const ViewProfileSkeletonComponent = (props) => {
  return (
    <div className="view-profile-skeleton">
      <div className=" grid-y align-center-middle view-profile-skeleton__member__header">
        <div className="align-center-middle view-profile-skeleton__content-circle__mod" />
        <div className="align-center-middle view-profile-skeleton__member__button__text" />
        <div className="align-center-middle view-profile-skeleton__content-circle" />
      </div>
      <div className="grid-x view-profile-skeleton__footer">
        <div className="grid-x view-profile-skeleton__content-active">
          <div className="grid-y small-3 align-center-middle view-profile-skeleton__mod">
            <div className="align-center-middle view-profile-skeleton__content-circle" />
            <div className="align-center-middle view-profile-skeleton__member__button__text-subtitle" />
          </div>
          <div className="grid-y small-3 align-center-middle view-profile-skeleton__content-mod">
            <div className=" align-center-middle view-profile-skeleton__content-circle-inactive" />
            <div className="align-center-middle view-profile-skeleton__member__button__text-subtitle-inactive" />
          </div>
          <div className="grid-y small-3 align-center-middle view-profile-skeleton__content-mod">
            <div className=" align-center-middle view-profile-skeleton__content-circle-inactive" />
            <div className="align-center-middle view-profile-skeleton__member__button__text-subtitle-inactive" />
          </div>
          <div className="grid-y small-3 align-center-middle view-profile-skeleton__content-mod">
            <div className=" align-center-middle view-profile-skeleton__content-circle-inactive" />
            <div className="align-center-middle view-profile-skeleton__member__button__text-subtitle-inactive" />
          </div>
        </div>
        <div className="grid-y view-profile-skeleton__content">
          <div className="align-center-middle view-profile-skeleton__member__button__text-mod" />
          <div className="align-center-middle view-profile-skeleton__member__button__text-mod" />
          <div className="align-center-middle view-profile-skeleton__member__button__text-mod" />
          <div className="align-center-middle view-profile-skeleton__member__button__text-mod" />
        </div>
      </div>
    </div>
  );
};
export default ViewProfileSkeletonComponent;
