//Style
import './contact-list.skeleton.component.scss'


const ViewContactListSkeletonComponent = (props) =>{
return (<div className='contact-list-skeleton'>
          <div className="contact-list-skeleton__letter">
          </div>
          <div className='contact-list-skeleton__container'>
            <div className="grid-x align-center-middle">
                <div className="contact-list-skeleton__img__content">
              </div>
                  <div className="contact-list-skeleton__name">
                  </div>
            </div>
            <div className="grid-x align-center-middle">
                <div className="contact-list-skeleton__img__content">
              </div>
                  <div className="contact-list-skeleton__name">
                  </div>
            </div>
            <div className="grid-x align-center-middle">
                <div className="contact-list-skeleton__img__content">
              </div>
                  <div className="contact-list-skeleton__name">
                  </div>
            </div>
            <div className="grid-x align-center-middle">
                <div className="contact-list-skeleton__img__content">
              </div>
                  <div className="contact-list-skeleton__name">
                  </div>
            </div>
            <div className="grid-x align-center-middle">
                <div className="contact-list-skeleton__img__content">
              </div>
                  <div className="contact-list-skeleton__name">
                  </div>
            </div>
            <div className="grid-x align-center-middle">
                <div className="contact-list-skeleton__img__content">
              </div>
                  <div className="contact-list-skeleton__name">
                  </div>
            </div>
            <div className="grid-x align-center-middle">
                <div className="contact-list-skeleton__img__content">
              </div>
                  <div className="contact-list-skeleton__name">
                  </div>
            </div>
            <div className="grid-x align-center-middle">
                <div className="contact-list-skeleton__img__content">
              </div>
                  <div className="contact-list-skeleton__name">
                  </div>
            </div>
            <div className="grid-x align-center-middle">
                <div className="contact-list-skeleton__img__content">
              </div>
                  <div className="contact-list-skeleton__name">
                  </div>
            </div>
            <div className="grid-x align-center-middle">
                <div className="contact-list-skeleton__img__content">
              </div>
                  <div className="contact-list-skeleton__name">
                  </div>
            </div>
            <div className="grid-x align-center-middle">
                <div className="contact-list-skeleton__img__content">
              </div>
                  <div className="contact-list-skeleton__name">
                  </div>
            </div>
            <div className="grid-x align-center-middle">
                <div className="contact-list-skeleton__img__content">
              </div>
                  <div className="contact-list-skeleton__name">
                  </div>
            </div>
            <div className="grid-x align-center-middle">
                <div className="contact-list-skeleton__img__content">
              </div>
                  <div className="contact-list-skeleton__name">
                  </div>
            </div>
            </div>
        </div>
)
}
export default ViewContactListSkeletonComponent;