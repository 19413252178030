//  Actions
import { 
	setFlagProcessValidateSessionSemiProtectedAuthenticationReducer, 
	resetFlagsAuthenticationSemiProtectedRoutesAuthenticationReducer
} from "../storage/reducers/authentication/authentication.actions"

// Components - shared
import LoadingAppComponent from "../shared/components/loadings/loading-home/loading-app.component"

// Libraries
import { Navigate, Outlet } from "react-router-dom"
import { connect } from "react-redux"
import { useEffect } from 'react'

// Services
import { 
	clearKeysAuthenticationService, 
	getTokenService, 
	getUserRolesService, 
	setTotalMembersService, 
	setUserLoggedService, 
	validateSessionService
 } from "../services/authentication.services"

// Utils
import { isContainedAnyRolesRequired } from "../utils/authentication.utils"

const authProcess = () => {
	let token = getTokenService()
	let role = getUserRolesService()

	if (token) {
		return {
			isAuth: true,
			role: role
		}
	} else {
		return {
			isAuth: false,
			role: ""
		}
	}
}

const SemiProtectedRoutes = (props) => {

	const {
		// Actions
		setFlagProcessValidateSessionSemiProtectedAuthenticationReducer,
		resetFlagsAuthenticationSemiProtectedRoutesAuthenticationReducer,
		// Variables
		isCheckingSessionSemiProtectedRoutes,
		isValidSessionSemiProtectedRoutes
	} = props

	useEffect(() => {
		processValidationToken()
		return () => { 
			resetFlagsAuthenticationSemiProtectedRoutesAuthenticationReducer()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	async function processValidationToken() {
		let token = getTokenService()
		validateSessionService(token)
		.then( res =>{
			const { userLogged, totalMembers } = res
			let isValidSession = false
			if (userLogged.idUser) {
				isValidSession = true
				setUserLoggedService(userLogged)
				setTotalMembersService(totalMembers)
				setFlagProcessValidateSessionSemiProtectedAuthenticationReducer(true, isValidSession)
			} else {
				setFlagProcessValidateSessionSemiProtectedAuthenticationReducer(true, isValidSession)
			}
		})
		.catch(err=>{	
			setFlagProcessValidateSessionSemiProtectedAuthenticationReducer(true, false)	
		})
	}

	if (!isCheckingSessionSemiProtectedRoutes && !isValidSessionSemiProtectedRoutes) {
		return <LoadingAppComponent />
	} else if (isCheckingSessionSemiProtectedRoutes && !isValidSessionSemiProtectedRoutes) {
		clearKeysAuthenticationService()
		return <Navigate replace to="/login" />
	} else {
		const { isAuth, role } = authProcess()
		if (props.roleRequired) {
			const isValidRole = isContainedAnyRolesRequired(props.roleRequired, role)
			return isAuth ? (
				isValidRole ? (
					<Outlet />
				) : (
					<Navigate replace to="/denied" />
				)
			) : (
				<Navigate to="/login" />
			)
		} else {
			return isAuth ? <Outlet /> : <Navigate to="/login" />
		}
	}
}

const mapStateToProps = ({ AuthenticationReducer }) => {
	const { 
		isCheckingSessionSemiProtectedRoutes, isValidSessionSemiProtectedRoutes
	} = AuthenticationReducer

	return {
		isCheckingSessionSemiProtectedRoutes,
		isValidSessionSemiProtectedRoutes
	}
}

const mapStateToPropsActions = {
	setFlagProcessValidateSessionSemiProtectedAuthenticationReducer,
	resetFlagsAuthenticationSemiProtectedRoutesAuthenticationReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(SemiProtectedRoutes)