// Dictionary
import string from "../../../shared/translations/I18n";
// Models
import ErrorInternalAppModel from "../error-internal-app.model";

export const createErrorInternalApp = (errorInternalModel) => {

    let description = string.t(`${errorInternalModel.stringTranslate}.description`)
    let message = string.t(`${errorInternalModel.stringTranslate}.message`)

    if (!message) {
        message = ""
    }

    if (!description) {
        description = ""
    }

   return new ErrorInternalAppModel(
        errorInternalModel.code,
        message,
        description,
    )
}