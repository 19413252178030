// Libraries
import React, { useState } from 'react';
import { ResponsiveContainer, BarChart, Bar, Brush, Cell, XAxis, YAxis } from "recharts";

const BarChartComponent = (props) => {
    const { data } = props;
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (props) => {
        if (activeIndex !== props.id) {
            setActiveIndex(props.id);
        } else {
            setActiveIndex(null);
        }
    };

    const getYears = () => {
        const years = [];
        data.forEach(item => {
            if (!years.includes(item.year)) {
                years.push(item.year)
            }
        })
        return years;
    };

    const CustomizedLabel = (props) => {
        const { id, x, y, width, fill, value } = props;
        return (
            id === activeIndex && (
                <text
                    x={x + width / 2}
                    y={y - 5}
                    fontSize="14"
                    fontWeight="bold"
                    fill={fill}
                    textAnchor="middle"
                >
                    {value}
                </text>
            )
        );
    };

    return (
        <ResponsiveContainer className='bar-chart'>
            <BarChart width='100%' height={190} data={data}>
                <YAxis hide padding={{ top: 15 }} />
                <XAxis dataKey="name" tick={{ stroke: "#371f7a", strokeWidth: 1 }} axisLine={{ stroke: "#371f7a", strokeWidth: 2, }} tickLine={false} />
                {((getYears().length > 1 && data?.length > 7) || data?.length > 7) &&
                    <Brush
                        tickFormatter={(v, i) => `${data[i].name}-${v}`}
                        dataKey="year"
                        height={15}
                        stroke="#9389B1"
                        onChange={() => !!activeIndex && setActiveIndex(null)}
                    />
                }
                <Bar dataKey="value" onClick={handleClick} label={<CustomizedLabel />}>
                    {data.map((entry, index) => (
                        <Cell
                            cursor="pointer"
                            fill={entry.name + entry.year === activeIndex ? "#5F3EF2" : "#9389B1"}
                            key={`cell-${index}`}
                            id={entry.name + entry.year}
                            radius={5}
                        />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}

export default BarChartComponent;