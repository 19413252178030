//Actions
import { setIsUnsubscribeMyAccountReducer, setIsUnsubscribeMemberReducer, setStateMyNetworkReducer } from '../../../../../storage/reducers/my-network/my-network.actions';

// Assets
import { Assets } from "../../../../../assets";

//Constants - Core
import Constants from '../../../../../core/constants';

//Components
import ErrorToastComponent from '../../../../../shared/components/toast/error-toast/error-toast.component';
import ModalUnsubscribeMyAccountComponent from './components/modal-unsubscribe-my-account/modal-unsubscribe-my-account.component';
import ModalUnsubscribeMemberComponent from './components/modal-unsubscribe-member/modal-unsubscribe-member.component';
import ModalReactivateAccountMemberComponent from './components/modal-reactivate-account-member/modal-reactivate-account-member.component';

// Libraries
import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

//Services
import { unsubscribeUserService } from '../../../../../services/my-network.service';
import { getTokenService, getUserLoggedService, setUserLoggedService } from '../../../../../services/authentication.services';

// Styles
import "./contact-card.component.scss";

// Utils
import { validateIsMobileDeviceUtils } from '../../../../../utils/validate-is-mobile-device.utils';

const ContactCardComponent = (props) => {

    const {
        //Actions
        setIsUnsubscribeMyAccountReducer,
        setIsUnsubscribeMemberReducer,
        setStateMyNetworkReducer,
        //Functions
        exitPlatformFunction,
        //Props
        cardUser,
        isUserLoggedSelected,
        //Variables
        isUnsubscribeMyAccount,
        isUnsubscribeMember,
        showViewProfile
    } = props;

    const [t] = useTranslation("translation");

    useEffect(() => {
        const userLogged = getUserLoggedService();
        const response = validateIsMobileDeviceUtils();
        if (response) {
            setState({
                ...state,
                isMobileDevice: response,
                userLogged
            });
        };
        return () => setStateMyNetworkReducer('isTriggerNetworkInformationUpdate', false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let { idUser } = getUserLoggedService();
        if ((idUser === cardUser?.uidRoot) && !cardUser?.isStateActive) {
            setIsUnsubscribeMemberReducer(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardUser]);

    const INITIAL_STATE = {
        isMobileDevice: false,
        isShowModalUnsubscribeMyAccount: false,
        isUnsubscribeMyAccountState: false,
        isShowModalUnsubscribeMember: false,
        isShowModalReactivateMember: false,
        userLogged: {}
    };

    const [state, setState] = useState(INITIAL_STATE);

    const { 
        isMobileDevice, isShowModalUnsubscribeMyAccount, isUnsubscribeMyAccountState, 
        userLogged, isShowModalUnsubscribeMember, isShowModalReactivateMember 
    } = state;

    const contactPhone = cardUser?.phone ? cardUser?.phone : "";
    const contactWhatsapp = cardUser?.phoneWhatsapp ? cardUser?.phoneWhatsapp : "";
    const nameUser = `${cardUser?.name ? cardUser.name : ''} ${cardUser?.lastName ? cardUser.lastName : ''}`;

    const unsubscribeMember = (params) => {
        let token = getTokenService();
        unsubscribeUserService({ uid: showViewProfile.uidMemberSelected }, token)
            .then(() => {
                switch (params) {
                    case 'disableMyAccount':
                    case 'reactivateMyAccount':
                        let userLogged = getUserLoggedService();
                        if (userLogged) {
                            if (params === 'disableMyAccount') {
                                userLogged.isStateActive = false;
                                setUserLoggedService(userLogged);
                                if (!userLogged.isStateActive && userLogged.isCompletedRegister) {
                                    setIsUnsubscribeMyAccountReducer(true);
                                    setState({
                                        ...state,
                                        isUnsubscribeMyAccountState: true
                                    });
                                };
                            } else {
                                userLogged.isStateActive = true;
                                setUserLoggedService(userLogged);
                                if (userLogged.isStateActive && userLogged.isCompletedRegister) {
                                    setIsUnsubscribeMyAccountReducer(false);
                                };
                            }
                        }
                        break;

                    case 'disableTheAffiliateAccount':
                    case 'reactivateTheAffiliateAccount':
                        if (params === 'disableTheAffiliateAccount') {
                            setIsUnsubscribeMemberReducer(true);
                            closeModal();
                        } else {
                            setIsUnsubscribeMemberReducer(false);
                        }
                        break;

                    default:
                        break;
                };
                setStateMyNetworkReducer('isTriggerNetworkInformationUpdate', true)
            })
            .catch((error) => {
                ErrorToastComponent({
                    title: `${error.code} ${error.message}`,
                    position: Constants.AlertConst.TOP_END_POSITION_TEXT,
                    timer: Constants.AlertConst.TIMER_MEDIUM,
                    iconHtml: {
                        src: Assets.SharedIcons.icon_alert_error,
                        alt: "icon_alert_error"
                    },
                    iconColor: "transparent"
                })
            })
    };

    const closeModal = () => {
        setState({
            ...state,
            isShowModalUnsubscribeMyAccount: false,
            isUnsubscribeMyAccountState: false,
            isShowModalUnsubscribeMember: false,
            isShowModalReactivateMember: false,
        })
    };

    const unsubscribeOrActivateAccountMember = () => {
        if (!isUnsubscribeMember) {
            setState({
                ...state,
                isShowModalUnsubscribeMember: true
            })
        }else{
            setState({
                ...state,
                isShowModalReactivateMember: true
            });
            unsubscribeMember('reactivateTheAffiliateAccount')
        }
    }

    return (
        <div className={isMobileDevice ? "contact-card__box" : "contact-card__box--isDesktop"}>
            <button
                className={isUnsubscribeMyAccount || isUnsubscribeMember
                    ? "contact-card__box__close--mod"
                    : "contact-card__box__close"
                }
                onClick={() => exitPlatformFunction()}
            >
                <img
                    src={Assets.SharedIcons.icon_close}
                    alt="icon_profile"
                />
            </button>
            <div className="contact-card__content-info">
                <div className="contact-card__content-info__content-img">
                    <img
                        src={cardUser?.photoProfile ? cardUser?.photoProfile : Assets.SharedIcons.icon_profile}
                        alt="icon_profile"
                        className="contact-card__content-info__img"
                    />
                    {((isUnsubscribeMember && !showViewProfile.isUserLoggedSelected) || (isUnsubscribeMyAccount && showViewProfile.isUserLoggedSelected)) &&                         
                        <img
                            alt="icon_warning_big"
                            src={Assets.SharedIcons.icon_warning_big}
                            className="contact-card__content-info__img-warning"
                        />
                    }
                </div>
                <div className="contact-card__content-info__box-text">
                    <label className="contact-card__content-info__name-user">{nameUser}</label>
                    <label className="contact-card__content-info__email">{cardUser?.email ? cardUser.email : ''}</label>
                </div>
                {isMobileDevice &&
                    <div className="grid-x contact-card__content-info__content-buttons align-center-middle">
                        {isUserLoggedSelected
                            ?
                                <>
                                    {/* TODO: hide the edit profile button */}
                                    {/* <button className={!isUnsubscribeMyAccount
                                                ? "grid-y small-6 align-center-middle"
                                                : "grid-y small-6 align-center-middle contact-card__content-info__content-buttons__inactive-buttons"
                                            }>
                                            <img src={Assets.SharedIcons.icon_edit_contact} alt="icon_edit_contact" />
                                            <div className="contact-card__content-info__content-buttons__text">
                                                <span>{t("home.myNetwork.contactCard.editProfile")}</span>
                                            </div>
                                        </button> */}
                                    <button
                                        className={!isUnsubscribeMyAccount
                                            ? "grid-y small-6 align-center-middle"
                                            : "grid-y small-6 align-center-middle contact-card__content-info__content-buttons__btn"
                                        }
                                        onClick={() => !isUnsubscribeMyAccount
                                            ?   setState({
                                                    ...state,
                                                    isShowModalUnsubscribeMyAccount: true
                                                })
                                            :  unsubscribeMember('reactivateMyAccount')
                                        }
                                    >
                                        <img src={Assets.SharedIcons.icon_warning} alt="icon_warning" />
                                        <div className={!isUnsubscribeMyAccount
                                                ? "contact-card__content-info__content-buttons__text"
                                                : "contact-card__content-info__content-buttons__text--mod"
                                            }
                                        >
                                            {!isUnsubscribeMyAccount
                                                ? <span>{t("home.myNetwork.contactCard.unsubscribe")}</span>
                                                : <span>{t("home.myNetwork.contactCard.reactivateAccount")}</span>
                                            }
                                        </div>
                                    </button>
                                </>
                            :
                                <>
                                    <a
                                        //TODO: Bases for messaging functionalities
                                        // https://t.me/+1XXXXXXX
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`https://wa.me/${contactWhatsapp}`}
                                        className={contactWhatsapp !== ''
                                            ? !isUnsubscribeMember 
                                                ? "grid-y small-4 align-center-middle" 
                                                : "grid-y small-4 align-center-middle contact-card__content-info__inactive-buttons"
                                            : "grid-y small-4 align-center-middle contact-card__content-info__inactive-buttons"
                                        }
                                    >
                                        <img src={Assets.SharedIcons.icon_send_message} alt="icon_send_message" />
                                        <div className="contact-card__content-info__content-buttons__text">
                                            <span>{t("home.myNetwork.contactCard.sendMessage")}</span>
                                        </div>
                                    </a>
                                    <a
                                        href={`tel:${contactPhone}`}
                                        className={contactPhone !== ''
                                            ? !isUnsubscribeMember 
                                                ? "grid-y small-4 align-center-middle" 
                                                : "grid-y small-4 align-center-middle contact-card__content-info__inactive-buttons"
                                            : "grid-y small-4 align-center-middle contact-card__content-info__inactive-buttons"
                                        }
                                    >
                                        <img src={Assets.SharedIcons.icon_make_calls} alt="icon_icon_calls" />
                                        <div className="contact-card__content-info__content-buttons__text">
                                            <span>{t("home.myNetwork.contactCard.makeCall")}</span>
                                        </div>
                                    </a>
                                    {userLogged?.idUser === cardUser?.uidRoot &&
                                        <button
                                            className={!isUnsubscribeMember
                                                ? "grid-y small-4 align-center-middle"
                                                : "grid-y small-4 align-center-middle contact-card__content-info__content-buttons__btn"
                                            }
                                            onClick={() => unsubscribeOrActivateAccountMember()}
                                        >
                                            <img src={Assets.SharedIcons.icon_warning} alt="icon_warning" />
                                            <div className="contact-card__content-info__content-buttons__text">
                                                {!isUnsubscribeMember
                                                    ? <span>{t("home.myNetwork.contactCard.unsubscribeMember")}</span>
                                                    : <span>{t("home.myNetwork.contactCard.reactivateAccountMember")}</span>
                                                }
                                            </div>
                                        </button>
                                    }
                                </>
                        }
                    </div>
                }
            </div>
            <ModalUnsubscribeMyAccountComponent
                isViewModalUnsubscribeMyAccount={isShowModalUnsubscribeMyAccount}
                isUnsubscribeMyAccountState={isUnsubscribeMyAccountState}
                closeModalUnsubscribeMyAccount={() => closeModal()}
                unsubscribeMyAccount={() => unsubscribeMember('disableMyAccount')}
            />
            <ModalUnsubscribeMemberComponent
                isViewModalUnsubscribeMember={isShowModalUnsubscribeMember}
                closeModalUnsubscribeMember={() => closeModal()}
                unsubscribeMember={() => unsubscribeMember('disableTheAffiliateAccount')}
            />
            <ModalReactivateAccountMemberComponent
                userLogged={userLogged}
                nameUserSelected={nameUser}
                isViewModalReactivateMember={isShowModalReactivateMember}
                closeModalReactivateMember={() => closeModal()}
            />
        </div>
    )
};

const mapStateToProps = ({ MyNetworkReducer }) => {
    const { showViewProfile, isUnsubscribeMyAccount, isUnsubscribeMember } = MyNetworkReducer;
    return {
        showViewProfile,
        isUnsubscribeMyAccount,
        isUnsubscribeMember
    };
};

const mapStateToPropsActions = {
    setIsUnsubscribeMyAccountReducer,
    setIsUnsubscribeMemberReducer,
    setStateMyNetworkReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(ContactCardComponent);