 // Constants
import AlertErrorsConst from "../../../../core/constants/errors/alerts-app/alerts-error.const";
import Constants from "../../../../core/constants";

// Libraries
import Swal from "sweetalert2";

// Models - DA0
import { createErrorInternalApp } from "../../../../models/aggregates/build-error-internal-app/build-error-internal-app.dao";

/**
* Error toast component
* 
* @param props - Contains configuration properties
* @param {string} [props.title = ''] - Title toast toast alert.
* @param {string} [props.text] - Content text toast alert.
* @param {number} [props.timer = 1500] - Numeric value of the time (milliseconds) in which the alert will be displayed.
* @param {string} [props.position = position] - Text value of the popup window position.
* @param {boolean} [props.timerProgressBar = false] - boolean value show a progress bar on the pop-up window.
* @param {string} [props.color = #FF0058] -Color for title, content and footer (CSS `color` property).
* @param {string} [props.iconHtml] -The custom HTML content for an icon.
* @param {string} [props.iconColor=''] -Use this to change the color of the icon if there is an iconHtml it should be set to true, otherwise false
*
* @returns {Object} Returns a SweetAlert2 popup, error toast alert
*/

const ErrorToastComponent = (props) => {

  if (!props) {
    throw createErrorInternalApp(AlertErrorsConst.paramsRequiredBuildToasts)
  }

  let {
    title = "",
    text,
    timer = 1500,
    position,
    timerProgressBar = false,
    color = '#FF0058',
    iconColor,
    icon = Constants.AlertConst.ERROR_ICON_TEXT,
    iconHtml,
  } = props 

  if (iconHtml && iconHtml.src && iconHtml.alt) {
    iconHtml = `<img src='${iconHtml.src}' alt='${iconHtml.alt}' />`
  }

  const Toast = Swal.mixin({
    toast: true,
    position,
    showConfirmButton: false,
    timer,
    timerProgressBar,
    didOpen: (toast) => {
      toast.addEventListener('click', () => { Toast.close() })
    }
  })


  return Toast.fire({
    icon,
    iconColor,
    iconHtml,
    title,
    text,
    color
  })
}

export default ErrorToastComponent