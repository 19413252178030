// Assets
import { Assets } from "../../../../../../../assets/index";

// Libraries
import React from 'react';
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

// Styles
import "./modal-unsubscribe-my-account.component.scss";

const ModalUnsubscribeMyAccount = (props) => {

    const {
        //Props
        isViewModalUnsubscribeMyAccount,
        isUnsubscribeMyAccountState,
        closeModalUnsubscribeMyAccount,
        unsubscribeMyAccount,
    } = props;

    const [t] = useTranslation("translation");

    return (
        <Modal
            isOpen={isViewModalUnsubscribeMyAccount}
            onRequestClose={() => closeModalUnsubscribeMyAccount()}
            bodyOpenClassName="modal-unsubscribe-myAccount__body"
            className="modal-unsubscribe-myAccount__content"
            overlayClassName="grid-x align-center modal-unsubscribe-myAccount__content__overlay"
            ariaHideApp={false}
        >
            <div className="modal-unsubscribe-myAccount__container">
                <div className="modal-unsubscribe-myAccount__container__content-close">
                    <button
                        type="button"
                        onClick={() => closeModalUnsubscribeMyAccount()}
                    >
                        <img
                            alt="icon_close"
                            src={Assets.SharedIcons.icon_close}
                        />
                    </button>
                </div>
                <div className="modal-unsubscribe-myAccount__container__content-information">
                    {!isUnsubscribeMyAccountState
                        ?
                            <>
                                <label className="modal-unsubscribe-myAccount__container__title">
                                    {t("home.myNetwork.contactCard.modals.unsubscribeMyAccount.title")}
                                </label>
                                <img
                                    alt="icon_warning_big"
                                    src={Assets.SharedIcons.icon_warning_big}
                                />
                                <label className="modal-unsubscribe-myAccount__container__description">
                                    {t("home.myNetwork.contactCard.modals.unsubscribeMyAccount.description")}
                                </label>
                                <div className="modal-unsubscribe-myAccount__container__content-btn">
                                    <button
                                        className="modal-unsubscribe-myAccount__container__btn"
                                        onClick={() => unsubscribeMyAccount()}
                                    >
                                        {t("home.myNetwork.contactCard.modals.unsubscribeMyAccount.textBtn")}
                                    </button>
                                    <button
                                        className="modal-unsubscribe-myAccount__container__btn-text"
                                        onClick={() => closeModalUnsubscribeMyAccount()}
                                    >
                                        {t("home.myNetwork.contactCard.modals.unsubscribeMyAccount.cancel")}
                                    </button>
                                </div>
                            </>
                        :
                            <>
                                <label className="modal-unsubscribe-myAccount__container__title--mod">
                                    {t("home.myNetwork.contactCard.modals.unsubscribeMyAccount.secondTitle")}
                                </label>
                                <img
                                    alt="icon_identification_warning"
                                    src={Assets.SharedIcons.icon_identification_warning}
                                />
                                <label className="modal-unsubscribe-myAccount__container__description">
                                    {t("home.myNetwork.contactCard.modals.unsubscribeMyAccount.secondDescription")}
                                </label>
                                <button
                                    className="modal-unsubscribe-myAccount__container__btn"
                                    onClick={() => closeModalUnsubscribeMyAccount()}
                                >
                                    {t("home.myNetwork.contactCard.modals.unsubscribeMyAccount.understood")}
                                </button>
                            </>
                    }
                </div>
            </div>
        </Modal>
    )
};

export default ModalUnsubscribeMyAccount;