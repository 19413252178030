class RoleDTO {

    constructor(idRole, rolName, rolDescription) {
        this.idRole = idRole;
        this.rolName = rolName;
        this.rolDescription = rolDescription;
    }

}

export default RoleDTO