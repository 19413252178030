import  { AuthenticationIcons }  from "./authentication";
import { HomeIcons } from "./home"
import { SharedIcons } from "./shared"
import { LoginIcons } from "./login";
import { ViewProfileIcons } from "./view-profile" 


export const Assets ={
    AuthenticationIcons,
    HomeIcons,
    SharedIcons,
    LoginIcons,
    ViewProfileIcons
}
