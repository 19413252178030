// Constants - core
import AuthenticationErrorCodesConst from "../../core/constants/errors/authentication/authentication-error-codes.const";
import AuthenticationErrorsConst from "../../core/constants/errors/authentication/authentication-error.const";
import InfrastructureErrorsConst from "../../core/constants/errors/infrastructure/alerts-error.const";

// Models - DAO
import { createErrorInternalApp } from "../../models/aggregates/build-error-internal-app/build-error-internal-app.dao";

// Translation
import string from "../../shared/translations/I18n";

export function conversionCodesAPIAuthentication(codeAssociate) {
    switch (codeAssociate) {
        // AU
        case AuthenticationErrorCodesConst.AU0001:
            return createErrorInternalApp(AuthenticationErrorsConst.codeNumericIsInvalid)
            
        case AuthenticationErrorCodesConst.AU0002:
            return createErrorInternalApp(AuthenticationErrorsConst.codeNumericRequired)

        case AuthenticationErrorCodesConst.AU0003:
            return createErrorInternalApp(AuthenticationErrorsConst.emailIsNotRegistered)

        case AuthenticationErrorCodesConst.AU0005:
            return createErrorInternalApp(AuthenticationErrorsConst.emailRequired)

        case AuthenticationErrorCodesConst.AU0013:
            return createErrorInternalApp(AuthenticationErrorsConst.uidRequired)

        case AuthenticationErrorCodesConst.AU0014:
            return createErrorInternalApp(AuthenticationErrorsConst.userWasDeleted)
        
        case AuthenticationErrorCodesConst.AU0016:
            return createErrorInternalApp(AuthenticationErrorsConst.userAlreadyExists)

        case AuthenticationErrorCodesConst.AU0017:
            return createErrorInternalApp(AuthenticationErrorsConst.permissionForAction)
    
        // US

        case AuthenticationErrorCodesConst.US0001:
            return createErrorInternalApp(AuthenticationErrorsConst.dateBirthIsRequired)

        case AuthenticationErrorCodesConst.US0002:
            return createErrorInternalApp(AuthenticationErrorsConst.emailIsInvalid)

        case AuthenticationErrorCodesConst.US0003:
            return createErrorInternalApp(AuthenticationErrorsConst.identificationNumberRequired)
            
        case AuthenticationErrorCodesConst.US0004:
            return createErrorInternalApp(AuthenticationErrorsConst.uidDoesNotExist)

        case AuthenticationErrorCodesConst.US0005:
            return createErrorInternalApp(AuthenticationErrorsConst.lastNameIsRequired)

        case AuthenticationErrorCodesConst.US0006:
            return createErrorInternalApp(AuthenticationErrorsConst.nameIsRequired)

        case AuthenticationErrorCodesConst.US0007:
            return createErrorInternalApp(AuthenticationErrorsConst.missingTokenSession)
            
        case AuthenticationErrorCodesConst.US0008:
            return createErrorInternalApp(AuthenticationErrorsConst.phoneNumberRequired)

        case AuthenticationErrorCodesConst.US0011:
            return createErrorInternalApp(AuthenticationErrorsConst.missingTokenSession)

        case AuthenticationErrorCodesConst.US0012:
            return createErrorInternalApp(AuthenticationErrorsConst.deletedUser)

        case AuthenticationErrorCodesConst.US0013:
            return createErrorInternalApp(AuthenticationErrorsConst.userDoesNotExist)
    
        case AuthenticationErrorCodesConst.US0014:
            return createErrorInternalApp(AuthenticationErrorsConst.userNoPartnerNetwork)
    
        case AuthenticationErrorCodesConst.US0019:
            return createErrorInternalApp(AuthenticationErrorsConst.userDoesNotPermission)

        case AuthenticationErrorCodesConst.US0017:
            return createErrorInternalApp(AuthenticationErrorsConst.locationIsRequired)
        
        case AuthenticationErrorCodesConst.US0020:
            return createErrorInternalApp(AuthenticationErrorsConst.typeIdentificationIsRequired)
        
        case AuthenticationErrorCodesConst.US0021:
            return createErrorInternalApp(AuthenticationErrorsConst.countryIsRequired)
       
        case AuthenticationErrorCodesConst.US0022:
            return createErrorInternalApp(AuthenticationErrorsConst.departmentIsRequired)
        
        case AuthenticationErrorCodesConst.US0026:
            return createErrorInternalApp(AuthenticationErrorsConst.genderIsRequired)
        
        case AuthenticationErrorCodesConst.US0027:
            return createErrorInternalApp(AuthenticationErrorsConst.ethnicGroupIsRequired)

        case AuthenticationErrorCodesConst.US0028:
            return createErrorInternalApp(AuthenticationErrorsConst.disabilityStatusIsRequired)
        
        case AuthenticationErrorCodesConst.US0029:
            return createErrorInternalApp(AuthenticationErrorsConst.typeOfHouseIsRequired)
        
        case AuthenticationErrorCodesConst.US0030:
            return createErrorInternalApp(AuthenticationErrorsConst.stratumIsRequired)
        
        case AuthenticationErrorCodesConst.US0031:
            return createErrorInternalApp(AuthenticationErrorsConst.phoneWhatsappIsRequired)
        
        case AuthenticationErrorCodesConst.US0032:
            return createErrorInternalApp(AuthenticationErrorsConst.peopleLivingIsRequired)
        
        case AuthenticationErrorCodesConst.US0033:
            return createErrorInternalApp(AuthenticationErrorsConst.votingTableIsRequired)
        
        case AuthenticationErrorCodesConst.US0034:
            return createErrorInternalApp(AuthenticationErrorsConst.educationLevelIsRequired)
        
        case AuthenticationErrorCodesConst.US0035:
            return createErrorInternalApp(AuthenticationErrorsConst.professionIsRequired)
        
        case AuthenticationErrorCodesConst.US0036:
            return createErrorInternalApp(AuthenticationErrorsConst.currentOccupationIsRequired)
        
        case AuthenticationErrorCodesConst.US0037:
            return createErrorInternalApp(AuthenticationErrorsConst.companyWorkingIsRequired)
        
        case AuthenticationErrorCodesConst.US0038:
            return createErrorInternalApp(AuthenticationErrorsConst.experienceMonthsIsRequired)
        
        case AuthenticationErrorCodesConst.US0039:
            return createErrorInternalApp(AuthenticationErrorsConst.seekingEmploymentIsRequired)

        case AuthenticationErrorCodesConst.US0057:
            return createErrorInternalApp(AuthenticationErrorsConst.userWasDeactivated)

        case AuthenticationErrorCodesConst.US0060:
            return createErrorInternalApp(AuthenticationErrorsConst.latitudeLongitudeRegisterSectorRequired)

        default:
            return {
                message: string.t(`${InfrastructureErrorsConst.responseErrorAxiosFound}.message`),
                description: ""
            }
    }
}