const AuthenticationEndPoints =  {
    LOGIN: `/api/auth/createCode`,
    LOGOUT: `/api/auth/logout`,
    USER_GROUP_SELECTED: `/api/auth/selectUserGroup`,
    VALIDATE_CODE: `/api/auth/validateCode`,
    CHANGE_PASSWORD: `/api/auth/changePassword`,
    VALIDATE_TOKEN_SESSION: `/api/auth/renewDataSession`,
    GET_USER: `/api/auth/getUser`,
    DECLINE: `/api/users/declineInvitation`,
    CHANGE_DATA: `/api/users/modificationRequest`
}
//TODO: CHANGE ROUTE ENDPOINT DECLINE 
export default AuthenticationEndPoints