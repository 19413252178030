//  Actions
import { 
	setProcessValidateSessionAuthenticationReducer,
	setStateAuthenticationReducer,
	resetFlagsAuthenticationAuthenticationReducer
} from "../storage/reducers/authentication/authentication.actions"

//Assets
import { Assets } from "../assets";

// Components - shared
import ErrorToastComponent from "../shared/components/toast/error-toast/error-toast.component";
import LoadingAppComponent from "../shared/components/loadings/loading-home/loading-app.component";

// Constants - core
import Constants from "../core/constants";

// Libraries
import { Navigate, Outlet } from "react-router-dom"
import { connect } from "react-redux"
import { useEffect } from 'react'
import { useTranslation } from "react-i18next";

// Services
import { 
	clearKeysAuthenticationService, 
	getTokenService, 
	getUserRolesService,
	setTotalMembersService, 
	setUserLoggedService, 
	validateSessionService
 } from "../services/authentication.services"

// Utils
import { isContainedAnyRolesRequired } from "../utils/authentication.utils"

const authProcess = () => {
	let token = getTokenService()
	let role = getUserRolesService()

	if (token) {
		return {
			isAuth: true,
			role: role
		}
	} else {
		return {
			isAuth: false,
			role: ""
		}
	}
}

const ProtectedRoutes = (props) => {

	const {
		// Actions
		setProcessValidateSessionAuthenticationReducer,
		setStateAuthenticationReducer,
		resetFlagsAuthenticationAuthenticationReducer,
		// Variables
		isCheckingSession,
		isValidSession,
		isCompletedRegisterUserLogged,
		isStateActiveUserLogged
	} = props

	const [t] = useTranslation("translation");

	useEffect(() => {
		processValidationToken()
		return () => { 
			resetFlagsAuthenticationAuthenticationReducer()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	async function processValidationToken() {
		let token = getTokenService()
		validateSessionService(token)
		.then( res =>{
			const { userLogged, totalMembers } = res
			let isValidSession = false
			if (userLogged.idUser) {
				isValidSession = true
				setUserLoggedService(userLogged)
				setTotalMembersService(totalMembers)
				setStateAuthenticationReducer("isCompletedRegisterUserLogged",userLogged.isCompletedRegister)
				setStateAuthenticationReducer("isStateActiveUserLogged", userLogged.isStateActive)
				setProcessValidateSessionAuthenticationReducer(true, isValidSession)
			} else {
				setProcessValidateSessionAuthenticationReducer(true, isValidSession)
			}
		})
		.catch(err=>{
			setProcessValidateSessionAuthenticationReducer(true, false)	
		})
	}

	if (!isCheckingSession && !isValidSession) {
		return <LoadingAppComponent />
	} else if (isCheckingSession && !isValidSession) {
		clearKeysAuthenticationService()
		return <Navigate replace to="/login" />
	} else {
		if (!isCompletedRegisterUserLogged) {
			return <Navigate replace to="/completeRegistration" />
		} else if (!isStateActiveUserLogged && isCompletedRegisterUserLogged) {
			clearKeysAuthenticationService();
			ErrorToastComponent({
				title: `${t("errors.authentication.userWasDeactivated.message")}`,
				position: Constants.AlertConst.TOP_END_POSITION_TEXT,
				timer: Constants.AlertConst.TIMER_MEDIUM,
				iconHtml: {
					src: Assets.SharedIcons.icon_alert_error,
					alt: "icon_alert_error"
				},
				iconColor: "transparent"
			});
			return <Navigate replace to="/login" />
		} else {
			const { isAuth, role } = authProcess()
			if (props.roleRequired) {
				const isValidRole = isContainedAnyRolesRequired(props.roleRequired, role)
				return isAuth ? (
					isValidRole ? (
						<Outlet />
					) : (
						<Navigate replace to="/denied" />
					)
				) : (
					<Navigate to="/login" />
				)
			} else {
				return isAuth ? <Outlet /> : <Navigate to="/login" />
			}
		}
	}
}

const mapStateToProps = ({ AuthenticationReducer }) => {
	const { 
		isCheckingSession, isValidSession, 
		isCompletedRegisterUserLogged, isStateActiveUserLogged
	} = AuthenticationReducer

	return {
		isCheckingSession,
		isValidSession,
		isCompletedRegisterUserLogged,
		isStateActiveUserLogged
	}
}

const mapStateToPropsActions = {
	setProcessValidateSessionAuthenticationReducer,
	setStateAuthenticationReducer,
	resetFlagsAuthenticationAuthenticationReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(ProtectedRoutes)