// Assets
import { Assets } from "../../../../../../../assets/index";

// Libraries
import React from 'react';
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

// Styles
import "./modal-reactivate-account-member.component.scss";

const ModalReactivateAccountMemberComponent = (props) => {

    const {
        //Props
        userLogged,
        nameUserSelected,
        isViewModalReactivateMember,
        closeModalReactivateMember
    } = props;

    const [t] = useTranslation("translation");

    return (
        <Modal
            isOpen={isViewModalReactivateMember}
            onRequestClose={() => closeModalReactivateMember()}
            bodyOpenClassName="modal-reactivate-account-member__body"
            className="modal-reactivate-account-member__content"
            overlayClassName="grid-x align-center modal-reactivate-account-member__content__overlay"
            ariaHideApp={false}
        >
            <div className="modal-reactivate-account-member__container">
                <div className="modal-reactivate-account-member__container__content-close">
                    <button
                        type="button"
                        onClick={() => closeModalReactivateMember()}
                    >
                        <img
                            alt="icon_close"
                            src={Assets.SharedIcons.icon_close}
                        />
                    </button>
                </div>
                <div className="modal-reactivate-account-member__container__content-information">
                    <label className="modal-reactivate-account-member__container__title">
                        {`${t("home.myNetwork.contactCard.modals.reactivateAccountMember.congratulations")} ${userLogged?.name} ${userLogged?.lastName}`}
                    </label>
                    <img
                        alt="icon_user_success"
                        src={Assets.SharedIcons.icon_user_success}
                    />
                    <label className="modal-reactivate-account-member__container__title">
                        {`${t("home.myNetwork.contactCard.modals.reactivateAccountMember.description")} ${nameUserSelected}`}
                    </label>
                    <label className="modal-reactivate-account-member__container__description">
                        {t("home.myNetwork.contactCard.modals.reactivateAccountMember.secondDescription")}
                    </label>
                    <button
                        className="modal-reactivate-account-member__container__btn"
                        onClick={() => closeModalReactivateMember()}
                    >
                        {t("home.myNetwork.contactCard.modals.reactivateAccountMember.textBtn")}
                    </button>
                </div>
            </div>
        </Modal>
    )
};

export default ModalReactivateAccountMemberComponent;
