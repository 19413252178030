class UserDTO {
    //TODO: refactoring according to the backend model
    constructor(
        idUser, 
        usrName, 
        usrLastName, 
        usrImgProfile, 
        usrIdentificationNumber, 
        usrTypeIdentificationNumber, 
        usrPhone,
        usrPhoneWhatsapp, 
        usrEmail, 
        usrGroupAssociated, 
        usrRole, 
        usrUserRoot = undefined,
        usrIsStateActive,
        usrOverviewNetwork = "",
        usrIsCompletedRegister = undefined, 
        usrDateBirth = undefined, 
        usrResidencePlace = undefined, 
        usrVotingStation = undefined, 
        usrCountry = undefined, 
        usrDepartment = undefined, 
        usrMunicipality = undefined, 
    ) {
      this.idUser = idUser;
      this.usrName = usrName;
      this.usrLastName = usrLastName;
      this.usrImgProfile = usrImgProfile;
      this.usrIdentificationNumber = usrIdentificationNumber;
      this.usrTypeIdentificationNumber = usrTypeIdentificationNumber;
      this.usrPhone = usrPhone;
      this.usrPhoneWhatsapp = usrPhoneWhatsapp;
      this.usrEmail = usrEmail;
      this.usrGroupAssociated = usrGroupAssociated;
      this.usrRole = usrRole;
      this.usrUserRoot = usrUserRoot;
      this.usrIsStateActive = usrIsStateActive;
      this.usrOverviewNetwork = usrOverviewNetwork;
      this.usrIsCompletedRegister = usrIsCompletedRegister;
      this.usrDateBirth = usrDateBirth;
      this.usrResidencePlace = usrResidencePlace;
      this.usrVotingStation = usrVotingStation;
      this.usrCountry = usrCountry;
      this.usrDepartment = usrDepartment;
      this.usrMunicipality = usrMunicipality;
    }
    
  }

  export default UserDTO;