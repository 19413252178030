//Actions
import { setModalAssociatesTabbarReducer } from "../../../../../../../storage/reducers/tabbar/tabbar.actions";
 import { setStateMyNetworkReducer } from './../../../../../../../storage/reducers/my-network/my-network.actions'
import { setOptionSelectedTabBarTabBarReducer } from './../../../../../../../storage/reducers/tabbar/tabbar.actions';

//Assets
import { Assets } from "./../../../../../../../assets";

//Libraries
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { useFormik } from "formik";
import { Autocomplete, Box, TextField, ThemeProvider } from '@mui/material';
import PhoneInput from "react-phone-input-2";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTranslation } from "react-i18next";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from 'date-fns/locale';
import { useNavigate } from "react-router-dom";

//Models - dto
import UserDTO from "../../../../../../../models/user/dto/user.dto";

//Services
import { registerUserService } from "../../../../../../../services/member.services";
import { getTokenService, setTotalMembersService } from "../../../../../../../services/authentication.services";
import { getUserLoggedService } from "../../../../../../../services/authentication.services";
import { getAllCountryService, getAllDepartmentsService, getAllMunicipalityService } from "../../../../../../../services/complete-registration.services";
import { clearFormValues, getFormValues, saveFormValues } from "../../../../../../../services/my-network.service";

//Styles
import "./member-associate.component.scss";
import "react-phone-input-2/lib/style.css";
import { calendarThemeMemberRegistration } from "../../../../../../styles/themes/calendarThemes";
import { validateRegisterAssociates } from "../../../../../../../utils/validate-recovery-password.utils";
import { redirectsLogin } from "../../../../../../../utils/authentication.utils";
import { firstWorkFlowCommonUpdateDataApplication } from "../../../../../../../utils/my-network.utils";

const MemberAssociatesComponent = (props) => {
  const { 
    // Actions
    setModalAssociatesTabbarReducer, 
    setStateMyNetworkReducer,
    setOptionSelectedTabBarTabBarReducer,
    // Variables 
    modalAssociate,
    network,
    levelsNetwork
  } = props;
  const [t] = useTranslation("translation");
  let history = useNavigate();
  const INITIAL_STATE = {
    modalSuccess: false,
    countries: [],
    departments: [],
    municipality: [],
    inputCountry: null,
    inputDepartment: null,
    inputMunicipality: null,
    documentType: { 
      label:"",
      value:0
    },
    phoneCallSignState: "57",
    isRegistrationAssociate: false
  }

  const [state, setState] = useState(INITIAL_STATE);
  
  const {
    modalSuccess, countries, inputCountry,
    inputDepartment, inputMunicipality, departments,
    municipality, phoneCallSignState, documentType,
    isRegistrationAssociate
  } = state;

  useEffect(() => {
    let token = getTokenService()
    const data = getFormValues();
    if (token) {
      getAllCountryService(token)
        .then((countries) => {
          if (!!data) {
            setFormValues(data, countries);
          } else {
            setState((s) => ({
              ...s,
              countries
            }));
          }
        })
        .catch((err) => {
          if(redirectsLogin(err)) history("/login", { replace: true });
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setFormValues = async (data, countries) => {
    const { values, selects } = data;
    setValues(values)  
    const valuesTrue = {}
    for (const key in values) {
      if (values[key]){
        valuesTrue[key] = true
      }
    }
    setTouched(valuesTrue);
    let departments = (!!selects.inputCountry) ? await allDepartments(selects.inputCountry) : [];
    let municipalities = (!!selects.inputDepartment) ? await allMunicipality(selects.inputDepartment) : [];
    setState({
      ...state,
      countries: countries,
      inputCountry: selects.inputCountry,
      departments: departments,
      inputDepartment: selects.inputDepartment,
      municipality: municipalities,
      inputMunicipality: selects.inputMunicipality,
    });
  };

  const modalClose = () => {
    clearFormValues();
    setModalAssociatesTabbarReducer(false);
    setOptionSelectedTabBarTabBarReducer(null)
    setState({
      ...state,
      modalSuccess: false,
      inputCountry: null,
      inputDepartment: null,
      inputMunicipality: null
    })
    resetForm()
  };

  const { handleSubmit, handleChange, values, errors, resetForm, setValues, handleBlur, touched, setFieldValue, setTouched} = useFormik({
    initialValues: {
      name: "",
      lastName: "",
      dateBirth: "",
      countryBirth: null,
      departmentBirth: null,
      municipalityBirth: null,
      documentNumber: "",
      phone: "",
      email: ""
    },
    initialErrors: {
      name: "",
      lastName: "",
      dateBirth: "",
      countryBirth: "",
      departmentBirth: "",
      municipalityBirth: "",
      documentNumber: "",
      phone: "",
      email: ""
    },
    validationSchema: validateRegisterAssociates,
    onSubmit: () => {
      if(!isRegistrationAssociate){
        setState({...state,isRegistrationAssociate: true})
        clearFormValues();
        createUser()
      }
    },
  });

  const createUser = () => {
    let token = getTokenService();
    const {groupAssociated} = getUserLoggedService();
    const userDTO = new UserDTO(
      undefined,
      values.name,
      values.lastName,
      undefined,
      values.documentNumber,
      documentType?.value,
      `+${phoneCallSignState}${values.phone}`,
      undefined,
      values.email.toLowerCase()
    )
    userDTO.usrDateBirth = values.dateBirth
    userDTO.usrCountry = inputCountry?.uid
    userDTO.usrDepartment = inputDepartment?.uid
    userDTO.usrMunicipality = inputMunicipality?.uid
    userDTO.usrGroup = groupAssociated?.idGroup;

    registerUserService(userDTO, token)
      .then(async (resp) => {
        setState({
          ...state,
          modalSuccess: resp,
          isRegistrationAssociate: false
        })

        await fetchData();
      })
      .catch((err) => {
        setState({
          ...state,
          isRegistrationAssociate: false
        })
        if(redirectsLogin(err)) history("/login", { replace: true });
      })
  }

  const options = [
    { value: 0, label: "C.C" },
    { value: 1, label: "C.E" }
  ];

  const handlerChangeStatesLocal = async ({ target, value }) => {
    switch (target) {
      case "inputCountry":
        if (value === null || (value !== inputDepartment && inputDepartment !== null)) {
          let departments = []
          if (value !== null && value !== inputDepartment && inputDepartment !== null) {
           departments = await allDepartments(value)
          }
          setState({
            ...state,
            departments,
            [target]: value,
            inputDepartment: null,
            inputMunicipality: null
          })
        }
        else {
          const departments = await allDepartments(value)
          setState({
            ...state,
            [target]: value,
            departments,
          })
        }

        break;


      case "inputDepartment":
        if (value === null || (value !== inputMunicipality && inputMunicipality !== null)) {
          let municipality = []
          if (value !== null && value !== inputMunicipality && inputMunicipality !== null) {
            municipality = await allMunicipality(value)
          }
          setState({
            ...state,
            [target]: value,
            municipality: municipality,
            inputMunicipality: null
          })
        } else {
          const municipality = await allMunicipality(value)
          setState({
            ...state,
            [target]: value,
            municipality: municipality,
          })
        }
        break;

      default:
        setState({
          ...state,
          [target]: value
        })
        break;
    }
  }

  const allDepartments = async ({ uid }) => {

    let token = getTokenService()
    try {
      const getAllDepartments = await getAllDepartmentsService(uid, token);
      return getAllDepartments

    } catch (err) {
      if(redirectsLogin(err)) history("/login", { replace: true });
    }
  };

  const allMunicipality = async ({ uid }) => {
    let token = getTokenService()
    try {
      const getAllMunicipality = await getAllMunicipalityService(uid, token);
      return getAllMunicipality
    } catch (err) {
      if(redirectsLogin(err)) history("/login", { replace: true });
    }
  };

  async function fetchData() {
    let token = getTokenService()
    let userLogged = getUserLoggedService()
    if (userLogged && userLogged.idUser && token) {

      setStateMyNetworkReducer('isTriggerNetworkInformationUpdate', true)
      setStateMyNetworkReducer('loadingBoxMemberActivated', 0)

      try {
        const responseDataUpdated = await firstWorkFlowCommonUpdateDataApplication(userLogged.idUser, token, true)

        setTotalMembersService(responseDataUpdated.totalMembersUserLogged)

        let updateFirstLevel = [...levelsNetwork]
        let newFirstLevel = responseDataUpdated.firstLevelData

        if (updateFirstLevel.length > 1) {
          let memberLevelSelected = updateFirstLevel[0].memberByLevel.find(
            (element) => element.isMemberSelected === true
          );
          newFirstLevel.memberByLevel.forEach(element => {
            if (element.member.idUser === memberLevelSelected.member.idUser) {
              element.isMemberSelected = true
            }
          });
        }

        newFirstLevel.overviewNetwork = { ...responseDataUpdated.firstLevelData.overviewNetwork }
        updateFirstLevel[0] = newFirstLevel

        setStateMyNetworkReducer('levelsNetwork', updateFirstLevel)
        setStateMyNetworkReducer('loadingBoxMemberActivated', null)

      } catch (error) {
        setStateMyNetworkReducer('loadingBoxMemberActivated', null)
      }
    }
  }

  const saveValues = () => {
      saveFormValues({
        values,
        selects:{
          inputCountry,
          inputDepartment,
          inputMunicipality
        }
      })
  };

  return (
    <>
      <Modal
        isOpen={modalAssociate}
        bodyOpenClassName="member-associate__body"
        className={
          modalSuccess
            ? "member-associate__content member-associate__content--mod"
            : "member-associate__content"
        }
        overlayClassName={
          "grid-x align-center member-associate__content__overlay " +
          (modalSuccess && "member-associate__content__overlay--mod")
        }
        onRequestClose={() => modalClose()}
        ariaHideApp={false}
      >
        <div
          className={
            "member-associate__container " +
            (modalSuccess ? "member-associate__container--mod" : "")
          }
        >
          <div className="grid-x  member-associate__container-title small-12 align-center-middle">
            <span
              className={
                "text-center member-associate__title small-11 flex-container align-center-middle " +
                (modalSuccess ? "member-associate__title--success" : "")
              }
            >
              {t("tabBar.memberAssociates.title")}
            </span>
            <button
              type="button"
              className="small-1 member-associate__button-back__text flex-container align-center-middle"
              onClick={() => modalClose()}
            >
              <img
                alt="icon_close"
                src={Assets.SharedIcons.icon_close}
                className="member-associate__button__close"
              />
            </button>
          </div>
          {!modalSuccess ? (
            <form onSubmit={handleSubmit}>
              <div className="member-associate__form__content">
                <div className="grid-y member-associate__form">
                  <div className="grid-x member-associate__document">
                    <div className="member-associate__document__select__content">
                    <Select
                        value={documentType?.value}
                        onChange={(e, event) =>
                          setState({ ...state, documentType: event?.props })
                        }
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        className={
                          errors.documentNumber && touched.documentNumber
                            ? "member-associate__document__select__error"
                            : "member-associate__document__select"
                        }
                        IconComponent={(props) => {
                          return (
                            <img
                              src={Assets.HomeIcons.icon_dropdown_arrow}
                              alt="icon_dropdown_arrow"
                              className={"member-associate__document__select__icon " + props?.className}
                            />
                          );
                        }}
                      >
                        {options.map((option) => (
                          <MenuItem
                            className="member-associate__document__select__options"
                            key={option?.value}
                            {...option}
                          >
                            {option?.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <TextField
                      className="member-associate__input-select"
                      label={t("tabBar.memberAssociates.documentNumber")}
                      value={values.documentNumber}
                      error={(errors.documentNumber && touched.documentNumber ) ? true : false}
                      onChange={(value) =>
                        handleChange("documentNumber")(value)
                      }
                      onBlur={(value) => {
                        handleBlur("documentNumber")(value)
                        saveValues();
                        }
                      }
                    />
                    {errors.documentNumber && touched.documentNumber && 
                    <label className="member-associate__input-select__error-text">
                      {errors.documentNumber}
                    </label>}
                  </div>
                  <TextField
                    className="member-associate__input"
                    label={t("tabBar.memberAssociates.names")}
                    value={values.name }
                    error={errors.name && touched.name  ? true : false}
                    onChange={(value) => handleChange("name")(value)}
                    onBlur={(value)=>{
                        saveValues()
                        handleBlur("name")(value)
                        }
                      }
                  />
                  <TextField
                    className="member-associate__input"
                    label={t("tabBar.memberAssociates.lastNames")}
                    value={values.lastName}
                    error={errors.lastName && touched.lastName ? true : false}
                    onChange={(value) => handleChange("lastName")(value)}
                    onBlur={(value)=>{
                        handleBlur("lastName")(value) 
                        saveValues()
                        }
                      }
                  />
                  <ThemeProvider theme={calendarThemeMemberRegistration}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                      <MobileDatePicker
                        sx={{color:"#5F3EF2"}}
                        componentsProps={{
                          switchViewButton:{
                            sx:{color:"#5F3EF2"}
                          },
                          leftArrowButton:{
                            sx:{color:"#5F3EF2"}
                          },
                          rightArrowButton:{
                            sx:{color:"#5F3EF2"}
                          }
                        }}
                        label={t("tabBar.memberAssociates.dateOfBirth")}
                        inputFormat="dd/MM/yyyy"
                        value={values.dateBirth ? new Date(values.dateBirth) : null}
                        onChange={(value) => setFieldValue("dateBirth", value)}
                        onAccept={(e) => {saveValues(e)}}
                        disableFuture={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="member-associate__input"
                            onBlur={(value)=>handleBlur("dateBirth")(value)}
                            error={errors.dateBirth && touched.dateBirth ? true : false}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
                  {errors.dateBirth && touched.dateBirth && 
                    <label className="member-associate__input-select__error-text--mod">
                    {errors.dateBirth}
                    </label>
                  }
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={countries}
                    autoHighlight
                    getOptionLabel={(option) => option.cntName}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    popupIcon={
                      <img
                        size={16}
                        alt="icon_dropdown_arrow"
                        src={Assets.HomeIcons.icon_dropdown_arrow}
                      />
                    }
                    onChange={(e, value) =>{
                      handlerChangeStatesLocal({
                        target: "inputCountry",
                        value,
                      })
                      setFieldValue("countryBirth",value)
                    }
                    }
                    onBlur={() => {saveValues()}}
                    value={values.countryBirth}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.cntName}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("tabBar.memberAssociates.countryOfBirth")}
                        className="member-associate__input-autocomplete"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                        onBlur={(value)=>handleBlur("countryBirth")(value)}
                        error={errors.countryBirth && touched.countryBirth ? true : false}
                      />
                    )}
                  />
                  <Autocomplete
                    value={inputDepartment}
                    sx={{ width: "100%" }}
                    options={departments}
                    autoHighlight
                    getOptionLabel={(option) => option.dptName}
                    onChange={(e, value) =>{
                      handlerChangeStatesLocal({
                        target: "inputDepartment",
                        value,
                      })
                      setFieldValue("departmentBirth",value)
                    }
                    }
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    disabled={inputCountry ? false : true}
                    popupIcon={
                      <img
                        size={16}
                        alt="icon_dropdown_arrow"
                        src={Assets.HomeIcons.icon_dropdown_arrow}
                      />
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.dptName}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("tabBar.memberAssociates.departmentOfBirth")}
                        className="member-associate__input-autocomplete"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                          readOnly: true
                        }}
                        onBlur={(value)=>handleBlur("departmentBirth")(value)}
                        error={
                          errors.departmentBirth && inputCountry && touched.departmentBirth ? true : false
                        }
                      />
                    )}
                  />
                  <Autocomplete
                    value={inputMunicipality}
                    sx={{ width: "100%" }}
                    options={municipality}
                    autoHighlight
                    getOptionLabel={(option) => option.mnpName}
                    onChange={(e, value) =>{
                      handlerChangeStatesLocal({
                        target: "inputMunicipality",
                        value,
                      })
                      setFieldValue("municipalityBirth",value)
                    }
                    }
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onBlur={(value)=>{
                      handleBlur("municipalityBirth")(value)
                      saveValues()
                      }
                    }
                    disabled={inputDepartment ? false : true}
                    popupIcon={
                      <img
                        size={16}
                        alt="icon_dropdown_arrow"
                        src={Assets.HomeIcons.icon_dropdown_arrow}
                      />
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.mnpName}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("tabBar.memberAssociates.municipalityOfBirth")}
                        className="member-associate__input-autocomplete"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                        onBlur={(value)=>handleBlur("municipalityBirth")(value)}
                        error={
                          errors.municipalityBirth && inputDepartment && touched.municipalityBirth 
                            ? true
                            : false
                        }
                      />
                    )}
                  />
                  <div className="grid-x member-associate__document align-bottom">
                    <PhoneInput
                      className="member-associate__input-phone"
                      onChange={(phone) =>
                        setState({
                          ...state,
                          phoneCallSignState: phone,
                        })
                      }
                      onlyCountries={["co", "ve"]}
                      country={"co"}
                      countryCodeEditable={false}
                      inputProps={{ readOnly: "readonly" }}
                    />
                    <TextField
                      className="member-associate__input-select"
                      label={t("tabBar.memberAssociates.cellPhone")}
                      value={values.phone}
                      error={errors.phone && touched.phone ? true : false}
                      onChange={(value) => handleChange("phone")(value)}
                      onBlur={(value)=>{
                          handleBlur("phone")(value)
                          saveValues()
                          }
                        }
                    />
                  </div>
                  <TextField
                    className="member-associate__input"
                    label={t("tabBar.memberAssociates.email")}
                    value={values.email.toLowerCase()}
                    error={errors.email && touched.email ? true : false}
                    onChange={(value) => handleChange("email")(value)}
                    onBlur={(value)=>{
                        handleBlur("email")(value)
                        saveValues()
                        }
                      }
                  />
                </div>
              </div>
                <button
                  type="submit"
                  className="member-associate__button"
                  disabled={isRegistrationAssociate || Object.keys(errors).length !==0}
                >
                  {t("tabBar.memberAssociates.save")}
                </button>
                <div className="grid-x align-center-middle member-associate__button__back">
                  <button
                    type="button"
                    className="member-associate__button__back__text"
                    onClick={()=>modalClose()}
                  >
                    {t("tabBar.memberAssociates.back")}
                  </button>
                </div>
            </form>
          ) : (
            <>
              <div className="member-associate__modal-success">
                <div className="grid-y member-associate__modal-success__content">
                  <div>
                    <span className="text-center align-center-middle flex-container member-associate__modal-success__header__name">
                      {t("tabBar.memberAssociates.congratulations")}
                    </span>
                    {network && (
                      <span className="text-center align-center-middle flex-container member-associate__modal-success__header__name member-associate__modal-success__header__name--mod">
                        {t("tabBar.memberAssociates.leadingUser")}
                      </span>
                    )}
                  </div>
                  <div className="grid-y member-associate__modal-success__icon">
                  <img
                    src={Assets.AuthenticationIcons.icon_user}
                    alt="icon_user_footer"
                  />
                  <img
                    src={Assets.SharedIcons.icon_circle_check}
                    className="member-associate__modal-success__icon-check"
                    alt="icon_circle_check"
                  />
                  </div>
                  <div className="member-associate__modal-success__text">
                    <span className="member-associate__modal-success__user__content">
                        {`${values.name}, ${t("tabBar.memberAssociates.partNetwork")}`}
                    </span>
                    <span className="text-center align-center-middle flex-container member-associate__modal-success__footer__text">
                      {t("tabBar.memberAssociates.addMember")}
                    </span>
                  </div>
                </div>
              </div>
              <button
                onClick={() => modalClose()}
                className="member-associate__modal-success__button"
              >
                {t("tabBar.memberAssociates.backTop")}
              </button>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ TabbarReducer, MyNetworkReducer }) => {
  const { modalAssociate } = TabbarReducer;
  const { network, levelsNetwork } = MyNetworkReducer;
  return {
    modalAssociate,
    network,
    levelsNetwork
  };
};
const mapStateToPropsActions = {
  setModalAssociatesTabbarReducer,
  setStateMyNetworkReducer,
  setOptionSelectedTabBarTabBarReducer
};
export default connect(
  mapStateToProps,
  mapStateToPropsActions
)(MemberAssociatesComponent);