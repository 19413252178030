const MyNetworkConst = {
    LIMIT_MEMBERS_TO_DISPLAY: 11,
    VOTING_STATION: "Voting Station",
    USER_RESIDENCE: "User Residence",

    //TypeFile Constants
    VOTING_STATION_TYPE: "voting station",
    SECTOR_TYPE: "sector",
    VOTING_STATION_AND_SECTOR_TYPE: "voting station and sector",
    AFFILIATE_INCOME_TYPE: "affiliate income",
    CURRENT_OCCUPATION_TYPE: "current occupation",
    HOUSE_TYPE: "house",
    CURRICULUM_TYPE: "curriculum",
    PORTFOLIO_TYPE: "portfolio"
}

export default MyNetworkConst