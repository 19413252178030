//Actions
import {
  setIsMemberMyNetworkReducer,
  setMemberMoreMyNetworkReducer,
  setShowViewProfileMyNetworkReducer,
  setStateMyNetworkReducer,
  setShowUnderlyingLevelsMyNetworkReducer,
  setNetworkOverviewByUserMyNetworkReducer
} from '../../../storage/reducers/my-network/my-network.actions';

//Assets
import { Assets } from '../../../assets';

// Components
import UserLevelComponent from './components/user-level/user-level.component';
import MembersFilterComponent from './components/members-filter/members-filter.component.js';
import ViewProfileComponent from './components/view-profile/view-profile.component';
import HierarchyComponent from './components/hierarchy-edit/hierarchy.component';
import ModalInformation from './components/modal-information/modal-information.component';

// Constants - core
import Constants from '../../../core/constants';
import { ModalInformativeConstants } from '../../../core/constants/modal-informative.constants';

// Libraries
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

//Services
import { getMyNetworkSummaryService} from '../../../services/my-network.service';
import { getTotalMembersService, getUserLoggedService, getTokenService, clearNumberDocumentService } from '../../../services/authentication.services';

// Styles
import './my-network.page.scss'

//Utils
import { redirectsLogin } from '../../../utils/authentication.utils';

const MyNetworkPage = (props) => {
  const {
    //Actions
    setStateMyNetworkReducer,
    setIsMemberMyNetworkReducer,
    setShowViewProfileMyNetworkReducer,
    setMemberMoreMyNetworkReducer,
    setShowUnderlyingLevelsMyNetworkReducer,
    setNetworkOverviewByUserMyNetworkReducer,
    // Variables
    isMembersNetwork,
    showViewProfile,
    innerWidth,
    memberMore,
    isShowEditHierarchyComponent,
    levelsNetwork,
    networkOverviewByUser,
    isShowUnderlyingLevels,
    isTriggerNetworkInformationUpdate
  } = props;

  const INITIAL_STATE = {
    totalMember: 0,
    userLogged: {},
    levelsTotal: 0,
    leadersTotal: 0,
    usersInactiveTotal:0,
    visibilityInformation: false,
    typeInformation: ""
  }

  const [state, setState] = useState(INITIAL_STATE)

  const {
    totalMember,
    userLogged,
    levelsTotal,
    leadersTotal,
    usersInactiveTotal,
    visibilityInformation,
    typeInformation
  } = state;

  const [t] = useTranslation("translation")
  let history = useNavigate();

  useEffect(() => {
    let token = getTokenService();
    const userLogged = getUserLoggedService();
    const totalMemberStorage = getTotalMembersService()

    if (totalMemberStorage && totalMemberStorage >= 1) {
      setIsMemberMyNetworkReducer(true);
    }else{
      setIsMemberMyNetworkReducer(false);
    }

    async function getMyNetworkInfo() {
      try {
        const dataInfoNetworkByUser = await getMyNetworkSummaryService(
          { uid: userLogged.idUser },
          token
        );
        setStateMyNetworkReducer('isTriggerNetworkInformationUpdate', false)

        let overviewInformativeNetworkByUser = {
          totalLevels: dataInfoNetworkByUser.levels || 0,
          totalLeaders: dataInfoNetworkByUser.leaders || 0,
          totalUsersInactive: dataInfoNetworkByUser.usersInactive || 0
        }

        setNetworkOverviewByUserMyNetworkReducer(overviewInformativeNetworkByUser)
        setState((s) => ({
          ...s,
          levelsTotal: dataInfoNetworkByUser.levels || 0,
          leadersTotal: dataInfoNetworkByUser.leaders || 0,
          usersInactiveTotal: dataInfoNetworkByUser.usersInactive || 0,
          totalMember: totalMemberStorage
        }));
      } catch (err) {
        if (redirectsLogin(err)) history("/login", { replace: true });
      }
    }

    if (isTriggerNetworkInformationUpdate && !isShowEditHierarchyComponent) {
      getMyNetworkInfo();
    } else {
      setState((s) => ({
        ...s,
        levelsTotal: networkOverviewByUser.totalLevels || 0,
        leadersTotal: networkOverviewByUser.totalLeaders || 0,
        usersInactiveTotal: networkOverviewByUser.totalUsersInactive || 0,
        totalMember: totalMemberStorage
      }));
    }

    return () => {
      setStateMyNetworkReducer('popupMenuOptionsLevelSelected', null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelsNetwork, isShowEditHierarchyComponent])


  useEffect(() => {
    setMemberMoreMyNetworkReducer({
      isShowViewMore: false,
      level: 0,
      totalChildren: 0,
      totalLeaders: 0,
      nameMemberMore: "",
      uidMemberMore: ""
    });
    clearNumberDocumentService()
    setStateMyNetworkReducer('isTriggerNetworkInformationUpdate', true)
    const totalMember = getTotalMembersService()
    const userLogged = getUserLoggedService()
    setState({
      ...state,
      totalMember: totalMember,
      userLogged: userLogged
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const viewInfo = (type) => {
    setState({
      ...state,
      visibilityInformation: true,
      typeInformation: type
    })
  };

  const viewEditProfileUser = () => {
    setShowViewProfileMyNetworkReducer({
      isShowViewProfile: true,
      uidMemberSelected: userLogged.idUser,
      isUserLoggedSelected: true
    });
  };

  const handlerGoBackUnderlyingLevels = () => { 
    let updateLevelsNetwork = [...levelsNetwork];
    updateLevelsNetwork[0].memberByLevel.forEach(
      (member) => (member.isMemberSelected = false)
    );

    setStateMyNetworkReducer('levelsNetwork', [updateLevelsNetwork[0]])
    setShowUnderlyingLevelsMyNetworkReducer(false)
   }

  return (
    <>
    <div>
      {!isShowUnderlyingLevels && !memberMore.isShowViewMore && !showViewProfile.isShowViewProfile && !isShowEditHierarchyComponent && (
        <div className="my-network-page__container my-network-page__container--mod">
          <div className="grid-x my-network-page__content align-middle">
            <button
              className="grid-x align-center-middle"
              onClick={() => viewEditProfileUser()}
              disabled={Object.keys(userLogged).length === 0}
            >
              <img className="my-network-page__user__icon" src={userLogged?.photoProfile ? userLogged?.photoProfile : Assets.SharedIcons.icon_profile} alt="icon_profile" />
              <div>
                <p className="my-network-page__user__name">{userLogged.name} {userLogged.lastName}</p>
                <p className="my-network-page__user__position">
                  {t("myNetworkPage.editProfile")}
                </p>
              </div>
            </button>
            <button
              className="my-network-page__members__content"
              onClick={() => viewInfo(ModalInformativeConstants.MEMBERS)}
            >
              <div className="my-network-page__members__members-total">
                  <img className="my-network-page__members__icon-info" src={Assets.HomeIcons.icon_info} alt="icon_info" />
                <label className="my-network-page__members__num">
                  {totalMember}
                </label>
              </div>
              <label className="my-network-page__members">
                {t("myNetworkPage.members")}
              </label>
            </button>
          </div>
          <div className="grid-x my-network-page__info align-middle grid-container">
            <button
              className="small-4 text-center my-network-page__info__content my-network-page__info__content__box"
              onClick={() => viewInfo(ModalInformativeConstants.LEVELS)}
            >
              <label className="align-middle my-network-page__info__box">
                {levelsTotal}
              </label>
                <div className='my-network-page__info__content__types-network'>
                  <img className="my-network-page__info__content__icon-info" src={Assets.HomeIcons.icon_info} alt="icon_info" />
                    <label className="my-network-page__info__text">
                      {t("myNetworkPage.levels")}
                    </label>
                </div>
            </button>
            <button
              className="small-4 text-center my-network-page__info__content my-network-page__info__content__box"
              onClick={() => viewInfo(ModalInformativeConstants.LEADERS)}
            >
              <label className="my-network-page__info__box">
                {leadersTotal}
              </label>
                <div className="my-network-page__info__content__types-network">
                  <img className="my-network-page__info__content__icon-info" src={Assets.HomeIcons.icon_info} alt="icon_info" />
                    <label className="my-network-page__info__text">
                      {t("myNetworkPage.leaders")}
                    </label>
                </div>
            </button>
            <button
              className="small-4 text-center my-network-page__info__content"
              onClick={() => viewInfo(ModalInformativeConstants.INACTIVE)}
            >
              <label className="my-network-page__info__box">
                {usersInactiveTotal}
              </label>
                <div className="my-network-page__info__content__types-network">
                  <img className="my-network-page__info__content__icon-info" src={Assets.HomeIcons.icon_info} alt="icon_info" />
                    <label className="my-network-page__info__text">
                      {t("myNetworkPage.inactive")}
                    </label>
                </div>
            </button>
          </div>
        </div>
        )}
        {isShowUnderlyingLevels && !memberMore.isShowViewMore && !showViewProfile.isShowViewProfile && !isShowEditHierarchyComponent &&
          <>
            <div className='grid-x my-network-page__sidebar__target__container-title'>
              <button
                onClick={() => {
                  handlerGoBackUnderlyingLevels()
                }}
              >
                <img 
                  src={Assets.HomeIcons.icon_dropdown_arrow}
                  alt="icon_arrow"
                  className="my-network-page__sidebar__target__button-go-back"
                />
              </button>
              <div className="grid-x my-network-page__sidebar__target__title">
                <img src={Assets.SharedIcons.icon_network} alt='icon_network' className="my-network-page__sidebar__target__icon-network" />
                <span>
                  {t("myNetworkPage.filter.myNetwork")}
                </span>
              </div>
            </div>
          </>
        }
        {isMembersNetwork
          ?
            <>
              {isShowEditHierarchyComponent
                ?
                  <HierarchyComponent />
                :
                  <>
                    <div className={!memberMore.isShowViewMore ? 'grid-x align-right' : "my-network-page__hide"}>
                      <div 
                        className={showViewProfile.isShowViewProfile
                          ? (innerWidth < Constants.AppConst.MEDIUM_WIDTH_SCREEN_SIZE ? "my-network-page__hide" : 'my-network-page__sidebar')
                          : 'my-network-page__sidebar__target--mod'
                        }
                      >
                        <UserLevelComponent/>
                        <div className="align-center-middle grid-y">
                          <div className={"my-network-page__select-container"}>
                            <img className="my-network-page__user__icon my-network-page__user__icon--mod" src={Assets.SharedIcons.icon_profile} alt="icon_profile" />
                            <p className="my-network-page__select-container--text">
                              {t("myNetworkPage.selectMembers")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {memberMore.isShowViewMore &&
                      <div>
                        <MembersFilterComponent /> 
                      </div>
                    }           
                  </>
              }
            </>
          : 
            <>
              {!showViewProfile.isShowViewProfile &&
                <div className="grid-y align-center-middle my-network-page__add-user__content">
                  <div className="my-network-page__add-user__container">
                    <img className="my-network-page__add-user__icon--last" src={Assets.SharedIcons.icon_profile} alt="icon_arrow" />
                    <img className="my-network-page__add-user__icon" src={Assets.SharedIcons.icon_profile} alt="icon_arrow" />
                    <img className="my-network-page__add-user__icon" src={Assets.HomeIcons.icon_plus} alt="icon_plus" />
                  </div>
                  <div className="grid-y align-middle">
                    <label className="my-network-page__add-user__text text-center">
                      {t("myNetworkPage.startText")}
                    </label>
                    <label className="my-network-page__add-user__text my-network-page__add-user__text__mod">
                      {t("myNetworkPage.endText")}
                    </label>
                  </div>
                  <img className="my-network-page__add-user__arrow" src={Assets.HomeIcons.icon_arrow} alt="icon_arrow" />
                </div>
              }
            </>
        }
        {showViewProfile.isShowViewProfile &&
          <div className={!memberMore.isShowViewMore ? 'grid-x align-right' : "my-network-page__hide"}>
            <div
              className={!showViewProfile.isShowViewProfile
                ? "my-network-page__sidebar-width"
                : innerWidth > Constants.AppConst.MEDIUM_WIDTH_SCREEN_SIZE
                  ? 'my-network-page__sidebar__target'
                  : "small-12"
              }
            >
              <ViewProfileComponent />
            </div>
          </div>
        }
      </div>
      <ModalInformation
        visibility={visibilityInformation}
        closeModal={() => setState({ ...state, visibilityInformation: false })}
        typeInformation={typeInformation}
        navigation={() => setState({ ...state, typeInformation: ModalInformativeConstants.MEMBERS })}
      />
    </>
  );
}

const mapStateToProps = ({ MyNetworkReducer, AppReducer }) => {
  const { 
    isMembersNetwork, showViewProfile, memberMore, 
    isShowEditHierarchyComponent, levelsNetwork, 
    networkOverviewByUser, isShowUnderlyingLevels,
    isTriggerNetworkInformationUpdate
  } = MyNetworkReducer;
  const { windowSize: { innerWidth } } = AppReducer;

  return {
    isMembersNetwork,
    showViewProfile,
    innerWidth,
    memberMore,
    isShowEditHierarchyComponent,
    levelsNetwork,
    networkOverviewByUser,
    isShowUnderlyingLevels,
    isTriggerNetworkInformationUpdate
  };
};

const mapStateToPropsActions = {
  setIsMemberMyNetworkReducer,
  setStateMyNetworkReducer,
  setShowViewProfileMyNetworkReducer,
  setMemberMoreMyNetworkReducer,
  setShowUnderlyingLevelsMyNetworkReducer,
  setNetworkOverviewByUserMyNetworkReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(MyNetworkPage);