//Libraries
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Assets } from "../../../assets/index";
import { clearKeysAuthenticationService, getTokenService, getUserLoggedService, logoutService } from '../../../services/authentication.services';
import { Checkbox, ConfigProvider } from 'antd';

//Styles
import "./welcome.page.scss";

//Utils
import { redirectsLogin } from '../../../utils/authentication.utils';

// Components
import ModalTermConditions from './components/modal-term-conditions/modal-term-conditions.component';

const WelcomePage = () => {

  const INITIAL_STATE = {
    userLogged: null,
    isAccept: false,
    showModal: false
  }

  const [state, setState] = useState(INITIAL_STATE)
  const { userLogged, isAccept, showModal } = state

  const [t] = useTranslation("translation");
  let history = useNavigate();

  useEffect(() => {
    const userLogged = getUserLoggedService()
    if (userLogged) {
      setState((prevState) => ({
        ...prevState,
        userLogged
      }))
    }
  }, []);

  const processLogout = () => {
    let token = getTokenService();
    logoutService(token)
      .then(() => {
        clearKeysAuthenticationService();;
        history("/login", { replace: true })
      })
      .catch((err) => {
        if (redirectsLogin(err)) history("/login", { replace: true });
      })
  };

  return (
    <>
      <div className="welcome__header">
        <div className="flex-container align-center-middle welcome__header__logo__content">
          <div className="welcome__header__logo flex-container align-center-middle">
            <img
              src={Assets.AuthenticationIcons.icon_demokratia}
              alt='icon_demokratia'
            />
          </div>
        </div>
      </div>

      <div className="welcome__footer grid-x">
        <p className="welcome__footer__title">
          {userLogged?.name} {userLogged?.lastName}
        </p>
        <p className="welcome__footer__text">
          {t("authentication.welcome.message")}
        </p>
        <div className="welcome__footer__content grid-y">
          <div className="grid-x align-center">
            <img
              src={Assets.AuthenticationIcons.icon_user}
              alt="icon_user"
              className="welcome__footer__icon"
            />
          </div>
          <p className="text-center welcome__footer__label">
            {t("authentication.welcome.completeProfile")}
          </p>
          <div className=' grid-x align-center-middle welcome__footer__checkbox-container'>
            <ConfigProvider theme={{
              token: {
                colorPrimary: "rgb(95, 62, 242)",
                colorBorder: "rgb(95, 62, 242)",
                lineWidth: 2,
                controlInteractiveSize: 20,
              }
            }}
            >
              <Checkbox checked={isAccept} onChange={() => setState((prevState) => ({ ...prevState, isAccept: !isAccept }))} />
            </ConfigProvider>
          <div className=' grid-x align-center-middle welcome__footer__checkbox-container__text'>
            <span>{t("authentication.welcome.checkbox")}</span>
            <button onClick={() => setState((prevState) => ({...prevState, showModal: true}))}>{t("authentication.welcome.checkboxLink")}</button>
          </div>
        </div>
        <div className="welcome__footer__button__content grid-x align-center-middle">
          <button
            disabled={!isAccept}
            className="welcome__footer__button"
            onClick={() => history("/completeRegistration")}
          >
            {t("authentication.welcome.login")}
          </button>
        </div>
      </div>
      <div className="welcome__footer__button__content grid-x align-center welcome__footer__button__content--mod">
        <button className="welcome__footer__back" onClick={() => processLogout()}>
          {t("authentication.welcome.exit")}
        </button>
      </div>
    </div>
      <ModalTermConditions visibility={showModal} closeModal={() => setState((prevState) => ({ ...prevState, showModal: false }))} />
    </>
  );
};

export default WelcomePage;