// Libraries
import React from 'react'
import Modal from 'react-modal'
import { t } from 'i18next'

// Styles
import './modal-term-conditions.scss'

// Assets
import { Assets } from '../../../../../assets'

const ModalTermConditions = ({
    // functions
    closeModal = () => { },
    // variables
    visibility = false
}) => {

    return (
        <Modal
            bodyOpenClassName="modal-term-conditions__body"
            isOpen={visibility}
            onRequestClose={closeModal}
            ariaHideApp={false}
            overlayClassName="modal-term-conditions__overlay grid-x align-center-middle"
            className="modal-term-conditions"
        >
            <div className="modal-term-conditions__header">
                <button onClick={closeModal}>
                <div  className="modal-term-conditions__header__icon">
                    <img src={Assets.SharedIcons.icon_arrow_left} alt='icon_arrow_left'/>
                </div>
                    {t("authentication.welcome.termConditions.close")}
                </button>

                <div className="modal-term-conditions__header__logo">
                    <img src={Assets.SharedIcons.icon_logo_info} alt='icon_logo_info'/>
                </div>
            </div>
            <div className="modal-term-conditions__content">
                <span className="modal-term-conditions__content__title">{t("authentication.welcome.termConditions.title")}</span>
                <div>
                    <p>Qui est et ipsum aliquip incididunt enim proident est mollit consectetur dolore do. Eiusmod cupidatat et cillum excepteur mollit nostrud. Fugiat sunt ea reprehenderit voluptate nulla tempor aute culpa. Nulla occaecat do elit consectetur minim et nostrud non labore aliquip incididunt Lorem.</p>
                    <p>Qui est et ipsum aliquip incididunt enim proident est mollit consectetur dolore do. Eiusmod cupidatat et cillum excepteur mollit nostrud. Fugiat sunt ea reprehenderit voluptate nulla tempor aute culpa. Nulla occaecat do elit consectetur minim et nostrud non labore aliquip incididunt Lorem.</p>
                    <p>Qui est et ipsum aliquip incididunt enim proident est mollit consectetur dolore do. Eiusmod cupidatat et cillum excepteur mollit nostrud. Fugiat sunt ea reprehenderit voluptate nulla tempor aute culpa. Nulla occaecat do elit consectetur minim et nostrud non labore aliquip incididunt Lorem.</p>
                    <p>Qui est et ipsum aliquip incididunt enim proident est mollit consectetur dolore do. Eiusmod cupidatat et cillum excepteur mollit nostrud. Fugiat sunt ea reprehenderit voluptate nulla tempor aute culpa. Nulla occaecat do elit consectetur minim et nostrud non labore aliquip incididunt Lorem.</p>
                </div>
            </div>
        </Modal>
    )
}

export default ModalTermConditions