// Components - shared
import NavbarComponentsManagerComponent from '../shared/components/nav-bar-components-manager/nav-bar-components-manager.component'
import TabBarComponentsManagerComponent from '../shared/components/tab-bar-components-manager/components/tab-bar-components-manager.component';

// Libraries
import { Navigate, Route, Routes } from 'react-router-dom';

// Views - Screen
import ContactListPage from '../pages/contact-list/contact-list/contact-list.page'

const ContactListRoutes = () => {

    return (
        <>
        <NavbarComponentsManagerComponent/>
            <div>
                <Routes>
                    <Route path="/" element={<ContactListPage/>}/>
                    <Route path="*" element={<Navigate replace to="/network"/>}/>
                </Routes>
            </div>
            <TabBarComponentsManagerComponent/>
        </>
    )
};

export default ContactListRoutes;