// Models- model
import LocationModel from "../model/location.model";

export const mapperToLocationModel = (LocationDTO) => {
    return new LocationModel(
        LocationDTO.idLocation,
        LocationDTO.lctPlaceName,
        LocationDTO.lctGeographicLocation.gplLatitude,
        LocationDTO.lctGeographicLocation.gplLongitude,
        LocationDTO.lctAddress,
        undefined,
        LocationDTO.lctImgLocation,
        LocationDTO.lctTypeLocation,
        LocationDTO.lctMunicipality
    );
};