// Constants
import Constants from "../../../../core/constants";
import AlertErrorsConst from "../../../../core/constants/errors/alerts-app/alerts-error.const";

// Libraries
import Swal from "sweetalert2";

// Models - DA0
import { createErrorInternalApp } from "../../../../models/aggregates/build-error-internal-app/build-error-internal-app.dao";

/**
* Success toast component
* 
* @param props - Contains configuration properties
* @param {string} [props.title = ''] - Title toast alert.
* @param {string} [props.text] - Content text toast alert.
* @param {number} [props.timer = 1500] - Numeric value of the time (milliseconds) in which the alert will be displayed.
* @param {string} [props.position = position] - Text value of the popup window position.
* @param {boolean} [props.timerProgressBar = false] - boolean value show a progress bar on the pop-up window.
* @param {string} [props.color = '#371f7a'] - Color for title, content and footer (CSS `color` property).
* @param {string} [props.iconHtml = iconHtml] - The custom HTML content for an icon. is used to change the icon
* @param {string} [props.iconColor = iconColor] - Use this to change the color of the icon. if iconHtml is true, iconColor is declared transparent
* @returns {Object} Returns a SweetAlert2 popup, success toast alert
*/

const SuccessToastComponent = (props) => {

  if (!props) {
    throw createErrorInternalApp(AlertErrorsConst.paramsRequiredBuildToasts)
  }

  let {
    title = "",
    text,
    timer = 1500,
    position = Constants.AlertConst.CENTER_POSITION_TEXT,
    timerProgressBar = false,
    color = `#371f7a`,
    icon = Constants.AlertConst.SUCCESS_ICON_TEXT,
    iconHtml,
    iconColor,
  } = props

  const Toast = Swal.mixin({
    toast: true,
    position,
    showConfirmButton: false,
    timer,
    timerProgressBar,
    didOpen: (toast) => {
      toast.addEventListener('click', () => { Toast.close() })
    }
  })
  if (iconHtml && iconHtml.src && iconHtml.alt) {
    iconHtml = `<img src='${iconHtml.src}' alt='${iconHtml.alt}' />`
  }

  return Toast.fire({
    icon,
    color,
    iconColor,
    iconHtml,
    title,
    text
  })
}

export default SuccessToastComponent