// Assets
import { Assets } from "../../../../../../../assets/index";

// Libraries
import React from 'react';
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

// Styles
import "./modal-unsubscribe-member.component.scss";

const ModalUnsubscribeMemberComponent = (props) => {

    const {
        //Props
        isViewModalUnsubscribeMember,
        closeModalUnsubscribeMember,
        unsubscribeMember,
    } = props;

    const [t] = useTranslation("translation");

    return (
        <Modal
            isOpen={isViewModalUnsubscribeMember}
            onRequestClose={() => closeModalUnsubscribeMember()}
            bodyOpenClassName="modal-unsubscribe-member__body"
            className="modal-unsubscribe-member__content"
            overlayClassName="grid-x align-center modal-unsubscribe-member__content__overlay"
            ariaHideApp={false}
        >
            <div className="modal-unsubscribe-member__container">
                <div className="modal-unsubscribe-member__container__content-close">
                    <button
                        type="button"
                        onClick={() => closeModalUnsubscribeMember()}
                    >
                        <img
                            alt="icon_close"
                            src={Assets.SharedIcons.icon_close}
                        />
                    </button>
                </div>
                <div className="modal-unsubscribe-member__container__content-information">
                    <label className="modal-unsubscribe-member__container__title">
                        {t("home.myNetwork.contactCard.modals.unsubscribeMember.title")}
                    </label>
                    <img
                        alt="icon_warning_big"
                        src={Assets.SharedIcons.icon_warning_big}
                    />
                    <label className="modal-unsubscribe-member__container__description">
                        {t("home.myNetwork.contactCard.modals.unsubscribeMember.description")}
                    </label>
                    <div className="modal-unsubscribe-member__container__content-btn">
                        <button
                            className="modal-unsubscribe-member__container__btn"
                            onClick={() => unsubscribeMember()}
                        >
                            {t("home.myNetwork.contactCard.modals.unsubscribeMember.textBtn")}
                        </button>
                        <button
                            className="modal-unsubscribe-member__container__btn-text"
                            onClick={() => closeModalUnsubscribeMember()}
                        >
                            {t("home.myNetwork.contactCard.modals.unsubscribeMember.cancel")}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
};

export default ModalUnsubscribeMemberComponent;
