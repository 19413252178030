import AppConst from './app.const'
import MyNetworkConst from "./my-network.const";
import AlertConst from './alerts.const';
import LocalStorageConst from './local-storage.const';
import ProfileFormConst from './profile-form.const';

const Constants = {
    AppConst,
    MyNetworkConst,
    AlertConst,
    LocalStorageConst,
    ProfileFormConst
}

export default Constants