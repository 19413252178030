// Libraries
import { Link } from 'react-router-dom';

//Assets
import { Assets } from "../../../../../assets";

//Styles
import "./secondary-nav-bar.component.scss";

const SecondaryNavBarComponent = () => {
  return (
      <div className="navbar-modules">
          <div className="navbar-modules__container-logo">
              <Link to="/network">
                  <img className="navbar-modules__img-logo" src={Assets.SharedIcons.icon_logo} alt="icon_logo" />
              </Link>
          </div>
      </div>
  )
}

export default SecondaryNavBarComponent