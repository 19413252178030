//Constant - Endpoints
import ContactListEndPoints from "./constants/contact-list.end-points";

//Infrastructure
import Http from "./infrastructure/http.infrastructure";

//Models - DTO
import UserDTO from "../models/user/dto/user.dto";
import { mapperToUserModel } from "../models/user/dto/user.dto.dao";

export async function getAllUsersGroupService (body, token) {
    try {
        const { data: responseContactList, error } = await Http.get(ContactListEndPoints.ALL_USERS_GROUP, body, token)
        if (responseContactList && responseContactList.status) {
            
            const { data: contactList } = responseContactList;
            const contactListUser = [];
            contactList?.forEach(( element ) => {
                const contactListDTO = new UserDTO(
                    element.uid,
                    element.usrName,
                    element.usrLastName,
                    element.usrImgProfile
                );
                contactListUser.push(mapperToUserModel(contactListDTO))
            });
            return contactListUser;
            
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
};

export async function getContactInformationService (body, token) {
    try {
        const { data: responseContactInformation, error } = await Http.get(ContactListEndPoints.CONTACT_INFORMATION, body, token)
        if (responseContactInformation && responseContactInformation.status) {
            const contactInformation = mapperToUserModel(responseContactInformation.data);
            return contactInformation;
        } else {
            throw error;
        }
    } catch (error) {
        throw error;
    }
};