//Actions
import {
  setNetworkMyNetworkReducer,
  setStateMyNetworkReducer,
  setShowViewProfileMyNetworkReducer,
  setMemberMoreMyNetworkReducer,
  setShowHierarchyEditReducer,
  setShowUnderlyingLevelsMyNetworkReducer
} from "../../../../../storage/reducers/my-network/my-network.actions";

//Assets
import { Assets } from "../../../../../assets";

//Constants - Core
import Constants from "../../../../../core/constants";

//Components
import ErrorToastComponent from "../../../../../shared/components/toast/error-toast/error-toast.component";
import UserInvitationSettingComponent from "../user-invitation-setting/user-invitation-setting.component";
import SuccessToastComponent from "../../../../../shared/components/toast/success-toast/success-toast.component";

//Libraries
import React, { useEffect, useState } from "react";
import { IoChevronBack } from "react-icons/io5";
import { AiOutlineLine } from "react-icons/ai";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { FaUserCircle } from "react-icons/fa";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

//Services
import { deleteUserDeclineInvitationService, resendInvitationService } from '../../../../../services/my-network.service'
import { getTokenService, getUserLoggedService, setNumberDocumentService, setTotalMembersService } from '../../../../../services/authentication.services';

//Styles
import "./box-member.component.scss";

//Utils
import { redirectsLogin } from "../../../../../utils/authentication.utils";
import { firstWorkFlowCommonUpdateDataApplication, secondWorkFlowCommonUpdateDataApplication } from "../../../../../utils/my-network.utils";

const BoxMemberComponent = (props) => {
  const [t] = useTranslation("translation");
  let history = useNavigate();
  let {
    // Actions
    setNetworkMyNetworkReducer,
    setStateMyNetworkReducer,
    setShowViewProfileMyNetworkReducer,
    setMemberMoreMyNetworkReducer,
    setShowHierarchyEditReducer,
    setShowUnderlyingLevelsMyNetworkReducer,
    // Variables
    level: member,
    index,
    levelsNetwork,
    innerWidth,
    totalMemberLevel
  } = props;

  const [more, setMore] = useState(0);

  const INITIAL_STATE = {
    showMember: false,
    memberSelectedToDeleted: null,
    isShowModalDeleteMember: false,
    memberSelectedMenuPopupDelete: false,
    //TODO: Check process flow when totalMember is equal to 0 (keep or delete modal)
    // showModalNotMember: {
    //   value: false,
    //   member: ""
    // },
    isShowContainerMemberOfLevel: false,
    previouslySelectedMember: undefined,
    expand: false,
    isShowModalEditInvitation: false
  }
  const [state, setState] = useState(INITIAL_STATE)

  const {
    memberSelectedToDeleted,
    isShowModalDeleteMember,
    //TODO: Check process flow when totalMember is equal to 0 (keep or delete modal)
    // showModalNotMember,
    isShowContainerMemberOfLevel,
    previouslySelectedMember,
    expand,
    isShowModalEditInvitation
  } = state;

  useEffect(() => {
    setMore(
      totalMemberLevel - Constants.MyNetworkConst.LIMIT_MEMBERS_TO_DISPLAY
    );
    return () => setMore(0);
  }, [totalMemberLevel]);

  useEffect(() => {
    let previouslySelectedMember = undefined;
    if (index > 0 && levelsNetwork[index - 1].memberByLevel !== undefined) {
      const previouslyMember = levelsNetwork[index - 1].memberByLevel.find(
        (element) => element.isMemberSelected === true
      );
      previouslySelectedMember = previouslyMember
        ? {
          idMember: previouslyMember.member.idUser,
          name: `${previouslyMember.member.name} ${previouslyMember.member.lastName}`,
          isCompleteRegister: previouslyMember.member.isCompletedRegister,
          isStateActive: previouslyMember.member.isStateActive,
          documentNumber: previouslyMember.member.documentNumber,
        }
        : undefined;
    }

    if (innerWidth < Constants.AppConst.MEDIUM_WIDTH_SCREEN_SIZE) {
      let initialIsShowContainerMemberOfLevel = isShowContainerMemberOfLevel;

      if (index + 1 === levelsNetwork.length) {
        initialIsShowContainerMemberOfLevel = true;
      } else {
        initialIsShowContainerMemberOfLevel = false;
      }

      setState({
        ...state,
        isShowContainerMemberOfLevel: initialIsShowContainerMemberOfLevel,
        previouslySelectedMember
      });
    } else {
      setState({
        ...state,
        isShowContainerMemberOfLevel: true,
        previouslySelectedMember,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelsNetwork, innerWidth]);

  const handlerGetChildrenByUser = async (level) => {
    member.forEach((element) => {
      element.isMemberSelected = false;
      if (element.member.idUser === level.member.idUser) {
        element.isMemberSelected = true;
      }
    });

    let token = getTokenService();
    clearLevelSpecific();

    try {
      if (token && level.member && level.member.idUser) {
        setStateMyNetworkReducer('loadingBoxMemberActivated', index)
        let startTimeRequest = new Date().getMilliseconds()

        const { dataLevelDetermined } = await secondWorkFlowCommonUpdateDataApplication(level.member.idUser, token)

        let endTimeRequest = new Date().getMilliseconds()
        let loadingSimulationTime = endTimeRequest - startTimeRequest
        setTimeout(() => {

          setNetworkMyNetworkReducer({
            levelMyNetwork: dataLevelDetermined,
            indexReference: index
          })

          setState({
            ...state,
            isMemberSelected: level,
            showMember: true,
            member: member,
          });

          setShowViewProfileMyNetworkReducer({
            isShowViewProfile: false,
            uidMemberSelected: "",
          });

          setStateMyNetworkReducer("loadingBoxMemberActivated", null);
        },
          loadingSimulationTime < 1000 ? 1000 : loadingSimulationTime
        );
      }
    } catch (err) {
      setStateMyNetworkReducer("loadingBoxMemberActivated", null);
      if(redirectsLogin(err)) history("/login", { replace: true });
    }
  };

  const deleteMemberSelected = () => {
    let newMyNetWork = member.filter(
      (memberElement) => memberElement.id !== memberSelectedToDeleted.id
    );
    const updateLevelsNetwork = [...levelsNetwork];
    updateLevelsNetwork[index] = newMyNetWork;
    setStateMyNetworkReducer("levelsNetwork", updateLevelsNetwork);
    setState({
      ...state,
      memberSelectedToDeleted: null,
      isShowModalDeleteMember: false
    })
  }

  const toggleModalDeleteMember = (isShowModal) => {
    setState({
      ...state,
      isShowModalDeleteMember: isShowModal
    })
  }

  // const togglePopupMenu = (memberSelectedMenuPopupDelete) => {
  //   setState({
  //     ...state,
  //     memberSelectedMenuPopupDelete
  //   })
  // }

  const goBackLevel = () => {
    let updateLevelsNetwork = [...levelsNetwork];
    updateLevelsNetwork = updateLevelsNetwork.slice(0, index);

    updateLevelsNetwork[updateLevelsNetwork.length - 1].memberByLevel.forEach(
      (member) => (member.isMemberSelected = false)
    );

    setStateMyNetworkReducer("levelsNetwork", updateLevelsNetwork);
    setShowViewProfileMyNetworkReducer({
      isShowViewProfile: false,
      uidMemberSelected: "",
    });
  };

  const goLevelSpecific = () => {
    let updateLevelsNetwork = [...levelsNetwork];
    updateLevelsNetwork = updateLevelsNetwork.slice(0, index + 1);

    updateLevelsNetwork[updateLevelsNetwork.length - 1].memberByLevel.forEach(
      (member) => (member.isMemberSelected = false)
    );

    setStateMyNetworkReducer("levelsNetwork", updateLevelsNetwork);
  };

  const clearLevelSpecific = () => {
    let updateLevelsNetwork = [...levelsNetwork];
    updateLevelsNetwork = updateLevelsNetwork.slice(0, index + 1);
    setStateMyNetworkReducer("levelsNetwork", updateLevelsNetwork);
  };

  const viewProfile = () => {
    setStateMyNetworkReducer("popupMenuOptionsLevelSelected", null);
    setShowViewProfileMyNetworkReducer({
      isShowViewProfile: true,
      uidMemberSelected: previouslySelectedMember?.idMember,
    });
  };

  const setMemberMore = () => {
    let uidMemberMore = "";
    let nameMemberMore = "";
    if (index > 0 && levelsNetwork[index - 1].memberByLevel !== undefined) {
      const previouslyMember = levelsNetwork[index - 1].memberByLevel.find(
        (element) => element.isMemberSelected === true
      );
      nameMemberMore = `${previouslyMember.member.name} ${previouslyMember.member.lastName}`;
      uidMemberMore = previouslyMember.member.idUser;
    } else {
      const userLogged = getUserLoggedService();
      nameMemberMore = `${userLogged.name} ${userLogged.lastName}`;
      uidMemberMore = userLogged.idUser;
    }

    const { overviewNetwork: { totalChildren, totalLeaders } } = levelsNetwork[index]

    setMemberMoreMyNetworkReducer({
      isShowViewMore: true,
      level: index + 1,
      nameMemberMore,
      uidMemberMore,
      totalChildren,
      totalLeaders
    })
    setShowUnderlyingLevelsMyNetworkReducer(true)
  }

  const renderIcon = (member) => {
    let icon = null;
    if (!member.isMemberSelected) {
      if (!member.member.isStateActive) {
        icon = (!member.member.isCompletedRegister)
            ? <img
                src={Assets.HomeIcons.icon_prohibited}
                alt="icon_prohibited"
                className="grid-x align-center-middle box-member__pending-icon"
              />
            : <img
                src={Assets.HomeIcons.icon_alert_inactive}
                alt="icon_alert_inactive"
                className="grid-x align-center-middle box-member__pending-icon"
              />
      } else if (!member.member.isCompletedRegister) {
        icon =  <img
                  src={Assets.HomeIcons.icon_pending}
                  alt="icon_pending"
                  className="grid-x align-center-middle box-member__pending-icon"
                />
      } else if (member.totalMember > 0) {
        icon =  <p 
                  className={member.totalMember > 99
                    ? "box-member__number-member box-member__number-member--mod"
                    : "box-member__number-member"
                  }
                >
                  {member.totalMember > 99 ? "99+" : member.totalMember}
                </p>
      }
    }

    return icon;
  };

  const resendInvitation = (idMember) => {
    let token = getTokenService();
    resendInvitationService({ uid: idMember }, token)
      .then((response) => {
        if (response) {
          let newLevelNetwork = [...levelsNetwork];
          newLevelNetwork[index - 1].memberByLevel.forEach(element => {
            if (element.isMemberSelected === true) {
              element.member.isStateActive = true;
            };
          });
          setStateMyNetworkReducer('levelsNetwork', newLevelNetwork);
          SuccessToastComponent({
            title: `${t("myNetworkPage.boxMember.alerts.titleResendInvitation")}`,
            position: Constants.AlertConst.TOP_POSITION_TEXT,
            timer: Constants.AlertConst.TIMER_MEDIUM,
            iconHtml:{
              src: Assets.SharedIcons.icon_resend_invitation,
              alt: "icon_resend_invitation"
            },
            iconColor: "transparent"
          })
        }
      })
      .catch((err) => {
        if(redirectsLogin(err)) history("/login", { replace: true });
      })
  };

  const removeInvitation =  (idMember, isDelete = false) => {
    let token = getTokenService();
    deleteUserDeclineInvitationService({ uid: idMember }, token)
      .then(async(response) => {
        if (response) {
          let updateLevelsNetwork = [...levelsNetwork];
          let newLevelNetwork = updateLevelsNetwork[index - 1].memberByLevel.filter(element =>
            element.isMemberSelected !== true
          );
          
          updateLevelsNetwork[index - 1].memberByLevel = newLevelNetwork;
          
          await fetchData(updateLevelsNetwork)

          ErrorToastComponent({
            title: `${isDelete ? t("myNetworkPage.boxMember.alerts.titleDeleteMember") : t("myNetworkPage.boxMember.alerts.titleRemoveInvitation")}`,
            position: Constants.AlertConst.TOP_POSITION_TEXT,
            timer: Constants.AlertConst.TIMER_MEDIUM,
            iconHtml:{
              src: Assets.SharedIcons.icon_decline_invitation,
              alt: "icon_decline_invitation"
            },
            iconColor: "transparent"
          })
          
        }
      })
      .catch((err) => {
        if(redirectsLogin(err)) history("/login", { replace: true });
      })
  };

  async function fetchData(levelsNetworkLocal) {
    let token = getTokenService()
    let userLogged = getUserLoggedService()
    if (userLogged && userLogged.idUser && token) {
      setStateMyNetworkReducer('isTriggerNetworkInformationUpdate', true)

      try {
        const responseDataUpdated = await firstWorkFlowCommonUpdateDataApplication(userLogged.idUser, token)
        setTotalMembersService(responseDataUpdated.totalMembersUserLogged)

        let levelNetworkCompare = [...levelsNetworkLocal]
        let newFirstLevel = responseDataUpdated.firstLevelData


        for (let indexNewMemberFound = 0; indexNewMemberFound < newFirstLevel.memberByLevel.length; indexNewMemberFound++) {
          const newMemberCompare = newFirstLevel.memberByLevel[indexNewMemberFound];
          let memberLevelSelected = levelNetworkCompare[index - 1].memberByLevel.findIndex(
            (element) => {
              return element.member.idUser === newMemberCompare.member.idUser
            }
          );
          if (memberLevelSelected === -1) {
            levelNetworkCompare[index - 1].memberByLevel.push(newMemberCompare)
            levelNetworkCompare.pop()
            break
          }
        }

        levelNetworkCompare[index - 1].overviewNetwork = { ...responseDataUpdated.firstLevelData.overviewNetwork }

        setStateMyNetworkReducer('levelsNetwork', levelNetworkCompare)

      } catch (error) {
      }
    }
  }

const  modalInvitation = () => {
  if (!previouslySelectedMember?.isStateActive ){
    resendInvitation(previouslySelectedMember.idMember );
  }else{
    setNumberDocumentService(previouslySelectedMember.documentNumber);
    setState({
    ...state,
    isShowModalEditInvitation: true
  })
  }
};
  return (
    <>
      <div
        className={
          !isShowContainerMemberOfLevel && index === 0 ? "box-member__show" : ""
        }
      >
        {index === 0 &&
          innerWidth < Constants.AppConst.MEDIUM_WIDTH_SCREEN_SIZE && <br />}
        <div
          className={
            innerWidth < Constants.AppConst.MEDIUM_WIDTH_SCREEN_SIZE &&
              index === 0
              ? "box-member__show"
              : "grid-x align-center-middle"
          }
        >
          <div className="grid-y align-center-middle">
            <div className="box-member__line"></div>
            <div className="box-member__line__circle"></div>
          </div>
        </div>
        <div className="grid-x align-center-middle box-member__content">
          <div className="box-member__container">
            {index > 0 && (
              <div className="box-member__header">
                <div className="grid-x align-middle justify-content-between">
                  <button
                    className="small-2 grid-x"
                    onClick={() => goBackLevel()}
                  >
                    <img
                      src={Assets.HomeIcons.icon_dropdown_arrow}
                      alt="icon_arrow"
                      className="box-member__header__button-go-back"
                    />
                  </button>
                  <div className="grid-y small-10" >
                    <button className="box-member__header__name" onClick={() => goLevelSpecific()}>
                      <span>{previouslySelectedMember?.name}</span>
                    </button>
                    <span className="box-member__header__name__label">
                      {t("myNetworkPage.boxMember.leverLeader")} {index}
                    </span>
                  </div>
                </div>
                <div className="grid-x flex-row-reverse align-middle">
                  {previouslySelectedMember?.isCompleteRegister
                    ? <button
                      className={`grid-x align-middle box-member__options__button ${levelsNetwork.length > 2 || !levelsNetwork[index].memberByLevel.length ? "box-member__options__button--mod" : ""}`}
                      onClick={() => viewProfile()}
                    >
                      <img
                        src={Assets.HomeIcons.icon_user_active}
                        alt="icon_update"
                        className="box-member__options__button__icon"
                      />
                      <span className="box-member__options__button__text">
                        {t("myNetworkPage.boxMember.viewProfile")}
                      </span>
                        </button>
                    :   <button
                          onClick={()=> {modalInvitation()}}
                          className={`grid-x align-middle box-member__options__button ${levelsNetwork.length > 2 ? "box-member__options__button--mod":""}`}
                        >
                          <img
                            className="box-member__options__button__icon"
                            src={!previouslySelectedMember?.isStateActive
                              ? Assets.HomeIcons.icon_share
                              : Assets.HomeIcons.icon_edit_profile
                            }
                            alt={!previouslySelectedMember?.isStateActive
                              ? "icon_share"
                              : "icon_edit_profile"
                            }
                          />
                          <span className="box-member__options__button__text">
                            {t(`myNetworkPage.boxMember.${!previouslySelectedMember?.isStateActive ? "resendInvitation" : "editInvitation"}`)}
                          </span>
                        </button>
                  }
                  {levelsNetwork[index].memberByLevel.length 
                    ? previouslySelectedMember?.isCompleteRegister
                      ? levelsNetwork.length > 2 &&
                      <button
                        className="grid-x align-middle box-member__options__button"
                        onClick={() => setShowHierarchyEditReducer(true)}
                      >
                        <img
                          src={Assets.HomeIcons.icon_adjust_hierarchy}
                          alt="icon_adjust_hierarchy"
                          className="box-member__options__button__icon"
                        />
                        <span className="box-member__options__button__text">
                          {t("myNetworkPage.boxMember.adjustHierarchy")}
                        </span>
                      </button>
                      : <button
                        className="grid-x align-middle box-member__options__button"
                        onClick={() => removeInvitation(previouslySelectedMember.idMember)}
                      >
                        <img
                          className="box-member__options__button__icon"
                          src={Assets.HomeIcons.icon_delete}
                          alt="icon_delete"
                        />
                        <span className="box-member__options__button__text">
                          {t("myNetworkPage.boxMember.removeInvitation")}
                        </span>
                      </button>
                    : !previouslySelectedMember?.isStateActive && <button
                      className="grid-x align-middle box-member__options__button"
                      onClick={() => removeInvitation(previouslySelectedMember.idMember, !previouslySelectedMember?.isCompleteRegister)}
                    >
                      <img
                        className="box-member__options__button__icon"
                        src={Assets.HomeIcons.icon_delete}
                        alt="icon_delete"
                      />
                      <span className="box-member__options__button__text">
                        {previouslySelectedMember?.isCompleteRegister
                          ? t("myNetworkPage.boxMember.deleteMember")
                          : t("myNetworkPage.boxMember.removeInvitation")
                        }
                      </span>
                    </button>
                  }
                </div>
              </div>
            )}
            {isShowContainerMemberOfLevel && (
              <>
                <div className="box-member__member__header">
                  <div className="box-member__member__level">
                    {levelsNetwork[index].overviewNetwork.totalChildren !== 0 &&
                      <>
                        <span>{t("myNetworkPage.boxMember.level")}</span>
                        <span className="box-member__member__icon__num">
                          {index <= 98 ? index + 1 : "99"}
                        </span>
                        {index > 98 && (
                          <span className="box-member__member__icon__more">+</span>
                        )}
                        <label className="box-member__member__title-affiliates">
                          {t("myNetworkPage.boxMember.affiliates")}{" "}
                          {index < 1 ? "por ti" : previouslySelectedMember?.name}
                        </label>
                      </>
                    }
                  </div>
                  <div className="box-member__member__subtitle">
                    <span>
                      {`${levelsNetwork[index].overviewNetwork.totalChildren} ${t("myNetworkPage.boxMember.members")} / ${levelsNetwork[index].overviewNetwork.totalLeaders} ${t("myNetworkPage.boxMember.leaders")}`}
                    </span>
                  </div>
                </div>
                <div
                  className={!expand ?
                    "grid-x box-member__member__box"
                    :
                    "grid-x box-member__member__box--open"
                  }
                >
                  {levelsNetwork[index].memberByLevel.length > 0
                    ? <>
                      {member && member.slice(0, Constants.MyNetworkConst.LIMIT_MEMBERS_TO_DISPLAY).map((member, index) => (
                        <React.Fragment key={index}>
                          <div
                            className={
                              member.isMemberSelected
                                ? "small-3 box-member__member__button box-member__member__button--active grid-y align-center-middle"
                                : "small-3 box-member__member__button grid-y align-center-middle"
                            }
                          >
                            <div className="box-member__member__box__details-user align-center-middle">
                              <button
                                className="grid-y align-center-middle box-member__member__box__button"
                                onClick={() =>
                                    /** 
                                    //TODO: Check process flow when totalMember is equal to 0 (keep or delete modal)
                                    : member.totalMember === 0
                                    ? setState({
                                        ...state,
                                        showModalNotMember: {
                                          value: true,
                                          member: member.name,
                                        },
                                      })
                                    */
                                    handlerGetChildrenByUser(member)
                                }
                              >
                                <div className={
                                      (!member.member.isStateActive && member.member.isCompletedRegister) 
                                      || 
                                      (!member.member.isCompletedRegister)
                                        ? "box-member__member__img--inactive" 
                                        : ""
                                    }
                                    >
                                  <img
                                    className="box-member__member__img"
                                    src={member?.member?.photoProfile ? member?.member?.photoProfile : Assets.SharedIcons.icon_profile}
                                    alt="icon_profile"
                                  />
                                </div>
                                { renderIcon(member) }
                                <span
                                  className={ member.isMemberSelected && member.totalMember > 0
                                      ? "box-member__member__name box-member__member__name--active text-center"
                                      : (!member.member.isStateActive &&  member.member.isCompletedRegister) || 
                                      (!member.member.isCompletedRegister)
                                        ? "box-member__member__name box-member__member__name--mod text-center"
                                        : "box-member__member__name text-center"
                                  }
                                >
                                  <span className="box-member__member__name__names">{member.member.name}</span>
                                  <span className="box-member__member__name__last-name">{member.member.lastName}</span>
                                </span>
                              </button>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                      {more > 0 ? (
                        <div className="small-3 box-member__member__content__view-more align-middle">
                          <button onClick={() => setMemberMore()}>
                            <p className="box-member__member__more">+{more}</p>
                            <p className="box-member__member__more__text">
                              {t("myNetworkPage.boxMember.seeMore")}
                            </p>
                          </button>
                        </div>
                      ) : null}
                    </>
                    //TODO: add case in which the user is not active
                    : !previouslySelectedMember?.isCompleteRegister
                      ? <div className="grid-x align-center-middle box-member__member__card-content ">
                        <span className="box-member__member__card-content__text">
                          {t("myNetworkPage.boxMember.incompleteRegister")}
                        </span>
                      </div>
                      : <div className="grid-x align-center-middle box-member__member__card-content ">
                        <span className="box-member__member__card-content__text">
                          {t("myNetworkPage.boxMember.unaffiliatedUsers")}
                        </span>
                      </div>
                  }
                </div>
                <div className="box-member__button__content">
                  {levelsNetwork[index].memberByLevel.length !== 0 &&
                    <button
                      className="grid-x align-center-middle box-member__button"
                      onClick={() =>
                        setState({
                          ...state,
                          expand: !expand,
                        })
                      }
                    >
                      {t("myNetworkPage.boxMember.expandLevel")}
                      <IoChevronBack
                        className={
                          expand
                            ? "box-member__button__icon--mod"
                            : "box-member__button__icon"
                        }
                      />
                    </button>
                  }
                </div>
              </>
            )}
          </div>
        </div>
        <Modal
          isOpen={isShowModalDeleteMember}
          className="box-member__delete-modal"
          overlayClassName={"box-member__delete-modal__overlay"}
          onRequestClose={() => toggleModalDeleteMember(false)}
          ariaHideApp={false}
        >
          <div className="box-member__delete-modal__top-button">
            <button onClick={() => toggleModalDeleteMember(false)}>
              <AiOutlineLine className="box-member__delete-modal__top-button__icon" />
            </button>
          </div>
          <div className="box-member__delete-modal__container-content">
            <p className="box-member__delete-modal__container-content__text box-member__delete-modal__container-content__text--mod">
              {t("myNetworkPage.boxMember.mainTitle")}{" "}
              {memberSelectedToDeleted && memberSelectedToDeleted.name}
            </p>
            <div className="box-member__delete-modal__container-content__img-container">
              <FaUserCircle
                className="box-member__delete-modal__container-content__img"
                color="gray"
              />
            </div>
            <p className="box-member__delete-modal__container-content__text">
              {t("myNetworkPage.boxMember.subTitle")}
            </p>
            <button
              onClick={deleteMemberSelected}
              className="box-member__delete-modal__button"
            >
              {t("myNetworkPage.boxMember.deleteAnyway")}
            </button>
          </div>
        </Modal>
      </div>
      {//TODO: Check process flow when totalMember is equal to 0 (keep or delete modal)
      /* <Modal
        ariaHideApp={false}
        isOpen={showModalNotMember.value}
        overlayClassName="box-member__delete-modal__overlay box-member__delete-modal__overlay--mod grid-x align-center-middle"
        bodyOpenClassName="box-member__delete-modal__body-open"
        className="box-member__modal-not-member"
        onRequestClose={() =>
          setState({
            ...state,
            showModalNotMember: { value: false, member: "" },
          })
        }
      >
        <div className="grid-container">
          <div className="grid-x align-right">
            <button
              className="box-member__modal-not-member__close"
              onClick={() =>
                setState({
                  ...state,
                  showModalNotMember: { value: false, member: "" },
                })
              }
            >
              <img src={Assets.SharedIcons.icon_close} alt="icon_close" />
            </button>
          </div>
          <div className="grid-y align-center-middle box-member__modal-not-member__content">
            <img
              className="box-member__modal-not-member__icon"
              src={Assets.SharedIcons.icon_profile}
              alt="icon_profile"
            />
            <span className="box-member__modal-not-member__text box-member__modal-not-member__text--mod text-center">
              {showModalNotMember.member}
            </span>
            <span className="box-member__modal-not-member__text text-center">
              {t("myNetworkPage.boxMember.noMembers")}
            </span>
          </div>
        </div>
      </Modal> */}
      {isShowModalEditInvitation &&
        <UserInvitationSettingComponent
          isShowModalEditInvitation={isShowModalEditInvitation }
          modalClose={() => setState({ ...state, isShowModalEditInvitation: false })}
          uidUserSelected={previouslySelectedMember?.idMember}
        />
      }
    </>
  );
}

const mapStateToProps = ({ MyNetworkReducer, AppReducer }) => {
  const { popupMenuOptionsLevelSelected, levelsNetwork } = MyNetworkReducer;
  const {
    windowSize: { innerWidth },
  } = AppReducer;

  return {
    popupMenuOptionsLevelSelected,
    levelsNetwork,
    innerWidth
  };
};

const mapStateToPropsActions = {
  setNetworkMyNetworkReducer,
  setStateMyNetworkReducer,
  setShowViewProfileMyNetworkReducer,
  setMemberMoreMyNetworkReducer,
  setShowHierarchyEditReducer,
  setShowUnderlyingLevelsMyNetworkReducer
};

export default connect(mapStateToProps,mapStateToPropsActions)(BoxMemberComponent);
