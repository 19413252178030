export const IS_MEMBER_MY_NETWORK = "@@mynetwork/IS_MEMBER_MY_NETWORK";
export const SET_NETWORK = "@@mynetwork/SET_NETWORK";
export const SET_STATE_MY_NETWORK = "@@myNetwork/SET_STATE_MY_NETWORK";
export const RESET_STATE_MY_NETWORK = "@@myNetwork/RESET_STATE_MY_NETWORK";
export const SET_SHOW_VIEW_PROFILE = "@@mynetwork/SET_SHOW_VIEW_PROFILE";
export const SET_SHOW_HIERARCHY_EDIT = "@@mynetwork/SET_SHOW_HIERARCHY_EDIT";
export const SET_MEMBER_VIEW_PROFILE = "@@myNetwork/SET_MEMBER_VIEW_PROFILE";
export const SET_MEMBER_MORE = "@@myNetwork/SET_MEMBER_MORE";
export const SET_NETWORK_OVERVIEW_BY_USER = "@@myNetwork/SET_NETWORK_OVERVIEW_BY_USER";
export const SET_IS_UNSUBSCRIBE = "@@myNetwork/SET_IS_UNSUBSCRIBE";
export const SET_IS_UNSUBSCRIBE_MEMBER = "@@myNetwork/SET_IS_UNSUBSCRIBE_MEMBER";
export const SET_SHOW_UNDERLYING_LEVELS = "@@myNetwork/SET_SHOW_UNDERLYING_LEVELS";