// Endpoints
import ReportsEndPoints from "./constants/reports.end-points"
// Infrastructure
import Http from "./infrastructure/http.infrastructure"

export const getFirstYearMemberRecord = async (uid, token) => {
    try {
        const { data: response, error } = await Http.get(ReportsEndPoints.getFirstYearMemberRecord, { uid }, token);
        if (response && response.status) {
            return response.data;
        } else {
            throw error;
        }
    } catch (error) {
        throw error;
    }
};

export const reportAffiliateIncome = async (yearInitial, monthInitial, yearFinish, monthFinish, token) => {
    try {
        const { data: response, error } = await Http.post(ReportsEndPoints.reportAffiliateIncome, { yearInitial, yearFinish, monthInitial, monthFinish }, token);

        if (response && response.status) {
            const { data, isActive, isNotActive, leaders, level } = response.data;
            let statistics = [];
            for (const item in data) {
                const date = item.split("-")
                statistics.push({
                    year: Number(date[0]),
                    month: Number(date[1]),
                    value: data[item]
                })
            }
            return {
                statistics,
                isActive,
                isNotActive,
                leaders,
                level
            };
        } else {
            throw error
        }
    } catch (error) {
        throw error;
    }
}

export const reportCurrentOccupation = async (token) => {
    try {
        const { data: response, error } = await Http.post(ReportsEndPoints.reportCurrentOccupation, null, token);
        if (response && response.status) {
            return response.data;
        } else {
            throw error
        }
    } catch (error) {
        throw error;
    }
}