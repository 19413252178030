import Constants from '../../../core/constants';
import * as completeProfileTypes from './complete-profile.types';

const INITIAL_STATE = {
    typeOfHouse: [],
    educationLevel:[],
    currentOccupation:[],
    typeIdentificationNumber:[],
    gender:[],
    economicSectors:[],
    user:{
      name:"",
      lastName:"",
      documentNumber:"",
      phone:"",
      phoneWhatsapp:"",
      typeIdentification:0,
      genre:null,
      ethnicity:"",
      disability:"",
      votingTable:null,
      educationalLevel:null,
      currentOccupation:null,
      timeExperience:"",
      lookingJob:null,
      dateBirth: null,
      countryBirth:null,
      departmentBirth:null,
      municipalityBirth:null,
      residenceLocation: undefined,
      residenceCountry: "",
      residenceDepartment: "",
      residenceMunicipality: "",
      residenceNeighborhood: "",
      residenceAddress:"",
      typeOfHousing:null,
      stratum: null,
      peopleLiveYou:"",
      imgResidence:"",
      votingDepartment:null,
      votingMunicipality:null,
      votingBooth:null,
      profession:"",
      companyName:"",
      nameLastCompany:"",
      typeOccupation:"",
      portfolio: null,
      socialNetworks: null,
      linkSocialNetworks:"",
      format:null,
      portfolioLink:"",
      economicSector:null,
      yourCompanyName:"",
      located:"",
      descriptionServices:"",
      photoProfile:null,
      attachPortfolio:""
    },
    allResidenceMunicipalityData:[],
    allResidenceDepartments:[],
    allResidenceNeighborhoodData:[],
    allDepartmentsBirth:[],
    allMunicipalityBirth:[],
    allVotingDepartments:[],
    allVotingMunicipalityData:[],
    allVotingBooth:[],
    allVotingStation:[],
    allCountry:[],
    showButtons: true,
    disabledButtonNext:true
};

/** 
* This reducer is intended to store information to be shared at complete profile.
* @param {[Array]} typeOfHouse to store the options for the type of house.
* @param {[Array]} educationLevel to store the options for the education level.
* @param {[Array]} currentOccupation to store the options for the current occupation.
* @param {[Array]} typeIdentificationNumber to store the options for the type identification number.
* @param {[Array]} gender to store the options for the gender.
*/
const CompleteProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case completeProfileTypes.RESET_STATE_COMPLETE_PROFILE:
      return { ...INITIAL_STATE };

    case completeProfileTypes.SET_INFO_USER:
      return {
        ...state,
        user:{
          ...state.user,
          [action.payload.props]: action.payload.value
        }
      };

    case completeProfileTypes.SET_STATE_INFO_USER:
      return {
        ...state,
        user:{
          ...state.user,
          ...action.payload
        }
      };
    
    case completeProfileTypes.RESET_INFO_USER:
      const userReset = Object.assign({},state.user,action.payload)
      return {
        ...state,
        user:userReset
      }

    case completeProfileTypes.SET_SELECTORS_INPUT:
      const { selectors } = action.payload;
      let stateSelectors = {};
      selectors.forEach((element) => {
        switch (element.typeSelector) {
          case Constants.ProfileFormConst.TYPE_OF_HOUSE:
            stateSelectors.typeOfHouse = element.values;
            break;
          case Constants.ProfileFormConst.EDUCATION_LEVEL:
            stateSelectors.educationLevel = element.values;
            break;
          case Constants.ProfileFormConst.CURRENT_OCCUPATION:
            stateSelectors.currentOccupation = element.values;
            break;
          case Constants.ProfileFormConst.TYPE_IDENTIFICATION_NUMBER:
            stateSelectors.typeIdentificationNumber = element.values.map(
              (value) => ({
                value: value,
                label: value,
              })
            );
            break;
          case Constants.ProfileFormConst.GENDER:
            stateSelectors.gender = element.values;
            break;
          case Constants.ProfileFormConst.ECONOMIC_SECTOR:
            stateSelectors.economicSectors = element.values;
            break;
          default:
            break;
        }
      });
      return {
        ...state,
        ...stateSelectors,
      };

      case completeProfileTypes.SET_STATE_DATA:
      return {
        ...state,
          [action.payload.props]: action.payload.value
      };

      case completeProfileTypes.SET_STATE_BUTTONS:
      return {
        ...state,
        showButtons: action.payload
      };

    default:
      return state;
  }
};
export default CompleteProfileReducer;
