// Assets
import { Assets } from '../../../../../assets';

// Libraries
import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import Modal from "react-modal";
import { es } from 'date-fns/locale';
import { TextField, ThemeProvider } from '@mui/material';
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select'
import { LocalizationProvider } from "@mui/x-date-pickers";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useNavigate } from 'react-router-dom';

//Models - dto
import UserDTO from '../../../../../models/user/dto/user.dto';


// Service
import { clearNumberDocumentService, getTokenService, getUserLoggedService } from '../../../../../services/authentication.services';
import { getUserFirstLevelService, updateUserFirstLevelService } from '../../../../../services/user-invitation-setting.services';

// Styles
import "./user-invitation-setting.component.scss";
import { calendarThemeMemberRegistration } from '../../../../../shared/styles/themes/calendarThemes';

// Utils
import { validateEditInvitation } from '../../../../../utils/validate-recovery-password.utils';
import { redirectsLogin } from '../../../../../utils/authentication.utils';

export const UserInvitationSettingComponent = (props) => {

    const {
        // Functions
        modalClose,
        // Variables
        uidUserSelected,
        isShowModalEditInvitation
    } = props;

    const [t] = useTranslation("translation");
    let history = useNavigate();
    const INITIAL_STATE = {
        modalSuccess: false,
        infoUserInvitation: undefined,
        nameUserLogged: "",
        isUpdateInvitation: false
    };

    useEffect(() => {
        getUserInvitation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const [state, setState] = useState(INITIAL_STATE);

    const {
        modalSuccess,infoUserInvitation, 
        nameUserLogged,isUpdateInvitation
    } = state;

    const { handleSubmit, handleChange, setFieldValue, values, errors, resetForm, setValues, handleBlur, touched } = useFormik({

        initialValues: {
            names: "",
            lastNames: "",
            indicatorPhone: "",
            phone: "",
            email: "",
            dateBirth: "",
            typeIdentification: "",
            documentNumber: "",
        },

        initialErrors: {
            names: "",
            lastNames: "",
            phone: "",
            email: "",
            dateBirth: "",
            typeIdentification: "",
            documentNumber: "",
        },
        validationSchema: validateEditInvitation,

        onSubmit: () => {
            if(!isUpdateInvitation){
                setState({...state, isUpdateInvitation: true})
                saveUpdateInvitationSetting()
                clearNumberDocumentService()
            }
        },
    });

    const getUserInvitation = () => {
        let token = getTokenService();
        getUserFirstLevelService({ uid: uidUserSelected }, token)
            .then((response) => {
                if (!!response) {
                    const infoUserSelected = {
                        names: response.name ? response.name : "",
                        lastNames: response.lastName ? response.lastName : "",
                        indicatorPhone: response.phoneNumber ? response.phoneNumber.slice(1, 3) : "",
                        phone: response.phoneNumber ? response.phoneNumber.slice(3, 13) : "",
                        email: response.email ? response.email : "",
                        dateBirth: response.dateBirth ? response.dateBirth : null,
                        typeIdentification: response.documentType !== undefined? response.documentType: 0,
                        documentNumber: response.documentNumber ? response.documentNumber : "",
                    };
                    setValues({
                        ...infoUserSelected
                    });
                    setState({
                        ...state,
                        infoUserInvitation: {
                            ...infoUserSelected
                        }
                    });
                };
            })
            .catch((err) => {
                if(redirectsLogin(err)) history("/login", { replace: true });
            });
    };

    const saveUpdateInvitationSetting = () => {
        let loadedData = {};
        let token = getTokenService();
        for (const [key, value] of Object.entries(values)) {
            if (infoUserInvitation[key] !== values[key]) {
                loadedData[key] = value;
            };
        };
        const userDTO = new UserDTO(
            undefined,
            loadedData.names,
            loadedData.lastNames,
            undefined,
            loadedData.documentNumber,
            loadedData.typeIdentification,
            (loadedData.indicatorPhone && loadedData.phone) 
                ? `+${loadedData.indicatorPhone}${loadedData.phone}` 
                : validatePhoneUser(loadedData.indicatorPhone, loadedData.phone),
            undefined,
            loadedData.email?.toLowerCase()
        )
        userDTO.usrDateBirth = loadedData.dateBirth;
        updateUserFirstLevelService({ uidUserSelected, userDTO }, token)
            .then((response) => {
                if (response) {
                    const { name } = getUserLoggedService();
                    setState({
                        ...state,
                        modalSuccess: response,
                        nameUserLogged: name,
                        isUpdateInvitation: false
                    });
                };
            })
            .catch((err) => {
                setState({
                    ...state,
                    isUpdateInvitation: false
                });
                if(redirectsLogin(err)) history("/login", { replace: true });
            })
    };

    const validatePhoneUser = (phoneIndicator, phoneValue) => {
        const phone = (phoneIndicator)
            ? `+${phoneIndicator}${infoUserInvitation.phone}`
            : (phoneValue)
                && `+${infoUserInvitation.indicatorPhone}${phoneValue}`;

        return phone;
    };

    const options = [
        { value: 0, label: "C.C" },
        { value: 1, label: "C.E" }
    ];

    const closeModal = () => {
        modalClose();
        setState({ INITIAL_STATE });
        resetForm();
        clearNumberDocumentService();
    };

    return (
        <Modal
            isOpen={isShowModalEditInvitation}
            bodyOpenClassName="user-invitation-setting__body"
            className={modalSuccess
                ? "user-invitation-setting__content user-invitation-setting__content--mod"
                : "user-invitation-setting__content"
            }
            overlayClassName={
                "grid-x align-center user-invitation-setting__content__overlay " +
                (modalSuccess && "user-invitation-setting__content__overlay--mod")
            }
            onRequestClose={() => closeModal()}
            ariaHideApp={false}
        >
            <div 
                className={
                    "user-invitation-setting__container " + 
                    (modalSuccess ? "user-invitation-setting__container--mod" : "")
                }
            >
                {!modalSuccess
                    ?   
                        <>
                            <div className="grid-x user-invitation-setting__container__content small-12 align-center-middle">
                                <span className="text-center user-invitation-setting__container__title small-11 flex-container align-center-middle">
                                    {t("myNetworkPage.userInvitationSetting.title")}
                                </span>
                                <button
                                    type="button"
                                    className="small-1 flex-container align-center-middle"
                                    onClick={() => closeModal()}
                                >
                                    <img
                                        alt="icon_close"
                                        src={Assets.SharedIcons.icon_close}
                                        className="user-invitation-setting__container__button-close"
                                    />
                                </button>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="user-invitation-setting__form__content">
                                    <div className="grid-y user-invitation-setting__form">
                                        <div className="grid-x user-invitation-setting__document">
                                            <div className="user-invitation-setting__document__select__content">
                                                <Select
                                                    value={values.typeIdentification}
                                                    onChange={(e) =>
                                                        setFieldValue("typeIdentification", e.target.value)
                                                    }
                                                    displayEmpty
                                                    inputProps={{ "aria-label": "Without label" }}
                                                    className={
                                                      errors.documentNumber && touched.documentNumber
                                                        ? "user-invitation-setting__document__select__error"
                                                        : "user-invitation-setting__document__select"
                                                    }
                                                    IconComponent={(props) => {
                                                      return (
                                                        <img
                                                          src={Assets.HomeIcons.icon_dropdown_arrow}
                                                          alt="icon_dropdown_arrow"
                                                          className={'user-invitation-setting__document__select__icon ' + props?.className}
                                                        />
                                                      );
                                                    }}
                                                    >                         
                                                    {options.map((option) => (
                                                      <MenuItem
                                                        className="user-invitation-setting__document__select__options"
                                                        key={option?.value}
                                                        {...option}
                                                      >
                                                        {option?.label}
                                                      </MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                            <TextField
                                                className="user-invitation-setting__input-select"
                                                label={t("myNetworkPage.userInvitationSetting.document")}
                                                value={values.documentNumber}
                                                error={(errors.documentNumber && touched.documentNumber) ? true : false}
                                                onChange={(value) => handleChange("documentNumber")(value)}
                                                onBlur={(value) => handleBlur("documentNumber")(value)}
                                            />
                                            {errors.documentNumber && touched.documentNumber &&
                                                <label className="user-invitation-setting__input-select__error-text">
                                                {errors.documentNumber}
                                                </label>
                                            }
                                        </div>
                                        <TextField
                                            className="user-invitation-setting__input"
                                            label={t("myNetworkPage.userInvitationSetting.names")}
                                            value={values.names}
                                            error={errors.names && touched.names ? true : false}
                                            onChange={(value) => handleChange("names")(value)}
                                            onBlur={(value)=>handleBlur("name")(value)}
                                        />
                                        <TextField
                                            className="user-invitation-setting__input"
                                            label={t("myNetworkPage.userInvitationSetting.lastNames")}
                                            value={values.lastNames}
                                            error={errors.lastNames && touched.lastNames ? true : false}
                                            onChange={(value) => handleChange("lastNames")(value)}
                                            onBlur={(value)=>handleBlur("lastName")(value)}
                                        />
                                        <ThemeProvider theme={calendarThemeMemberRegistration}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                                            <MobileDatePicker
                                            sx={{color:"#5F3EF2"}}
                                                componentsProps={{
                                                switchViewButton:{
                                                    sx:{color:"#5F3EF2"}
                                                },
                                                leftArrowButton:{
                                                    sx:{color:"#5F3EF2"}
                                                },
                                                rightArrowButton:{
                                                    sx:{color:"#5F3EF2"}
                                                }
                                                }}
                                                label={t("myNetworkPage.userInvitationSetting.dateBirth")}
                                                inputFormat="dd/MM/yyyy"
                                                value={values.dateBirth ? new Date(values.dateBirth) : null}
                                                onChange={(value) => setFieldValue("dateBirth", value)}
                                                disableFuture={true}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className="user-invitation-setting__input"
                                                        onBlur={(value)=>handleBlur("dateBirth")(value)}
                                                        error={errors.dateBirth && touched.dateBirth ? true : false}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                        </ThemeProvider>
                                        {errors.dateBirth && touched.dateBirth && 
                                            <label className="user-invitation-setting__input-select__error-text">
                                            {errors.dateBirth}
                                            </label>
                                        }
                                        <div className="grid-x user-invitation-setting__document align-bottom">
                                            <PhoneInput
                                                className="user-invitation-setting__input-phone"
                                                onlyCountries={["co", "ve"]}
                                                countryCodeEditable={false}
                                                inputProps={{ readOnly: "readonly" }}
                                                value={values.indicatorPhone}
                                                onChange={(value) => handleChange("indicatorPhone")(value)}
                                            />
                                            <TextField
                                                className="user-invitation-setting__input-select"
                                                label={t("myNetworkPage.userInvitationSetting.phone")}
                                                value={values.phone}
                                                error={errors.phone && touched.phone ? true : false}
                                                onChange={(value) => handleChange("phone")(value)}
                                                onBlur={(value)=>{handleBlur("phone")(value)}}
                                            />
                                        </div>
                                        <TextField
                                            className="user-invitation-setting__input"
                                            label={t("myNetworkPage.userInvitationSetting.email")}
                                            value={values.email.toLowerCase()}
                                            error={errors.email && touched.email ? true : false}
                                            onChange={(value) => handleChange("email")(value)}
                                            onBlur={(value)=>{handleBlur("email")(value)}}
                                        />
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="user-invitation-setting__button"
                                    disabled={Object.keys(errors).length !==0 || isUpdateInvitation}
                                >
                                    {t("myNetworkPage.userInvitationSetting.saveChanges")}
                                </button>
                                <div className="grid-x align-center-middle user-invitation-setting__button__back">
                                    <button
                                        type="button"
                                        className="user-invitation-setting__button__back__text"
                                        onClick={() => closeModal()}
                                    >
                                        {t("myNetworkPage.userInvitationSetting.cancel")}
                                    </button>
                                </div>
                            </form>
                        </>
                    : 
                        <div className="user-invitation-setting__modal-success">
                            <label className="user-invitation-setting__modal-success__user">
                                {`${t("myNetworkPage.userInvitationSetting.congratulations")} ${nameUserLogged}`}
                            </label>
                            <img
                                className="user-invitation-setting__modal-success__icon"
                                src={Assets.AuthenticationIcons.icon_user}
                                alt="icon_user"
                            />
                            <label className="user-invitation-setting__modal-success__description">
                                {t("myNetworkPage.userInvitationSetting.description")}
                            </label>
                            <button
                                onClick={() => closeModal()}
                                className="user-invitation-setting__modal-success__button-close"
                            >
                                {t("myNetworkPage.userInvitationSetting.agreed")}
                            </button>
                        </div>
                }
            </div>
        </Modal>
    )
}

export default UserInvitationSettingComponent