import * as myNetworkTypes from './my-network.types';

const INITIAL_STATE = {
    isMembersNetwork: false,
    levelsNetwork: [],
    popupMenuOptionsLevelSelected: null,
    showViewProfile: {
        isShowViewProfile: false,
        uidMemberSelected: "",
        isUserLoggedSelected: false,
    },
    memberViewProfile:{},
    memberMore:{
        isShowViewMore: false,
        level:0,
        totalChildren:0,
        totalLeaders:0,
        nameMemberMore:"",
        uidMemberMore: ""
    },
    isShowEditHierarchyComponent: false,
    loadingBoxMemberActivated: null,
    networkOverviewByUser: {
        totalLevels:0,
        totalLeaders: 0,
        totalUsersInactive:0
    },
    isModalPreview:false,
    isUnsubscribeMyAccount: false,
    isUnsubscribeMember: false,
    isShowUnderlyingLevels: false,
    isTriggerNetworkInformationUpdate: false
};

/** 
* This reducer is intended to store information to be shared at my network level.

* @param {[boolean]} isMembersNetwork returns true or false depending if the user has or not members associated to his network.
* @param {[array]} levelsNetwork Contains the array that contains in each position the array of the levels of the cards shown in my network.
* @param {[string]} popupMenuOptionsLevelSelected is used to identify to which card in my network the settings tooltip should be displayed.
* @param {[object]} showViewProfile contains the status of the profile view and the user id.
* @param {[boolean]} showViewProfile.isShowViewProfile view profile view status.
* @param {[string]} showViewProfile.uidMemberSelected id of the selected user to view profile.
* @param {[object]} memberViewProfile information of the selected user to view profile.
* @param {[object]} memberMore contains the status of the member filter view and the id of the member to view all associated members.
* @param {[boolean]} memberMore.isShowViewMore display the member filter view to see the other members of the card.
* @param {[string]} memberMore.level level of the selected member to display the members in view more.
* @param {[string]} memberMore.totalChildren total children of the selected member to display the members in view more.
* @param {[string]} memberMore.totalLeaders total leaders of the selected member to display the members in view more.
* @param {[string]} memberMore.nameMemberMore name of the selected member to display the members in view more.
* @param {[string]} memberMore.uidMemberMore id of the selected member to display the members is view more.
* @param {[object]} memberMore contains the status of the member filter view and the id of the member to view all associated members.
* @param {[boolean]} isShowEditHierarchyComponent Flag to show/hide the 'HierarchyComponent'.
* @param {[number]} loadingBoxMemberActivated It is used to display a loading type 'skeleton' for when you are performing the action of obtaining a certain level (it stores the index of the level of my network that you want to obtain the respective members).

* @param {[number]} networkOverviewByUser This object contains properties that relate to data representing a summary of the logged-in user's network.
* @param {[number]} networkOverviewByUser.totalLevels Property representing the total number of network levels of the logged in user.
* @param {[number]} networkOverviewByUser.totalLeaders Property representing the total number of leaders in the network of the logged user.
* @param {[number]} networkOverviewByUser.totalUsersInactive Property representing the total number of inactive users of the logged in user's network.

* @param {[boolean]} isUnsubscribeMyAccount will control the deactivation on the profile screen.
* @param {[boolean]} isUnsubscribeMember controls the active status of a member who is a child of my network.
* @param {[boolean]} isShowUnderlyingLevels Flag to identify when the system is enabled to display the underlying levels of the network.
* @param {[boolean]} isTriggerNetworkInformationUpdate Flag that allows identifying when to trigger the update of network information.
*/

const MyNetworkReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case myNetworkTypes.IS_MEMBER_MY_NETWORK:
            return {
                ...state,
                isMembersNetwork: action.payload
            };

        case myNetworkTypes.SET_NETWORK:
            const { levelMyNetwork, indexReference } = action.payload
            
            let newLevelsNetwork = [...state.levelsNetwork]
            
            if ((indexReference + 1) < newLevelsNetwork.length){
                newLevelsNetwork = newLevelsNetwork.slice(0,  (indexReference + 1))
            }
            
            newLevelsNetwork.push(levelMyNetwork)
    
            return {
                ...state,
                levelsNetwork: newLevelsNetwork
            };

        case myNetworkTypes.SET_STATE_MY_NETWORK:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            };

        case myNetworkTypes.RESET_STATE_MY_NETWORK:
            return {
                ...state,
                INITIAL_STATE
            };

        case myNetworkTypes.SET_SHOW_VIEW_PROFILE:
            return {
                ...state,

                showViewProfile:{
                    isShowViewProfile: action.payload.isShowViewProfile,
                    uidMemberSelected: action.payload.uidMemberSelected,
                    isUserLoggedSelected: action.payload.isUserLoggedSelected
                }
            };

        case myNetworkTypes.SET_MEMBER_VIEW_PROFILE:
            return {
                ...state,
                memberViewProfile: action.payload
            };

        case myNetworkTypes.SET_MEMBER_MORE:
            return{
                ...state,
                memberMore: {
                    ...state.memberMore,
                    isShowViewMore: action.payload.isShowViewMore,
                    level: action.payload.level,
                    nameMemberMore: action.payload.nameMemberMore,
                    uidMemberMore: action.payload.uidMemberMore,
                    totalChildren: action.payload.totalChildren,
                    totalLeaders: action.payload.totalLeaders
                }
            }
            
        case myNetworkTypes.SET_SHOW_HIERARCHY_EDIT:
                return { 
                    ...state,
                    isShowEditHierarchyComponent: action.payload
                };
        
        case myNetworkTypes.SET_NETWORK_OVERVIEW_BY_USER:
            return {
                ...state,
                networkOverviewByUser: {...action.payload}
            };

        case myNetworkTypes.SET_IS_UNSUBSCRIBE:
            return {
                ...state,
                isUnsubscribeMyAccount: action.payload
            };

        case myNetworkTypes.SET_IS_UNSUBSCRIBE_MEMBER:
            return {
                ...state,
                isUnsubscribeMember: action.payload
            };
        
        case myNetworkTypes.SET_SHOW_UNDERLYING_LEVELS:
            return {
                ...state,
                isShowUnderlyingLevels: action.payload
            };
                
        default:
            return state;
    }

};
export default MyNetworkReducer;
