// Constants - core
import Constants from "../core/constants";
//Endpoints
import MyNetworkEndPoints from "./constants/my-network.end-points";
//Infrastructure
import Http from "./infrastructure/http.infrastructure";
import LocalStorage from "./infrastructure/local-storage.infrastructure";
//Model-DTO
import UserDTO from "../models/user/dto/user.dto";
import { mapperToUserModel } from "../models/user/dto/user.dto.dao";

export async function getCountAllChildrenService(uid,pagination,search,token) {
    
    try {
        const { data: responseChildrenAll, error } = await Http.get(MyNetworkEndPoints.USER_CHILDREN_ALL, {pagination,uid, search } , token);
        
        if (responseChildrenAll && responseChildrenAll.status) {
            
            const userChildren = [];
            responseChildrenAll.data.forEach(element=>{
                const userDTO = new UserDTO(
                    element._id,
                    element.usrName,
                    element.usrLastName,
                    element.usrImgProfile
                )
                userDTO.usrIsStateActive = element.usrIsStateActive;
                userDTO.usrIsCompletedRegister = element.usrIsRegistered;
                userDTO.usrIdentificationNumber = element.usrIdentificationNumber;
                userChildren.push({
                    member: mapperToUserModel(userDTO), 
                    totalMember: element.count
                })
            })
            return userChildren;
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}

export async function getChildrenByUserService(uidMember, token) {

    try {
        const { data: responseChildren, error } = await Http.get(MyNetworkEndPoints.USER_CHILDREN, uidMember , token);
        if (responseChildren && responseChildren.status) {
            const { children } = responseChildren.data;
            const memberByLevel = [];

            children.forEach(element=>{
                const userDTO = new UserDTO(
                    element._id,
                    element.usrName,
                    element.usrLastName,
                    element.usrImgProfile
                )
                userDTO.usrIsStateActive = element.usrIsStateActive;
                userDTO.usrIsCompletedRegister = element.usrIsRegistered;
                userDTO.usrIdentificationNumber = element.usrIdentificationNumber;
                
                memberByLevel.push({
                    member: mapperToUserModel(userDTO), 
                    totalMember: element.count, 
                    isMemberSelected: false
                })
            })

            return {memberByLevel}
            
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}


export async function getUserService (uidMember, token) {

    try {
        const { data: responseUser, error } = await Http.get(MyNetworkEndPoints.GET_USER, uidMember, token);
        if (responseUser && responseUser.status) {
            const {card, personalInformation, homeData, votingData, jobProfile } = responseUser.data;
            const showUser = {
                cardUser: {
                    name: card?.usrName,
                    lastName: card?.usrLastName,
                    photoProfile: card?.usrImgProfile,
                    email: card?.usrEmail,
                    phone: card?.usrPhone,
                    phoneWhatsapp: card?.usrPhoneWhatsapp,
                    uidRoot: card.usrRoot,
                    isStateActive: card.usrIsStateActive
                },
                personalDataUser: {
                    name: personalInformation?.usrName,
                    lastName: personalInformation?.usrLastName,
                    dateBirth: personalInformation?.usrDateBirth,
                    countryBirth: personalInformation?.usrCountry,
                    departmentBirth: personalInformation?.usrDepartment,
                    municipalityBirth: personalInformation?.usrMunicipality,
                    typeIdentification: personalInformation?.usrTypeIdentificationNumber,
                    documentNumber: personalInformation?.usrIdentificationNumber,
                    phone: personalInformation?.usrPhone,
                    phoneWhatsapp: personalInformation?.usrPhoneWhatsapp,
                    genre: personalInformation?.usrGender,
                    ethnicity: personalInformation?.usrIsEthnicGroup,
                    disability: personalInformation?.usrIsDisabilityStatus
                },
                homeDataUser: {
                    department: homeData?.department,
                    municipality: homeData?.municipality,
                    neighborhood: homeData?.sector,
                    address:  homeData?.usrLocation,
                    typeOfHousing: homeData?.usrTypeOfHouse,
                    stratum: homeData?.usrStratum,
                    peopleLiveYou: homeData?.usrTotalPeopleLiving
                },
                votingDataUser: {
                    department: votingData?.department,
                    municipality: votingData?.municipality,
                    votingBooth: votingData?.votingStation,
                    votingTable: votingData?.usrVotingTable
                },
                jobProfileUser: {
                    companyName: jobProfile?.usrCompanyWorking,
                    educationalLevel: jobProfile?.usrEducationLevel,
                    currentOccupation: jobProfile?.usrCurrentOccupation,
                    profession: jobProfile?.usrProfession,
                    timeExperience: jobProfile?.usrExperienceMonths,
                    lookingJob: jobProfile?.usrIsSeekingEmployment,

                    typeOccupation: jobProfile?.usrOfficeHeld,
                    portfolio: jobProfile?.usrIsServicePortfolio,
                    socialNetworks:jobProfile?.usrIsSocialNetwork,
                    linkSocialNetworks:jobProfile?.usrSocialNetworkLink ,
                    format:jobProfile?.usrPortfolioFormatType ,
                    portfolioLink :jobProfile?.usrPortfolioFile ,
                    economicSector:jobProfile?.usrEconomicSector ,
                    located:jobProfile?.usrCompanyWorkingLocation ,
                    descriptionServices:jobProfile?.usrDescriptionServiceOffered,
                }
            }
            return showUser;
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}

export async function userRootChangeService (uidMemberToMove, uidMemberUserRoot, token) {
    const objectToChange = {
        uid: uidMemberToMove,
        uidChange: uidMemberUserRoot
    }
    try {
        const { data: responseChangeUserRoot, error } = await Http.post(MyNetworkEndPoints.USER_ROOT_CHANGE, objectToChange , token);
        if (responseChangeUserRoot && responseChangeUserRoot.status) {
            return true
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }

}

export async function getMyNetworkSummaryService(uidMember, token) {
    try {
        const { data: responseInfo, error } = await Http.get(MyNetworkEndPoints.GET_LEVELS_LEADERS, uidMember, token);
        if (responseInfo && responseInfo.status) {
            return responseInfo.data;
        } else {
            throw error;
        }
    } catch (error) {
        return error;
    }
}

export async function getTotalMembersAndLeadersService(uidMember, token) {
    try {
        const { data: responseTotalMembersAndLeaders, error } = await Http.get(MyNetworkEndPoints.GET_TOTAL_MEMBERS_AND_LEADERS, uidMember, token);
        if (responseTotalMembersAndLeaders && responseTotalMembersAndLeaders.status) {
            return responseTotalMembersAndLeaders.data;
        } else {
            throw error;
        }
    } catch (error) {
        return error;
    }
}

export async function validateNumberIdentificationService(body) {
    try {
        const { data: responseNumberIdentification, error } = await Http.post(MyNetworkEndPoints.VALIDATE_NUMBER_IDENTIFICATION, body)
        if (responseNumberIdentification && responseNumberIdentification.status) {
            return responseNumberIdentification.data;
        } else {
            throw error;
        }
    } catch (error) {
        throw error;
    }
};

export async function resendInvitationService(body, token) {
    try {
        const { data: responseResendInvitation, error } = await Http.post(MyNetworkEndPoints.RESEND_INVITATION, body, token)
        if (responseResendInvitation && responseResendInvitation.status) {
            return responseResendInvitation.status;
        } else {
            throw error;
        }
    } catch (error) {
        throw error;
    }
};

export async function deleteUserDeclineInvitationService(body, token) {

    try {
        const { data: responseDeleteUserDeclineInvitation, error } = await Http.delete(MyNetworkEndPoints.DELETE_USER_DECLINE_INVITATION, body, token)
        if (responseDeleteUserDeclineInvitation && responseDeleteUserDeclineInvitation.status) {
            return responseDeleteUserDeclineInvitation.status;
        } else {
            throw error;
        }
    } catch (error) {
        throw error;
    }
};

export async function getFilesFromApiFiles(body, token) {
    try {
        const { data: response, error } = await Http.get(MyNetworkEndPoints.GET_FILES_FROM_API_FILES, body, token);
        if (response && response.status) {
            const { data } = response;
            return data.url;
        } else {
            throw error;
        }
    } catch (error) {
        throw error;
    }
}

export async function unsubscribeUserService(body, token) {
    try {
        const { data: responseUnsubscribeUserService, error } = await Http.post(MyNetworkEndPoints.UNSUBSCRIBE_USER, body, token)
        if (responseUnsubscribeUserService && responseUnsubscribeUserService.status) {
            return responseUnsubscribeUserService.status;
        } else {
            throw error;
        }
    } catch (error) {
        throw error;
    }
};
/** 
* Block LocalStorage Services 
**/

export function saveFormValues(values) {
    LocalStorage.set(Constants.LocalStorageConst.SAVE_FORM_VALUES, values);
}

export function getFormValues() {
    return LocalStorage.get(Constants.LocalStorageConst.SAVE_FORM_VALUES, true);
}

export function clearFormValues() {
    return LocalStorage.remove(Constants.LocalStorageConst.SAVE_FORM_VALUES);
}