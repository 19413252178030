// Constants
import AuthenticationErrorCodesConst from "./authentication-error-codes.const"
// Models
import BuildErrorInternalAppModel from "../../../../models/aggregates/build-error-internal-app/build-error-internal-app.model"

const AuthenticationErrorsConst = {
    rolesAndGroupsIsEmpty: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.RGA_ATH_001, "errors.authentication.rolesAndGroupsIsEmpty"),
    errorGettingVerificationCode: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.RGA_ATH_002, "errors.authentication.errorGettingVerificationCode"),
    errorGettingVerificationIdentifier: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.RGA_ATH_003, "errors.authentication.errorGettingVerificationIdentifier"),
    // AU
    codeNumericIsInvalid: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.AU0001, "errors.authentication.codeNumericIsInvalid"),
    codeNumericRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.AU0002, "errors.authentication.codeNumericRequired"),
    emailIsNotRegistered: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.AU0003, "errors.authentication.emailIsNotRegistered"),
    emailRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.AU0005, "errors.authentication.emailRequired"),
    uidRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.AU0013, "errors.authentication.uidRequired"),
    userWasDeleted: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.AU0014, "errors.authentication.userWasDeleted"),
    userAlreadyExists: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.AU0016, "errors.authentication.userAlreadyExists"),
    permissionForAction: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.AU0017, "errors.authentication.permissionForAction"),
    // US
    dateBirthIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0001, "errors.authentication.identificationNumberRequired"),
    emailIsInvalid: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0002, "errors.authentication.emailIsInvalid"),
    identificationNumberRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0003, "errors.authentication.identificationNumberRequired"),
    uidDoesNotExist: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0004, "errors.authentication.uidDoesNotExist"),
    lastNameIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0005, "errors.authentication.lastNameIsRequired"),
    nameIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0006, "errors.authentication.nameIsRequired"),
    missingTokenSession: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0007, "errors.authentication.missingTokenSession"),
    phoneNumberRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0008, "errors.authentication.phoneNumberRequired"),
    tokenSessionExpired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0011, "errors.authentication.tokenSessionExpired"),
    deletedUser: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0012, "errors.authentication.deletedUser"),
    userDoesNotExist: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0013, "errors.authentication.userDoesNotExist"),
    userDoesNotPermission: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0019, "errors.authentication.userDoesNotPermission"),
    userNoPartnerNetwork: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0014, "errors.authentication.userNoPartnerNetwork"),
    locationIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0017, "errors.authentication.locationIsRequired"),
    typeIdentificationIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0020, "errors.authentication.typeIdentificationIsRequired"),
    countryIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0021, "errors.authentication.countryIsRequired"),
    departmentIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0022, "errors.authentication.departmentIsRequired"),
    votingStationIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0025, "errors.authentication.votingStationIsRequired"),
    genderIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0026, "errors.authentication.genderIsRequired"),
    ethnicGroupIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0027, "errors.authentication.ethnicGroupIsRequired"),
    disabilityStatusIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0028, "errors.authentication.disabilityStatusIsRequired"),
    typeOfHouseIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0029, "errors.authentication.typeOfHouseIsRequired"),
    stratumIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0030, "errors.authentication.stratumIsRequired"),
    phoneWhatsappIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0031, "errors.authentication.phoneWhatsappIsRequired"),
    peopleLivingIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0032, "errors.authentication.peopleLivingIsRequired"),
    votingTableIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0033, "errors.authentication.votingTableIsRequired"),
    educationLevelIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0034, "errors.authentication.educationLevelIsRequired"),
    professionIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0035, "errors.authentication.professionIsRequired"),
    currentOccupationIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0036, "errors.authentication.currentOccupationIsRequired"),
    companyWorkingIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0037, "errors.authentication.companyWorkingIsRequired"),
    experienceMonthsIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0038, "errors.authentication.experienceMonthsIsRequired"),
    seekingEmploymentIsRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0039, "errors.authentication.seekingEmploymentIsRequired"),
    userWasDeactivated: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0057, "errors.authentication.userWasDeactivated"),
    latitudeLongitudeRegisterSectorRequired: new BuildErrorInternalAppModel(AuthenticationErrorCodesConst.US0060, "errors.authentication.latitudeLongitudeRegisterSectorRequired"),

}

export default AuthenticationErrorsConst