// Constants - core
import Constants from '../../../../core/constants';

// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { Skeleton } from '@mui/material';

// Styles
import './box-member-skeleton.component.scss'

const BoxMemberSkeletonComponent = (props) => {

    const {
        // Variables
        innerWidth,
        boxLevelIndex
    } = props


    const mockupMembersSkeleton = () => {
        return <div className="grid-x  grid-container box-member-skeleton__member__box">
            {
                (function (rows, index, quantityMemberShowed) {
                    while (++index <= quantityMemberShowed) {
                        const totalMember = Math.floor(Math.random() * 2)
                        rows.push(
                            <React.Fragment key={index}>
                                <button
                                    className={"small-3 box-member-skeleton__member__button grid-y align-center-middle"}
                                >
                                    <div className='map-page__details-container__details-user align-center-middle'>
                                        <div className="box-member-skeleton__member__button__circle"/>
                                        {totalMember > 0 &&
                                            <Skeleton className={totalMember > 1 ?
                                                'box-member-skeleton__number-member box-member-skeleton__number-member--mod' :
                                                'box-member-skeleton__number-member'} variant="circular" width={20} height={20}
                                            />
                                        }
                                        <div className="box-member-skeleton__member__button__text"/>
                                    </div>
                                </button>
                            </React.Fragment>
                            
                        )
                    }
                    return rows;
                })([], 0, 4)}
        </div>
    }

    return (
        <div >
            {boxLevelIndex === 0 && innerWidth < Constants.AppConst.MEDIUM_WIDTH_SCREEN_SIZE &&
                <br />
            }
            <div className={innerWidth < 640 && boxLevelIndex === 0 ? "box-member-skeleton__show" : "grid-x align-center-middle"}>
                <div className="grid-y align-center-middle">
                    <div className="box-member-skeleton__line"></div>
                    <div className="box-member-skeleton__line__circle"></div>
                </div>
            </div>
            <div className="grid-x align-center-middle box-member-skeleton__content">
                <div className="box-member-skeleton__container">
                    <>
                        <div className="grid-x box-member-skeleton__member__header">
                            <div>
                                <div className='box-member-skeleton__member__text'/>
                            </div>
                            <div>
                                <div className='box-member-skeleton__member__text'/>
                            </div>
                        </div>
                        {mockupMembersSkeleton()}
                    </>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ AppReducer }) => {
    const { windowSize: { innerWidth } } = AppReducer;

    return {
        innerWidth
    };
};

export default connect(mapStateToProps, null)(BoxMemberSkeletonComponent);