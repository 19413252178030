// Actions
import { setStateAppReducer } from './../../storage/reducers/app/app.actions'

// Libraries
import AppRouter from '../../routes/app-root.router';
import { BrowserRouter } from "react-router-dom";
import { useEffect } from 'react';
import { connect } from 'react-redux';

function getWindowSize() {
  const {innerWidth } = window;
  return {innerWidth };
}

function App (props) {

  const {
    // Actions
    setStateAppReducer
  } = props

  useEffect(() => {
     const handleWindowResize = () => {
      setStateAppReducer('windowSize', getWindowSize())
    }
    setStateAppReducer('windowSize', getWindowSize())

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </div>
  );
}

const mapStateToProps = ({ AppReducer }) => {
  const {windowSize: { innerWidth } } = AppReducer;

  return {
    innerWidth
  };
};

const mapStateToPropsActions = {
  setStateAppReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(App);