//Assets
import { Assets } from '../../../assets'

// Constant - core
import Constants from '../../../core/constants';
import MyNetworkConst from '../../../core/constants/my-network.const';

// Components
import LoadingComponent from '../../../shared/components/loading/loading.component';

// Libraries
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from "react-i18next";
import { Autocomplete, Box, TextField } from '@mui/material';
import string from '../../../shared/translations/I18n';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// Services
import { 
  getVotingStationService, 
  getVotingTableService, 
  getUsersByVotingStationService, 
  getUsersBySectorService, 
  generateReportVotingStation,
  generateReportSector
} from '../../../services/map.services';
import { getTokenService, getUserLoggedService } from '../../../services/authentication.services';
import { getFilesFromApiFiles } from '../../../services/my-network.service';

// Share
import ErrorToastComponent from '../../../shared/components/toast/error-toast/error-toast.component';
import { cleanMap, getAddress, getLocation, GoogleMap, initMap, Marker, MarkerCluster } from '../../../shared/components/google-map/google-map.component';

// Styles
import './map.page.scss';

//Utils
import { redirectsLogin } from '../../../utils/authentication.utils';

const MapPage = () => {

  const INITIAL_STATE = {
    filterTypeState: [],
    listDepartmentState: [],
    listMunicipalityState: [],
    listMunicipalityFilter: [],
    listVotingStationState: [],
    listVotingStationFilter: [],
    listVotingTableState: [],
    listDepartmentResidenceState:[],
    listMunicipalityResidenceState:[],
    listSectorResidenceState:[],
    listMunicipalityResidence:[],
    listSectorResidence:[],
    departmentResidenceSelected: null,
    municipalityResidenceSelected: null,
    sectorResidenceSelected: null,
    selectedDepartmentState: null,
    selectedMunicipalityState: null,
    selectedVotingStationState: null,
    selectedVotingTableState: null,
    leakedMembersState: [],
    votingStation:undefined,
    isLoadingState: false,
    isGenerateReportState: false,
    isLeaked: false,
    centerMapState: undefined,
    isExpandedState: false,
    urlReport:"",
    isLoaded: false,
    loadError: false,
    isGeneratingReport: false
  };

  const [state, setState] = useState(INITIAL_STATE);
  const {
    filterTypeState,
    listDepartmentState,
    listMunicipalityState,
    listVotingStationState,
    listVotingTableState,
    selectedDepartmentState,
    selectedMunicipalityState,
    selectedVotingStationState,
    selectedVotingTableState,
    leakedMembersState,
    votingStation,
    isLoadingState,
    isGenerateReportState,
    isLeaked,
    centerMapState,
    isExpandedState,
    listMunicipalityFilter,
    listVotingStationFilter,
    listDepartmentResidenceState,
    listMunicipalityResidenceState,
    listSectorResidenceState,
    listMunicipalityResidence,
    listSectorResidence,
    departmentResidenceSelected,
    municipalityResidenceSelected,
    sectorResidenceSelected,
    urlReport,
    isGeneratingReport,
    isLoaded,
    loadError
  } = state;
  const [t] = useTranslation("translation");
  let history = useNavigate();

  const refScroll = useRef()

  const mapFilterOptions = [
    {
      label: string.t("map.votingBooths"),
      value: "1",
    },
    {
      label: string.t("map.address"),
      value: "2",
    },
  ];

  useEffect(() => {
    function getUserLocation() {
      if (!!navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(({ coords }) =>
          setState((prevState) => ({
            ...prevState,
            centerMapState: {
              lat: coords.latitude,
              lng: coords.longitude
            }
          })),
          (error) => {
            ErrorToastComponent({
              title: `${error.message}`,
              position: Constants.AlertConst.TOP_END_POSITION_TEXT,
              timer: Constants.AlertConst.TIMER_MEDIUM,
              iconHtml: {
                src: Assets.SharedIcons.icon_alert_error,
                alt: "icon_alert_error"
              },
              iconColor: "transparent"
            })
            setState((prevState) => ({
              ...prevState,
              loadError: true
            }))
          }
        )
      } else {
        ErrorToastComponent({
          title: `'EL navegador no soporta geolocation'`,
          position: Constants.AlertConst.TOP_END_POSITION_TEXT,
          timer: Constants.AlertConst.TIMER_MEDIUM,
          iconHtml:{
            src: Assets.SharedIcons.icon_alert_error,
            alt: "icon_alert_error"
          },
          iconColor: "transparent"
        });
        setState((prevState) => ({
          ...prevState,
          loadError: true
        }))
      }
    };
    getUserLocation();
    return () => setState((s) => s);
  }, [])

  useEffect(() => {
    async function loadMap() {
      try {
        const { isLoaded, loadError } = await initMap(Constants.AppConst.GOOGLE_MAPS_API_KEY);
        if (loadError)   throw loadError
      
        getAddress(centerMapState);
        setState((prevState) => ({
          ...prevState,
          isLoaded,
          loadError
        }))
      } catch (error) {
        ErrorToastComponent({
          title: `${error.message}`,
          position: Constants.AlertConst.TOP_END_POSITION_TEXT,
          timer: Constants.AlertConst.TIMER_MEDIUM,
          iconHtml:{
            src: Assets.SharedIcons.icon_alert_error,
            alt: "icon_alert_error"
          },
          iconColor: "transparent"
        })
      }
    }
    centerMapState && loadMap();
  }, [centerMapState]);

  const selectedVotingStation =  async(value) => {
    const votingStation = value.uid;
    const token = getTokenService();
    try {
      const listVotingTable = await getVotingTableService(
        votingStation, 
        token
      );

      const { votingTable } = listVotingTable;

      let tablesListDataNumeric = []
      let tablesListSortByAsc = []

      votingTable.forEach(element => {
        tablesListDataNumeric.push(Number(element))
      });

      tablesListDataNumeric.sort(function (a, b) { return a - b })

      tablesListDataNumeric.forEach(element => {
        tablesListSortByAsc.push(element.toString())
      });

      return tablesListSortByAsc

    } catch (err) {
      if(redirectsLogin(err)) history("/login", { replace: true });
    }
  };

  const filterBy = async (item) => {
      let filterType = new Array(item)
  
      if (filterType.length === 0) {
        setState({
          ...state,
          filterTypeState: filterType,
          selectedDepartmentState: null,
          selectedMunicipalityState: null,
          selectedVotingStationState: null,
          selectedVotingTableState: null,
        });
      } else {
        setState({
          ...state,
          filterTypeState: filterType,
          selectedDepartmentState: null,
          selectedMunicipalityState: null,
          selectedVotingStationState: null,
          selectedVotingTableState: null,
          departmentResidenceSelected: null,
          municipalityResidenceSelected:null,
          sectorResidenceSelected: null,
          listDepartmentResidence:[],
          listMunicipalityResidence:[],
          listSectorResidence:[],
          listMunicipalityFilter: [],
          listVotingStationFilter: [],
          listVotingTableState: [],
          leakedMembersState: [],
          votingStation:undefined,
          isLeaked: false
        });
        const token = getTokenService();
        try {
          getAddress(centerMapState);
          let userProfile={};
          switch (item.value) {
            case "1":
              userProfile = await getVotingStationService(Constants.MyNetworkConst.VOTING_STATION, token);
              setState((s) => ({
                ...s,
                listDepartmentState: userProfile.department,
                listMunicipalityState: userProfile.municipality,
                listVotingStationState: userProfile.votingStation,
              }));
              break;
            case "2":
              userProfile = await getVotingStationService(Constants.MyNetworkConst.USER_RESIDENCE, token);
              setState((s) => ({
                ...s,
                listDepartmentResidenceState: userProfile.department,
                listMunicipalityResidenceState: userProfile.municipality,
                listSectorResidenceState: userProfile.sectors,
              }));
              break;
            default:
              break;
          }
        } catch (err) {
          ErrorToastComponent({
            title: err?.message,
            position: Constants.AlertConst.TOP_END_POSITION_TEXT,
            timer: Constants.AlertConst.TIMER_MEDIUM,
            iconHtml: {
              src: Assets.SharedIcons.icon_error_map,
              alt: "icon_alert_error"
            },
            color: '#5F3EF2',
            iconColor: "transparent"
          })
          if(err?.code === "US0007") history("/login", { replace: true });
        }
      }
  };

  const filterMember = async () => {
    try {
      const token = getTokenService();
      let users = []
      let votingStation = undefined;
      switch (filterTypeState[0].value) {
        case "1":
          const { filterMemberUser, votingStationResp } = await getUsersByVotingStationService(
            selectedVotingStationState.uid,
            selectedVotingTableState,
            token
          );
          votingStation = votingStationResp;
          users = filterMemberUser;
          break;
        case "2":
          users = await getUsersBySectorService(
            sectorResidenceSelected.uid, 
            token
          );
          break;
        case "3":
          const usersByVotingStation = await getUsersByVotingStationService(
            selectedVotingStationState.uid,
            selectedVotingTableState,
            token
          );
          const usersBySector = await getUsersBySectorService(sectorResidenceSelected.uid, token);
          users = [...usersByVotingStation, ...usersBySector];
          break;
        default:
          users = [];
          break;
      }
      setState({
        ...state,
        leakedMembersState: users,
        isLeaked: true,
        votingStation,
        isGeneratingReport: false,
      });
    } catch (err) {
      setState({
        ...state,
        isGeneratingReport: false,
      })
      if(redirectsLogin(err)) history("/login", { replace: true });
    }
  };

  const handlerChangeStatesLocal = async ({ target, value }) => {
    switch (target) {
      case "selectedDepartmentState":
        if (value === null || (value !== selectedMunicipalityState && selectedMunicipalityState !== null)) {
          let municipality = []
          getAddress(centerMapState);
          if (value !== null && value !== selectedMunicipalityState && selectedMunicipalityState !== null) {
            getAddress(value.dptName);
            municipality = listMunicipalityState.filter(
              element => element.mnpDepartment === value.uid
            );
          }
          setState({
            ...state,
            [target]: value,
            isLeaked: false,
            selectedMunicipalityState: null,
            selectedVotingStationState: null,
            selectedVotingTableState: null,
            leakedMembersState:[],
            votingStation:undefined,
            listMunicipalityFilter: municipality
          });
        } else {
          const municipality = listMunicipalityState.filter(
            element => element.mnpDepartment === value.uid
            );
            setState({
              ...state,
              [target]: value,
              listMunicipalityFilter: municipality
            })
            getLocation(value.dptName);
          }
          cleanMap();

        break;

      case "selectedMunicipalityState":
        if (value === null || (value !== selectedVotingStationState && selectedVotingStationState !== null)) {
          let votingStation = []
          getLocation(selectedDepartmentState.dptName);
          if (value !== null && value !== selectedVotingStationState && selectedVotingStationState !== null) {
            getLocation(value.mnpName, 11);
            votingStation = listVotingStationState.filter(
              element => element.lctMunicipality === value.uid
            );
          }
          setState({
            ...state,
            [target]: value,
            isLeaked: false,
            selectedVotingStationState: null,
            selectedVotingTableState: null,
            leakedMembersState:[],
            votingStation:undefined,
            listVotingStationFilter: votingStation
          });
        }else {
          const votingStation = listVotingStationState.filter(
            element => element.lctMunicipality === value.uid
            );
            
            setState({
              ...state,
              [target]: value,
              listVotingStationFilter: votingStation,
            })
            getLocation(value.mnpName, 11);
          }
          cleanMap();
        break;

      case "selectedVotingStationState":
        if (value === null || (value !== selectedVotingTableState && selectedVotingTableState !== null)) {
          let listVotingTable = []
          if (value !== null && value !== selectedVotingTableState && selectedVotingTableState !== null) {

            listVotingTable =await  selectedVotingStation(value)
          }

          setState({
            ...state,
            [target]: value,
            isLeaked: false,
            selectedVotingTableState: null,
            leakedMembersState:[],
            votingStation:undefined,
            listVotingTableState: listVotingTable
          });
        }else {
          const listVotingTable = await selectedVotingStation(value)

          setState({
            ...state,
            [target]: value,
            listVotingTableState: listVotingTable
          })
        }
        cleanMap();
        break;

      case "selectedVotingTableState":
        cleanMap();
        setState({
          ...state,
          [target]: value,
            isLeaked: false,
            leakedMembersState:[],
          votingStation:undefined
        })
        break;

      case "departmentResidenceSelected":
        if (value === null || (value !== municipalityResidenceSelected && municipalityResidenceSelected !== null)) {
          let municipality = []
          if (value !== null && value !== municipalityResidenceSelected && municipalityResidenceSelected !== null) {
            municipality = listMunicipalityResidenceState.filter(element => element.mnpDepartment === value.uid)
            getLocation(value.dptName);
          }
          getAddress(centerMapState);

          setState({
            ...state,
            [target]: value,
            isLeaked: false,
            municipalityResidenceSelected: null,
            sectorResidenceSelected: null,
            leakedMembersState:[],
            listMunicipalityResidence: municipality
          });
        } else {
          const municipality = listMunicipalityResidenceState.filter(
            element => element.mnpDepartment === value.uid
            );
            setState({
              ...state,
              [target]: value,
              listMunicipalityResidence: municipality
            })
            getLocation(value.dptName);
          }
          cleanMap();
        break;

      case "municipalityResidenceSelected":
        if (value === null || (value !== sectorResidenceSelected && sectorResidenceSelected !== null)) {
          let sectorResidence = []
          if (value !== null && value !== sectorResidenceSelected && sectorResidenceSelected !== null) {
            listSectorResidenceState.filter(element => element.sctMunicipality === value.uid)
            getLocation(value.mnpName, 11);
          }
          getLocation(departmentResidenceSelected.dptName);
          setState({
            ...state,
            [target]: value,
            sectorResidenceSelected: null,
            isLeaked: false,
            leakedMembersState:[],
            listSectorResidence: sectorResidence
          });
        }else {
          const sectorResidence = listSectorResidenceState.filter(
            element => element.sctMunicipality === value.uid
            );
            
            setState({
              ...state,
              [target]: value,
              listSectorResidence: sectorResidence,
            })
            getLocation(value.mnpName, 11);
          }
          cleanMap();
        break;
      case "sectorResidenceSelected":
          
        
        if (value !== null ||  value !== sectorResidenceSelected) {
          setState({
            ...state,
            [target]: value,
            isLeaked: false,
            leakedMembersState:[],
          });
          value === null
            ? getLocation(municipalityResidenceSelected.mnpName, 11) 
            : getLocation(`${value.sctName}, ${municipalityResidenceSelected.mnpName}`, 14);
        }
        cleanMap();
        
        break;
      default:
        setState({
          ...state,
          [target]: value
        })
        break;
    }
  }

  const cleanReportResults = () => {
    if(filterTypeState[0]?.value === "1") {
      setState({
        ...state,
        votingStation:undefined,
        isLeaked: false,
        selectedDepartmentState: null,
        selectedMunicipalityState: null,
        selectedVotingStationState: null,
        selectedVotingTableState: null,
        leakedMembersState: [],
      });
    } else 
    if (filterTypeState[0]?.value === "2") {
      setState({
        ...state,
        isLeaked: false,
        departmentResidenceSelected: null,
        municipalityResidenceSelected:null,
        sectorResidenceSelected: null,
        leakedMembersState: [],
      });
    }
    cleanMap()
    getAddress(centerMapState);
  }

  const saveReport = async () => {
    try {
      const token = getTokenService();
      const { idUser } = getUserLoggedService();
      let URL;
      if (filterTypeState[0]?.value === "1") {
        await generateReportVotingStation({ votingStation: selectedVotingStationState.uid, votingTable: selectedVotingTableState }, token);
        URL = await getFilesFromApiFiles({ typeFile: MyNetworkConst.VOTING_STATION_TYPE, uid: idUser }, token);
      } else if (filterTypeState[0]?.value === "2") {
        await generateReportSector({ sector: sectorResidenceSelected.uid }, token);
        URL = await getFilesFromApiFiles({ typeFile: MyNetworkConst.SECTOR_TYPE, uid: idUser }, token);
      }
      setState({
        ...state,
        isLoadingState: false,
        isGenerateReportState: !isGenerateReportState,
        isExpandedState: false,
        urlReport: URL,
        isGeneratingReport: false,
      });
    } catch (error) {
      setState({
        ...state,
        isGeneratingReport: false,
      })
      ErrorToastComponent({
        title: `${error.code} ${error.message}`,
        position: Constants.AlertConst.TOP_END_POSITION_TEXT,
        timer: Constants.AlertConst.TIMER_MEDIUM,
        iconHtml: {
          src: Assets.SharedIcons.icon_alert_error,
          alt: "icon_alert_error"
        },
        iconColor: "transparent"
      })
    }
  };

  return (
    <div>
      <div>
        {
          isLoaded
            ? (
              <div className='map-page__map-container'>
                <GoogleMap >
                  {!!votingStation && (
                    <Marker
                      position={{
                        lat: votingStation?.latitude,
                        lng: votingStation?.longitude
                      }}
                    />
                  )}
                  {(filterTypeState[0]?.value === "2" && isLeaked) &&
                    <MarkerCluster
                      locations={leakedMembersState.map(user => {
                        const {latitude, longitude} = user.residencePlace;
                        return ({lat: latitude, lng: longitude})
                      })}
                    />
                  }
                </GoogleMap>
              </div>
            )
            : loadError
              ? (
                <div
                  className="grid-x align-center-middle map-page__error">
                <img src={Assets.SharedIcons.icon_error_map} alt='error_map'/>
                  <div className='text-center map-page__error__container' >
                  <span className='map-page__error__text'>
                  {t("map.googleMap.errorLoadingMap")}</span>
                  </div>
                </div>
              )
              : (<div 
                  className="grid-y align-center-middle map-page__loading">
                  <div className='map-page__loading-spinner'>
                  <img
                    src={Assets.SharedIcons.icon_loading} 
                    alt="icon_loading"
                  />
                  </div>
                  <span className='map-page__loading__text'>
                  {t("map.googleMap.loadingMap")}</span>
                </div>
              )
        }
      </div>
      {
        isGenerateReportState === false && (
          <div
            className=" align-center-middle grid-y map-page"
          >
            {
              (isLoadingState || (!isLoadingState && !isGenerateReportState)) &&
              (
                <>
                  <div className="map-page__details-container__details-filters" >
                    <img src={Assets.HomeIcons.icon_filter} alt="icon_filter"
                      className="map-page__details-container__details-filters__image"
                    />
                    <Select
                      disabled={!isLoaded}
                      value={filterTypeState[0]?.value || ""}
                      onChange={(e,event)=>filterBy(event?.props)}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label'}}
                      className="map-page__select"
                      IconComponent={(props)=>
                        <img src={Assets.HomeIcons.icon_dropdown_arrow} alt="icon_dropdown_arrow"
                          className={"map-page__select__icon " + props?.className}
                        />
                      }
                      renderValue={()=>{
                        return(
                          <MenuItem>
                            {filterTypeState[0]?.label || t("map.filter")}
                          </MenuItem>
                        )
                      }}
                    >
                    {mapFilterOptions.map((options) => (
                      <MenuItem 
                        className='map-page__select__options'
                        key={options?.value}
                        {...options}
                      >
                        {options?.label}
                      </MenuItem>
                    )
                    )}
                    </Select>
                    {
                      filterTypeState.length > 0 && (filterTypeState[0]?.value === "1")
                        ?<>
                          <form>
                            <Autocomplete
                              options={listDepartmentState}
                              value={selectedDepartmentState}
                              onChange={(e, value) => handlerChangeStatesLocal({
                                target: "selectedDepartmentState",
                                value,
                              })}
                              autoHighlight
                              getOptionLabel={(option) => option.dptName}
                              popupIcon={
                                <img
                                  size={16}
                                  alt="icon_dropdown_arrow"
                                  src={Assets.HomeIcons.icon_dropdown_arrow}
                                />
                              }
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    backgroundColor: "#F1F1F8",
                                    borderBottom: "1px solid #887EA6",
                                  }}
                                  {...props}
                                >
                                  {option.dptName}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t("map.forms.selectDepartment")}
                                  className="map-page__input-autocomplete"
                                  inputProps={{
                                    ...params.inputProps
                                  }}
                                />
                              )}
                            />
                            <Autocomplete
                              options={listMunicipalityFilter}
                              value={selectedMunicipalityState}
                              onChange={(e, value) => handlerChangeStatesLocal({
                                target: "selectedMunicipalityState",
                                value,
                              })}
                              autoHighlight
                              sx={{ width: "100%", marginTop: "40px" }}
                              getOptionLabel={(option) => option.mnpName}
                              disabled={selectedDepartmentState ? false : true}
                              popupIcon={
                                <img
                                  size={16}
                                  alt="icon_dropdown_arrow"
                                  src={Assets.HomeIcons.icon_dropdown_arrow}
                                />
                              }
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    backgroundColor: "#F1F1F8",
                                    borderBottom: "1px solid #887EA6",
                                  }}
                                  {...props}
                                >
                                  {option.mnpName}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t("map.forms.selectMunicipality")}
                                  className="map-page__input-autocomplete"
                                  inputProps={{
                                    ...params.inputProps
                                  }}
                                />
                              )}
                            />
                            <Autocomplete
                              options={listVotingStationFilter}
                              value={selectedVotingStationState}
                              onChange={(e, value) => handlerChangeStatesLocal({
                                target: "selectedVotingStationState",
                                value,
                              })}
                              sx={{ width: "100%", marginTop: "40px" }}
                              autoHighlight
                              getOptionLabel={(option) => option.lctPlaceName}
                              disabled={selectedMunicipalityState ? false : true}
                              popupIcon={
                                <img size={16} alt="icon_dropdown_arrow" src={Assets.HomeIcons.icon_dropdown_arrow} />
                              }
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    backgroundColor: "#F1F1F8",
                                    borderBottom: "1px solid #887EA6",
                                  }}
                                  {...props}
                                >
                                  {option.lctPlaceName}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t("map.forms.selectVotingStation")}
                                  className="map-page__input-autocomplete"
                                  inputProps={{
                                    ...params.inputProps
                                  }}
                                />
                              )}
                            />
                            <Autocomplete
                              options={listVotingTableState}
                              value={selectedVotingTableState}
                              onChange={(e, value) =>
                                handlerChangeStatesLocal({
                                  target: "selectedVotingTableState",
                                  value,
                                })
                              }
                              sx={{ width: "100%", marginTop: "40px" }}
                              autoHighlight
                              getOptionLabel={(option) => option}
                              disabled={selectedVotingStationState ? false : true}
                              popupIcon={
                                <img
                                  size={16}
                                  alt="icon_dropdown_arrow"
                                  src={Assets.HomeIcons.icon_dropdown_arrow}
                                />
                              }
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    backgroundColor: "#F1F1F8",
                                    borderBottom: "1px solid #887EA6",
                                  }}
                                  {...props}
                                >
                                  {option}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t("map.forms.selectVotingTable")}
                                  className="map-page__input-autocomplete"
                                  inputProps={{
                                    ...params.inputProps
                                  }}
                                />
                              )}
                            />
                          </form>
                        </>
                        : (filterTypeState[0]?.value === "2") &&
                        <>
                          <form>
                            <Autocomplete
                              options={listDepartmentResidenceState}
                              value={departmentResidenceSelected}
                              onChange={(e, value) => handlerChangeStatesLocal({
                                target: "departmentResidenceSelected",
                                value,
                              })}
                              autoHighlight
                              sx={{ width: "100%" }}
                              getOptionLabel={(option) => option?.dptName}
                              popupIcon={
                                <img
                                  size={16}
                                  alt="icon_dropdown_arrow"
                                  src={Assets.HomeIcons.icon_dropdown_arrow}
                                />
                              }
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    backgroundColor: "#F1F1F8",
                                    borderBottom: "1px solid #887EA6",
                                  }}
                                  {...props}
                                >
                                  {option.dptName}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t("map.forms.selectDepartmentResidence")}
                                  className="map-page__input-autocomplete"
                                  inputProps={{
                                    ...params.inputProps
                                  }}
                                />
                              )}
                              />
                            <Autocomplete
                              options={listMunicipalityResidence}
                              value={municipalityResidenceSelected}
                              onChange={(e, value) => handlerChangeStatesLocal({
                                target: "municipalityResidenceSelected",
                                value,
                              })}
                              autoHighlight
                              sx={{ width: "100%", marginTop: "40px" }}
                              getOptionLabel={(option) => option?.mnpName}
                              disabled={departmentResidenceSelected? false : true}
                              popupIcon={
                                <img
                                  size={16}
                                  alt="icon_dropdown_arrow"
                                  src={Assets.HomeIcons.icon_dropdown_arrow}
                                />
                              }
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    backgroundColor: "#F1F1F8",
                                    borderBottom: "1px solid #887EA6",
                                  }}
                                  {...props}
                                >
                                  {option.mnpName}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t("map.forms.selectMunicipalityResidence")}
                                  className="map-page__input-autocomplete"
                                  inputProps={{
                                    ...params.inputProps
                                  }}
                                />
                              )}
                              />
                            <Autocomplete
                              options={listSectorResidence}
                              value={sectorResidenceSelected}
                              onChange={(e, value) => handlerChangeStatesLocal({
                                target: "sectorResidenceSelected",
                                value,
                              })}
                              autoHighlight
                              sx={{ width: "100%", marginTop: "40px" }}
                              getOptionLabel={(option) => option?.sctName}
                              disabled={municipalityResidenceSelected? false: true}
                              popupIcon={
                                <img
                                  size={16}
                                  alt="icon_dropdown_arrow"
                                  src={Assets.HomeIcons.icon_dropdown_arrow}
                                />
                              }
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    backgroundColor: "#F1F1F8",
                                    borderBottom: "1px solid #887EA6",
                                  }}
                                  {...props}
                                >
                                  {option.sctName}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t("map.forms.selectSectorResidence")}
                                  className="map-page__input-autocomplete"
                                  inputProps={{
                                    ...params.inputProps
                                  }}
                                />
                              )}
                              />
                          </form>
                        </>
                      
                    }
                  </div>
                  <div className="map-page__details-container__search-container">
                    {isLeaked && (
                      <div
                        className="grid-x grid-padding-x 
                        map-page__details-container__leaked-members"
                      >
                        <div className="map-page__details-container__results grid-y small-11">
                          <p className="map-page__details-container__text__results">
                            <strong>{leakedMembersState.length}</strong>{" "}
                            {t("map.result")}
                          </p>
                        </div>
                        <button
                          className="grid-y small-1 map-page__details-container__button-clean"
                          onClick={cleanReportResults}
                        >
                          <img 
                            src={Assets.HomeIcons.icon_clean} 
                            alt="icon_clean"
                          />
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )
            }

            {
              isLeaked &&
              !isLoadingState &&
              !isGenerateReportState &&
              (
                <div className={"map-page__container"}>
                  <div className={"map-page__details-container__user-container"}>
                    {leakedMembersState.length
                      ? <>
                        <div ref={refScroll}
                          className={
                            isExpandedState
                              ? "map-page__details-container__user-container--active grid-x"
                              : "map-page__details-container__user-container--items grid-x"
                          }
                        >
                          {leakedMembersState.map((leakedMember) => (
                            <div
                              key={leakedMember.idUser}
                              className="small-3 map-page__details-container__details-user align-center-middle"
                            >
                              <div className='align-center-middle '>
                                <img
                                  src={leakedMember?.photoProfile ? leakedMember?.photoProfile : Assets.SharedIcons.icon_profile}
                                  alt="icon_profile"
                                  className="map-page__image"
                                />
                              </div>
                              <span className="map-page__details-container__text map-page__details-container__text--mod">
                                <span className='map-page__details-container__text map-page__details-container__text--mod__name'>
                                  {leakedMember.name}
                                </span>
                                <span className='map-page__details-container__text map-page__details-container__text--mod__lastName'>
                                  {leakedMember.lastName}
                                </span>
                              </span>
                            </div>
                          ))}
                        </div>
                        <button
                          className="map-page__details-container__member-text-found"
                          onClick={() => {
                            refScroll.current.scrollTo(0, 0)
                            setState({
                              ...state,
                              isExpandedState: !isExpandedState
                            })
                          }}
                        >
                          {t("map.expandLevel")}
                          <img
                            alt="arrow"
                            className={
                              !isExpandedState
                                ? "map-page__details-container__collapse-icon"
                                : "map-page__details-container__collapse-icon--activated"
                            }
                            src={Assets.HomeIcons.icon_dropdown_arrow}
                          />
                        </button>
                      </>
                      : <div className='map-page__details-container__notFound align-center-middle grid-x'>
                        <span>{t("map.notFound")}</span>
                      </div>
                    }
                  </div>
                </div>
              )
            }

            {
              (
                !filterTypeState.length &&
                (
                  <div className="align-center-middle grid-y">
                    <div className={"map-page__details-container__no-data"}>
                      <p className="map-page__details-container__text__no-data">
                        {t("map.searchCriteria")}
                      </p>
                    </div>
                  </div>
                )
              )
            }
          </div>
        )
      }
      {
        !isGenerateReportState && (
          <div className="map-page__details-container__text__no-data--icon-search">
            {isLeaked &&
              !isLoadingState &&
              !isGenerateReportState && (
                <button
                  onClick={() => {
                    setState({
                      ...state,
                      isGeneratingReport: true,
                      isLoadingState: true,
                    })
                    saveReport()
                  }}
                  disabled={!leakedMembersState.length || isGeneratingReport}
                  className="map-page__details-container__text"
                >
                  {t("map.saveReport")}
                </button>
              )}
            {
              !isLeaked && !isGenerateReportState && (
                <button
                  onClick={()=>{
                    setState({
                      ...state,
                      isGeneratingReport: true
                    })
                    filterMember()
                  }}
                  disabled={(filterTypeState[0]?.value === "1" && !selectedVotingStationState) || (filterTypeState[0]?.value === "2" && !sectorResidenceSelected) 
                  || !filterTypeState.length || isGeneratingReport}
                >
                  {t("map.generateReport")}
                </button>
              )}
          </div>
        )
      }
      <LoadingComponent
        loading={isLoadingState}
      />
      {
        isGenerateReportState && (
          <div className="align-center-middle grid-y">
            <button
              onClick={() => {
                setState({
                  ...state,
                  selectedDepartmentState: null,
                  selectedMunicipalityState: null,
                  selectedVotingStationState: null,
                  selectedVotingTableState: null,
                  filterTypeState: [],
                  leakedMembersState: [],
                  isLeaked: false,
                  isGenerateReportState: !isGenerateReportState,
                });
              }}
              className="map-page__details-container__text map-page__report__buttons--back"
            >
              <img
                alt="back_icon"
                className="map-page__report__back-icon"
                src={Assets.HomeIcons.icon_dropdown_arrow}
              />{t("map.back")}
            </button>
            <div
              className="map-page__report__container align-center-middle grid-y">
              <div className="map-page__report__back-img">
                <img
                  alt="icon_report"
                  src={Assets.HomeIcons.icon_report}
                />
              </div>
              <p className="map-page__report__back-text">
                {t("map.finishedReport")}
              </p>
            </div>
            <div className="grid-x">
              <a href={urlReport} target="_blank"  rel="noreferrer" className="map-page__report__buttons map-page__report__buttons__save">
                <img
                  alt="icon_download"
                  src={Assets.HomeIcons.icon_download}
                />
                {t("map.download")}
              </a>
            </div>
          </div>
        )
      }
    </div>
  )
}


export default MapPage