// Actions
import { setOptionSelectedTabBarTabBarReducer } from '../../../../../storage/reducers/tabbar/tabbar.actions'

//Assets
import { Assets } from '../../../../../assets';

//Libraries
import React, { useEffect, useState } from 'react'
import { FaUserCircle } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//Services
import { getUserLoggedService } from '../../../../../services/authentication.services';

//Styles
import "./main-nav-bar.component.scss";

export const MainNavBarComponent = (props) => {

    const {
        // Actions
        setOptionSelectedTabBarTabBarReducer,
        //Variables
        modalAssociate,
        modalMenu
    } = props;

    const [ t ] = useTranslation("translation");
    const INITIAL_STATE = {
        userLoggedState: {},
        pathModule: ""
    };
    const [state, setState] = useState(INITIAL_STATE);
    const { userLoggedState } = state;

    useEffect(() => {
        const userLogged = getUserLoggedService()
        setState({
            userLoggedState: userLogged
        })
        return () => setState(INITIAL_STATE);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handlerOptionSelectedTabBar = () => {
        setOptionSelectedTabBarTabBarReducer(null);
    };

    return (
        <div className={modalAssociate || modalMenu ? "top-bar navbar" : "top-bar navbar  top-bar--mod"}>
            <div className="navbar__title__content">
                <div className="navbar__title">
                    <Link to="/network">
                        <img className="navbar__title__img-logo" src={Assets.SharedIcons.icon_logo} alt="icon_logo" />
                    </Link>
                </div>
            </div>
            <div className="grid-x navbar__link">
                <NavLink
                    onClick={() => { handlerOptionSelectedTabBar() }}
                    className={({ isActive }) =>
                        "nav-item navbar__button nav-link  " +
                        (isActive ? "navbar__button--active" : "")
                    }
                    to="/network"
                >
                    <label className="navbar__button__text">
                        {t("navBarHome.myNetworkModule")}
                    </label>
                </NavLink>
                <NavLink
                    onClick={() => { handlerOptionSelectedTabBar() }}
                    className={({ isActive }) =>
                        "nav-item navbar__button nav-link  " +
                        (isActive ? "navbar__button--active" : "")
                    }
                    to="/map"
                >
                    <label className="navbar__button__text">
                        {t("navBarHome.mapModule")}
                    </label>
                </NavLink>
                <NavLink
                    onClick={() => { handlerOptionSelectedTabBar() }}
                    className={({ isActive }) =>
                        "nav-item navbar__button nav-link  " +
                        (isActive ? "navbar__button--active" : "")
                    }
                    to="/reports"
                >
                    <label className="navbar__button__text">
                        {t("navBarHome.reportsModule")}
                    </label>
                </NavLink>
            </div>
            <div className="navbar__user">
                <div className="grid-x">
                    <div>
                        <label className="navbar__user__name">{userLoggedState?.name}</label>
                        <label className="navbar__user__cargo">{userLoggedState?.role?.name}</label>
                    </div>
                    <div className="navbar__user__logo align-middle grid-x">
                        <FaUserCircle className="navbar__user__img" />
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = ({ TabbarReducer }) => {
    const { modalAssociate, modalMenu } = TabbarReducer;
    
    return {
        modalAssociate,
        modalMenu
    };
};

const mapStateToPropsActions = {
    setOptionSelectedTabBarTabBarReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(MainNavBarComponent);