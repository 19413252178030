// Assets
import { Assets } from '../../../assets';

// Core - Constants
import Constants from '../../../core/constants';
import MyNetworkConst from '../../../core/constants/my-network.const';

// Components
import BarChart from "./components/bar-chart/bar-chart.component";
import PieChartComponent from './components/pie-chart/pie-chart.component';

// Libraries
import Select, { components } from "react-select";
import React, { useEffect, useState } from 'react';
import { t } from "i18next";

// Services
import { getTokenService, getUserLoggedService, setTotalMembersService, validateSessionService } from '../../../services/authentication.services';
import { getFirstYearMemberRecord, reportAffiliateIncome, reportCurrentOccupation } from '../../../services/reports.service';
import { getFilesFromApiFiles, getMyNetworkSummaryService } from '../../../services/my-network.service';

// Share
import ErrorToastComponent from '../../../shared/components/toast/error-toast/error-toast.component';

// Styles
import './reports.page.scss';

const currentYear = new Date().getFullYear();
const INITIAL_STATE = {
    data: [],
    isActive: 0,
    isNotActive: 0,
    leaders: 0,
    level: 0,
    totalMembers: 0,
    range: {
        from: {
            month: undefined,
            year: undefined
        },
        to: {
            month: undefined,
            year: undefined
        }
    },
    report: '', 
    pieData: [],
    noData: false,
    loading: false,
    isLoading: true,
    isSee: false
}
const MONTHS = [
    { value: 0, label: t("reportsPage.barChart.range.labels.january") },
    { value: 1, label: t("reportsPage.barChart.range.labels.february") },
    { value: 2, label: t("reportsPage.barChart.range.labels.march") },
    { value: 3, label: t("reportsPage.barChart.range.labels.april") },
    { value: 4, label: t("reportsPage.barChart.range.labels.may") },
    { value: 5, label: t("reportsPage.barChart.range.labels.june") },
    { value: 6, label: t("reportsPage.barChart.range.labels.july") },
    { value: 7, label: t("reportsPage.barChart.range.labels.august") },
    { value: 8, label: t("reportsPage.barChart.range.labels.september") },
    { value: 9, label: t("reportsPage.barChart.range.labels.october") },
    { value: 10, label: t("reportsPage.barChart.range.labels.november") },
    { value: 11, label: t("reportsPage.barChart.range.labels.december") }];

let years = [{ value: currentYear, label: currentYear },];

const ReportsPage = () => {
    const [state, setState] = useState(INITIAL_STATE);
    const { 
        range, 
        data, 
        isActive, 
        isNotActive, 
        leaders,
        totalMembers,
        level, 
        report, 
        pieData, 
        noData,
        loading,
        isLoading,
        isSee
    } = state;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        fillYearSelector();
        await fetchData()

        return () => setState(INITIAL_STATE)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [INITIAL_STATE])
    
    async function fetchData() {

        let token = getTokenService()
        let userLogged = getUserLoggedService()
        if (userLogged && userLogged.idUser && token) {
            const objectRequest = { uid: userLogged.idUser }
            await Promise.allSettled(
                [
                    validateSessionService(token),
                    getMyNetworkSummaryService(objectRequest, token)
                ]
            ).then(async (responses) => {
                let overviewInformativeNetworkByUser = {}
                for (let index = 0; index < responses.length; index++) {
                    const elementResponse = responses[index];
                    if (elementResponse.status === Constants.AppConst.FULFILLED) {
                        switch (index) {
                            case 0:
                                const { totalMembers } = elementResponse.value
                                setTotalMembersService(totalMembers)
                                overviewInformativeNetworkByUser.totalMembers = totalMembers
                                break;

                            case 1:
                                let value = elementResponse.value
                                overviewInformativeNetworkByUser.level = value.levels
                                overviewInformativeNetworkByUser.leaders = value.leaders
                                overviewInformativeNetworkByUser.isNotActive = value.usersInactive
                                break;

                            default:
                                break;
                        }
                    }
                }

                const { report, pieData } = await createPieData();
                setState({
                    ...state,
                    ...overviewInformativeNetworkByUser,
                    report,
                    pieData,
                    isLoading: false,
                })
            })
        }
    }

    const fillYearSelector = async () => {
        const token = getTokenService();
        const {idUser} = getUserLoggedService();
        years = [{ value: currentYear, label: currentYear },]
        const firstRegister = await getFirstYearMemberRecord(idUser, token) || currentYear;
        for (let i = currentYear - 1; i >= firstRegister; i--) {
            years.unshift({ value: i, label: i })
        }
    };

    const createAnalytics = async () => {
        try {
            const token = getTokenService();
            const { from, to } = range;

            if ((from.year === to.year && from.month <= to.month) || (from.year < to.year)) {
                const { statistics, isActive } = await reportAffiliateIncome(
                    from.year,
                    from.month + 1,
                    to.year,
                    to.month + 1,
                    token
                );
                let data = [];
                if (statistics.reduce((a, b) => a + b.value, 0) !== 0) {
                    statistics.forEach(item => {
                        data.push({
                            name: MONTHS[item.month - 1].label,
                            year: item.year,
                            value: item.value
                        })
                    })
                }
                setState({ 
                    ...state, 
                    data, 
                    isActive, 
                    noData: !data.length, 
                    loading: false,
                    isSee: false
                });
            } else {
                throw new Error(t('reportsPage.invalidRange'));
            }
        } catch (error) {
            setState({
                ...state,
                isSee: false
            })
            ErrorToastComponent({
                title: error.message,
                position: Constants.AlertConst.TOP_END_POSITION_TEXT,
                timer: Constants.AlertConst.TIMER_SHORT,
                iconHtml: {
                    src: Assets.SharedIcons.icon_alert_error,
                    alt: "icon_alert_error"
                },
                iconColor: "transparent"
            });
        }
    };

    const createPieData = async () => {
        try {
            const token = getTokenService();
            const { idUser } = getUserLoggedService();
            const data = await reportCurrentOccupation(token);
            const url = await getFilesFromApiFiles({ typeFile: MyNetworkConst.CURRENT_OCCUPATION_TYPE, uid: idUser }, token);
            return {

                report: url,
                pieData: [
                    { color: '#5F3EF2', value: data.employee, id: 'employee' },
                    { color: '#FF0058', value: data.unemployed, id: 'unemployed' },
                    { color: '#F7d468', value: data.entrepreneur, id: 'entrepreneur' },
                    { color: '#9389B1', value: data.businessman, id: 'businessman' }
                ]
            }
        } catch (error) {
            ErrorToastComponent({
                title: `${error.code} ${error.message}`,
                position: Constants.AlertConst.TOP_END_POSITION_TEXT,
                timer: Constants.AlertConst.TIMER_SHORT,
                iconHtml: {
                    src: Assets.SharedIcons.icon_alert_error,
                    alt: "icon_alert_error"
                },
                iconColor: "transparent"
            })
        }
    };

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <img
                    src={Assets.HomeIcons.icon_dropdown_arrow}
                    alt="icon_dropdown_arrow"
                    style={
                        props.selectProps.menuIsOpen ?
                            { transform: "rotate(180deg)" } : null
                    }
                />
            </components.DropdownIndicator>
        );
    };

    const ReportSummary = () => {
        return (
            <div className="grid-y align-middle reports__summary">
                <div className="reports__summary__totalMembers">
                    <span>{totalMembers} {t("reportsPage.summary.members")}</span>
                </div>
                <div className="grid-x align-middle grid-container reports__summary__card">
                    <button onClick={() => null} className="small-4 text-center reports__summary__card__content reports__summary__card__content__box">
                        <label className="align-middle reports__summary__card__box">
                            {level}
                        </label>
                        <div className='reports__summary__card__content__types-network'>
                            <img className="reports__summary__card__content__icon-info" src={Assets.HomeIcons.icon_info} alt="icon_info" />
                            <label className="reports__summary__card__text">
                                {t("reportsPage.summary.card.levels")}
                            </label>
                        </div>
                    </button>
                    <button onClick={() => null} className="small-4 text-center reports__summary__card__content reports__summary__card__content__box">
                        <label className="reports__summary__card__box">
                            {leaders}
                        </label>
                        <div className='reports__summary__card__content__types-network'>
                            <img className="reports__summary__card__content__icon-info" src={Assets.HomeIcons.icon_info} alt="icon_info" />
                            <label className="reports__summary__card__text">
                                {t("reportsPage.summary.card.leaders")}
                            </label>
                        </div>
                    </button>
                    <button onClick={() => null} className="small-4 text-center reports__summary__card__content">
                        <label className="align-middle reports__summary__card__box">
                            {isNotActive}
                        </label>
                        <div className='reports__summary__card__content__types-network'>
                            <img className="reports__summary__card__content__icon-info" src={Assets.HomeIcons.icon_info} alt="icon_info" />
                            <label className="reports__summary__card__text">
                                {t("reportsPage.summary.card.inactive")}
                            </label>
                        </div>
                    </button>
                </div>
            </div>
        )
    };

    const MembersOccupation = () => {

        const getPercentage = (value) => {
            const total = pieData.reduce((a, b) => a + b.value, 0);
            if (total === 0) return 0;
            return Math.round((value * 100) / total);
        };

        return (
            <div className='grid-y align-middle reports__pieChart-container'>
                <div className='grid-x reports__pieChart-container__header'>
                    <div className='grid-y reports__pieChart-container__header__title-container'>
                        <span className='reports__pieChart-container__header__title-container__title'>{t("reportsPage.pieChart.title")}</span>
                        <span className='reports__pieChart-container__header__title-container__description'>{t("reportsPage.pieChart.description")}</span>
                    </div>
                    <div className='reports__pieChart-container__header__button-container'>
                        <a target="_blank"  rel="noreferrer" href={report} className={`grid-x align-center-middle reports__pieChart-container__header__button-container__${report? 'download':'download--disabled'}`}>
                            <img src={Assets.HomeIcons.icon_download_active} alt='icon_download' />
                            <span className='align-center-middle reports__pieChart-container__header__button-container__download__text'>{t("reportsPage.pieChart.download")}</span>
                        </a>
                    </div>
                </div>
                <div className='grid-x reports__pieChart-container__card'>
                    <div className='grid-y align-center-middle reports__pieChart-container__card__pie-chart'>
                        <PieChartComponent data={pieData} />
                    </div>
                    <div className='reports__pieChart-container__card__analytics'>
                        <div className='reports__pieChart-container__card__analytics__header'>
                            <span className='reports__pieChart-container__card__analytics__header__title'>{t("reportsPage.pieChart.analytics.title")}</span>
                        </div>
                        <div className='grid-y align-center-middle reports__pieChart-container__card__analytics__statistics'>
                            {
                                pieData.map((data, index) => (
                                    <div key={index} className='grid-x small-3 align-middle reports__pieChart-container__card__analytics__statistics__item'>
                                        <div className='grid-x align-middle'>
                                            <div style={{ backgroundColor: data.color }} className='reports__pieChart-container__card__analytics__statistics__item__icon' />
                                            <span className='reports__pieChart-container__card__analytics__statistics__item__text'>
                                                {t(`reportsPage.pieChart.analytics.${data.id}`)}
                                            </span>
                                        </div>
                                        <span className='reports__pieChart-container__card__analytics__statistics__item__percentage'>%{getPercentage(data?.value)}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div className='grid-y align-middle reports'>
            <span className="reports__network-title">{t("reportsPage.summary.title")}</span>
            <span className="reports__network-line"/>
            <div className='grid-y align-middle reports__general-network'>
            {isLoading ? 
                (
                    <div className='reports__barChart-container__loading'>
                        <img
                            src={Assets.SharedIcons.icon_loading}
                            alt="icon_loading"
                        />
                    </div>
                ): (
                        <>
                            <ReportSummary />
                            <MembersOccupation />
                        </>
                )
            }
            </div>
            <span className="reports__network-title">{t("reportsPage.affiliateSpecific.title")}</span>
            <span className="reports__network-line"/>
            <div className='grid-y reports__barChart-container'>
                <div className='grid-x align-middle reports__barChart-container__header'>
                    <div className='grid-y'>
                        <span className='reports__barChart-container__header__title'>{t("reportsPage.barChart.title")}</span>
                        <span className='reports__barChart-container__header__subtitle'>{t("reportsPage.barChart.subtitle")}</span>
                    </div>
                    <button
                        onClick={() => {
                            setState({ ...state, loading: true, isSee:true })
                            createAnalytics()
                        }}
                        disabled={!(
                            range.from.month !== undefined &&
                            range.from.year !== undefined &&
                            range.to.month !== undefined &&
                            range.to.year !== undefined
                        ) || isSee}
                        className='reports__barChart-container__header__button'
                    >
                        {t("reportsPage.barChart.button")}
                    </button>
                </div>
                <div className='grid-y reports__barChart-container__barChart'>
                    <div className='grid-x small-3 reports__barChart-container__barChart__range'>
                        <div className='grid-x small-6'>
                            <div className='small-6 reports__barChart-container__barChart__range__label'>
                                <span>{t("reportsPage.barChart.range.labelFrom")}</span>
                            </div>
                            <div className='grid-y small-6'>
                                <div className='small-6'>
                                    <Select
                                        className="reports__barChart-container__barChart__range__select"
                                        options={MONTHS}
                                        placeholder={t("reportsPage.barChart.month")}
                                        onChange={({ value }) => setState({ ...state, range: { ...state.range, from: { ...state.range.from, month: value } } })}
                                        isSearchable={false}
                                        components={{
                                            DropdownIndicator,
                                        }}
                                    ></Select>
                                </div>
                                <div className='small-6'>
                                    <Select
                                        className="reports__barChart-container__barChart__range__select"
                                        options={years}
                                        placeholder={t("reportsPage.barChart.year")}
                                        onChange={({ value }) => {setState({ ...state, range: { ...state.range, from: { ...state.range.from, year: value } } })}}
                                        isSearchable={false}
                                        components={{
                                            DropdownIndicator,
                                        }}
                                    ></Select>
                                </div>
                            </div>
                        </div>
                        <div className='grid-x small-6'>
                            <div className='small-6 reports__barChart-container__barChart__range__label'>
                                <span>{t("reportsPage.barChart.range.labelTo")}</span>
                            </div>
                            <div className='grid-y small-6'>
                                <div className='small-6'>
                                    <Select
                                        className="reports__barChart-container__barChart__range__select"
                                        options={MONTHS}
                                        placeholder={t("reportsPage.barChart.month")}
                                        onChange={({ value }) => setState({ ...state, range: { ...state.range, to: { ...state.range.to, month: value } } })}
                                        isSearchable={false}
                                        components={{
                                            DropdownIndicator,
                                        }}
                                    ></Select>
                                </div>
                                <div className='small-6'>
                                    <Select
                                        className="reports__barChart-container__barChart__range__select"
                                        options={years}
                                        placeholder={t("reportsPage.barChart.year")}
                                        onChange={({ value }) => setState({ ...state, range: { ...state.range, to: { ...state.range.to, year: value } } })}
                                        isSearchable={false}
                                        components={{
                                            DropdownIndicator,
                                        }}
                                    ></Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid-y small-9 justify-content-end align-middle '>
                        {loading
                            ? <div className='reports__barChart-container__loading'>
                                <img
                                    src={Assets.SharedIcons.icon_loading}
                                    alt="icon_loading"
                                />
                            </div>
                            : data.length !== 0
                                ? <BarChart data={data} />
                                : <div className='grid-y align-middle reports__barChart-container__preview'>
                                    {noData
                                        ? <span className='reports__barChart-container__preview__title'>{t("reportsPage.barChart.preview.noData")}</span>
                                        : <>
                                            <span className='reports__barChart-container__preview__title'>{t("reportsPage.barChart.preview.title")}</span>
                                            <span className='reports__barChart-container__preview__description'>{t("reportsPage.barChart.preview.description")}</span>
                                        </>
                                    }
                                </div>
                        }
                    </div>
                </div>
                <div className='grid-x reports__barChart-container__counters'>
                    <div className="small-6 grid-y">
                        <span className={`reports__barChart-container__counters__members__${data.length !==0 ? 'count' : 'count--disabled'}`}>{isActive}</span>
                        <span className={`reports__barChart-container__counters__members__${data.length !==0 ? 'label' : 'label--disabled'}`}>{t("reportsPage.barChart.totalMembers")}</span>
                    </div>
                    <div className="small-6 grid-y">
                        <span className={`reports__barChart-container__counters__defections__${data.length !==0 ? 'count' : 'count--disabled'}`}>{isNotActive}</span>
                        <span className={`reports__barChart-container__counters__defections__${data.length !==0 ? 'label' : 'label--disabled'}`}>{t("reportsPage.barChart.totalDefections")}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportsPage;