// Constants - Endpoints
import UserInvitationSettingEndPoints from "./constants/user-invitation-setting.end-points";

// Infrastructures - constants
import Http from "./infrastructure/http.infrastructure";

//Models - DTO
import { mapperToUserModel } from "../models/user/dto/user.dto.dao";

export async function getUserFirstLevelService(uid, token) {
    try {
        const { data: responseUser, error } = await Http.get(UserInvitationSettingEndPoints.GET_USER_FIRST_LEVEL, uid, token);
        if (responseUser && responseUser.status) {
            const response = mapperToUserModel(responseUser.data);
            return response;
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}

export async function updateUserFirstLevelService(body, token) {

    let change = {}
    for (const value in body.userDTO) {
        if (body.userDTO[value] !== undefined) {
            change[value] = body.userDTO[value];
        }
    };
    try {
        const { data: responseUpdateUser, error } = await Http.put(UserInvitationSettingEndPoints.UPDATE_USER_FIRST_LEVEL, { uid: body.uidUserSelected, ...change }, token);
        if (responseUpdateUser && responseUpdateUser.status) {
            return responseUpdateUser.status;
        } else {
            throw error;
        }
    } catch (error) {
        throw error
    }
}