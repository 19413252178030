// Components
import ContactListComponent from './components/contact-list/contact-list.component';
import ViewContactComponent from './components/view-contact/view-contact.component';

// Libraries
import React from 'react'
import { connect } from 'react-redux';

const ContactListPage =  (props) => {

  const { 
    //Variables
    isViewContact
  } = props

  return (
    <div>
    { isViewContact ?
      <ViewContactComponent/>
      :
      <ContactListComponent/>
    }
    </div>
  );
};

const mapStateToProps = ({ TabbarReducer }) => {
  const { isViewContact } = TabbarReducer;
  return {
    isViewContact
  };
};

export default connect(mapStateToProps,null)(ContactListPage);