// Actions
import {
  setManyRolesAndGroupByUserAuthenticationReducer,
  setStateAuthenticationReducer
} from './../../../storage/reducers/authentication/authentication.actions'

// Assets
import { Assets } from '../../../assets';

// Components - Shared
import ErrorToastComponent from '../../../shared/components/toast/error-toast/error-toast.component';

// Constants - core
import Constants from '../../../core/constants';

//Libraries
import Countdown from 'react-countdown';
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import PinInput from 'react-pin-input';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';

//Styles
import './validate-code.page.scss'

//Utils
import { validateCode } from '../../../utils/validate-recovery-password.utils';


// Services
import { 
  clearValidateCode,
  isValidateCode,
  loginService,
  setTokenService, 
  setUserLoggedService, 
  setValidateCode, 
  validateCodeService 
} from '../../../services/authentication.services';
import HeaderLogoPage from '../header-logo/header-logo.page';
import { createErrorInternalApp } from '../../../models/aggregates/build-error-internal-app/build-error-internal-app.dao';
import AuthenticationErrorsConst from '../../../core/constants/errors/authentication/authentication-error.const';

const ValidateCodePage = (props) => {

  const {
    // Actions
    setManyRolesAndGroupByUserAuthenticationReducer,
    setStateAuthenticationReducer,
    // Variables
    isMemberGuest
  } = props

  const INITIAL_STATE = {
    isUserGuest: true,
    countdownState: false,
    time: Date.now() + 120000,
    refValidateCodeGenerated: undefined
  } 
  const[state, setState]= useState(INITIAL_STATE)
  const { isUserGuest, countdownState, time, refValidateCodeGenerated } = state;

  const [t] = useTranslation("translation");

  let history = useNavigate();
  const { state: { usrEmail }} = useLocation();

  useEffect(() => {
    setState((s) => ({
      ...s,
      isUserGuest: isMemberGuest,
    }));
    return () => { setStateAuthenticationReducer('isMemberGuest', false) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const refValidateCodeGenerated = isValidateCode()
    if (!refValidateCodeGenerated?.uidCode || refValidateCodeGenerated?.uidCode === "") {
      const error = createErrorInternalApp(AuthenticationErrorsConst.errorGettingVerificationCode)
      ErrorToastComponent({
        title: `${error.code} ${error.message}`,
        position: Constants.AlertConst.TOP_END_POSITION_TEXT,
        timer: Constants.AlertConst.TIMER_MEDIUM,
        iconHtml: {
          src: Assets.SharedIcons.icon_alert_error,
          alt: "icon_alert_error"
        },
        iconColor: "transparent"
      })
      history("/login", { replace: true })
    } else {
      setState((s) => ({
        ...s,
        refValidateCodeGenerated
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      code: "",
    },
    initialErrors: {
      code: "",
    },
    validate: validateCode,
    onSubmit: () => {
      processValidateCode();
    },

  });

  const processValidateCode = () => {
    validateCodeService({
      uidCode: refValidateCodeGenerated.uidCode,
      code: values.code
    })
      .then((res) => {
        const { objectResponse, token } = res
        if (Array.isArray(objectResponse)) {
          const roleAndGroupByUser = [...objectResponse]
          
          switch (roleAndGroupByUser.length) {
            case 0:
              let error = createErrorInternalApp(AuthenticationErrorsConst.rolesAndGroupsIsEmpty)
              ErrorToastComponent({
                title: `${error.code} ${error.message}`,
                position: Constants.AlertConst.TOP_END_POSITION_TEXT,
                timer: Constants.AlertConst.TIMER_MEDIUM,
                iconHtml:{
                  src: Assets.SharedIcons.icon_alert_error,
                  alt: "icon_alert_error"
                },
                iconColor: "transparent"
              })
              break;

            default:
              clearValidateCode();
              setManyRolesAndGroupByUserAuthenticationReducer(roleAndGroupByUser)
              setTokenService(token)
              history("/groupsSelection", { replace: true });
              break;
          }
        } else {
          setTokenService(token)
          setUserLoggedService(objectResponse)
          setManyRolesAndGroupByUserAuthenticationReducer([])
          if (objectResponse.isCompletedRegister) {
            clearValidateCode();
            history("/network", { replace: true });
          } else {
            clearValidateCode();
            history("/welcome", { replace: true });
          }
        }
      })
      .catch((err) => {
        ErrorToastComponent({
          title: `${err.code} ${err.message}`,
          position: Constants.AlertConst.TOP_END_POSITION_TEXT,
          timer: Constants.AlertConst.TIMER_MEDIUM,
          iconHtml:{
            src: Assets.SharedIcons.icon_alert_error,
            alt: "icon_alert_error"
          },
          iconColor: "transparent"
        })
      })
  }

  const resendCode = async () => {
    setState({
      ...state,
      time: Date.now() + 120000,
      countdownState: true,
    })
    try {
      if (refValidateCodeGenerated) {
        const { uidCode } = await loginService(refValidateCodeGenerated);
        setState((prevState) => ({
          ...prevState,
          refValidateCodeGenerated: {
            ...refValidateCodeGenerated,
            uidCode
          }
        }))
        setValidateCode({
          ...refValidateCodeGenerated,
          uidCode
        });
      }
    } catch (error) {
      setState({
        ...state,
        countdownState: false,
      })
      ErrorToastComponent({
        title: `${error.code} ${error.message}`,
        position: Constants.AlertConst.TOP_END_POSITION_TEXT,
        timer: Constants.AlertConst.TIMER_MEDIUM,
        iconHtml:{
          src: Assets.SharedIcons.icon_alert_error,
          alt: "icon_alert_error"
        },
        iconColor: "transparent"
      })
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <HeaderLogoPage
      />
      <div className="validate-code-page__footer">
        <div className="grid-x validate-code-page__footer__content">
          <div>
            <button type="button">
              <img src={Assets.LoginIcons.icon_user_footer} alt="icon_user_footer" className="validate-code-page__footer__button" />
            </button>
          </div>
          <div className="validate-code-page__footer__line"></div>
          <div>
            <button type="button">
              <img src={Assets.LoginIcons.icon_house_footer} alt="icon_house_footer" className="validate-code-page__footer__button" />
            </button>
          </div>
        </div>
        <div className="validate-code-page__user grid-y align-middle">
          <p className="text-center validate-code-page__user__text">
            {t("authentication.validateCode.mainTitle")}
          </p>
          <p className="text-center validate-code-page__user__text--phone">
            {usrEmail 
              ?`${usrEmail.split('@')[0].substring(0, 4)}xxxxx@${usrEmail.split('@')[1]}`
              : null
            }
          </p>
          {isUserGuest ? (<>
          <p className="text-center validate-code-page__user__text--text">
          {t("authentication.validateCode.canLogin")}
          </p>
          </>
          ) : (<>
            <p className="text-center validate-code-page__user__text--text">
          {t("authentication.validateCode.textLogin")}
          </p>
          </>
          )}

          <div className="validate-code-page__pin-input">
            <PinInput
              length={6}
              onChange={(value) => {
                handleChange(`code`)(value);
              }}
              type="numeric"
              inputMode="number"
              style={{
                 display: "flex", 
                 padding: 10 
              }}
              inputStyle={{
                backgroundColor: "$#FFFFFF",
                border: 0,
                borderBottom: "1px solid gray",
                boxShadow: "none",
                height: 25,
                width: 30,
                margin:"0 10px",
                color:"#371f7a"
              }}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              focus={true}
            />
            {errors.code && (
              <p className="validate-code-page__input__error">{errors.code}</p>
            )}
          </div>
          <div className="validate-code-page__button__container grid-y small-2">
            <span className="small-4 text-center validate-code-page__user__text--mod mb-1">
              {t("authentication.validateCode.textResend")}
            </span>
            {countdownState 
              ? <div className='small-5 grid-x align-center-middle text-center validate-code-page__countdown'>
                <span className="validate-code-page__countdown__text-container">
                  {t("authentication.validateCode.countdown")}
                </span>
                <Countdown
                  key={time}
                  date={time}
                  autoStart={true}
                  onComplete={() => setState((prevState) => ({ ...prevState, countdownState: false }))}
                  renderer={({ minutes, seconds }) => (
                    <span>
                      {`${minutes < 10
                        ? "0" + minutes
                        : minutes
                        }:${seconds < 10
                          ? "0" + seconds
                          : seconds
                        }`}
                    </span>
                  )}
                />
              </div>
              : <button
                onClick={() => resendCode()}
                disabled={countdownState}
                type="button"
                className="small-5 text-center validate-code-page__user__text--mood"
              >
                {t("authentication.validateCode.resend")}
              </button>
            }
          </div>
                <button
                type="submit"
                className="validate-code-page__button"
                disabled={Object.keys(errors).length !== 0}
              >
               {isUserGuest ?(<>
                  {t("authentication.validateCode.activateAccount")}
                </>
               ) : (
                <>
                  {t("authentication.validateCode.loginButton")}
                </>
               )}
              </button>
              <div className="validate-code-page__button__container">
                <button 
                  type="button" 
                  className="validate-code-page__button__text"
                  onClick={() => {
                    clearValidateCode();
                    history("/login", { replace: true })
                  }}
                >
                  {t("authentication.validateCode.back")}
                </button>
              </div>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = ({ AuthenticationReducer }) => {
  const {
    refValidateCodeGenerated, isMemberGuest
  } = AuthenticationReducer

  return {
    refValidateCodeGenerated,
    isMemberGuest
  }
}

const mapStateToPropsActions = {
  setManyRolesAndGroupByUserAuthenticationReducer,
  setStateAuthenticationReducer
}

export default connect(mapStateToProps, mapStateToPropsActions)(ValidateCodePage)