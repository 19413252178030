//Libraries
import React, { useEffect } from 'react';
/**
 * @license
 * Copyright 2019 Google LLC. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */
import { Loader } from "@googlemaps/js-api-loader";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

// Styles
import "./google-map.component.scss"

let google;
let map;
let marker;
let geocoder;
let cluster;
// let poly;
// let circle;

export const initMap = async(apiKey) => {
    try {
        const loader = new Loader({
            apiKey: apiKey,
            version: "weekly",
        })
        google = await loader.load();
        geocoder = new google.maps.Geocoder();

        return {
            isLoaded: true,
            loadError: false
        };
    } catch (error) {
        return {
            isLoaded: false,
            loadError: error
        }
    }
}

export const GoogleMap = (props) => {
    const {
        children
    } = props;

    useEffect(() => {
        map = new google.maps.Map(document.getElementById("map"), {
            center: new google.maps.LatLng(0, 0),
            zoom: 5,
            streetViewControl: false,
            mapTypeControl: false,
            disableDefaultUI: true,
            gestureHandling: "greedy"
        })
    }, [])

    useEffect(() => {
        if (!!children?.length) {
            children.forEach(child => {
                if (child) {
                    child.type({ ...child.props, map })
                }
            });
        } else {
            if (marker) {
                marker.setMap(null);
            }
            children?.type({ ...children.props, map });
        }
    }, [children])

    return (
        <div id="map" className='google-map' />
    )
}

export const Marker = (props) => {
    const {
        //Variables
        position,
        draggable = false,
        // Functions
        onDragEnd = () => null
    } = props;

    marker = new google.maps.Marker({
        position: position,
        map: map,
        draggable: draggable
    });

    marker.addListener('dragend', (e) => onDragEnd({ lat: e.latLng.lat(), lng: e.latLng.lng() }));

    return marker;
}

export const MarkerCluster = (props) => {
    const {
        locations = [],
    } = props;

    if (!!locations.length) {
        const markers = locations.map((position, i) => {
            const marker = Marker({ position })
            return marker;
        });

        cluster = new MarkerClusterer({ markers, map });
    }

}

export const getAddress = async (location, zoom = 5) => {
    try {
        const { results } = await new Promise((resolve, reject) => {
            geocoder.geocode({ 'location': location }, (results, status) => {
                if (status === 'OK') {
                    map?.setZoom(zoom);
                    map?.setCenter(results[0].geometry.location);
                    resolve({ results });
                } else {
                    reject(new Error(`Geocode was not successful for the following reason: ${status}`));
                }
            });
        });

        return results[0];
    } catch (error) {
        throw error;
    }
};
export const getHomeAddress = async (location, zoom = 5) => {
    const { results } = await geocoder.geocode({ 'location': location }, (results, status) => {
        if (status === 'OK') {
            map.setCenter(results[0].geometry.location);
            return results;
        } else {
            throw new Error(`Geocode was not successful for the following reason: ${status}`);
        }
    });

    return results[0];
}

export const getLocation = async (address, zoom = 8) => {
    const { results } = await geocoder.geocode({ 'address': address, componentRestrictions: { country: 'CO' } }, (results, status) => {
        if (status === 'OK') {
            map.setZoom(zoom);
            map.setCenter(results[0].geometry.location);
            // printPolygon(results[0].geometry.viewport);
            // printCircle(results[0].geometry.location, results[0].geometry.viewport)
            return results;
        } else {
            throw new Error(`Geocode was not successful for the following reason: ${status}`)
        }
    });

    return results[0];
}

export const getHomeLocation = async (address, zoom = 12) => {
    const { results } = await geocoder.geocode({ 'address': address }, (results, status) => {
        if (status === 'OK') {
            map.setZoom(zoom);
            map.setCenter(results[0].geometry.location);
            return results;
        } else {
            throw new Error(`Geocode was not successful for the following reason: ${status}`)
        }
    });

    return results[0];
}

export const cleanMap = () => {
    if (cluster) {
        cluster.setMap(null)
    }
    if (marker) {
        marker.setMap(null);
    }
}

// const printPolygon = (viewport) => {
//     const { eb, Ha } = viewport;
//     if (poly) {
//         poly.setMap(null);
//     }
//     poly = new google.maps.Polygon({
//         path: [
//             new google.maps.LatLng(eb.hi, Ha.lo),
//             new google.maps.LatLng(eb.hi, Ha.hi),
//             new google.maps.LatLng(eb.lo, Ha.hi),
//             new google.maps.LatLng(eb.lo, Ha.lo),
//         ],
//         strokeColor: "blue",
//         strokeOpacity: 0.2,
//         strokeWeight: 3,
//         fillColor: "transparent",
//         map: map
//     });
// };


// const printCircle = (center, viewport) => {
//     const { eb, Ha } = viewport;
//     const longitude = distancePoints({lat:eb.lo, lng: Ha.lo},{lat:eb.hi, lng: Ha.hi})

//     if (circle) {
//         circle.setMap(null);
//     }
//     circle = new google.maps.Circle({
//         strokeColor: "blue",
//         strokeOpacity: 0.2,
//         strokeWeight: 3,
//         fillColor: "transparent",
//         map,
//         center: center,
//         radius: longitude / 2
//     });
// };


// const distancePoints = (p1, p2) => {
//     const rad = (x) => x * Math.PI / 180;
//     const RADIO_EARTH = 6378.137;

//     var dLat = rad(p2.lat - p1.lat);
//     var dLong = rad(p2.lng - p1.lng);

//     var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
//     var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

//     return (RADIO_EARTH * c).toFixed(3) * 1000;

// };