//Actions
import { setStateMyNetworkReducer } from "../../../../../storage/reducers/my-network/my-network.actions";

// Assets
import { Assets } from "../../../../../assets/index";

//Components
import PreviewDocumentComponent from "../view-profile/components/preview-document/preview-document.component";

// Core - Constants
import MyNetworkConst from "../../../../../core/constants/my-network.const";
import Constants from "../../../../../core/constants";

// Libraries
import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

// Services
import { getFilesFromApiFiles } from "../../../../../services/my-network.service";
import { getTokenService } from "../../../../../services/authentication.services";

// Share
import ErrorToastComponent from "../../../../../shared/components/toast/error-toast/error-toast.component";

// Styles
import "./work-profile.component.scss";

//Utils
import { getAffirmDenyUtils, getFormatUtils } from "../../../../../utils/complete-profile.utils";

const WorkProfileComponent = (props) => {
    
    const { 
        //Actions
        setStateMyNetworkReducer, 
        //Variables
        jobProfileUser,
        user
    } = props;
    const [t] = useTranslation("translation");
    const INITIAL_STATE = {
        curriculum : "",
        optionFormat: [],
        optionAffirmDeny: []
    }
    const [state, setState] = useState(INITIAL_STATE)
    const {curriculum, optionFormat, optionAffirmDeny } = state

    useEffect(() => {
        getAffirmDenyUtils().then(({ option }) => {
            setState((s) => ({
              ...s,
              optionAffirmDeny: option,
            }));
          })
      
          getFormatUtils().then(({ options }) => {
            setState((s) => ({
              ...s,
              optionFormat: options,
            }));
          })
    }, [])

    useEffect(() => {
        async function getCurriculum() {
            try {
                const token = getTokenService();
                const url = await getFilesFromApiFiles({ typeFile: jobProfileUser?.lookingJob ? MyNetworkConst.CURRICULUM_TYPE : MyNetworkConst.PORTFOLIO_TYPE, uid: user }, token);
                setState((s) => ({...s,curriculum:url}));
            } catch (error) {
                ErrorToastComponent({
                    title: `${error.code} ${error.message}`,
                    position: Constants.AlertConst.TOP_END_POSITION_TEXT,
                    timer: Constants.AlertConst.TIMER_MEDIUM,
                    iconHtml: {
                        src: Assets.SharedIcons.icon_alert_error,
                        alt: "icon_alert_error"
                    },
                    iconColor: "transparent"
                })
            }
        }
        (jobProfileUser?.lookingJob || jobProfileUser?.format === 0) && getCurriculum();
        return () => setState((s) =>({...s,curriculum:""}));
    }, [user, jobProfileUser]);

    return (
        <div className="work-profile">
            <div className="work-profile__content">
                <label className="work-profile__title">{t("home.myNetwork.workProfile.educationLevel")}</label>
                <div className='work-profile__description'>
                    <span>{jobProfileUser?.educationalLevel}</span>
                </div>
                <label className="work-profile__title">{t("home.myNetwork.workProfile.profession")}</label>
                <div className='work-profile__description'>
                    <span>{jobProfileUser?.profession}</span>
                </div>
                <label className="work-profile__title">{t("home.myNetwork.workProfile.presentOccupation")}</label>
                <div className='work-profile__description'>
                    <span>{jobProfileUser?.currentOccupation}</span>
                </div>
                { jobProfileUser?.currentOccupation !== t("home.myNetwork.workProfile.entrepreneur") &&
                    <>
                        <label className="work-profile__title">
                            { jobProfileUser?.currentOccupation === t("home.myNetwork.workProfile.employee") && t("home.myNetwork.workProfile.nameOfCompany")}
                            { jobProfileUser?.currentOccupation === t("home.myNetwork.workProfile.unemployed") && t("home.myNetwork.workProfile.nameOfLastCompany")}
                            { jobProfileUser?.currentOccupation === t("home.myNetwork.workProfile.businessman") && t("home.myNetwork.workProfile.companyName")}
                        </label>
                        <div className='work-profile__description'>
                            <span>{jobProfileUser?.companyName}</span>
                        </div>
                    </>
                }

                { jobProfileUser?.currentOccupation !== t("home.myNetwork.workProfile.businessman") &&
                    <>
                        <label className="work-profile__title">{t("home.myNetwork.workProfile.timeOfExperience")}</label>
                        <div className='work-profile__description'>
                            <span>{jobProfileUser?.timeExperience}</span>
                        </div>
                    </>
                }

                { jobProfileUser?.currentOccupation === t("home.myNetwork.workProfile.unemployed") &&
                    <>
                        <label className="work-profile__title">{t("home.myNetwork.workProfile.typeOfOccupation")}</label>
                        <div className='work-profile__description'>
                            <span>{jobProfileUser?.typeOccupation}</span>
                        </div>
                    </>
                }

                { (jobProfileUser?.currentOccupation === t("home.myNetwork.workProfile.employee") || jobProfileUser?.currentOccupation === t("home.myNetwork.workProfile.unemployed")) &&
                    <>
                        <label className="work-profile__title">{t("home.myNetwork.workProfile.searchWork")}</label>
                        <div className='work-profile__description'>
                            <span>{(optionAffirmDeny && (jobProfileUser?.lookingJob || jobProfileUser?.lookingJob === false)) ? (jobProfileUser?.lookingJob ? optionAffirmDeny[0] : optionAffirmDeny[1]) : null}</span>
                        </div>
                        { jobProfileUser?.lookingJob &&
                            <button
                                onClick={() =>{
                                    setStateMyNetworkReducer("isModalPreview",true)
                                }}
                                className="grid-x justify-content-between work-profile__btn--view-pdf"
                            >
                                <label className="work-profile__title-pdf">{t("home.myNetwork.workProfile.viewResume")}</label>
                                <img
                                    src={Assets.ViewProfileIcons.icon_pdf}
                                    alt="icon_pdf"
                                />
                            </button>
                        }
                    </>
                }

                { jobProfileUser?.currentOccupation === t("home.myNetwork.workProfile.businessman") &&
                    <>
                        <label className="work-profile__title">{t("home.myNetwork.workProfile.located")}</label>
                        <div className='work-profile__description'>
                            <span>{jobProfileUser?.located}</span>
                        </div>
                        <label className="work-profile__title">{t("home.myNetwork.workProfile.economicSector")}</label>
                        <div className='work-profile__description'>
                            <span>{jobProfileUser?.economicSector}</span>
                        </div>
                    </>
                }

                { (jobProfileUser?.currentOccupation === t("home.myNetwork.workProfile.entrepreneur") || jobProfileUser?.currentOccupation === t("home.myNetwork.workProfile.businessman")) &&
                    <>
                        <label className="work-profile__title">{t("home.myNetwork.workProfile.descriptionServices")}</label>
                        <div className='work-profile__description work-profile__description--mod work-profile__info'>
                            <textarea disabled={true} className="work-profile__services work-profile__content-textarea" defaultValue={jobProfileUser?.descriptionServices}/>
                        </div>
                        <label className="work-profile__title">{t("home.myNetwork.workProfile.portfolio")}</label>
                        <div className='work-profile__description'>
                            <span>{optionFormat && (jobProfileUser?.portfolio || jobProfileUser?.portfolio === false) ? (jobProfileUser?.portfolio ? optionAffirmDeny[0] : optionAffirmDeny[1]): null}</span>
                        </div>
                        { jobProfileUser?.portfolio &&
                            <>
                                <label className="work-profile__title">{t("home.myNetwork.workProfile.format")}</label>
                                <div className='work-profile__description'>
                                    <span>{(jobProfileUser?.format || jobProfileUser?.format === 0) && optionFormat ? (optionFormat[jobProfileUser?.format]) : null}</span>
                                </div> 
                                { (jobProfileUser?.format || jobProfileUser?.format === 0) &&(
                                    jobProfileUser?.format === 0 ?
                                    <>
                                            <button
                                                onClick={() =>{
                                                    setStateMyNetworkReducer("isModalPreview",true)
                                                }}
                                                className="grid-x justify-content-between work-profile__btn work-profile__btn--view-pdf"
                                            >
                                                <label className="work-profile__title-pdf">{t("home.myNetwork.workProfile.viewPortfolio")}</label>
                                                <img
                                                    src={Assets.ViewProfileIcons.icon_pdf}
                                                    alt="icon_pdf"
                                                />
                                            </button>
                                    </>
                                    :
                                    <>
                                        <label className="work-profile__title">{t("home.myNetwork.workProfile.portfolioLink")}</label>
                                        <div className='work-profile__description'>
                                            <a
                                                className="work-profile__title-pdf"
                                                href={
                                                    jobProfileUser?.portfolioLink.startsWith("http://") || jobProfileUser?.portfolioLink.startsWith("https://") ? 
                                                    jobProfileUser?.portfolioLink : `http://${jobProfileUser?.portfolioLink}`
                                                }
                                            >
                                            
                                                {jobProfileUser?.portfolioLink}
                                            </a>
                                        </div>
                                    </>
                                  )
                                }
                            </>
                        }
                            <label className="work-profile__title">{t("home.myNetwork.workProfile.socialNetworks")}</label>
                            <div className='work-profile__description'>
                                <span>{(jobProfileUser?.socialNetworks || jobProfileUser?.socialNetworks === false) && optionAffirmDeny ? ( jobProfileUser?.socialNetworks ? optionAffirmDeny[0] : optionAffirmDeny[1]): null}</span>
                            </div> 
                            { jobProfileUser?.socialNetworks &&
                                <>
                                    <label className="work-profile__title">{t("home.myNetwork.workProfile.linkSocialNetworks")}</label>
                                    <div className='work-profile__description'>
                                        <a 
                                            className="work-profile__title-pdf"
                                            href={
                                                jobProfileUser?.linkSocialNetworks.startsWith("http://") || jobProfileUser?.linkSocialNetworks.startsWith("https://") ? 
                                                jobProfileUser?.linkSocialNetworks : `http://${jobProfileUser?.linkSocialNetworks}`
                                            }>
                                            {jobProfileUser?.linkSocialNetworks}
                                        </a>
                                    </div>
                                </>
                            }
                    </>
                }
            </div>
            <PreviewDocumentComponent pdf={curriculum} />
        </div>
    )
}
  
const mapStateToPropsActions = {
  setStateMyNetworkReducer
};
  
export default connect(null, mapStateToPropsActions)(WorkProfileComponent);
