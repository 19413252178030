class UserModel {
    
    constructor(
        idUser, 
        name, 
        lastName, 
        photoProfile, 
        documentNumber, 
        documentType, 
        phoneNumber,
        phoneWhatsapp, 
        email, 
        groupAssociated, 
        role, 
        userRoot = undefined,
        isStateActive,
        overviewNetwork = "", 
        isCompletedRegister = undefined, 
        dateBirth = undefined, 
        residencePlace = undefined, 
        votingStation = undefined, 
        country = undefined, 
        department = undefined, 
        municipality = undefined
    ) {
      this.idUser = idUser;
      this.name = name;
      this.lastName = lastName;
      this.photoProfile = photoProfile;
      this.documentNumber = documentNumber;
      this.documentType = documentType;
      this.phoneNumber = phoneNumber;
      this.phoneWhatsapp = phoneWhatsapp;
      this.email = email;
      this.groupAssociated = groupAssociated;
      this.role = role;
      this.userRoot = userRoot;
      this.isStateActive = isStateActive;
      this.overviewNetwork = overviewNetwork;
      this.isCompletedRegister = isCompletedRegister;
      this.dateBirth = dateBirth;
      this.residencePlace = residencePlace;
      this.votingStation = votingStation;
      this.country = country;
      this.department = department;
      this.municipality = municipality;
    }
    
  }

  export default UserModel;