//Assets
import { Assets } from '../../../assets'

// Libraries
import React from 'react'
import Modal from 'react-modal';

// Styles
import './loading.component.scss';


const LoadingComponent = (props) => {
const {loading, loadingText} = props
    return (
        <Modal
        isOpen={loading}
        ariaHideApp={false}
        className="react-modal"
        >
        <div className="align-center-middle grid-y react-modal__container">
          <div className="loading-spinner">
            <img 
              src={Assets.SharedIcons.icon_loading} 
              alt="icon_loading"
            />
          </div>
          <div className="align-center-middle react-modal__container__text">
            {loadingText}
          </div>
        </div>
      </Modal>
    )

}

export default LoadingComponent;