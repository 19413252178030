//Style
import './view-contact-skeleton.component.scss'

const ViewContactSkeletonComponent = (props) =>{
return(
    <div className="view-contact-skeleton__container">
            <div className="view-contact__target">
                <div className="view-contact__box">
                    <div
                        
                    />
                    <div className="view-contact-skeleton__box__content-info">
                        <div className="view-contact-skeleton__box__content-info__content-user">
     
                        </div>
                            <div className="view-contact-skeleton__box__content-info__box-text"> 
                            </div>
                        
                            <div className="grid-x small-4 view-contact-skeleton__box__content-info__content-buttons">
                                <div>
                                    <div className="view-contact-skeleton__box__content-info__content-buttons__text">   
                                    </div>
                                </div>
                                <div>
                                    <div className="view-contact-skeleton__box__content-info__content-buttons__text">   
                                    </div>
                                </div>
                            </div>
                        
                    </div>
                </div>
                <div className="view-contact-skeleton__box-contacts">
                    <div className="view-contact-skeleton__box-contacts__content-info">
                        <div className="view-contact-skeleton__box-contacts__content-info__title"></div>
                    </div>
                    
                </div>
                <div className="view-contact-skeleton__box-contacts">
                    <div className="view-contact-skeleton__box-contacts__content-info">
                        <div className="view-contact-skeleton__box-contacts__content-info__title"></div>
                    </div>
                    
                </div>
                <div className="view-contact-skeleton__box-contacts">
                    <div className="view-contact-skeleton__box-contacts__content-info">
                        <div className="view-contact-skeleton__box-contacts__content-info__title"></div>
                      
                    </div>
                    
                </div>
            </div>
        </div>
    )
};
export default ViewContactSkeletonComponent;

