// Constants - core
import InfrastructureErrorsConst from "../../core/constants/errors/infrastructure/alerts-error.const";

// Translation
import string from "../../shared/translations/I18n";

// Utils
import { extractAcronymAPIErrorCode } from "../authentication.utils";
import { conversionCodesAPIAuthentication } from "./authentication-conversion-codes.utils";

export function conversionCodeAPI(codeAssociate) {
    const acronymCode = extractAcronymAPIErrorCode(codeAssociate);
    switch (acronymCode) {
        case "US":
        case "AU":
            return conversionCodesAPIAuthentication(codeAssociate)

        default:
            return {
                message: string.t(`${InfrastructureErrorsConst.responseErrorAxiosFound}.message`),
                description: ""
            }
    }
}