// Assets
import { Assets } from '../assets'

// Constants - core
import Constants from '../core/constants'

// Libraries
import { t } from 'i18next'

// Services
import { validateSessionService } from '../services/authentication.services'
import { getChildrenByUserService, getTotalMembersAndLeadersService } from '../services/my-network.service'

const memberInformation = [
    {
        isActive: true,
        isRegistered: true,
        type: t("myNetworkPage.modalInformation.members.summary.leader.type"),
        description: t("myNetworkPage.modalInformation.members.summary.leader.description"),
    },
    {
        isActive: true,
        isRegistered: true,
        type: t("myNetworkPage.modalInformation.members.summary.member.type"),
        description: t("myNetworkPage.modalInformation.members.summary.member.description"),

    },
    {
        isActive: false,
        isRegistered: true,
        icon: Assets.HomeIcons.icon_alert_inactive,
        type: t("myNetworkPage.modalInformation.members.summary.inactive.type"),
        description: t("myNetworkPage.modalInformation.members.summary.inactive.description")

    },
    {
        isActive: false,
        isRegistered: false,
        icon: Assets.HomeIcons.icon_pending,
        type: t("myNetworkPage.modalInformation.members.summary.pending.type"),
        description: t("myNetworkPage.modalInformation.members.summary.pending.description"),

    },
    {
        isActive: false,
        isRegistered: false,
        icon: Assets.HomeIcons.icon_prohibited,
        type: t("myNetworkPage.modalInformation.members.summary.rejected.type"),
        description: t("myNetworkPage.modalInformation.members.summary.rejected.description"),

    },
]

const firstWorkFlowCommonUpdateDataApplication = async (uidMember, token, isReportError = false) => {

  let objectResponse = {
    totalMembersUserLogged: 0,
    firstLevelData: {
      memberByLevel: [],
      overviewNetwork: {
        totalChildren: 0, 
        totalLeaders: 0
      }
    },
    overviewInformativeNetworkByUser: {
      totalLevels: 0,
      totalLeaders: 0,
      totalUsersInactive: 0
    }
  }
  const objectRequest = { uid: uidMember }

  await Promise.allSettled(
    [
      validateSessionService(token),
      getChildrenByUserService(objectRequest, token),
      getTotalMembersAndLeadersService(objectRequest, token)
    ]
  )
    .then((responses) => {
      for (let indexRequest = 0; indexRequest < responses.length; indexRequest++) {
        const elementResponse = responses[indexRequest];
        if (elementResponse.status === Constants.AppConst.FULFILLED) {
          switch (indexRequest) {
            case 0:
              const { totalMembers } = elementResponse.value
              objectResponse.totalMembersUserLogged = totalMembers
              break;

            case 1:
              objectResponse.firstLevelData.memberByLevel = [...elementResponse.value.memberByLevel]
              break;

            case 2:
              objectResponse.firstLevelData.overviewNetwork = { ...elementResponse.value }
              break;   

            default:
              break;
          }
        }
      }
    })
    .catch((err) => {
      if (isReportError) throw err
    })
  return objectResponse
}

const secondWorkFlowCommonUpdateDataApplication = async (uidMember, token, isReportError = false) => {

  let objectResponse = {
    dataLevelDetermined: {
      memberByLevel: [],
      overviewNetwork: {
        totalChildren: 0, 
        totalLeaders: 0
      }
    }
  }
  const objectRequest = { uid: uidMember }

  await Promise.allSettled([
    getChildrenByUserService(objectRequest, token),
    getTotalMembersAndLeadersService(objectRequest, token)
  ]).then((responses) => {
    for (let indexRequest = 0; indexRequest < responses.length; indexRequest++) {
      const elementResponse = responses[indexRequest];
      if (elementResponse.status === Constants.AppConst.FULFILLED) {
        switch (indexRequest) {
          case 0:
            objectResponse.dataLevelDetermined.memberByLevel = [...elementResponse.value.memberByLevel]
            break;

          case 1:
            objectResponse.dataLevelDetermined.overviewNetwork = { ...elementResponse.value }
            break;
            
          default:
            break;
        }
      }
    }
  })
    .catch((err) => {
      if (isReportError) throw err
    })
  return objectResponse
}

export {
    memberInformation,
    firstWorkFlowCommonUpdateDataApplication,
    secondWorkFlowCommonUpdateDataApplication
}