// Libraries
import React from 'react'
import { useTranslation } from "react-i18next";

// Styles
import "./household-information.component.scss";

const HouseholdInformationComponent = (props) => {

    const { homeDataUser } = props;
    
    const [t] = useTranslation("translation");
    
    return (
        <div className="household-information">
            <div className='household-information__content'>
                <label className="household-information__title">
                    {t("home.myNetwork.householdInformation.department")}
                </label>
                <div className='household-information__description'>
                    <span>{homeDataUser?.department}</span>
                </div>
                <label className="household-information__title">
                    {t("home.myNetwork.householdInformation.municipality")}
                </label>
                <div className='household-information__description'>
                    <span>{homeDataUser?.municipality}</span>
                </div>
                <label className="household-information__title">
                    {t("home.myNetwork.householdInformation.neighborhood")}
                </label>
                <div className='household-information__description'>
                    <span>{homeDataUser?.neighborhood}</span>
                </div>
                <label className="household-information__title">
                    {t("home.myNetwork.householdInformation.address")}
                </label>
                <div className='household-information__description'>
                    <span>{homeDataUser?.address}</span>
                </div>
                <label className="household-information__title">
                    {t("home.myNetwork.householdInformation.typeOfHouse")}
                </label>
                <div className='household-information__description'>
                    <span>{homeDataUser?.typeOfHousing}</span>
                </div>
                <label className="household-information__title">
                    {t("home.myNetwork.householdInformation.stratum")}
                </label>
                <div className='household-information__description'>
                    <span>{homeDataUser?.stratum}</span>
                </div>
                <label className="household-information__title">
                    {t("home.myNetwork.householdInformation.numberOfPerson")}
                </label>
                <div className='household-information__description'>
                    <span>{homeDataUser?.peopleLiveYou}</span>
                </div>
            </div>
        </div>
    )
}

export default HouseholdInformationComponent;