// Libraries
import React from 'react'
import { useTranslation } from "react-i18next";

// Styles
import "./voting-information.component.scss";

const VotingInformationComponent = (props) => {

    const { votingDataUser } = props;
    
    const [t] = useTranslation("translation");
    
    return (
        <div className="voting-information">
            <div className="voting-information__content">
                <label className="voting-information__title">{t("home.myNetwork.votingInformation.department")}</label>
                <div className='voting-information__description'>
                    <span>{votingDataUser?.department}</span>
                </div>
                <label className="voting-information__title">{t("home.myNetwork.votingInformation.municipality")}</label>
                <div className='voting-information__description'>
                    <span>{votingDataUser?.municipality}</span>
                </div>
                <label className="voting-information__title">{t("home.myNetwork.votingInformation.votingStation")}</label>
                <div className='voting-information__description'>
                    <span>{votingDataUser?.votingBooth}</span>
                </div>
                <label className="voting-information__title">{t("home.myNetwork.votingInformation.votingTable")}</label>
                <div className='voting-information__description'>
                    <span>{votingDataUser?.votingTable}</span>
                </div>
            </div>
        </div>
    )
}

export default VotingInformationComponent;